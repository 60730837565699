import React from "react";
import "./SelectableButton.css";

function SelectableButton({ selected, setSelected, label, onSuccess= ()=>null }) {
  return (
    <div
      className={selected ? "active-container" : "button-container"}
      onClick={() => {
        setSelected((prev) => !prev);
        onSuccess();
      }}
    >
      <p>{label}</p>
    </div>
  );
}

export default SelectableButton;
