import Modal from "@mui/material/Modal";
import "./SignoutCard.css";

export default function SignoutCard({
  signoutModalOpen,
  closeSignoutModal,
  signoutUser,
}) {
  return (
    <Modal open={signoutModalOpen} onClose={closeSignoutModal}>
      <div className="modal-default modal-signout">
        <h3 className="signout-heading">Signout</h3>
        <p>Are you sure you want to sign out?</p>
        <div className="signout-actions">
          <span onClick={closeSignoutModal}>close</span>
          <span onClick={signoutUser}>signout</span>
        </div>
      </div>
    </Modal>
  );
}
