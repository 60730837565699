import { SET_LISTING_STAGE_POINTER, SET_LISTING_CATEGORY, listingCategories } from "../constants"

export default function listingReducer(state={
    currentStagePointer:1,
    category:listingCategories.BOARDING_CENTER,
},action={}){
    switch(action.type){
        case SET_LISTING_STAGE_POINTER:
            return{
                ...state,
                currentStagePointer:action.payload
            }
        case SET_LISTING_CATEGORY:
            return{
                ...state,
                category:action.payload
            }
        default:
            return state
    }
}