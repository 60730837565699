import { combineReducers } from "redux";
import adminReducer from "./adminReducer";
import listingReducer from "./listingReducer";
import uiReducer from "./uiReducer";
import splootFinderReducer from "./splootFinderReducer";
import userReducer from "./userReducer";
import universalReducer from "./universalReducer";
import discussReducer from "./discussReducer";
import salesReducer from "./salesReducer";
import eventsReducer from "./eventsReducer";

const rootReducer = combineReducers({
  admin: adminReducer,
  user: userReducer,
  universal: universalReducer,
  discuss: discussReducer,
  ui: uiReducer,
  listing: listingReducer,
  splootFinder: splootFinderReducer,
  sales: salesReducer,
  events: eventsReducer
});

export default rootReducer;
