import { Button, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from "@mui/styles"
import { createContext, forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { fetchDistrictsHandler, getWalksByFilter, updateWalkerPenalty } from './apis';
import "react-phone-number-input/style.css";
import SalesWalkCard from '../../components/SalesWalkCard/SalesWalkCard';
import SalesEditWalkCard from '../../components/SalesEditWalkCard/SalesEditWalkCard';
import { defaultCountry, EditWalkMode, SalesCity, EPOCH, WalkerCancellationReason, WalkerCompletionReason, walkerFilterType, WalkStatus, SubscriptionTypes, WalkCancellationFilter, TodayStartEpoch, TomorrowStartEpoch, WalkingType, WalkingTrialType, HubStatus, WalkRequestStatus } from '../../redux/constants';
import { getCustomCities, salesTriggerNotifications, updateWalk } from '../../redux/dataFetchers/salesDashboardApis';
import { getWalkersByFilter } from '../../components/SalesEditWalkCard/apis';
import SalesWalkDetailsModal from '../../components/SalesWalkCard/SalesWalkDetailsModal';
import SalesWalkUserDetailsModal from '../../components/SalesWalkCard/SalesWalkUserDetailsModal';
import { useLoadScript } from '@react-google-maps/api';
import env from "../../environment/production.json";
import SalesLiveWalkModal from '../../components/SalesWalkCard/SalesLiveWalkModal';
import './Walks.css';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import usePaginated from '../usePaginatedData';
import { MediaUploadLoaderContext } from '../../App';
import SelectableButton from '../../common/SelectableButton/SelectableButton';
import { request } from "../../utils/apiRequest";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import AutoCompleteInput from '../../common/AutoCompleteInput/AutoCompleteInput';
import DateTimePickerComponent from '../../components/DateTimePickerComponent/DateTimePickerComponent';
import ModalTitle from '../../common/ModalTitle/ModalTitle';
import PhoneInput from "react-phone-number-input";
import { getStates } from '../../redux/dataFetchers/appUserApis';
import { setActionNotif } from '../../redux/actions';
import StatisticsOverview from './StatisticsOverview';
import { exportHandler } from '../../redux/dataFetchers/common';
import { SubscriptionsContext } from '../Users/Users';
import SalesSubscribeUserCard from '../../components/SalesSubscribeUserCard/SalesSubscribeUserCard';
import { useLocation } from "react-router-dom"
import { getHubs } from '../apis';

export const notificationsType = {
  USER_UNREACHABLE: "(U) User is not reachable",
  WALKER_LATE: "(U) Walker will be late",
  WALK_REMINDER: "(W) Remind walker for the walk"
}


const CustomerPhoneCustomInputElement = forwardRef((props, ref) => (
  <TextField
    {...props}
    required
    id={`outlined-basic-${Math.random()}`}
    label="Customer Phone"
    variant="outlined"
    fullWidth
    inputRef={ref}
  />
));

const WalkerPhoneCustomInputElement = forwardRef((props, ref) => (
  <TextField
    {...props}
    required
    id={`outlined-basic-${Math.random()}`}
    label="Walker Phone"
    variant="outlined"
    fullWidth
    inputRef={ref}
  />
));

const classes = {
  btnDefault: {
    boxShadow: "0px 3px 2px 1px gainsboro",
    backgroundColor: "#454545",
    color: "white",
    fontWeight: "bold",
    borderRadius: "40px"
  },
  checkBox: { color: "#974EC3" },
  walkConfirmModal: { display: "flex", flexDirection: 'column' },
}

export const WalksContext = createContext();

export default function Walks() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isLeaveParam = !!query.get('isLeave')
  const walkerIdParam = query.get('walkerId');
  const startDateParam = +query.get('startDate');
  const endDateParam = +query.get('endDate');
  const requestId = query.get('requestId');

  useEffect(() => {
    if (isLeaveParam === false) {
      setWalkerSelected({});
      reloadWalks()
    }
  }, [isLeaveParam]);

  const [pageNo, setPageNo] = useState(0)
  const { sales: { accessToken, customCities }, user: { states } } = useSelector(state => state)
  const [filterSlotStartTime, setFilterSlotStartTime] = useState(requestId ? null : (startDateParam || TodayStartEpoch));
  const [filterSlotEndTime, setFilterSlotEndTime] = useState(requestId ? null : (endDateParam || TomorrowStartEpoch));
  const [subCitySelected, setSubCitySelected] = useState("");
  const [walkers, setWalkers] = useState([]);
  const [isReasonTextfieldShow, setIsReasonTextfieldShow] = useState(false);
  const [walkerSelected, setWalkerSelected] = useState(walkerIdParam ? { id: walkerIdParam
  } : {});
  const [isStatisticsVisible, setIsStatisticsVisible] = useState(false)
  const [isExporting, setIsExporting] = useState(false);
  const [filterWalkStatus, setFilterWalkStatus] = useState([]);
  const [walkingSessionsType, setWalkingSessionsType] = useState("");
  const [filterUserPhoneNumber, setFilterUserPhoneNumber] = useState("");
  const [filterWalkerPhoneNumber, setFilterWalkerPhoneNumber] = useState("");
  const [isStatisticsReload, setIsStatisticsReload] = useState(false);
  const [editWalkModal, setEditWalkModal] = useState(false)
  const [walkDetailsModal, setWalkDetailsModal] = useState(false)
  const [triggerNotificationModal, setTriggerNotificationModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState("");
  const [userDetailsModal, setUserDetailsModal] = useState(false)
  const [liveWalkModal, setLiveWalkModal] = useState(false);
  const [currentWalk, setCurrentWalk] = useState(null);
  const [cityFilter, setCityFilter] = useState('');
  const [isDuplicateWalk, setIsDuplicateWalk] = useState(false);
  const [delayedWalks, setDelayedWalks] = useState(false);
  const [replacedWalks, setReplacedWalks] = useState(false);
  const [unassignedWalkerWalks, setUnassignedWalkerWalks] = useState(false);
  const [ascendingOrder, setAscendingOrder] = useState(true);
  const [selectedDay, setSelectedDay] = useState({ today: true, yesterday: false, tomorrow: false, custom: false, mtd: false });
  const [cancelWalkConfirmModal, setCancelWalkConfirmModal] = useState(false)
  const [completeWalkConfirmModal, setCompleteWalkConfirmModal] = useState(false)
  const [subscribeUserModalOpen, setSubscribeUserModalOpen] = useState(false)
  const [walkCompletionType, setWalkCompletionType] = useState("");
  const [PenaltyAppliedToWalkerWalks, setPenaltyAppliedToWalkerWalks] = useState(false);
  const [walkCompletionReason, setWalkCompletionReason] = useState("");
  const [cancelReasonFilter, setCancelReasonFilter] = useState([])
  const [subscriptionType, setSubscriptionType] = useState()
  const [trialType, setTrialType] = useState("")
  const [walkerReplacementHistoryModal, setWalkerReplacementHistoryModal] = useState(false)
  const [isEditWalkBackdropDismissible, setIsEditWalkBackdropDismissible] = useState(true);
  const [walkerUnassignedReason, setWalkerUnassignedReason] = useState("")
  const [unassignedWalkConfirmModal, setUnassignWalkConfirmModal] = useState()
  const [addPenaltyModal, setAddPenaltyModal] = useState(false);
  const [removePenaltyModal, setRemovePenaltyModal] = useState(false);
  const dispatch = useDispatch();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [walkCancellationReason, setWalkCancellationReason] = useState("");
  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(MediaUploadLoaderContext);

  const [districts, setDistricts] = useState([]);
  const [pincodes, setPincodes] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [locationFilters, setLocationFilters] = useState({});
useEffect(() => console.log(walkerIdParam ? walkerSelected : "no walker id param", 'walker selected'), [])
  const openEditWalkModal = () => setEditWalkModal(true);
  const closeEditWalkModal = (event, reason) => {
    if (!isEditWalkBackdropDismissible && reason && reason === "backdropClick") return;
    setIsEditWalkBackdropDismissible(true);
    setEditWalkModal(false);
    setIsDuplicateWalk(false);
  }
  const openCancelWalkConfirmModal = () => setCancelWalkConfirmModal(true);
  const closeCancelWalkConfirmModal = () => setCancelWalkConfirmModal(false);

  const openUnassignWalkConfirmModal = () => setUnassignWalkConfirmModal(true);
  const closeUnassignWalkConfirmModal = () => setUnassignWalkConfirmModal(false);

  const openAddPenaltyConfirmModal = () => setAddPenaltyModal(true);
  const closeAddPenaltyConfirmModal = () => setAddPenaltyModal(false);

  const openRemovePenaltyConfirmModal = () => setRemovePenaltyModal(true);
  const closeRemovePenaltyConfirmModal = () => setRemovePenaltyModal(false);

  const openCompleteWalkConfirmModal = () => setCompleteWalkConfirmModal(true);
  const closeCompleteWalkConfirmModal = () => setCompleteWalkConfirmModal(false);

  const openSubscribeUserModal = () => setSubscribeUserModalOpen(true);
  const closeSubscribeUserModal = () => setSubscribeUserModalOpen(false);

  const openWalkDetailsModal = () => setWalkDetailsModal(true);
  const closeWalkDetailsModal = () => setWalkDetailsModal(false);
  const openUserDetailsModal = () => setUserDetailsModal(true);
  const closeUserDetailsModal = () => setUserDetailsModal(false);
  const openLiveWalkModal = () => setLiveWalkModal(true);
  const closeLiveWalkModal = () => setLiveWalkModal(false);
  const openWalkerReplacementHistoryModal = () => setWalkerReplacementHistoryModal(true);
  const initGetWalkersWithMetrics = async (page, limit) => {
    let walkerId = walkerSelected?.id;
    if (filterWalkerPhoneNumber) {
      walkerId = searchWalkerWithPhoneNumber(filterWalkerPhoneNumber)?.id
    }

    try {
      return await getWalksByFilter({
        accessToken,
        dispatch,
        slotStartTime: filterSlotStartTime,
        slotEndTime: filterSlotEndTime,
        subCityId: subCitySelected?.id,
        delayed: delayedWalks,
        replaced: replacedWalks,
        sortAscending: ascendingOrder,
        unassignedWalker: unassignedWalkerWalks,
        walkerId,
        userPhoneNumber: filterUserPhoneNumber,
        cityFilter,
        status: filterWalkStatus,
        stateCode: locationFilters?.["stateCode"]?.code,
        districtId: locationFilters?.["districtId"]?.id,
        pinCode: locationFilters?.["pinCode"],
        walkingSessionsType: walkingSessionsType?.toUpperCase(),
        subscriptionType,
        trialType,
        cancelReasonFilter,
        page,
        limit,
        hubIds:locationFilters?.hubs?.length ? locationFilters.hubs?.map(hub => hub.id) : [],
        cityCode:locationFilters?.cities?.length ? locationFilters.cities?.map((city)=>city.code) : [],
        isLeave: isLeaveParam,
        isPenalty: PenaltyAppliedToWalkerWalks,
        requestId
      });
    } catch (error) {
      console.log(error);
    }
  }
  const { loading: loadingWalks, paginatedData: walks, hasMore, setLoading: setLoadingWalks, totalDataCount: totalCount } = usePaginated(
    pageNo,
    initGetWalkersWithMetrics
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loadingWalks) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          console.log("Intersected")
          setPageNo((prev) => prev + 1)
          setLoadingWalks(true)
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingWalks, hasMore, dispatch]
  );

  const useStyles = makeStyles({
    input: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    },
  });

  const UIClasses = useStyles();

  const searchWalkerWithPhoneNumber = (walkerPhoneNumber) => {
    let walker = walkers.find((walker) => walker.phoneNumber == walkerPhoneNumber);

    return walker;
  }

  const handleDiscardFilters = () => {
    setSelectedDay({ today: true, yesterday: false, tomorrow: false, custom: false, mtd: false });
    setFilterSlotStartTime(TodayStartEpoch);
    setFilterSlotEndTime(TomorrowStartEpoch);
    setWalkerSelected({});
    setFilterUserPhoneNumber("");
    setFilterWalkerPhoneNumber("");
    setCityFilter("");
    setWalkingSessionsType("")
    setTrialType("")
    setSubscriptionType("")
    setTrialType();
    setFilterWalkStatus([]);
    setAscendingOrder(false);
    setDelayedWalks(false);
    setLocationFilters({});
    setReplacedWalks(false);
    setCancelReasonFilter([]);
    reloadWalks();
    setUnassignedWalkerWalks(false);
  }

  const openTriggerNotificationModal = (walk) => {
    setCurrentWalk(walk);
    setTriggerNotificationModal(true);
  }
  const closeTriggerNotificationModal = () => setTriggerNotificationModal(false);

  const handleWalkClick = (walk) => {
    if([WalkRequestStatus.FINDING, WalkRequestStatus.CREATED].includes(walk.currentRequest?.status)){
      dispatch(setActionNotif({
        open: true,
        message: `A ${walk.currentRequest?.type} request is already placed for this walk! Force stop the request to place a new one`,
        severity: "error",
      }))
      return;
    }
    setCurrentWalk(walk)
    openEditWalkModal()
  }

  const handleLiveViewClick = (walk) => {
    setCurrentWalk(walk);
    openLiveWalkModal();
  }

  const handleTriggerNotification = () => {
    dispatch(salesTriggerNotifications(currentWalk, selectedNotification));
    closeTriggerNotificationModal();
  }


  const reloadWalks = () => {
    setPageNo(0);
    setLoadingWalks(true)
    isStatisticsVisible && setIsStatisticsReload(!isStatisticsReload);
  }

  const walksContextValue = {
    closeEditWalkModal,
    reloadWalks,
  }

  const subscriptionContextValue = {
    setLoadingParentData: reloadWalks
  }

  const cancelWalkClickHandler = (walk) => {
    setCurrentWalk(walk)
    openCancelWalkConfirmModal()
    setIsDuplicateWalk(false);
  }

  const completeWalkClickHandler = (walk) => {
    setCurrentWalk(walk);
    openCompleteWalkConfirmModal();
    setIsDuplicateWalk(false);
  }

  const convertToSubscriptionClickHandler = (walk) => {
    setCurrentWalk(walk);
    openSubscribeUserModal();
  }

  const walkDetailsClickHandler = (walk) => {
    setCurrentWalk(walk)
    openWalkDetailsModal()
  }

  const walkerReplacementHistoryClickHandler = (walk) => {
    setCurrentWalk(walk)
    openWalkerReplacementHistoryModal()
  }

  const userDetailsClickHandler = (walk) => {
    setCurrentWalk(walk)
    openUserDetailsModal()
  }

  const cancelWalkSuccessHandler = () => {
    setWalkCancellationReason("")
    closeMediaUploadLoader();
    closeCancelWalkConfirmModal()
    reloadWalks()
  }

  const completeWalkSuccessHandler = () => {
    closeMediaUploadLoader();
    closeCompleteWalkConfirmModal()
    reloadWalks()
  }

  const updateWalkClickHandler = (walkStatus = WalkStatus.CANCELLED) => {
    openMediaUploadLoader()
    let bodyObject = { status: walkStatus }
    switch (walkStatus) {
      case WalkStatus.COMPLETED:
        bodyObject = {
          startTime: currentWalk.scheduledAt,
          status: WalkStatus.MANUALLY_COMPLETED,
          completionType: walkCompletionType,
          completionReason: walkCompletionReason
        }
        break;
      case WalkStatus.CANCELLED:
        bodyObject = { ...bodyObject, updateType: EditWalkMode.WALK_CANCELLED, cancellationReason: walkCancellationReason }
        setWalkCancellationReason("");
        setIsReasonTextfieldShow(false);
        break;
    }

    dispatch(updateWalk({ userId: currentWalk?.user?.id, walkId: currentWalk?.id, body: bodyObject, onSuccess: walkStatus === WalkStatus.COMPLETED ? completeWalkSuccessHandler : cancelWalkSuccessHandler }))
  }

  const unassignedWalkerClickhandler = async () => {
    try {
      openMediaUploadLoader()
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          walkerUnassignedReason
        }),
      };

      await request(
        `${nodeServerBaseUrl}/users/${currentWalk.user.id}/walking-sessions/${currentWalk.id}/unassigned`,
        requestOptions
      )
      closeUnassignWalkConfirmModal();
      reloadWalks();
      closeMediaUploadLoader();
    } catch (err) {
      console.log(err)
      closeMediaUploadLoader();
      return dispatch(setActionNotif({
        open: true,
        message: "Something went wrong !",
        severity: "error",
      }));
    }
  }

  const exportButtonHandler = async () => {
    try {
      openMediaUploadLoader()
      setIsExporting(true);
      // if(!filterWalkStatus) {
      //   closeMediaUploadLoader();
      //   setIsExporting(false);
      //   return dispatch(setActionNotif({
      //     open: true,
      //     message: `Select Walk Status First !`,
      //     severity: "error",
      // }));
      // }
      
      if(filterSlotEndTime - filterSlotStartTime > 4*EPOCH.ONE_MONTH_MS) {
        closeMediaUploadLoader();
        setIsExporting(false);
        return dispatch(setActionNotif({
          open: true,
          message: `You can't export more than 4 months data !`,
          severity: "error",
        }));
      }

      let walkerId = walkerSelected?.id;
      if (filterWalkerPhoneNumber) {
        walkerId = searchWalkerWithPhoneNumber(filterWalkerPhoneNumber)?.id
      }

      const data = await getWalksByFilter({
        accessToken,
        dispatch,
        slotStartTime: filterSlotStartTime,
        slotEndTime: filterSlotEndTime,
        subCityId: subCitySelected?.id,
        delayed: delayedWalks,
        replaced: replacedWalks,
        sortAscending: ascendingOrder,
        walkerId,
        walkingSessionsType: walkingSessionsType.toUpperCase(),
        userPhoneNumber: filterUserPhoneNumber,
        cityFilter,
        status: filterWalkStatus,
        stateCode: locationFilters?.["stateCode"]?.code,
        districtId: locationFilters?.["districtId"]?.id,
        pinCode: locationFilters?.["pinCode"],
        walkingSessionsType: walkingSessionsType?.toUpperCase(),
        subscriptionType,
        trialType,
        cancelReasonFilter,
        exportData: true,
        limit: 0,
        page: 0,
        isLeave: isLeaveParam,
        isPenalty: PenaltyAppliedToWalkerWalks,
        hubIds:locationFilters?.hubs?.length ? locationFilters.hubs?.map(hub => hub.id) : [],
        cityCode:locationFilters?.cities?.length ? locationFilters.cities?.map((city)=>city.code) : [],
      });

      // const exportedArray = [];

      // let startDate = new Date(filterSlotStartTime).toLocaleDateString("en-us", {
      //   month: "long",
      //   day: "numeric"
      // })

      // let endDate = new Date(filterSlotEndTime).toLocaleDateString("en-us", {
      //   month: "long",
      //   day: "numeric"
      // });

      // data.data.map((walk) => {
      //   console.log(walk, 'walk')
      //   let replacedWalkers = walk?.replacedWalkers?.map((walker) => walker?.walker?.name);
      //   exportedArray.push({
      //     trial: walk?.trial,
      //     status: walk?.status,
      //     schedule: new Date(walk?.scheduledAt).toLocaleDateString("en-us", {
      //       year: "numeric",
      //       month: "long",
      //       day: "numeric",
      //       hour: "numeric",
      //       minute: "numeric"
      //     }),
      //     isReplaced: walk?.replacedWalkers?.length > 0 ? true : false,
      //     planCode: walk?.planCode,
      //     replacedWalkers: walk?.replacedWalkers?.length > 0 ? replacedWalkers.join(', ') : '',
      //     slotDuration: parseInt(walk.slotDuration / EPOCH.ONE_MINUTE_MS),
      //     ...(walk.startTime ? {
      //       start: new Date(walk?.startTime).toLocaleTimeString('it-IT', {
      //         hour12: false,
      //         hour: '2-digit',
      //         minute: '2-digit'
      //       })
      //     } : {}),
      //     ...(walk.endTime ? {
      //       end: new Date(walk?.endTime).toLocaleTimeString('it-IT', {
      //         hour12: false,
      //         hour: '2-digit',
      //         minute: '2-digit',
      //       })
      //     } : {}),
      //     walker: walk?.walker?.name,
      //     user: walk?.user?.name,
      //     phoneNumber: walk?.user?.phoneNumber,
      //     city: walk?.user?.cityCode,
      //     "cancel reason": walk?.cancellationReason,
      //     "completion reason": walk?.completionReason,
      //     "feedback": walk?.feedback?.content,
      //     "rating": walk?.feedback?.rating
      //   });
      // });
      // exportHandler(exportedArray, `${filterWalkStatus} Walks Data from ${startDate} to ${endDate}.csv`)
      
      dispatch(setActionNotif({
        open: true,
        message: "Data export scheduled! will arrive at boop@sploot.tech",
        severity: "success",
      }))
      setIsExporting(false);
      closeMediaUploadLoader()
    } catch (error) {
      console.log(error)
      setIsExporting(false);
      closeMediaUploadLoader()
    }

  }

  const getHubsHandler = async ({ cityCodes, status }) => {
    try{
      const data = await getHubs({ cityCodes, status })
      // selecting the city codes
      setHubs(data)
    } catch(error){

    }
  }

  const duplicateWalkClickHandler = (walk) => {
    setCurrentWalk(walk);
    setEditWalkModal(true);
    setIsDuplicateWalk(true)
  }

  const addPenaltyClickHandler = (walk) => {
    setCurrentWalk(walk);
    openAddPenaltyConfirmModal();
  }

  const removePenaltyClickHandler = (walk) => {
    setCurrentWalk(walk);
    openRemovePenaltyConfirmModal()
  }

  const unassignedWalkerClickHandler = (walk) => {
    setCurrentWalk(walk);
    openUnassignWalkConfirmModal();
    setWalkerUnassignedReason("");
    setIsDuplicateWalk(false);
  }

  useEffect(async () => {
    const walkers = await getWalkersByFilter({
      accessToken,
      dispatch,
      filterType: walkerFilterType.SINGLE_WALK
    });
    setWalkers(walkers)
  }, []);

  useEffect(() => {
    reloadWalks();
  },[PenaltyAppliedToWalkerWalks])

  useEffect(() => {
    if (!customCities?.length) {
      dispatch(getCustomCities());
    }    
    getHubsHandler({});
  },[]);

  const removePenaltyBtnClickHandler = async () =>{
    try{
      openMediaUploadLoader()
      await updateWalkerPenalty(dispatch, accessToken, walkerIdParam, false, currentWalk.id);
      closeMediaUploadLoader()
      closeRemovePenaltyConfirmModal()
      reloadWalks()
    } catch (err) {
      closeMediaUploadLoader()
    }
  }

  const addPenaltyBtnlickHandler = async () =>{
    try{
      openMediaUploadLoader()
      await updateWalkerPenalty(dispatch, accessToken, walkerIdParam, true, currentWalk.id);
      closeMediaUploadLoader()
      closeAddPenaltyConfirmModal()
      reloadWalks()
    } catch (err) {
      closeMediaUploadLoader()
    }
  }

  return (
    <SubscriptionsContext.Provider value={subscriptionContextValue}>
      <WalksContext.Provider value={walksContextValue}>
        <div className="sales-container">
          <div className='sales-controls'>
            {<div className='filters-bar'>
              {!(isLeaveParam || requestId) && <div className='walk-filters'>
                <DateTimePickerComponent
                  selectedDay={selectedDay}
                  setSelectedDay={setSelectedDay}
                  selectedStartTime={filterSlotStartTime}
                  setSelectedStartTime={setFilterSlotStartTime}
                  selectedEndTime={filterSlotEndTime}
                  setSelectedEndTime={setFilterSlotEndTime}
                />

                {!filterWalkerPhoneNumber && <AutoCompleteInput
                  optionsList={walkers}
                  selected={walkerSelected?.name || ""}
                  setSelected={setWalkerSelected}
                  value={walkerSelected?.name || ""}
                  validationFunc={(option, value) => option?.name === value || value === ""}
                  findlabelFunc={(option) => option?.name ?? option}
                  label={"Search Walker"}
                />}

                <div className="dash-filter filter">
                  <PhoneInput
                    key="walker-emergency-phone"
                    style={{ width: "100%" }}
                    value={filterUserPhoneNumber}
                    onChange={setFilterUserPhoneNumber}
                    inputComponent={CustomerPhoneCustomInputElement}
                    defaultCountry="IN"
                    className={UIClasses.input}
                  />
                </div>

                <div className="dash-filter filter">
                  <PhoneInput
                    key="walker-emergency-phone"
                    style={{ width: "100%" }}
                    value={filterWalkerPhoneNumber}
                    onChange={setFilterWalkerPhoneNumber}
                    inputComponent={WalkerPhoneCustomInputElement}
                    defaultCountry="IN"
                    className={UIClasses.input}
                  />
                </div>

                {/* <div className="dash-filter filter">
                  <FormControl style={{width: "100%"}}>
                      <InputLabel id="select-input-id">City</InputLabel>
                      <Select
                        labelId="city"
                        id="select-verification"
                        value={cityFilter}
                        style={{ minWidth: "9rem" }}
                        onChange={(event) => setCityFilter(event.target.value)}>
                        {Object.values(SalesCity).map(city =>
                          <MenuItem value={city} key={city}>{city}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                </div> */}

                {!isStatisticsVisible && <div className="dash-filter filter">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="select-input-id">Walk Status</InputLabel>
                    <Select
                      multiple 
                      labelId="walk-status"
                      id="walk-status"
                      value={filterWalkStatus || []}
                      style={{ minWidth: "9rem" }}
                      onChange={(event) =>{ 
                        console.log("event on walk status",event.target.value)
                       return setFilterWalkStatus(event.target.value)}}>
                      {Object.values(WalkStatus).map(status => <MenuItem value={WalkStatus[status]} key={status}>{WalkStatus[status]}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {/* autocomplete ask for permission*/}
                </div>
                }
                {filterWalkStatus?.includes(WalkStatus.CANCELLED) && <div className="dash-filter filter">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="select-input-id" required>
                      Cancel Reason
                    </InputLabel>
                    <Select
                      labelId="select-mui"
                      id="select-mui-id"
                      multiple
                      defaultValue=""
                      value={cancelReasonFilter || []}
                      onChange={(event) => {
                        setCancelReasonFilter(event.target.value);
                      }}
                    >
                      {WalkCancellationFilter?.map(
                        (walkFilter, index) => (
                          <MenuItem
                            value={walkFilter}
                            key={index}
                          >
                            {walkFilter.source} - {walkFilter.reason}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>}
                <div className="dash-filter filter"
                  style={{width:"25rem"}}
                >
                  <Autocomplete
                    id="Custom City"
                    multiple
                    disablePortal
                    options={customCities || []}
                    sx={{ width: "100%" }}
                    value={locationFilters?.cities ?? []}
                    isOptionEqualToValue={(option, value) =>
                        option?.code === value?.code || value === ""
                    }
                    getOptionLabel={(option) => option?.name ?? option}
                    onChange={(event, value) => {
                      setLocationFilters({cities: value })
                      let cityCodes = value?.map(city => city.code)
                      getHubsHandler({ cityCodes })
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="Custom City" />
                    )}
                  />
                </div>

                <div
                  className="dash-filter filter"
                  style={{width:"25rem"}}
                >
                  <Autocomplete
                    id="hubs"
                    multiple
                    options={hubs || []}
                    sx={{ width: "100%" }}
                    value={locationFilters?.hubs ?? []}
                    getOptionLabel={(option) => `${option?.status === HubStatus.NON_SERVICEABLE ? "(U)" : ""} ${option?.name}`}
                    onChange={(event, value) => {
                      setLocationFilters({ ...locationFilters, hubs:value });                     
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="Hub" />
                    )}
                  />
                </div>

                {/* <div
                  className="dash-filter filter"
                >
                  <Autocomplete
                    disablePortal
                    id="districts"
                    options={districts || []}
                    sx={{ width: "100%" }}
                    value={locationFilters?.districtId?.name ?? ""}
                    disableClearable={true}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value.id || value === ""
                    }
                    getOptionLabel={(option) => option?.name ?? option}
                    onChange={(event, value) => {
                      setLocationFilters(prev => { return { ...prev, districtId: value, pinCode: "" } });
                      let pincodes = districts.find(
                        (district) => district.id === value.id
                      ).pincodes;
                      setPincodes(pincodes);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="District" required />
                    )}
                  />
                </div> */}

                {/* <div
                  className="dash-filter filter"

                >
                  <Autocomplete
                    disablePortal
                    id="pinCode"
                    options={pincodes}
                    sx={{ width: "100%" }}
                    value={locationFilters?.pinCode ?? ""}
                    disableClearable={true}
                    isOptionEqualToValue={(option, value) =>
                      option === value || value === ""
                    }
                    getOptionLabel={(option) => option ?? option}
                    onChange={(event, value) => {
                      setLocationFilters(prev => { return { ...prev, pinCode: value } });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Pincode" required />
                    )}
                  />
                </div> */}

                <div className="dash-filter filter">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="select-input-id" required>
                      Walk Type
                    </InputLabel>
                    <Select
                      labelId="select-mui"
                      id="select-mui-id"
                      value={walkingSessionsType}
                      sx={{ width: "100%" }}
                      onChange={(event) => {
                        setWalkingSessionsType(event.target.value)
                      }}
                    >
                      {
                        Object.keys(WalkingType).map((option, index) => {
                          return <MenuItem value={WalkingType[option]} key={index}>
                            {WalkingType[option]}
                          </MenuItem>
                        })
                      }

                    </Select>
                  </FormControl>
                </div>

                {walkingSessionsType === WalkingType.SUBSCRIPTION && <div className="dash-filter filter">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="select-input-id" required>
                      Subscription Type
                    </InputLabel>
                    <Select
                      labelId="select-mui"
                      id="select-mui-id"
                      value={subscriptionType}
                      sx={{ width: "100%" }}
                      onChange={(event) => {
                        setSubscriptionType(event.target.value)
                        setTrialType()
                      }}
                    >
                      {
                        Object.keys(SubscriptionTypes).map((option, index) => {
                          return <MenuItem value={SubscriptionTypes[option]} key={index}>
                            {SubscriptionTypes[option]}
                          </MenuItem>
                        })
                      }

                    </Select>
                  </FormControl>
                </div>}

            {walkingSessionsType === WalkingType.TRIAL && 
                <div className="dash-filter filter">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="select-input-id" required>
                      Trial Type
                    </InputLabel>
                    <Select
                      labelId="select-mui"
                      id="select-mui-id"
                      value={trialType}
                      sx={{ width: "100%" }}
                      onChange={(event) => {
                        setTrialType(event.target.value)
                        setSubscriptionType()
                      }}
                    >
                      {
                        Object.keys(WalkingTrialType).map((option, index) => {
                          return <MenuItem value={WalkingTrialType[option]} key={index}>
                            {WalkingTrialType[option]}
                          </MenuItem>
                        })
                      }

                    </Select>
                  </FormControl>
                </div>
                }

              </div>}
{!(isLeaveParam || requestId) && <>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{ display: "flex", gap: "2rem" }}>
                      <Button variant='contained' style={{backgroundColor:"#974EC3", color: "#fff", marginTop: "2rem", fontWeight: "bold", fontSize: "1rem", width: "7rem"}} onClick={reloadWalks}>
                          Apply
                      </Button>
                      <Button variant='contained' style={{backgroundColor:"#974EC3", color: "#fff", marginTop: "2rem", fontWeight: "bold", fontSize: "1rem", width: "7rem"}} onClick={handleDiscardFilters}>
                          Discard
                      </Button>
                    {!isStatisticsVisible &&  <Button variant='contained' disabled={isExporting} style={{backgroundColor:"#974EC3", color: "#fff", marginTop: "2rem", fontWeight: "bold", fontSize: "1rem", width: "7rem"}} onClick={exportButtonHandler}>
                          {isExporting? "wait...":"Export"}
                    </Button>}
                    {!isStatisticsVisible &&  <div style={{display: "flex", gap: "2rem", marginTop: "2rem"}}>
                    <SelectableButton selected={ascendingOrder} setSelected={setAscendingOrder} label="Ascending Order" />
                    <SelectableButton selected={delayedWalks} setSelected={setDelayedWalks} label="Delayed" />
                    <SelectableButton selected={replacedWalks} setSelected={setReplacedWalks} label="Replaced" />
                    <SelectableButton selected={unassignedWalkerWalks} setSelected={setUnassignedWalkerWalks} label="Unassigned" />
                </div>}
                </div>
                  
                {!isStatisticsVisible && <h3 style={{display: "flex", flexDirection: "column", justifyContent: "end"}}>Count- {totalCount}</h3>}
              </div>

              <div style={{display: "flex",color:"#EF7123", justifyContent: "flex-end", fontWeight:"500"}}>
                <div onClick={()=>setIsStatisticsVisible(!isStatisticsVisible)} style={{display:"flex", justifyContent:"center", alignItems:"center", cursor:"pointer"}}>
                Statistic Overview {isStatisticsVisible?<VisibilityIcon style={{marginLeft:"5px"}}/> : <VisibilityOffIcon style={{marginLeft:"5px"}}/>}
                </div>

              </div>
              </>}
              {!(isLeaveParam) &&  <div style={{display:"flex", justifyContent:"space-between"}}>
                {!requestId && <SelectableButton selected={PenaltyAppliedToWalkerWalks} setSelected={setPenaltyAppliedToWalkerWalks} label="Penalty" />}
                <span style={{display: "flex", flexDirection: "column", justifyContent: "end", fontWeight:"900"}}>Count- {totalCount}</span>
                </div>}
            </div>}
          </div>
          {!isStatisticsVisible ? <>
          <div className="walks-info-header notify walks-heading" key="walks-header">
            <span>User | Pet</span>
            <span>Type</span>
            <span>Scheduled</span>
            <span>Walker Assigned</span>
            <span>Status</span>
            {
              isLeaveParam && <span>Penalty</span>
            }
            <span>Distance/Duration</span>
            <span>Notify</span>
          </div>
          {walks?.map((walk,walkIndex) =>
            <SalesWalkCard walk={walk} key={walk.id}
              ref={walkIndex === walks?.length - 3 ? lastElementRef : null}
              onClick={() => walk.status === WalkStatus.UPCOMING && handleWalkClick(walk)}
              cancelWalkClickHandler={cancelWalkClickHandler}
              completeWalkClickHandler={completeWalkClickHandler}
              duplicateWalkClickHandler={duplicateWalkClickHandler} 
              unassignedWalkerClickHandler = {unassignedWalkerClickHandler}
              addPenaltyClickHandler={addPenaltyClickHandler}
              removePenaltyClickHandler={removePenaltyClickHandler}
              openTriggerNotificationModal={openTriggerNotificationModal}
              walkDetailsClickHandler={walkDetailsClickHandler}
              isLeaveParam={isLeaveParam}
              walkerIdParam={walkerIdParam}
              userDetailsClickHandler={userDetailsClickHandler}
              PenaltyAppliedToWalkerWalks={PenaltyAppliedToWalkerWalks}
              handleLiveViewClick={handleLiveViewClick}
              walkerReplacementHistoryClickHandler={walkerReplacementHistoryClickHandler}
              convertToSubscriptionClickHandler={convertToSubscriptionClickHandler}
            />
          )}
          {loadingWalks && (
            <Loader
              wrapperStyle={{ height: "20vh" }}
              loaderStyle={{ color: "#974EC3" }}
            />
          )}
          <Modal open={editWalkModal} onClose={closeEditWalkModal} >
            <div className="modal-default modal-sales" style={{maxHeight: "46rem"  ,overflowY: "auto"}}>
              <SalesEditWalkCard 
              isLeaveParam={isLeaveParam} 
              defaultEditMode={isDuplicateWalk ? EditWalkMode.DUPLICATE_WALK: false} 
              walk={currentWalk} 
              setIsBackdropDismissible={setIsEditWalkBackdropDismissible} 
              onClose={closeEditWalkModal}
              startDateParam={startDateParam}
              endDateParam={endDateParam}
              />
            </div>
          </Modal>
          <Modal open={walkDetailsModal} onClose={closeWalkDetailsModal}>
            <div className="modal-default modal-sales details-modal">
              <SalesWalkDetailsModal walk={currentWalk} onClose={closeWalkDetailsModal}/>
            </div>
          </Modal>
          <Modal open={userDetailsModal} onClose={closeUserDetailsModal}>
            <div className="modal-default modal-sales details-modal">
              <SalesWalkUserDetailsModal walk={currentWalk} onClose={closeUserDetailsModal}/>
            </div>
          </Modal>
          <Modal open={liveWalkModal} onClose={closeLiveWalkModal}>
            <div className="modal-default modal-sales details-modal">
              <SalesLiveWalkModal mapScript={{ isLoaded, loadError }} walk={currentWalk} onClose={closeLiveWalkModal}/>
            </div>
          </Modal>
          <Modal open={cancelWalkConfirmModal} onClose={closeCancelWalkConfirmModal}>
            <div className="modal-default modal-sales" style={classes.walkConfirmModal}>
              <ModalTitle title={"Cancel"} onClose={closeCancelWalkConfirmModal} />

              <div className="card-container">
              <span>Are you Sure you want to cancel ?</span>
              <div className="dash-filter filter" style={{ width: "100%" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="select-input-id" required>
                  Cancellation Reason
                  </InputLabel>
                  <Select
                    labelId="select-mui"
                    id="select-mui-id"
                    defaultValue=""
                    onChange={(event) => {
                      setIsReasonTextfieldShow(event.target.value === WalkerCancellationReason.OTHER)
                      setWalkCancellationReason(event.target.value);
                    }}
                  >
                    {Object.keys(WalkerCancellationReason)?.map(
                      (reasonKey, index) => (
                        <MenuItem
                          value={WalkerCancellationReason[reasonKey]}
                          key={index}
                        >
                          {WalkerCancellationReason[reasonKey]}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
              {isReasonTextfieldShow && <TextField
                  id="outlined-basic"
                  label="Reason...?"
                  multiline
                  rows={2}
                  variant="outlined"
                  style={{ width: "100%", margin:"0.2rem 0" }}
                  onChange={(e) => setWalkCancellationReason(e.target.value)}
                  inputProps={{ form: { autocomplete: "off" } }}
              />}
              </div>
              
              <Button disabled={(!walkCancellationReason || walkCancellationReason === WalkerCancellationReason.OTHER)} variant="contained" onClick={() => updateWalkClickHandler(WalkStatus.CANCELLED)} style={{ height: "3rem", fontSize: 18, fontWeight: 600, width: "fit-content", display: "flex", margin: "0 auto", marginBottom: "1rem" }} color="warning">Confirm</Button>
            </div>
          </Modal>
          <Modal open={unassignedWalkConfirmModal} onClose={closeUnassignWalkConfirmModal}>
          <div className="modal-default modal-sales" style={classes.walkConfirmModal}>
              <ModalTitle title={"UnAssign Walker"} onClose={closeUnassignWalkConfirmModal} />
              <div className="card-container" style={{display:'flex', justifyContent:"center"}}>
              <span>Are you Sure you want to Unassign this walker ?</span>
              <TextField
                  id="outlined-basic"
                  label="Reason...?"
                  multiline
                  rows={2}
                  variant="outlined"
                  value={walkerUnassignedReason}
                  style={{ width: "100%", margin:"0.2rem 0" }}
                  onChange={(e) => setWalkerUnassignedReason(e.target.value)}
                  inputProps={{ form: { autocomplete: "off" } }}
              />
              </div>
              
              <Button variant="contained" 
              disabled={!walkerUnassignedReason}
              onClick={() => unassignedWalkerClickhandler(WalkStatus.CANCELLED)} 
              style={{ height: "3rem", fontSize: 18, fontWeight: 600, width: "fit-content", display: "flex", margin: "0 auto", marginBottom: "1rem" }} 
              color="warning">Confirm</Button>
            </div>
          </Modal>

          <Modal open={addPenaltyModal} onClose={closeAddPenaltyConfirmModal}>
          <div className="modal-default modal-sales" style={classes.walkConfirmModal}>
              <ModalTitle title={"Add Penalty"} onClose={closeAddPenaltyConfirmModal} />
              <div className="card-container" style={{display:'flex', justifyContent:"center"}}>
              <span>Are you Sure you want to add Penalty on this walker for this walk ?</span>
              </div>
              
              <Button variant="contained" 
              onClick={() => addPenaltyBtnlickHandler()} 
              style={{ height: "3rem", fontSize: 18, fontWeight: 600, width: "fit-content", display: "flex", margin: "0 auto", marginBottom: "1rem" }} 
              color="warning">Confirm</Button>
            </div>
          </Modal>

          <Modal open={removePenaltyModal} onClose={closeRemovePenaltyConfirmModal}>
          <div className="modal-default modal-sales" style={classes.walkConfirmModal}>
              <ModalTitle title={"Remove Penalty"} onClose={closeRemovePenaltyConfirmModal} />
              <div className="card-container" style={{display:'flex', justifyContent:"center"}}>
              <span>Are you Sure you want to remove Penalty on this walker for this walk ?</span>
              </div>
              
              <Button variant="contained" 
              onClick={() => removePenaltyBtnClickHandler()} 
              style={{ height: "3rem", fontSize: 18, fontWeight: 600, width: "fit-content", display: "flex", margin: "0 auto", marginBottom: "1rem" }} 
              color="warning">Confirm</Button>
            </div>
          </Modal>

          <Modal open={completeWalkConfirmModal} onClose={closeCompleteWalkConfirmModal}>
            <div className="modal-default modal-sales" style={classes.walkConfirmModal}>
              <ModalTitle title={"Complete Walk"} onClose={closeCompleteWalkConfirmModal} />
              <div className="card-container">
                  <span style={{ textAlign: "center", fontSize: 18, margin: "1rem 0"}}>Are you Sure you want to complete the walk ?</span>
                  <div className="dash-filter filter" style={{ width: "100%" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="select-input-id" required>
                      Completion Reason
                      </InputLabel>
                      <Select
                        labelId="select-mui"
                        id="select-mui-id"
                        defaultValue=""
                        onChange={(event) => {
                          setWalkCompletionType(event.target.value);
                        }}
                      >
                        {Object.keys(WalkerCompletionReason)?.map(
                          (reasonKey, index) => (
                            <MenuItem
                              value={WalkerCompletionReason[reasonKey]}
                              key={index}
                            >
                              {WalkerCompletionReason[reasonKey]}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
              </div>
              <TextField
                  id="outlined-basic"
                  label="Reason...?"
                  multiline
                  rows={2}
                  variant="outlined"
                  style={{ width: "100%", margin:"0.2rem 0" }}
                  onChange={(e) => setWalkCompletionReason(e.target.value)}
                  inputProps={{ form: { autocomplete: "off" } }}
              />
              </div>
              <Button variant="contained" disabled={!walkCompletionReason || !walkCompletionType} onClick={() => updateWalkClickHandler(WalkStatus.COMPLETED)} style={{ height: "3rem", fontSize: 18, fontWeight: 600, width: "fit-content", display: "flex", margin: "0 auto", marginBottom: "1rem" }} color="warning">Complete</Button>
            </div>
          </Modal>
          <Modal open={triggerNotificationModal} onClose={closeTriggerNotificationModal}>
            <div className="modal-default modal-sales" style={classes.walkConfirmModal}>

              <ModalTitle title={"Cancel"} onClose={closeTriggerNotificationModal} />
              
              <div className="card-container">
              <h3>Trigger Notification</h3>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                style={{ marginBottom: '1rem' }}
                value={selectedNotification || notificationsType.WALK_REMINDER}
                onChange={(e) => setSelectedNotification(e.target.value)}>
                {Object.entries(notificationsType).map(([key, value]) =>
                  <FormControlLabel
                    value={value}
                    key={key}
                    control={<Radio />}
                    label={value} />
                )}
                </RadioGroup>
                </div>
              <Button onClick={handleTriggerNotification} style={{ height: "3rem", fontSize: 18, fontWeight: 600, width: "fit-content", display: "flex", margin: "0 auto", marginBottom: "1rem" }} color="warning">🔔 Notify! 🔔</Button>
            </div>
          </Modal>
          <Modal open={subscribeUserModalOpen} onClose={closeSubscribeUserModal}>
          <div className="modal-default modal-sales modal-maxh">
            <SalesSubscribeUserCard onClose={closeSubscribeUserModal} defaultUser={currentWalk?.user} defaultPetIds={currentWalk?.petIds} defaultWalkerId={currentWalk?.walker?.id} trial={walkingSessionsType === WalkingType.TRIAL ? true : false}/>
          </div>
        </Modal>
          </> : <StatisticsOverview 
          filterSlotStartTime={filterSlotStartTime}
          filterSlotEndTime={filterSlotEndTime}
          stateCode={locationFilters?.["stateCode"]?.code}
          districtId={locationFilters?.["districtId"]?.id}
          pinCode={locationFilters?.["pinCode"]}
          cityCode={locationFilters?.city?.code}
          hubIds={locationFilters?.hubs?.map(hub => hub.id) ?? []}
          searchWalkerWithPhoneNumber={searchWalkerWithPhoneNumber}
          isStatisticsReload={isStatisticsReload}
          filterWalkerPhoneNumber={filterWalkerPhoneNumber}
          walkingSessionsType={walkingSessionsType}
          walkerId={walkerSelected?.id}
          phoneNumber={filterUserPhoneNumber}
          accessToken={accessToken}
          />}
        </div>
      </WalksContext.Provider>
    </SubscriptionsContext.Provider>
  )
}
