import React, { memo } from "react";
import LinksList from "../LinksList/LinksList";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./Footer.css";
import externalLinks from "../../data/external_links.json";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-body">
        <div className="footer-body-left">
          <div className="find-logo" style={{ margin: 0 }}>
            <img
              alt="sploot-logo"
              src={process.env.PUBLIC_URL + "/assets/images/sploot-logo.webp"}
              onClick={() => (window.location.href = window.location.origin)}
            />
          </div>
          <p>Making you a better pet parent</p>
          <div className="social-icons">
            <span>
              <a href={`${externalLinks.splootYoutubeHandle}`}>
                <YouTubeIcon style={{ color: "rgba(255,88,5)" }} />
              </a>
            </span>
            <span>
              <a href={`${externalLinks.splootInstagramHandle}`}>
                <InstagramIcon style={{ color: "rgba(255,88,5)" }} />
              </a>
            </span>
            <span>
              <a href={`${externalLinks.splootTwitterHandle}`}>
                <TwitterIcon style={{ color: "rgba(255,88,5)" }} />
              </a>
            </span>
            <span>
              <a href={`${externalLinks.splootLinkedinHandle}`}>
                <LinkedInIcon style={{ color: "rgba(255,88,5)" }} />
              </a>
            </span>
          </div>
        </div>
        <div className="footer-body-right">
          <LinksList
            Heading={"Sploot"}
            Links={[
              { text: "Home", link: "/", follow: true, external: false },
              {
                text: "Join the community",
                external: false,
                link: "/community",
                follow: true,
              },
              {
                text: "Fresh Food",
                link: "/fresh-food",
                external: false,
                follow: true,
              },
              {
                text: "Dog Walking",
                link: "/dog-walking",
                external: false,
                follow: true,
              },
              {
                text: "Events",
                link: "/events",
                external: false,
                follow: true,
              },
              {
                text: "Blogs",
                link: "/blogs",
                external: false,
                follow: true,
              },
            ]}
          />
          <LinksList
            Heading={"Our Products"}
            Links={[
              {
                text: "Android App",
                link: externalLinks.splootAndroidPlaystore,
                external: true,
                follow: true,
              },
              {
                text: "Ios App",
                link: externalLinks.splootIosApp,
                external: true,
                follow: true,
              },
            ]}
          />
          <LinksList
            Heading={"Our Policies"}
            Links={[
              {
                text: "Shipping Policy",
                link: externalLinks.splootShippingPolicy,
                external: true,
                follow: true,
              },
              {
                text: "Cancellation & Refund Policy",
                link: externalLinks.splootCancellationAndRefundPolicy,
                external: true,
                follow: true,
              },
              {
                text: "Privacy Policy",
                external: true,
                link: '/pages/privacy-policy',
                follow: true,
              },
              {
                text: "Terms of Use",
                link: externalLinks.splootTermsOfUse,
                external: true,
                follow: true,
              },
            ]}
          />
        </div>
      </div>
      <div className="footer-bottom">
        <div>Copyright © 2022, Sploot</div>
      </div>
    </div>
  );
};

export default memo(Footer);
