import {
  setAdminAuthToken,
  setAdminRole,
  setPromptsList,
  setCMSExperiencesList,
  setCmsFeed,
  setCmsFeedTotal,
  setCMSBannersList,
  setCMSTopicsList,
  setCmsFeedDeletionIds,
  setCMSComment,
  setCMSFeedFormData,
  setExpertUserProfile,
  setCMSTopicPostsList,
  setCMSPinnedPosts,
  setAdminUserData,
  setCMSScheduledPosts,
  setCmsGroupChats,
  setCmsOpenChatMembers,
  setBusinessCategoriesList,
  setBusinessListingsList,
  setShopDiscountsList,
  setShopSectionList,
  setScrapedDataList,
  setBotsList,
  setLearnCategoriesList,
  setLearnCoursesList,
  setLearnChaptersList,
  setLearnQuiz,
  setLearnTask,
  setEventTagsList,
  setEventVideosList,
} from "../actions/admin";
import {
  setAdminDashboardDataLoader,
  setAccountRole,
  setInitialAppLoad,
  setActionNotif,
} from "../actions";
import {
  accountRoles,
  TOPICS,
  CMS_FEED_PAGE_SIZE,
  EXPERIENCES,
  SHOWANDTELL,
  BANNERS,
  WalkStatus,
} from "../constants";
import { nodeServerBaseUrl } from "./apiUrls";
import Swal from "sweetalert2";
import { request } from "../../utils/apiRequest";

export const getAppAdminData =
  (adminAuthToken, navigate) => async (dispatch, getState) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${adminAuthToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/user`,
      requestOptions,
      async (response) => {
        let userData = response.data?.data;
        userData = {
          ...userData,
          petProfiles: userData?.petProfiles?.filter((pet) => !pet?.isDeleted),
        };

        dispatch(setAdminRole(true));
        dispatch(setAdminAuthToken(adminAuthToken));
        dispatch(setAdminUserData(userData));
        dispatch(setAccountRole(accountRoles.ADMIN));
        dispatch(setInitialAppLoad(false));

        if (window.location.pathname.split("/")[2] === "login")
          navigate("/admin/dashboard/feed");
      }
    );
  };

export const getCMSDashboardData =
  (bannerType) => async (dispatch, getState) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    dispatch(setAdminDashboardDataLoader(true));
    switch (getState().admin.dashboardCurrentForm.type) {
      case EXPERIENCES:
        await request(
          `${nodeServerBaseUrl}/cms/events?limit=1000`,
          requestOptions,
          (res) => dispatch(setCMSExperiencesList(res.data.data)),
        );
        dispatch(getEventTags());
        break;
      case SHOWANDTELL:
        await request(
          `${nodeServerBaseUrl}/cms/prompts?limit=1000`,
          requestOptions,
          (res) => dispatch(setPromptsList(res.data.data)),
        );
        break;
      case BANNERS:
        await request(
          `${nodeServerBaseUrl}/cms/banner?limit=1000${
            bannerType ? "&type=" + bannerType : ""
          }`,
          requestOptions,
          (res) => dispatch(setCMSBannersList(res.data.data)),
        );
        break;
      case TOPICS:
        await request(
          `${nodeServerBaseUrl}/cms/post-categories?limit=1000`,
          requestOptions,
          (res) => dispatch(setCMSTopicsList(res.data.data)),
        );
        break;
      default:
        break;
    }
    dispatch(setAdminDashboardDataLoader(false));
  };

export const getCMSFeed = () => async (dispatch, getState) => {
  const adminAuthToken = getState().admin.authToken;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminAuthToken}`,
    },
  };
  dispatch(setAdminDashboardDataLoader(true));

  await request(
    `${nodeServerBaseUrl}/posts?limit=${CMS_FEED_PAGE_SIZE}&page=${
      getState().admin.cmsFeed.pageNo
    }`,
    requestOptions,
    (res) => {
      console.log(res);
      dispatch(setCmsFeed(res.data.data));
      dispatch(setAdminDashboardDataLoader(false));
      dispatch(
        setCmsFeedTotal(Math.floor(res.data.totalCount / CMS_FEED_PAGE_SIZE))
      );
    }
  );
};

export const deleteCmsFeedItems = () => async (dispatch, getState) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };
  const postIds = getState().admin.cmsFeed.deleteIds;

  postIds.forEach(async (postId) => {
    await request(
      `${nodeServerBaseUrl}/posts/${postId}`,
      requestOptions,
      () => {},
      (e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: e,
        });

        console.log(e);
      }
    );
  });

  Swal.fire({
    icon: "success",
    text: "Posts Deleted Successfully!",
    timer: 2500,
  });
  dispatch(getCMSFeed());
  dispatch(setCmsFeedDeletionIds([]));
};

export const deleteCmsThread = (postId) => (dispatch, getState) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };

  let commentIds = getState().admin.cmsCommentsDeleteIds;

  console.log(commentIds);

  commentIds.forEach(async (commentId) => {
    await request(
      `${nodeServerBaseUrl}/posts/${postId}/comments/${commentId}`,
      requestOptions,
      (response) => {
        return response;
      },
      (e) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: e,
        });
        console.log(e);
      }
    );
  });

  dispatch(getCMSFeed());
  Swal.fire({
    icon: "success",
    text: "Thread Deleted Successfully!",
    timer: 2500,
  });
};

export const adminAddCMSFeedData = () => async (dispatch, getState) => {
  const data = { ...getState().admin.cmsFeedFormData };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify(data),
  };

  await request(
    `${nodeServerBaseUrl}/posts`,
    requestOptions,
    (response) => {
      dispatch(getCMSFeed());
      dispatch(setCMSFeedFormData({}));
      if (response.statusCode !== 200) {
        dispatch(getCMSFeed());
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Post Not added",
        });
      }
      Swal.fire({
        icon: "success",
        text: "Posted Successfully!",
        timer: 2500,
      });
    },
    (e) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e,
      });
      console.log(e);
    }
  );
};

export const adminEditCMSFeedData = (postId) => async (dispatch, getState) => {
  const data = { ...getState().admin.cmsFeedFormData };
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify(data),
  };
  await request(
    `${nodeServerBaseUrl}/posts/${postId}`,
    requestOptions,
    (data) => {
      console.log(data);
      dispatch(getCMSFeed());
      dispatch(setCMSFeedFormData({}));
      if (data.statusCode !== 200) {
        dispatch(getCMSFeed());
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Post Not Edited",
        });
      }
      Swal.fire({
        icon: "success",
        text: "Post Edited Successfully!",
        timer: 2500,
      });
    },
    (e) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e,
      });
      console.log(e);
    }
  );
};

export const adminCMSAddComment = () => async (dispatch, getState) => {
  const data = { ...getState().admin.cmsFeedComment };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify({ comment: data.comment, imageUrl: data.imageUrl }),
  };
  const url = data.isRoot
    ? `${nodeServerBaseUrl}/posts/${data.postId}/comments`
    : `${nodeServerBaseUrl}/posts/${data.postId}/comments/${data.threadId}/reply`;

  await request(
    url,
    requestOptions,
    (data) => {
      console.log(data);
      if (data.statusCode !== 200) {
        dispatch(getCMSFeed());
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Comment Not added",
        });
      }
      Swal.fire({
        icon: "success",
        text: "Commented Successfully!",
        timer: 600,
      });
    },
    (e) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e,
      });
      console.log(e);
    }
  );
  dispatch(setCMSComment(""));
};

export const adminCMSLikePost =
  (postId, liked) => async (dispatch, getState) => {
    const requestHeader = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/posts/${postId}/like`,
      {
        ...requestHeader,
        method: "PATCH",
        body: JSON.stringify({ liked: !liked }),
      },
      (likedResponse) => {
        console.log(likedResponse);
        if (likedResponse.statusCode !== 200) {
          dispatch(getCMSFeed());
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Post Not Liked",
          });
        }
      },
      (error) => {
        console.log(error);
        dispatch(getCMSFeed());
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      }
    );
  };

export const logoutAdmin = (navigate) => (dispatch) => {
  dispatch(setAdminRole(false));
  dispatch(setAdminAuthToken(""));
  dispatch(setAdminUserData({}));
  dispatch(setAccountRole(accountRoles.GUEST));
  window.localStorage.removeItem("authToken");
  window.localStorage.removeItem("authTokenExpires");
  navigate("/admin/login");
};

export const searchUser = (phoneNumber) => async (dispatch, getState) => {

  const requestHeader = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify({phoneNumber: phoneNumber})
  };
  await request(`${nodeServerBaseUrl}/users`, requestHeader,
    (res) => {
      dispatch(setExpertUserProfile(res?.data?.data[0]))
    }, (error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  );
};

export const adminCMSUpdateProfile = (data) => async (dispatch, getState) => {
  const { expertUserProfile } = { ...getState().admin };

  const requestHeader = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify(data),
  };
  await request(
    `${nodeServerBaseUrl}/users/${expertUserProfile.id}`,
    requestHeader,
    (res) => {
      dispatch(setExpertUserProfile([]));
      Swal.fire({
        icon: "success",
        text: "Profile updated successfully!",
        timer: 800,
      });
    },
    (error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  );
};

export const adminCMSBanner =
  (data, bannerId, type) => async (dispatch, getState) => {
    const action = getState().admin.currentDashboardAction;
    let url = `${nodeServerBaseUrl}/cms/banner/`;
    if (action === "DELETE" || action === "PATCH") {
      url += bannerId;
    }

    const requestHeader = {
      method: action,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      ...((action === "POST" || action === "PATCH") && {
        body: JSON.stringify(data),
      }),
    };
    await request(
      url,
      requestHeader,
      (data) => {
        dispatch(getCMSDashboardData(type));

        Swal.fire({
          icon: "success",
          text: `Banner ${action} successfully!`,
          timer: 800,
        });
      },
      (error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      }
    );
  };

export const adminCMSLearn =
  (data, bannerId, type) => async (dispatch, getState) => {
    const action = getState().admin.currentDashboardAction;
    let url = `${nodeServerBaseUrl}/cms/banner/`;
    if (action === "DELETE" || action === "PATCH") {
      url += bannerId;
    }
    const requestHeader = {
      method: action,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      ...((action === "POST" || action === "PATCH") && {
        body: JSON.stringify(data),
      }),
    };
    await request(
      url,
      requestHeader,
      (data) => {
        dispatch(getCMSDashboardData(type));

        Swal.fire({
          icon: "success",
          text: `Banner ${action} successfully!`,
          timer: 800,
        });
      },
      (error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      }
    );
  };

export const adminCMSShowAndTell =
  (data, SATId) => async (dispatch, getState) => {
    const action = getState().admin.currentDashboardAction;
    let url = `${nodeServerBaseUrl}/cms/prompts/`;
    if (action === "DELETE" || action === "PATCH") {
      url += SATId;
    }
    const requestHeader = {
      method: action,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      ...((action === "POST" || action === "PATCH") && {
        body: JSON.stringify(data),
      }),
    };
    await request(
      url,
      requestHeader,
      (data) => {
        console.log(data);
        dispatch(getCMSDashboardData());

        Swal.fire({
          icon: "success",
          text: `Show & Tell ${action} successfully!`,
          timer: 800,
        });
      },
      (error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      }
    );
  };

export const adminCMSExperience =
  (data, experienceId) => async (dispatch, getState) => {
    const action = getState().admin.currentDashboardAction;
    let url = `${nodeServerBaseUrl}/cms/events/`;
    if (action === "DELETE" || action === "PATCH") {
      url += experienceId;
    }
    const requestHeader = {
      method: action,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      ...((action === "POST" || action === "PATCH") && {
        body: JSON.stringify(data),
      }),
    };
    await request(
      url,
      requestHeader,
      (data) => {
        console.log(data);
        dispatch(getCMSDashboardData());

        Swal.fire({
          icon: "success",
          text: `Experience ${action} successfully!`,
          timer: 800,
        });
      },
      (error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      }
    );
  };

export const adminCMSTopics = (data, topicId) => async (dispatch, getState) => {
  const action = getState().admin.currentDashboardAction;
  let url = `${nodeServerBaseUrl}/cms/post-categories/`;
  if (action === "DELETE" || action === "PATCH") {
    url += topicId;
  }
  const requestHeader = {
    method: action,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    ...((action === "POST" || action === "PATCH") && {
      body: JSON.stringify(data),
    }),
  };
  await request(
    url,
    requestHeader,
    (data) => {
      console.log(data);
      dispatch(getCMSDashboardData());

      Swal.fire({
        icon: "success",
        text: `Topic ${action} successfully!`,
        timer: 800,
      });
    },
    (error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  );
};

export const getCMSTopicPosts = (categoryId) => async (dispatch, getState) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };
  dispatch(setAdminDashboardDataLoader(true));
  await request(
    `${nodeServerBaseUrl}/cms/post-categories/${categoryId}/posts?limit=1000`,
    requestOptions,
    (res) => {
      console.log(res);
      dispatch(setCMSTopicPostsList(res.data?.data));
    }
  );
  dispatch(setAdminDashboardDataLoader(false));
};

export const adminCMSTopicPosts =
  (data, categoryId, postID) => async (dispatch, getState) => {
    const action = getState().admin.currentDashboardAction;
    let url = `${nodeServerBaseUrl}/cms/post-categories/${categoryId}/posts/`;
    if (action === "DELETE" || action === "PATCH") {
      url += postID;
    }
    const requestHeader = {
      method: action,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      ...((action === "POST" || action === "PATCH") && {
        body: JSON.stringify(data),
      }),
    };
    await request(
      url,
      requestHeader,
      (res) => {
        dispatch(getCMSTopicPosts(categoryId));

        Swal.fire({
          icon: "success",
          text: `Topic ${action} successfully!`,
          timer: 800,
        });
      },
      (error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      }
    );
  };

export const getCMSExpertsList = () => async (dispatch, getState) => {
  const requestHeader = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };

  await request(`${nodeServerBaseUrl}/cms/experts`, requestHeader, (res) =>
    dispatch(setExpertUserProfile(res.data.data))
  );
};

export const getPinnedPosts = () => async (dispatch, getState) => {
  const requestHeader = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };

  await request(
    `${nodeServerBaseUrl}/pinned-posts`,
    requestHeader,
    (res) => {
      if (res.statusCode !== 200)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.message,
        });

      dispatch(setCMSPinnedPosts(res.data.data));
    },
    (error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  );
};

export const getScheduledPosts = (pageNo) => async (dispatch, getState) => {
  const requestHeader = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };

  await request(
    `${nodeServerBaseUrl}/scheduled-posts?page=${pageNo}`,
    requestHeader,
    (res) => {
      if (res.statusCode !== 200)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.message,
        });

      dispatch(setCMSScheduledPosts(res.data));
    },
    (error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  );
};

export const cmsAnswerPoll = (postId, pollId) => async (dispatch, getState) => {
  const requestHeader = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };

  await request(
    `${nodeServerBaseUrl}/posts/${postId}/polls/${pollId}`,
    requestHeader,
    (res) => {
      if (res.statusCode !== 200)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.message,
        });
      else
        Swal.fire({
          icon: "success",
          title: "Poll answered!",
          timer: 800,
        });
    },
    (error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  );
  dispatch(getCMSFeed());
};

export const getGroupChats = () => async (dispatch, getState) => {
  const requestHeader = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };

  await request(
    `${nodeServerBaseUrl}/chats`,
    requestHeader,
    (res) => {
      const data = res.data?.data;
      data?.length && dispatch(setCmsGroupChats(data));
    },
    (error) => {
      console.log(error);
    }
  );
};

export const createGroupChat =
  (...info) =>
  async (dispatch, getState) => {
    const [chatBody, closeAddChatModal] = info;
    console.log({ chatBody, info });
    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(chatBody),
    };

    await request(
      `${nodeServerBaseUrl}/chats`,
      requestHeader,
      (res) => {
        dispatch(
          setActionNotif({
            open: true,
            message: "Chat created!",
            severity: "success",
          })
        );
        closeAddChatModal();
        dispatch(getGroupChats());
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: "Something went wrong!",
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const removeGroupChat =
  (...info) =>
  async (dispatch, getState) => {
    const [chatId, closeChatRemovalConfirmationModal] = info;
    console.log({ chatId });
    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/chats/${chatId}`,
      requestHeader,
      (res) => {
        dispatch(
          setActionNotif({
            open: true,
            message: "Chat Deleted!",
            severity: "success",
          })
        );
        closeChatRemovalConfirmationModal();
        dispatch(getGroupChats());
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: "Something went wrong!",
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const getOpenChatMembers = () => async (dispatch, getState) => {
  const requestHeader = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };

  await request(
    `${nodeServerBaseUrl}/chats/${getState().admin.openChat.id}/members`,
    requestHeader,
    (res) => {
      const data = res.data?.data;
      console.log({ openChatMembers: data });
      data?.length && dispatch(setCmsOpenChatMembers(data));
    },
    (error) => {
      console.log(error);
    }
  );
};

export const removeChatMember =
  (...info) =>
  async (dispatch, getState) => {
    const [memberId] = info;

    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    const chatId = getState().admin.openChat.id;
    await request(
      `${nodeServerBaseUrl}/chats/${chatId}/members/${memberId}`,
      requestHeader,
      (res) => {
        dispatch(
          setActionNotif({
            open: true,
            message: "Member Removed from Chat!",
            severity: "success",
          })
        );
        dispatch(getOpenChatMembers(chatId));
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: "Something went wrong!",
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const addBusinessListing =
  (...info) =>
  async (dispatch, getState) => {
    const [body, page, categoryId] = info;

    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(body),
    };

    await request(
      `${nodeServerBaseUrl}/business-listings`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(getBusinessListings(page, categoryId));
          dispatch(
            setActionNotif({
              open: true,
              message: "Business listing created successfully!",
              severity: "success",
            })
          );
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const addBusinessListingCategory =
  (...info) =>
  async (dispatch, getState) => {
    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(info[0]),
    };

    await request(
      `${nodeServerBaseUrl}/business-listings/categories`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(getBusinessListingCategories());
          dispatch(
            setActionNotif({
              open: true,
              message: "Business listing category created successfully!",
              severity: "success",
            })
          );
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const updateBusinessListing =
  (body, listingId, page, categoryId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(body),
    };

    await request(
      `${nodeServerBaseUrl}/business-listings/${listingId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(getBusinessListings(page, categoryId));
          dispatch(
            setActionNotif({
              open: true,
              message: "Business listing updated successfully!",
              severity: "success",
            })
          );
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const updateBusinessListingCategory =
  (body, categoryId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(body),
    };

    await request(
      `${nodeServerBaseUrl}/business-listings/categories/${categoryId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(getBusinessListingCategories());
          dispatch(
            setActionNotif({
              open: true,
              message: "Business listing category updated successfully!",
              severity: "success",
            })
          );
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const getBusinessListingCategories =
  () => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/business-listings/categories`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(setBusinessCategoriesList(data));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const getTestimonials = () => async (dispatch, getState) => {
  const requestHeader = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };

  await request(
    `${nodeServerBaseUrl}/business-listings/categories`,
    requestHeader,
    (res) => {
      if (res.statusCode === 200) {
        const data = res.data?.data;
        dispatch(setBusinessCategoriesList(data));
      }
    },
    (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: `Something went wrong, ${JSON.stringify(error)}`,
          severity: "error",
        })
      );
      console.log(error);
    }
  );
};

export const getBusinessListings =
  (pageNo, categoryId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    let url = `${nodeServerBaseUrl}/business-listings${
      categoryId ? `/categories/${categoryId}` : ""
    }?page=${pageNo ?? 0}&limit=12`;
    await request(
      url,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(setBusinessListingsList(data));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };
  

  export const getShopSections = (sectionDataType) => async (dispatch, getState) => {
    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body:JSON.stringify({
        limit:1000,
        page:0,
        isPopulated:true,
        ...(sectionDataType?{section:sectionDataType}:{})
      })
    };
    await request(
      `${nodeServerBaseUrl}/shop/sections/filter`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(setShopSectionList(data));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };
  

export const getScrapedPosts = (pageNo = 0, category = "TOP_ALL_TIME", status = "PENDING") => async (dispatch, getState) => {
  const requestHeader = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify({category: category, status: status})
    
  };
  
  let url = `${nodeServerBaseUrl}/scraped-data?page=${pageNo}&limit=12`
  await request(url,
    requestHeader,
    (res) => {
      if (res.statusCode === 200) {
        const data = res.data?.data;
        dispatch(setScrapedDataList(data));
      }
    }, (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: `Something went wrong, ${JSON.stringify(error)}`,
          severity: "error",
        })
      );
      console.log(error);
    });
};

export const getAllBots = () => async (dispatch, getState) => {
  const requestHeader = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify({type: "BOT"})
    
  };
  
  let url = `${nodeServerBaseUrl}/users`
  await request(url,
    requestHeader,
    (res) => {
      if (res.statusCode === 200) {
        const data = res.data?.data;
        dispatch(setBotsList(data));
      }
    }, (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: `Something went wrong, ${JSON.stringify(error)}`,
          severity: "error",
        })
      );
      console.log(error);
    });
};

export const discardScrapedPost = (id, pageNo, category) => async (dispatch, getState) => {
  const requestHeader = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify({status: "DISCARDED"})
    
  };
  
  let url = `${nodeServerBaseUrl}/scraped-data/${id}`
  await request(url,
    requestHeader,
    (res) => {
      if (res.statusCode === 200) {
        // const data = res.data?.data;
        dispatch(getScrapedPosts(pageNo, category));
        dispatch(
          setActionNotif({
            open: true,
            message: `Post Discarded`,
            severity: "success",
          })
        );
      }
    }, (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: `Something went wrong, ${JSON.stringify(error)}`,
          severity: "error",
        })
      );
      console.log(error);
    });
};


export const approveScrapedPost = (postId, userId, tags, title, pageNo ) => async (dispatch, getState) => {
  const requestHeader = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify({status: "APPROVED", userId: userId, tags: tags, title: title})
    
  };
  
  let url = `${nodeServerBaseUrl}/scraped-data/${postId}`
  await request(url,
    requestHeader,
    (res) => {
      if (res.statusCode === 200) {
        // const data = res.data?.data;
        dispatch(getScrapedPosts(pageNo));
        dispatch(
          setActionNotif({
            open: true,
            message: `Post Approved`,
            severity: "success",
          })
        );
      }
    }, (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: `Something went wrong, ${JSON.stringify(error)}`,
          severity: "error",
        })
      );
      console.log(error);
    });
};

export const getShopDiscounts = () => async (dispatch, getState) => {
  const requestHeader = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
  };

  await request(
    `${nodeServerBaseUrl}/store/discounts?limit=1000`,
    requestHeader,
    (res) => {
      if (res.statusCode === 200) {
        const data = res.data?.data;
        dispatch(setShopDiscountsList(data));
      }
    },
    (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: `Something went wrong, ${JSON.stringify(error)}`,
          severity: "error",
        })
      );
      console.log(error);
    }
  );
};

export const addShopDiscount =
  (body, setProcessing, closeDiscountModal) => async (dispatch, getState) => {
    setProcessing(true);

    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(body),
    };

    await request(
      `${nodeServerBaseUrl}/store/discounts`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(
            setActionNotif({
              open: true,
              message: "Discount added successfully",
              severity: "success",
            })
          );
          closeDiscountModal();
          dispatch(getShopDiscounts());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
    setProcessing(false);
  };

export const addShopSection =
  (body, setProcessing, closeShopSectionModal) =>
  async (dispatch, getState) => {
    setProcessing(true);

    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(body),
    };

    await request(
      `${nodeServerBaseUrl}/shop/sections`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(
            setActionNotif({
              open: true,
              message: "Shop Section added successfully",
              severity: "success",
            })
          );
          closeShopSectionModal();
          dispatch(getShopSections(body.section));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
    setProcessing(false);
  };

export const getShopifyProducts =
  (setProducts) => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/store/shopify-products`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          setProducts(data);
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong while fetching products, ${JSON.stringify(
              error
            )}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

  export const updateShopSection =
  (shopSectionId, body, setEditMode) => async (dispatch, getState) => {
    const requestHeader = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(body.data),
    };

    await request(
      `${nodeServerBaseUrl}/shop/sections/${shopSectionId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(
            setActionNotif({
              open: true,
              message: "ShopSection updated successfully",
              severity: "success",
            })
          );
          dispatch(getShopSections(body.data.section));
          setEditMode(null)
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const updateShopDiscount =
  (discountId, body) => async (dispatch, getState) => {
    const requestHeader = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(body.data),
    };

    await request(
      `${nodeServerBaseUrl}/store/discounts/${discountId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(
            setActionNotif({
              open: true,
              message: "Discount updated successfully",
              severity: "success",
            })
          );
          dispatch(getShopDiscounts());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

  export const deleteShopSection =
  (shopSectionId, sectionDataType) => async (dispatch, getState) => {
    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/shop/sections/${shopSectionId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(
            setActionNotif({
              open: true,
              message: "Discount deleted successfully",
              severity: "success",
            })
          );
          dispatch(getShopSections(sectionDataType));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const deleteShopDiscount =
  (discountId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/store/discounts/${discountId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(
            setActionNotif({
              open: true,
              message: "Discount deleted successfully",
              severity: "success",
            })
          );
          dispatch(getShopDiscounts());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const searchUsersByName =
  (name, setSearchedUsers) => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/users/search?name=${name}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          setSearchedUsers(data);
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const modifyBusinessListingOwner =
  (actionType, body, listingId) => async (dispatch, getState) => {
    const requestHeader = {
      method: actionType === "Add" ? "PATCH" : "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
      body: JSON.stringify(body),
    };

    await request(
      `${nodeServerBaseUrl}/business-listings/${listingId}/owner`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(getBusinessListings());
          dispatch(
            setActionNotif({
              open: true,
              message: `Business listing owner ${actionType} successfully!`,
              severity: "success",
            })
          );
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const deleteBusinessListing =
  (listingId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/business-listings/${listingId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Deleted successfully!`,
              severity: "success",
            })
          );
          dispatch(getBusinessListings(0));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const deleteBusinessListingCategory =
  (categoryId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/business-listings/categories/${categoryId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Deleted successfully!`,
              severity: "success",
            })
          );
          dispatch(getBusinessListingCategories());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const getLearnCategories =
  () => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/categories`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(setLearnCategoriesList(data));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const getEventTags =
  () => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/events/tags`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(setEventTagsList(data));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const getLearnCourses =
  (categoryId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/${categoryId}/courses`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(setLearnCoursesList(data));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const getLearnQuiz =
  (courseId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/${courseId}/quiz`,
      requestHeader,
      (res) => {
      console.log(res, 'Res')
        if (res.statusCode === 200) {
          const data = res.data;
        if(data === null)dispatch(setLearnQuiz({}))
        else dispatch(setLearnQuiz(data?.data));
        }
     
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const getLearnTask =
  (courseId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/${courseId}/task`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data;
        if(data === null)dispatch(setLearnTask({}))
        else dispatch(setLearnTask(data?.data));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const updateLearnTask =
  (courseId, taskData) => async (dispatch, getState) => {
  let learnTaskData = {
    courseId: courseId,
    task: taskData
  }
    const requestHeader = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(learnTaskData)
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/task`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Task updated`,
              severity: "success",
            })
          );
          dispatch(getLearnTask(courseId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const getLearnChapters =
  (courseId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/${courseId}/chapters`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(setLearnChaptersList(data));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };
export const addLearnCategory=
  (categoryData) => async (dispatch, getState) => {
    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(categoryData)
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/categories`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Category added`,
              severity: "success",
            })
          );
          dispatch(getLearnCategories());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const addLearnCourses =
  (categoryId, courseData) => async (dispatch, getState) => {
  let learnCourseData = {
    categoryId: categoryId,
    courseData: courseData
  }
  console.log(learnCourseData, 'course data' )
    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(learnCourseData)
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/courses`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Courses added`,
              severity: "success",
            })
          );
          dispatch(getLearnCourses(categoryId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const addLearnChapter =
  (courseId, chapterData) => async (dispatch, getState) => {
  let learnChapterData = {
    courseId: courseId,
    chapterData: chapterData
  }
    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(learnChapterData)
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/chapters`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Chapter added`,
              severity: "success",
            })
          );
          dispatch(getLearnChapters(courseId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const addLearnQuiz =
  (courseId, quizData) => async (dispatch, getState) => {
  let learnQuizData = {
    courseId: courseId,
    quiz: quizData
  }
    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(learnQuizData)
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/quiz`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Quiz added`,
              severity: "success",
            })
          );
          dispatch(getLearnQuiz(courseId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const getEventVideos =
  () => async (dispatch, getState) => {
    const requestHeader = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/events/videos`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          const data = res.data?.data;
          dispatch(setEventVideosList(data));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  };

export const addLearnTask =
  (courseId, taskData) => async (dispatch, getState) => {
  let learnTaskData = {
    courseId: courseId,
    task: taskData
  }
    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(learnTaskData)
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/task`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Task added`,
              severity: "success",
            })
          );
          dispatch(getLearnTask(courseId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const addEventTag =
  (tagData) => async (dispatch, getState) => {

    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(tagData)
    };

    await request(
      `${nodeServerBaseUrl}/cms/events/tags`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Tag created`,
              severity: "success",
            })
          );
          dispatch(getEventTags());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const addEventVideo = (videoData) => async (dispatch, getState) => {
  const requestHeader = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().admin.authToken}`,
    },
    body: JSON.stringify(videoData),
  };

  await request(
    `${nodeServerBaseUrl}/cms/events/videos`,
    requestHeader,
    (res) => {
      if (res.statusCode === 200) {
        dispatch(
          setActionNotif({
            open: true,
            message: `Video updated`,
            severity: "success",
          }),
        );
        dispatch(getEventVideos());
      }
    },
    (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: `Something went wrong, ${JSON.stringify(error)}`,
          severity: "error",
        }),
      );
      console.log(error);
    },
  );
};


export const updateLearnQuiz =
  (quizId,courseId, quizData) => async (dispatch, getState) => {

    const requestHeader = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(quizData)
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/quiz/${quizId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Quiz updated`,
              severity: "success",
            })
          );
          dispatch(getLearnQuiz(courseId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const updateEventTag =
  (tagId, tagData) => async (dispatch, getState) => {
    const requestHeader = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(tagData) 
    };

    await request(
      `${nodeServerBaseUrl}/cms/events/tags/${tagId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Event Tag updated`,
              severity: "success",
            })
          );
          dispatch(getEventTags());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const removeEventTag =
  (tagId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/events/tags/${tagId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Tag removed`,
              severity: "success",
            })
          );
          dispatch(getEventTags());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const removeLearnCategory=
  (categoryId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/category/${categoryId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Category removed`,
              severity: "success",
            })
          );
          dispatch(getLearnCategories());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const removeLearnCourse=
  (courseId, categoryId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/course/${courseId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Course removed`,
              severity: "success",
            })
          );
          dispatch(getLearnCourses(categoryId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const removeLearnChapter=
  (chapterId, courseId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/chapter/${chapterId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Chapter removed`,
              severity: "success",
            })
          );
          dispatch(getLearnChapters(courseId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }


export const updateLearnCategory=
  (categoryId, categoryData) => async (dispatch, getState) => {
    const requestHeader = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(categoryData) 
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/category/${categoryId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Category updated`,
              severity: "success",
            })
          );
          dispatch(getLearnCategories());
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const updateLearnCourse =
  (courseId, courseData, categoryId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(courseData) 
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/course/${courseId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Course updated`,
              severity: "success",
            })
          );
          dispatch(getLearnCourses(categoryId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }

export const updateLearnChapter =
  (chapterId, chapterData, courseId) => async (dispatch, getState) => {
    const requestHeader = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().admin.authToken}`,
      },
    body: JSON.stringify(chapterData) 
    };

    await request(
      `${nodeServerBaseUrl}/cms/learn/chapter/${chapterId}`,
      requestHeader,
      (res) => {
        if (res.statusCode === 200) {
          dispatch(
            setActionNotif({
              open: true,
              message: `Chapter updated`,
              severity: "success",
            })
          );
          dispatch(getLearnChapters(courseId));
        }
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error)}`,
            severity: "error",
          })
        );
        console.log(error);
      }
    );
  }
