import {
  SET_TITLE,
  SET_DESCRIPTION,
  SET_TAG,
  SET_IMG_URL,
  SET_REDIRECT_URI,
  SET_CMS_TYPE,
  SET_IMG,
  SET_STATUS,
  SET_DASHBOARD_FORM_DATA,
  SET_CMS_BANNERS_LIST,
  SET_CMS_TOPICS_LIST,
  SET_CMS_EXPERIENCES_LIST,
  SET_CMS_SHOWANDTELL_LIST,
  SET_CURRENT_DASHBOARD_ACTION,
  CREATE,
  SET_ADMIN_AUTH_TOKEN,
  SET_ADMIN_ROLE,
  SET_CMS_FEED,
  SET_CMS_FEED_PAGE,
  SET_CMS_FEED_TOTAL,
  SET_CMS_FEED_DELETION_IDS,
  SET_CMS_COMMENTS_DELETION_IDS,
  SET_CMS_COMMENT,
  SET_CMS_FEED_FORM_DATA,
  SET_SEARCH_RESULTS_USER,
  SET_CMS_TOPIC_POSTS_LIST,
  SET_CMS_PINNED_POSTS,
  SET_ADMIN_USER_DATA,
  SET_CMS_SCHEDULED_POSTS,
  SET_CMS_GROUP_CHATS,
  SET_CMS_OPEN_CHAT,
  SET_CMS_OPEN_CHAT_MEMBERS,
  SET_BUSINESS_CATEGORIES_LIST,
  SET_BUSINESS_LISTINGS_LIST,
  SET_SHOP_DISCOUNTS_LIST,
  SET_SHOP_SECTION_LIST,
  SET_SCRAPED_DATA_LIST,
  SET_BOTS_LIST,
  SET_LEARN_CATEGORIES_LIST,
  SET_LEARN_COURSES_LIST,
  SET_LEARN_CHAPTERS_LIST,
  learnType,
  learnTypes,
  SET_CATEGORY_ID,
  SET_LEARN_WHAT_TO_SHOW,
  SET_LEARN_COURSE_ID,
  SET_LEARN_CHAPTER_ID,
  SET_LEARN_CATEGORY_ID,
  SET_LEARN_QUIZ,
  SET_LEARN_TASK,
  SET_EVENT_WHAT_TO_SHOW,
  SET_EVENT_TAGS_LIST,
  SET_EVENT_VIDEOS_LIST,
} from "../constants";

export default function adminReducer(
  state = {
    dashboardCurrentForm: {
      id: "",
      title: "",
      description: "",
      status: false,
      image: null,
      imageUrl: "",
      type: null,
      redirectUrl: "",
      tag: "",
    },
    experiencesList: [],
    cmsPinnedPosts: [],
    cmsScheduledPosts: [],
    bannersList: [],
    topicsList: [],
    topicPostsList: [],
    promptsList: [],
    groupChats: [],
    openChat: [],
    openChatMembers: [],
    currentDashboardAction: CREATE,
    authToken: "",
    adminUserData: {},
    isAdmin: false,
    cmsFeed: {
      data: [],
      pageNo: 0,
      totalPages: 1,
      deleteIds: [],
    },
    cmsFeedComment: "",
    cmsFeedFormData: {},
    cmsCommentsDeleteIds: [],
    expertUserProfile: [],
    businessCategories: [],
    businessListings: [],
    shopDiscountsList: [],
    shopSectionList: [],
    scrapedListings: [],
    botsListings: [],
    learnData: {
      whatToShow: learnTypes.CATEGORY,
      categoryId: null,
      courseId: null,
      chapterId: null,
    },
    learnCategoriesList: [],
    learnCoursesList: [],
    learnChaptersList: [],
    learnQuiz: {},
    learnTask: {},
    eventData: {
      whatToShow: null,
    },
    eventTagsList: [],
    eventVideosList: {},
  },
  action = {}
) {
  switch (action.type) {
    case SET_DASHBOARD_FORM_DATA:
      return {
        ...state,
        dashboardCurrentForm: action.payload,
      };
    case SET_TITLE:
      return {
        ...state,
        dashboardCurrentForm: {
          ...state.dashboardCurrentForm,
          title: action.payload,
        },
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        dashboardCurrentForm: {
          ...state.dashboardCurrentForm,
          description: action.payload,
        },
      };
    case SET_TAG:
      return {
        ...state,
        dashboardCurrentForm: {
          ...state.dashboardCurrentForm,
          tag: action.payload,
        },
      };
    case SET_REDIRECT_URI:
      return {
        ...state,
        dashboardCurrentForm: {
          ...state.dashboardCurrentForm,
          redirectUrl: action.payload,
        },
      };
    case SET_STATUS:
      return {
        ...state,
        dashboardCurrentForm: {
          ...state.dashboardCurrentForm,
          status: action.payload,
        },
      };
    case SET_CMS_TYPE:
      return {
        ...state,
        dashboardCurrentForm: {
          ...state.dashboardCurrentForm,
          type: action.payload,
        },
      };
    case SET_IMG_URL:
      return {
        ...state,
        dashboardCurrentForm: {
          ...state.dashboardCurrentForm,
          imageUrl: action.payload,
        },
      };
    case SET_IMG:
      return {
        ...state,
        dashboardCurrentForm: {
          ...state.dashboardCurrentForm,
          image: action.payload,
        },
      };
    case SET_CMS_SHOWANDTELL_LIST:
      return {
        ...state,
        promptsList: action.payload,
      };
    case SET_CMS_BANNERS_LIST:
      return {
        ...state,
        bannersList: action.payload,
      };
    case SET_BUSINESS_CATEGORIES_LIST:
      return {
        ...state,
        businessCategories: action.payload,
      };
    case SET_BUSINESS_LISTINGS_LIST:
      return {
        ...state,
        businessListings: action.payload,
      };
    case SET_SCRAPED_DATA_LIST:
        return {
          ...state,
          scrapedListings: action.payload,
        };
    case SET_BOTS_LIST:
          return {
            ...state,
            botsListings: action.payload,
          };
    case SET_SHOP_DISCOUNTS_LIST:
      return {
        ...state,
        shopDiscountsList: action.payload,
      };
    case SET_SHOP_SECTION_LIST:
      return {
        ...state,
        shopSectionList: action.payload,
      };
    case SET_CMS_TOPICS_LIST:
      return {
        ...state,
        topicsList: action.payload,
      };
    case SET_CMS_TOPIC_POSTS_LIST:
      return {
        ...state,
        topicPostsList: action.payload,
      };
    case SET_CMS_EXPERIENCES_LIST:
      return {
        ...state,
        experiencesList: action.payload,
      };
    case SET_CMS_PINNED_POSTS:
      return {
        ...state,
        cmsPinnedPosts: action.payload,
      };
    case SET_CMS_SCHEDULED_POSTS:
      return {
        ...state,
        cmsScheduledPosts: action.payload,
      };
    case SET_CURRENT_DASHBOARD_ACTION:
      return {
        ...state,
        currentDashboardAction: action.payload,
      };
    case SET_ADMIN_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    case SET_ADMIN_ROLE:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case SET_CMS_FEED:
      return {
        ...state,
        cmsFeed: { ...state.cmsFeed, data: action.payload },
      };
    case SET_CMS_FEED_PAGE:
      return {
        ...state,
        cmsFeed: { ...state.cmsFeed, pageNo: action.payload },
      };
    case SET_CMS_FEED_TOTAL:
      return {
        ...state,
        cmsFeed: { ...state.cmsFeed, totalPages: action.payload },
      };
    case SET_CMS_FEED_DELETION_IDS:
      return {
        ...state,
        cmsFeed: { ...state.cmsFeed, deleteIds: action.payload },
      };
    case SET_CMS_COMMENTS_DELETION_IDS:
      return {
        ...state,
        cmsCommentsDeleteIds: action.payload,
      };
    case SET_CMS_FEED_FORM_DATA:
      return {
        ...state,
        cmsFeedFormData: action.payload,
      };
    case SET_ADMIN_USER_DATA:
      return {
        ...state,
        adminUserData: action.payload,
      };
    case SET_CMS_COMMENT:
      return {
        ...state,
        cmsFeedComment: action.payload,
      };
    case SET_SEARCH_RESULTS_USER:
      return {
        ...state,
        expertUserProfile: action.payload,
      };
    case SET_CMS_GROUP_CHATS:
      return {
        ...state,
        groupChats: action.payload,
      };
    case SET_CMS_OPEN_CHAT:
      return {
        ...state,
        openChat: action.payload,
      };
    case SET_CMS_OPEN_CHAT_MEMBERS:
      return {
        ...state,
        openChatMembers: action.payload,
      };
    case SET_LEARN_WHAT_TO_SHOW:
      return {
        ...state,
        learnData: {
          ...state.learnData,
          whatToShow: action.payload,
        },
      };
    case SET_LEARN_CATEGORY_ID:
      return {
        ...state,
        learnData: {
          ...state.learnData,
          categoryId: action.payload,
        },
      };
    case SET_LEARN_COURSE_ID:
      return {
        ...state,
        learnData: {
          ...state.learnData,
          courseId: action.payload,
        },
      };
    case SET_LEARN_CHAPTER_ID:
      return {
        ...state,
        learnData: {
          ...state.learnData,
          chapterId: action.payload,
        },
      };
    case SET_LEARN_CATEGORIES_LIST:
      return {
        ...state,
        learnCategoriesList: action.payload,
      };
    case SET_LEARN_COURSES_LIST:
      return {
        ...state,
        learnCoursesList: action.payload,
      };
    case SET_LEARN_CHAPTERS_LIST:
      return {
        ...state,
        learnChaptersList: action.payload,
      };
    case SET_LEARN_QUIZ:
      return {
        ...state,
        learnQuiz: action.payload,
      };
    case SET_LEARN_TASK:
      return {
        ...state,
        learnTask: action.payload,
      };
    case SET_EVENT_WHAT_TO_SHOW:
      return {
        ...state,
        eventData: {
          whatToShow: action.payload,
        },
      };
    case SET_EVENT_TAGS_LIST:
      return {
        ...state,
        eventTagsList: action.payload,
      };
    case SET_EVENT_VIDEOS_LIST:
      return {
        ...state,
        eventVideosList: action.payload,
      };
    default:
      return state;
  }
}
