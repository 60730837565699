import React, { Fragment } from "react";
import List from "./List/List";
import { IconButton, SwipeableDrawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { logoutAdmin } from "../../redux/dataFetchers/adminCMSDashboardApis";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button/Button";
import { accountRoles } from "../../redux/constants";
import "./SideBar.css";

const classes = {
  signupBtn: {
    borderRadius: "5px",
    padding: "0.6rem 0.8rem",
    margin: "0 auto",
    fontSize: "0.9rem",
    width: "70%",
  },
};

export default function SideBar({
  toggleDrawer,
  drawerState,
  navList,
  openSignoutModal,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    admin: { isAdmin },
    universal: { accountRole },
    user: { accessToken },
  } = useSelector((state) => state);
  const isUserRole =
    accountRole === accountRoles.REGISTERED_USER && accessToken;

  const navigateAndClose = (anchor = "left", route = "/", event) => {
    toggleDrawer(anchor, false)(event);
    navigate(route);
  };

  return ["left"].map((anchor) => (
    <Fragment key={anchor}>
      <SwipeableDrawer
        anchor={anchor}
        open={drawerState[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        <div className="logo-wrapper">
          <img
            alt="sploot-logo"
            src={process.env.PUBLIC_URL + "/assets/images/sploot-logo.webp"}
            onClick={(e) =>
              isUserRole ? undefined : navigateAndClose(anchor, "/", e)
            }
            onKeyDown={(e) =>
              isUserRole ? undefined : navigateAndClose(anchor, "/", e)
            }
            className="app-logo"
          />
        </div>
        <List
          anchor={anchor}
          toggleDrawer={toggleDrawer}
          navList={navList}
          openSignoutModal={openSignoutModal}
        />

        {window.location.pathname.split("/")[1] === "admin" && isAdmin ? (
          <IconButton onClick={() => dispatch(logoutAdmin(navigate))}>
            <LogoutIcon />
          </IconButton>
        ) : null}

        {accountRole === accountRoles.GUEST && (
          <div
            className="sidenav-login-btn"
            onClick={(e) => navigateAndClose(anchor, "/login", e)}
            onKeyDown={(e) => navigateAndClose(anchor, "/login", e)}
          >
            Login
          </div>
        )}
        {accountRole === accountRoles.GUEST && (
          <Button
            text="SIGN UP"
            btnStyle={classes.signupBtn}
            clickAction={(e) => navigateAndClose(anchor, "/signup", e)}
          />
        )}
      </SwipeableDrawer>
    </Fragment>
  ));
}
