import { GoogleMap, Marker } from "@react-google-maps/api";
import { doc, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { db } from "../../lib/init-firebase";
import ModalTitle from "../../common/ModalTitle/ModalTitle";

const classes = {
    mapContainerStyle:{
        width: "100%",
        height: "15rem",
    }
}

const center = {
    lat: 43.653225,
    lng: -79.383186,
};

export default function SalesLiveWalkModal({mapScript, walk, onClose}) {
    const mapRef = useRef(null);
    const onMapLoad = useCallback((map) => {
      mapRef.current = map;
    }, []);
    const { isLoaded, loadError } = mapScript;
    const [currMarker, setCurrMarker] = useState(null);
    const [liveDetails, setLiveDetails] = useState({
        timer:"00:00:00",
        distance:0
    })

    const panTo = useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
      }, []);

    useEffect(() => {
        onSnapshot(doc(db,"dogwalking",walk.user.id,"locations",walk.id),(snap) => {
            if(snap.exists){
                const data = snap.data();
                let loc = data.currentLocation;
                const coords = {
                    lat:loc.latitude,
                    lng: loc.longitude
                }
                panTo(coords)
                setCurrMarker(coords)
                setLiveDetails({
                    distance:data.distance,
                    timer:data.timer
                })
            }
        })
    },[])

  return (
    <div>

      <ModalTitle title={"Edit Walk"} onClose={onClose} />
      
    <div className="card-container" style={{ paddingBottom: "1rem" }}>
        <h4 style={{margin:"0.2rem",textAlign:"center",color:"#EF7123"}}>Walker - Live View</h4>
        {!isLoaded && <div>Loading Maps...</div>}
        {loadError ? (
          <div>Unable to load maps!</div>
        ) : (
          isLoaded && (
            <GoogleMap
              mapContainerStyle={classes.mapContainerStyle}
              zoom={14}
              center={center}
              onLoad={onMapLoad}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                fullscreenControl: true,
                clickableIcons:false
              }}
            >
              {currMarker && (
                <Marker
                  position={{ lat: currMarker.lat, lng: currMarker.lng }}
                />
              )}
            </GoogleMap>
          )
        )}
        <div>Time Elapsed : {liveDetails.timer}</div>
        <div>Distance Traveled : {liveDetails.distance} m</div>
        </div>
    </div>
  )
}
