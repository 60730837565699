import React from "react";
function DogWalkingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#292D32"
        d="M5.188 2c-.725 0-1.313.563-1.313 1.258v.18c0 .694.563 1.437 1.313 1.437S6.5 4.132 6.5 3.437v-.18C6.5 2.564 5.913 2 5.188 2zm-.14 3.981a6.405 6.405 0 00-1.615.245.733.733 0 00-.51.506L2.04 9.94c-.079.286-.04.59.107.85l1.331 2.356-.353 6.823-.216.415c-.104.2-.159.42-.159.642v.38h1.125l.578-.276a.363.363 0 00.202-.266l1.094-6.645h.375l1.62 6.551c.04.162.19.277.364.277h1.767v-.514a.357.357 0 00-.183-.31L9.297 20l-1.111-7.398-.562-5.211s-.155-1.214-1.856-1.382a5.996 5.996 0 00-.72-.028zm3.502 2.04L8.57 9.96l2.565 1.421.108.06.25.138h.001l7.37 4.03.156-.26 1.162-.243a.984.984 0 00.736-.64l.199-.586a.185.185 0 00-.145-.24l-1.044-.205-.177-.284a.504.504 0 00-.348-.227l-.896-.143-.75-.719c-.403.415-.188 1.079-.188 1.079l-.292.771-4.999-2.733-.122-.643a.365.365 0 00-.255-.278l-.614-.188L8.55 8.02zm3.118 5.88s-.29.362-.29.93c0 .928.97 1.953.97 1.953l-.902 3.365c-.122.455.236.898.726.898h.721c0-.364-.188-.542-.188-.542l1.313-2.153 2.625.359-.125 1.562c-.034.418.31.774.747.774h.69c0-.448-.187-.54-.187-.54l.913-4.082-2.179-1.235-3.194.78s-1.251-.4-1.251-1.698c0-.206-.149-.372-.389-.372z"
      ></path>
    </svg>
  );
}
export default DogWalkingIcon;