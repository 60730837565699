import { Button, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from "@mui/styles"
import { createContext, forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { getWalkRequestsByFilter, triggerWalkRequestAction } from './apis';
import "react-phone-number-input/style.css";
import SalesWalkRequestCard from '../../components/SalesWalkRequestCard/SalesWalkRequestCard';
import { defaultCountry, EditWalkMode, SalesCity, EPOCH, WalkerCancellationReason, WalkerCompletionReason, walkerFilterType, WalkStatus, SubscriptionTypes, WalkCancellationFilter, TodayStartEpoch, TomorrowStartEpoch, WalkingType, WalkingTrialType, HubStatus, WalkRequestStatus, WalkRequestStage, WalkRequestCycle, WalkRequestTypeDropdown, WalkRequestAction } from '../../redux/constants';
import { getCustomCities } from '../../redux/dataFetchers/salesDashboardApis';
import { getWalkersByFilter } from '../../components/SalesEditWalkCard/apis';
import './WalkRequests.css';
import usePaginated from '../usePaginatedData';
import { MediaUploadLoaderContext } from '../../App';
import SelectableButton from '../../common/SelectableButton/SelectableButton';
import AutoCompleteInput from '../../common/AutoCompleteInput/AutoCompleteInput';
import ModalTitle from '../../common/ModalTitle/ModalTitle';
import PhoneInput from "react-phone-number-input";
import { useLocation } from "react-router-dom"
import { getHubs } from '../apis';
import SalesWalkRequestDetailsModal from '../../components/SalesWalkRequestCard/SalesWalkRequestDetailsModal';
import DateTimePickerComponent from '../../components/DateTimePickerComponent/DateTimePickerComponent';

export const notificationsType = {
  USER_UNREACHABLE: "(U) User is not reachable",
  WALKER_LATE: "(U) Walker will be late",
  WALK_REMINDER: "(W) Remind walker for the walk"
}


const CustomerPhoneCustomInputElement = forwardRef((props, ref) => (
  <TextField
    {...props}
    id={`outlined-basic-${Math.random()}`}
    label="Customer Phone"
    variant="outlined"
    fullWidth
    inputRef={ref}
  />
));

const classes = {
  btnDefault: {
    boxShadow: "0px 3px 2px 1px gainsboro",
    backgroundColor: "#454545",
    color: "white",
    fontWeight: "bold",
    borderRadius: "40px"
  },
  checkBox: { color: "#974EC3" },
  walkConfirmModal: { display: "flex", flexDirection: 'column' },
}

export const WalkRequestsContext = createContext();

export default function WalkRequests() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isLeaveParam = !!query.get('isLeave')
  const walkerIdParam = query.get('walkerId');
  const requestId = query.get('requestId');

  useEffect(() => {
    if (isLeaveParam === false) {
      setWalkerSelected({});
      reloadWalkRequests()
    }
  }, [isLeaveParam]);

  const [pageNo, setPageNo] = useState(0)
  const { sales: { accessToken, customCities }, user: { states } } = useSelector(state => state)
  const [filterStartTime, setFilterStartTime] = useState(TodayStartEpoch);
  const [filterEndTime, setFilterEndTime] = useState(TomorrowStartEpoch);
  const [walkers, setWalkers] = useState([]);
  const [walkerSelected, setWalkerSelected] = useState(walkerIdParam ? {
    id: walkerIdParam
  } : {});
  const [assignedWalkerSelected, setAssignedWalkerSelected] = useState();
  const [walkingSessionsType, setWalkingSessionsType] = useState("");
  const [requestStatus, setRequestStatus] = useState([]);
  const [requestStage, setRequestStage] = useState([]);
  const [currentRequestCycle, setCurrentRequestCycle] = useState("");
  const [requestType, setRequestType] = useState("");
  const [filterUserPhoneNumber, setFilterUserPhoneNumber] = useState("");
  const [filterWalkerPhoneNumber, setFilterWalkerPhoneNumber] = useState("");
  const [walkRequestDetailsModal, setWalkRequestDetailsModal] = useState(false)
  const [currentWalkRequest, setCurrentWalk] = useState(null);
  const [cityFilter, setCityFilter] = useState('');
  const [delayedWalks, setDelayedWalks] = useState(false);
  const [replacedWalks, setReplacedWalks] = useState(false);
  const [unassignedWalkerWalks, setUnassignedWalkerWalks] = useState(false);
  const [overdue, setOverdue] = useState();
  const [forceStopFilter, setForceStopFilter] = useState();
  const [selectedDay, setSelectedDay] = useState({ today: true, yesterday: false, tomorrow: false, custom: false, mtd: false });
  const [requestActionConfirmModal, setRequestActionConfirmModal] = useState(false)
  const [cancelReasonFilter, setCancelReasonFilter] = useState("")
  const [subscriptionType, setSubscriptionType] = useState()
  const [requestActionReason, setRequestActionReason] = useState()
  const [trialType, setTrialType] = useState("")
  const [isEditWalkBackdropDismissible, setIsEditWalkBackdropDismissible] = useState(true);
  const dispatch = useDispatch();
  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(MediaUploadLoaderContext);
  const [hubs, setHubs] = useState([]);
  const [locationFilters, setLocationFilters] = useState({});
  useEffect(() => console.log(walkerIdParam ? walkerSelected : "no walker id param", 'walker selected'), [])
  const openWalkRequestDetailsModal = () => setWalkRequestDetailsModal(true);
  const closeWalkRequestDetailsModal = (event, reason) => {
    if (!isEditWalkBackdropDismissible && reason && reason === "backdropClick") return;
    setIsEditWalkBackdropDismissible(true);
    setWalkRequestDetailsModal(false);
  }
  const openRequestActionConfirmModal = () => setRequestActionConfirmModal(true);
  const closeRequestActionConfirmModal = () => setRequestActionConfirmModal(false);

  const initGetWalkRequests = async (page, limit) => {
    let walkerId = walkerSelected?.id;
    if (filterWalkerPhoneNumber) {
      walkerId = searchWalkerWithPhoneNumber(filterWalkerPhoneNumber)?.id
    }

    try {
      return await getWalkRequestsByFilter({
        accessToken,
        dispatch,
        startTime: filterStartTime,
        endTime: filterEndTime,
        overdue,
        walkerId,
        assignedWalkerId:assignedWalkerSelected?.id,
        userPhoneNumber: filterUserPhoneNumber,
        status: requestStatus,
        stage: requestStage,
        currentCycle:currentRequestCycle,
        type:requestType,
        walkingSessionsType: walkingSessionsType?.toUpperCase(),
        subscriptionType,
        trialType,
        page,
        limit,
        hubIds: locationFilters?.hubs?.length ? locationFilters.hubs?.map(hub => hub.id) : [],
        ...locationFilters.cities?.length ? { cityCode: locationFilters?.cities?.map((city)=>city.code) } : [],
        ...requestId && { requestId },
        forceStopFilter
      });
    } catch (error) {
      console.log(error);
    }
  }
  const { loading: loadingWalks, paginatedData: walkRequests, hasMore, setLoading: setLoadingWalkRequests, totalDataCount: totalCount } = usePaginated(
    pageNo,
    initGetWalkRequests
  );

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loadingWalks) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          console.log("Intersected")
          setPageNo((prev) => prev + 1)
          setLoadingWalkRequests(true)
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingWalks, hasMore, dispatch]
  );

  const useStyles = makeStyles({
    input: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    },
  });

  const UIClasses = useStyles();

  const searchWalkerWithPhoneNumber = (walkerPhoneNumber) => {
    let walker = walkers.find((walker) => walker.phoneNumber == walkerPhoneNumber);

    return walker;
  }

  const handleDiscardFilters = () => {
    setSelectedDay({ today: true, yesterday: false, tomorrow: false, custom: false, mtd: false });
    setFilterStartTime(TodayStartEpoch);
    setFilterEndTime(TomorrowStartEpoch);
    setWalkerSelected({});
    setAssignedWalkerSelected({})
    setFilterUserPhoneNumber("");
    setFilterWalkerPhoneNumber("");
    setWalkingSessionsType("")
    setSubscriptionType("")
    setTrialType();
    setOverdue("");
    setLocationFilters({});
    setRequestStage([])
    setRequestStatus([])
    setCurrentRequestCycle("")
    setRequestType("")
    reloadWalkRequests();
  }

  const handleWalkClick = (walk) => {
    setCurrentWalk(walk)
    openWalkRequestDetailsModal()
  }

  const reloadWalkRequests = () => {
    setPageNo(0);
    setLoadingWalkRequests(true)
  }

  const walkRequestsContextValue = {
    closeWalkRequestDetailsModal,
    reloadWalkRequests,
  }

  const walkRequestActionClickHandler = async (requestAction, reason) => {
    openMediaUploadLoader()
    let body = {}
    switch (requestAction) {
      case WalkRequestAction.FORCE_STOP:
        body = {
          ...reason && { forceStopReason:reason }
        }
        break;
    }

    try{
      await triggerWalkRequestAction(currentWalkRequest.id, requestAction, dispatch, accessToken, body, closeWalkRequestDetailsModal, closeRequestActionConfirmModal, reloadWalkRequests)
    } catch(error){
      console.log({error})
    }

    closeMediaUploadLoader();
  }

  const getHubsHandler = async ({ cityCodes, status }) => {
    try {
      const data = await getHubs({ cityCodes, status })
      setHubs(data)
    } catch (error) {

    }
  }

  useEffect(async () => {
    const walkers = await getWalkersByFilter({
      accessToken,
      dispatch,
      filterType: walkerFilterType.SINGLE_WALK
    });
    setWalkers(walkers)
  }, []);

  useEffect(() => {
    if (!customCities?.length) {
      dispatch(getCustomCities());
    }
    getHubsHandler({});
  }, []);

  return (
    <WalkRequestsContext.Provider value={walkRequestsContextValue}>
      <div className="sales-container">
        <div className='sales-controls'>
          {!requestId && <div className='filters-bar'>
            <div className='walk-filters'>
              <DateTimePickerComponent
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                selectedStartTime={filterStartTime}
                setSelectedStartTime={setFilterStartTime}
                selectedEndTime={filterEndTime}
                setSelectedEndTime={setFilterEndTime}
              />

              <div className="dash-filter filter"
                style={{ width: "25rem" }}
              >
                <Autocomplete
                  disablePortal
                  multiple
                  id="custom-city"
                  options={customCities}
                  sx={{ width: "100%" }}
                  value={locationFilters?.cities ?? []}
                  isOptionEqualToValue={(option, value) =>
                    option?.code === value.code || value === ""
                  }
                  getOptionLabel={(option) => option?.name ?? option}
                  onChange={(event, value) => {
                    setLocationFilters({ cities: value });
                    let cityCodes = value?.map(city => city.code)
                    getHubsHandler({ cityCodes })
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Custom City" />
                  )}
                />
              </div>

              <div
                className="dash-filter filter"
                style={{ width: "25rem" }}
              >
                <Autocomplete
                  id="hubs"
                  multiple
                  options={hubs || []}
                  sx={{ width: "100%" }}
                  value={locationFilters?.hubs ?? []}
                  getOptionLabel={(option) => `${option?.status === HubStatus.NON_SERVICEABLE ? "(U)" : ""} ${option?.name}`}
                  onChange={(event, value) => {
                    setLocationFilters({ ...locationFilters, hubs: value });
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Hub" />
                  )}
                />
              </div>

              <div className="dash-filter filter">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="select-input-id">
                    Walk Type
                  </InputLabel>
                  <Select
                    labelId="select-mui"
                    id="select-mui-id"
                    value={walkingSessionsType}
                    sx={{ width: "100%" }}
                    onChange={(event) => {
                      setWalkingSessionsType(event.target.value)
                    }}
                  >
                    {
                      Object.keys(WalkingType).map((option, index) => {
                        return <MenuItem value={WalkingType[option]} key={index}>
                          {WalkingType[option]}
                        </MenuItem>
                      })
                    }

                  </Select>
                </FormControl>
              </div>

              <div className="dash-filter filter"
                style={{ width: "25rem" }}
              >
                <Autocomplete
                  id="request-stage"
                  multiple
                  options={Object.values(WalkRequestStage).map((option,index) => {
                    return option
                  }) || []}
                  sx={{ width: "100%" }}
                  value={requestStage}
                  getOptionLabel={(option) => `${option ?? option}`}
                  onChange={(event, value) => {
                    console.log({value})
                    setRequestStage(value)
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Request Stage" />
                  )}
                />
              </div>

              <div className="dash-filter filter"
                style={{ width: "25rem" }}
              >
                <Autocomplete
                  id="request-status"
                  multiple
                  options={Object.values(WalkRequestStatus).map((option,index) => {
                    return option
                  }) || []}
                  sx={{ width: "100%" }}
                  value={requestStatus}
                  getOptionLabel={(option) => `${option ?? option}`}
                  onChange={(event, value) => {
                    console.log({value})
                    setRequestStatus(value)
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Request Status" />
                  )}
                />
              </div>

              <div className="dash-filter filter">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="select-input-id">
                    Current Cycle
                  </InputLabel>
                  <Select
                    labelId="select-mui"
                    id="select-current-cycle"
                    value={currentRequestCycle}
                    sx={{ width: "100%" }}
                    onChange={(event) => {
                      setCurrentRequestCycle(event.target.value)
                    }}
                  >
                    {
                      Object.keys(WalkRequestCycle).map((option, index) => {
                        return <MenuItem value={WalkRequestCycle[option].value} key={index}>
                          {WalkRequestCycle[option].name}
                        </MenuItem>
                      })
                    }

                  </Select>
                </FormControl>
              </div>

              <div className="dash-filter filter">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="select-input-id">
                    Request Type
                  </InputLabel>
                  <Select
                    labelId="select-mui"
                    id="select-request-type"
                    value={requestType}
                    sx={{ width: "100%" }}
                    onChange={(event) => {
                      setRequestType(event.target.value)
                    }}
                  >
                    {
                      Object.keys(WalkRequestTypeDropdown).map((option, index) => {
                        return <MenuItem value={WalkRequestTypeDropdown[option].value} key={index}>
                          {WalkRequestTypeDropdown[option].name}
                        </MenuItem>
                      })
                    }

                  </Select>
                </FormControl>
              </div>

              {!filterWalkerPhoneNumber && <AutoCompleteInput
                optionsList={walkers}
                selected={walkerSelected?.name || ""}
                setSelected={setWalkerSelected}
                value={walkerSelected?.name || ""}
                validationFunc={(option, value) => option?.name === value || value === ""}
                findlabelFunc={(option) => option?.name ?? option}
                label={"Current Walker"}
              />}

              {!filterWalkerPhoneNumber && <AutoCompleteInput
                optionsList={walkers}
                selected={assignedWalkerSelected?.name || ""}
                setSelected={setAssignedWalkerSelected}
                value={assignedWalkerSelected?.name || ""}
                validationFunc={(option, value) => option?.name === value || value === ""}
                findlabelFunc={(option) => option?.name ?? option}
                label={"Assigned Walker"}
              />}

              <div className="dash-filter filter">
                <PhoneInput
                  key="walker-emergency-phone"
                  style={{ width: "100%" }}
                  value={filterUserPhoneNumber}
                  onChange={setFilterUserPhoneNumber}
                  inputComponent={CustomerPhoneCustomInputElement}
                  defaultCountry="IN"
                  className={UIClasses.input}
                />
              </div>

            </div>
            {<>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "2rem" }}>
                  <Button variant='contained' style={{ backgroundColor: "#974EC3", color: "#fff", marginTop: "2rem", fontWeight: "bold", fontSize: "1rem", width: "7rem" }} onClick={reloadWalkRequests}>
                    Apply
                  </Button>
                  <Button variant='contained' style={{ backgroundColor: "#974EC3", color: "#fff", marginTop: "2rem", fontWeight: "bold", fontSize: "1rem", width: "7rem" }} onClick={handleDiscardFilters}>
                    Discard
                  </Button>
                  <div style={{ display: "flex", gap: "2rem", marginTop: "2rem" }}>
                    <SelectableButton selected={overdue} setSelected={setOverdue} label="Overdue" />
                    <SelectableButton selected={forceStopFilter} setSelected={setForceStopFilter} label="No response" />
                  </div>
                </div>

                <h3 style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>Count- {totalCount}</h3>
              </div>
            </>}
          </div>}
        </div>

          <div className="walks-info-header notify walks-heading" key="walks-header">
            <span>User</span>
            <span>Walk Type</span>
            <span>Request Type</span>
            <span>Created at</span>
            <span>City</span>
            <span>Request Stage</span>
            <span>Request Status</span>
            <span>Current Cycle</span>
          </div>
          {walkRequests?.map((walkRequest, walkRequestIndex) =>
            <SalesWalkRequestCard data={walkRequest} key={walkRequest.id}
              ref={walkRequestIndex === walkRequests?.length - 3 ? lastElementRef : null}
              onClick={() => handleWalkClick(walkRequest)}
            />
          )}
          {loadingWalks && (
            <Loader
              wrapperStyle={{ height: "20vh" }}
              loaderStyle={{ color: "#974EC3" }}
            />
          )}


          <Modal open={walkRequestDetailsModal} onClose={closeWalkRequestDetailsModal} >
            <div className="modal-default modal-sales" style={{ maxHeight: "46rem", overflowY: "auto" }}>
              <SalesWalkRequestDetailsModal
                data={currentWalkRequest}
                onClose={closeWalkRequestDetailsModal}
                openRequestActionConfirmModal={openRequestActionConfirmModal}
              />
            </div>
          </Modal>
          <Modal open={requestActionConfirmModal} onClose={closeRequestActionConfirmModal}>
            <div className="modal-default modal-sales" style={classes.walkConfirmModal}>
              <ModalTitle title={WalkRequestAction.FORCE_STOP} onClose={closeRequestActionConfirmModal} />

              <div className="card-container">
                <span>Are you Sure you want to {WalkRequestAction.FORCE_STOP} ?</span>
                <div className="dash-filter filter" style={{ width: "100%" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="outlined-basic"
                      label="Reason"
                      multiline
                      rows={2}
                      variant="outlined"
                      style={{ width: "100%", margin: "0.2rem 0" }}
                      onChange={(e) => setRequestActionReason(e.target.value)}
                      inputProps={{ form: { autocomplete: "off" } }}
                    />
                  </FormControl>
                </div>
              </div>

              <Button variant="contained" onClick={() => walkRequestActionClickHandler(WalkRequestAction.FORCE_STOP, requestActionReason)} style={{ height: "3rem", fontSize: 18, fontWeight: 600, width: "fit-content", display: "flex", margin: "0 auto", marginBottom: "1rem" }} color="warning">Confirm</Button>
            </div>
          </Modal>
      </div>
    </WalkRequestsContext.Provider>
  )
}
