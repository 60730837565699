import { javaServerBaseUrl, nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { setAccountRole, setActionNotif } from "../../redux/actions";
import { setCookie } from "../../utils/cookies";
import { decodeToken } from "react-jwt";
import env from "../../environment/production.json";
import { request } from "../../utils/apiRequest";
import { AppAnalytics } from "../../utils/appAnalytics";
import { setAppUserData, setUserAccessToken } from "../../redux/actions/user";
import { getAppUserData } from "../../redux/dataFetchers/appUserApis";
import { accountRoles } from "../../redux/constants";

const getRootHost = (host) => {
  if (!host) return "sploot.space";

  let hostArr = host.split(".");
  return hostArr.slice(hostArr.length - 2, hostArr.length).join(".");
};

export const sendOtp = async (email, setProcessing, setStepNumber, dispatch) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: email
    })
  };

  setProcessing(true);

  await request(
    `${nodeServerBaseUrl}/auth/otp?forgotPassword=false`,
    requestOptions,
    async (response) => {
      dispatch(
        setActionNotif({
          open: true,
          message: "OTP Sent Successfully",
          severity: "success",
        })
      );
      AppAnalytics.track("SendOtp_Clicked", {
        result: "success",
      });

      setStepNumber((prev) => prev + 1);
      setProcessing(false);
    },
    async (error) => {
      dispatch(
        setActionNotif({ open: true, message: error.message, severity: "error" })
      );
      AppAnalytics.track("SendOtp_Clicked", {
        result: "failure",
        reason: error.message,
      });
      setProcessing(false);
    })
};

export const verifyOtp = async (
  email,
  otp,
  setProcessing,
  setStepNumber,
  dispatch
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: email,
      otp: parseInt(otp)
    }),
  };

  setProcessing(true);

  await request(
    `${nodeServerBaseUrl}/auth/otp/verify?forgotPassword=false`,
    requestOptions,
    async (response) => {
      dispatch(
        setActionNotif({
          open: true,
          message: "OTP Verified",
          severity: "success",
        })
      );
      AppAnalytics.track("VerifyOtp", {
        result: "success",
      });
      setStepNumber((prev) => prev + 1);
      setProcessing(false);
    },
    async (error) => {
      dispatch(
        setActionNotif({ open: true, message: error.message, severity: "error" })
      );
      AppAnalytics.track("VerifyOtp", {
        result: "failure",
        reason: error.message,
      });

      setProcessing(false);
    })
};

export const mobileSignin = async (
  accessToken,
  dispatch,
  onSuccess,
  onFailure
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "access_token": accessToken
    }
  };

  await request(
    `${nodeServerBaseUrl}/auth/mobile/signin`,
    requestOptions,
    async (response) => {
      let data = response.data?.data;
      if (data.authToken) {
        const domain = document.domain;
        AppAnalytics.track("Social_Verification", {
          source: "mobile",
          result: "success",
        });

        const decodedToken = decodeToken(data.authToken);
        console.log({ exp: decodedToken.exp })
        await dispatch(setUserAccessToken(data.authToken))
        await dispatch(getAppUserData(data.authToken, null, null, async (data) => {
          await dispatch(setAppUserData(data))
          onSuccess(data);
        }, (err) => { console.log({ err }) }))
        setCookie(
          "userAccessToken",
          data.authToken,
          decodedToken.exp * 1000,
          domain
        );
        await dispatch(setAccountRole(accountRoles.REGISTERED_USER))
        return;
      }

      throw new Error("Something went wrong! Please Try again");
    },
    async (error) => {
      dispatch(
        setActionNotif({ open: true, message: error.message, severity: "error" })
      );
      AppAnalytics.track("VerifyOtp", {
        result: "failure",
        reason: error.message,
      });
      onFailure(error);
    })
};

export const saveUserPassword = async (
  email,
  password,
  confirmPassword,
  setProcessing,
  dispatch,
  navigate
) => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: email,
      password,
      confirmPassword,
    }),
  };

  setProcessing(true);

  await request(
    `${nodeServerBaseUrl}/auth/set-password?forgotPassword=false`,
    requestOptions,
    async (response) => {
      dispatch(
        setActionNotif({
          open: true,
          message: "Password set successfully",
          severity: "success",
        })
      );
      AppAnalytics.track("PasswordCreated", {
        result: "success",
      });
      setProcessing(false);
      navigate("/login");
    },
    async (error) => {
      dispatch(
        setActionNotif({ open: true, message: error.message, severity: "error" })
      );
      AppAnalytics.track("PasswordCreated", {
        result: "failure",
        reason: error.message,
      });

      setProcessing(false);
    })
};

export const googleLogin = async (access_token, dispatch, redirect) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      access_token,
    },
  };

  // console.log(access_token)
  await request(
    `${nodeServerBaseUrl}/auth/google/signin`,
    requestOptions,
    async (response) => {
      let data = response.data?.data;
      dispatch(
        setActionNotif({
          open: true,
          message: "Logging you in...",
          severity: "success",
        })
      );

      console.log(data);

      if (data.authToken) {
        const domain = document.domain;
        AppAnalytics.track("Social_Verification", {
          source: "google",
          result: "success",
        });
        // console.log(data)

        const decodedToken = decodeToken(data.authToken);
        console.log({ exp: decodedToken.exp })
        setCookie(
          "userAccessToken",
          data.authToken,
          decodedToken.exp * 1000,
          domain
        );

        window.location = `${window.location.protocol}//${env.REACT_APP_SUBDOMAIN
          }.${getRootHost(window.location.host)}/${redirect ? redirect : 'onboarding'}`;
        return;
      }

      throw new Error("Something went wrong! Please Try again");
    },
    async (error) => {

      AppAnalytics.track("Social_Verification", {
        source: "google",
        result: "failure",
        reason: error.message,
      });

      dispatch(setActionNotif({ open: true, message: error.message, severity: "error" }));
    })
};

export const fbLogin = async (accessToken, dispatch) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: `${accessToken}`,
    },
  };

  try {
    const response = await fetch(
      `${javaServerBaseUrl}/auth/social/facebook`,
      requestOptions
    );

    if (response.ok) {
      let data = await response.json();
      dispatch(
        setActionNotif({
          open: true,
          message: "Logging you in...",
          severity: "success",
        })
      );
      // console.log(data);
      if (data.access_token) {
        const domain = document.domain;

        const decodedToken = decodeToken(data.access_token);
        setCookie(
          "userAccessToken",
          data.accessToken,
          decodedToken.exp * 1000,
          domain
        );

        window.location = `${window.location.protocol}//${env.REACT_APP_SUBDOMAIN
          }.${getRootHost(window.location.host)}/onboarding`;
        return;
      }

      throw new Error("Something went wrong! Please Try again");
    } else {
      let error = new Error(`${response.status} ${response.statusText}`);
      error.response = await response.json();
      throw error;
    }
  } catch (error) {
    console.log(error);
    const message = error.response?.details[0] || error.message;
    dispatch(setActionNotif({ open: true, message, severity: "error" }));
  }
};
