export default function ShareIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3925 6.52054L9.29242 10.0612C8.71404 9.32283 7.81382 8.84815 6.80371 8.84815C5.059 8.84815 3.64307 10.2647 3.64307 12.0088C3.64307 13.7534 5.059 15.1695 6.80371 15.1695C7.81132 15.1695 8.70956 14.6972 9.28863 13.9614L16.3938 17.5234C16.3236 17.7857 16.2857 18.062 16.2857 18.3465C16.2857 20.0912 17.7016 21.5071 19.4463 21.5071C21.191 21.5071 22.607 20.0912 22.607 18.3465C22.607 16.6017 21.191 15.1858 19.4463 15.1858C18.4387 15.1858 17.5405 15.6581 16.9614 16.3938L9.85627 12.8317C9.9264 12.5694 9.96435 12.2933 9.96435 12.0088C9.96435 11.7262 9.92711 11.4525 9.85754 11.192L16.9576 7.65147C17.536 8.38984 18.4362 8.86451 19.4463 8.86451C21.191 8.86451 22.607 7.44858 22.607 5.70386C22.607 3.95915 21.191 2.54321 19.4463 2.54321C17.7016 2.54321 16.2857 3.95915 16.2857 5.70386C16.2857 5.98649 16.3229 6.26007 16.3925 6.52054ZM19.4463 16.45C20.4931 16.45 21.3427 17.2996 21.3427 18.3464C21.3427 19.3932 20.4931 20.2428 19.4463 20.2428C18.3995 20.2428 17.5499 19.3932 17.5499 18.3464C17.5499 17.2996 18.3995 16.45 19.4463 16.45ZM6.80375 10.1125C7.85055 10.1125 8.70013 10.9621 8.70013 12.0089C8.70013 13.0556 7.85055 13.9053 6.80375 13.9053C5.75694 13.9053 4.90736 13.0556 4.90736 12.0089C4.90736 10.9621 5.75694 10.1125 6.80375 10.1125ZM19.4463 3.80744C20.4931 3.80744 21.3427 4.65702 21.3427 5.70383C21.3427 6.75063 20.4931 7.60022 19.4463 7.60022C18.3995 7.60022 17.5499 6.75063 17.5499 5.70383C17.5499 4.65702 18.3995 3.80744 19.4463 3.80744Z"
          fill="#121212"
        />
      </svg>
    </div>
  );
}
