import {
  SET_DISCUSS_DATA,
  SET_DISCUSS_PAGE_NO,
  DISCUSS_DEFAULT_PAGE_SIZE,
  DISCUSS_INITIAL_PAGE_NO,
  SET_SEARCH_QUERY,
  SET_SEPARATE_POST_ID,
} from "../constants";

export default function discussReducer(
  state = {
    data: [],
    pageNo: DISCUSS_INITIAL_PAGE_NO,
    pageSize: DISCUSS_DEFAULT_PAGE_SIZE,
    searchQuery: {
      key: "",
      value: "",
    },
    separatePostID: null,
  },
  action = {}
) {
  switch (action.type) {
    case SET_DISCUSS_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_DISCUSS_PAGE_NO:
      return {
        ...state,
        pageNo: action.payload,
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case SET_SEPARATE_POST_ID:
      return {
        ...state,
        separatePostID: action.payload,
      };
    default:
      return state;
  }
}
