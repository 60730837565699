import React from "react";
import "./LocationSearch.css";
import usePlacesAutoComplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useDispatch } from "react-redux";
import {
  setSplootFinderAddress,
  setSplootFinderInputModified,
} from "../../redux/actions";

export default function LocationSearch({
  panTo = () => null,
  markSearchedLocation = () => null,
  inputStyle = {},
  splootFinderTool = false,
  placeholder = "",
}) {
  const {
    ready,
    value,
    setValue,
    clearSuggestions,
    suggestions: { status, data },
  } = usePlacesAutoComplete({
    requestOptions: {
      location: { lat: () => 43.653225, lng: () => -79.383186 },
      radius: 20000 * 1000,
    },
  });

  // useEffect(() => {
  //     if(splootFinderTool){
  //         setValue(splootFinder.address);
  //     }
  // },[splootFinder.navigationState])

  const dispatch = useDispatch();

  return (
    <div className="location-search">
      <Combobox
        onSelect={async (address) => {
          setValue(address, false);
          try {
            const results = await getGeocode({ address });
            const latLng = await getLatLng(results[0]);
            panTo(latLng);
            markSearchedLocation(latLng);
            clearSuggestions();
            if (splootFinderTool) {
              dispatch(setSplootFinderInputModified(true));
              dispatch(setSplootFinderAddress(address));
            }
          } catch (error) {
            console.log(error);
          }
        }}
      >
        <ComboboxInput
          type="search"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          disabled={!ready}
          placeholder={placeholder}
          style={inputStyle}
          className="search-input"
        />
        <ComboboxPopover style={{ zIndex: "99" }}>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
