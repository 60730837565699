import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setActionNotif } from "../../redux/actions";
import { request } from "../../utils/apiRequest";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { applyButtonHandler } from "../../sales/Location/apis";

const SelectHotspots = ({
  setIsAddServicableArea,
  servicableAreaHotspots,
  filterSlotStartTime,
  filterSlotEndTime,
  filters,
  setHotspots,
  setServicableAreaHotspots
}) => {
  const {
    sales: { accessToken },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const markAsServicableButtonHandler = async () => {
    try {
      if (Object.keys(servicableAreaHotspots).length === 0) {
        dispatch(
          setActionNotif({
            open: true,
            message: `Please Enter Filters First !`,
            severity: "error",
          })
        );
        return;
      }

      const requestHeader = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          hotspotIds: servicableAreaHotspots,
          status: "ACTIVE",
        }),
      };

      let url = `${nodeServerBaseUrl}/hotspots`;
      await request(
        url,
        requestHeader,
        (res) => {
          setIsAddServicableArea(false);
          applyButtonHandler(
            {
              startTime: filterSlotStartTime,
              endTime: filterSlotEndTime,
              ...(filters["stateCode"].code
                ? { stateCode: filters["stateCode"].code }
                : {}),
              ...(filters["districtId"].id
                ? { districtId: filters["districtId"].id }
                : {}),
              ...(filters["pinCode"]
                ? { pinCode: filters["pinCode"] }
                : {}),
            },
            dispatch,
            accessToken,
            setHotspots
          )
          setServicableAreaHotspots([]);
          dispatch(
            setActionNotif({
              open: true,
              message: `hotspots marked successfully !`,
              severity: "success",
            })
          );
        },
        (error) => {
          dispatch(
            setActionNotif({
              open: true,
              message: `Something went wrong, ${JSON.stringify(error)}`,
              severity: "error",
            })
          );
        }
      );
    } catch (err) {}
  };

  return (
    <div
      style={{
        background: "white",
        width: "15rem",
        minHeight: "4rem",
        borderRadius: "0.6rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: "5rem",
        top: "5rem",
        padding: "1rem",
      }}
    >
      <div>
        <h1 style={{ fontSize: "1.2rem", marginTop: 0 }}>
          Select the hotspots{" "}
          <CloseIcon
            style={{ position: "relative", top: "5px" }}
            onClick={() => {
              setIsAddServicableArea(false);
              setServicableAreaHotspots([]);
            }}
          />
        </h1>
        <p style={{ fontSize: "0.8rem" }}>
          Click on the hotspots you want to mark as serviceable and save.
        </p>
        <button
          style={{
            outline: "none",
            background: "#1B2149",
            color: "white",
            borderRadius: "0.5rem",
            padding: "0.6rem",
            cursor: servicableAreaHotspots.length === 0 ? "none" : "pointer",
          }}
          onClick={markAsServicableButtonHandler}
          disabled={servicableAreaHotspots.length === 0}
        >
          <DoneIcon
            style={{
              fontSize: "1rem",
              position: "relative",
              top: "3px",
              marginRight: "0.2rem",
            }}
          />
          Mark as Active ({servicableAreaHotspots.length})
        </button>
      </div>
    </div>
  );
};

export default SelectHotspots;
