import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { setPetBreeds } from "../actions/user";
import { setActionNotif } from "../actions";
import { request } from "../../utils/apiRequest";
import { AppAnalytics } from "../../utils/appAnalytics";

export const getPetBreeds = () => async (dispatch, getState) => {
  const userAccessToken = getState().user.accessToken;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userAccessToken}`,
    },
  };

  request(
    `${nodeServerBaseUrl}/pet-breeds`,
    requestOptions,
    async (response) => {
      const data = response.data?.data;
      let modifiedBreeds = {};
      for (let petType in data) {
        let breeds = data[petType];
        breeds = breeds.map((breed) => ({ ...breed, label: breed.name }));
        modifiedBreeds[petType] = breeds;
      }

      console.log(modifiedBreeds);
      dispatch(setPetBreeds(modifiedBreeds));
    }
  )
};

export const editPetProfile =
  (...rest) =>
  async (dispatch, getState) => {
    const { user } = getState();
    const userAccessToken = user.accessToken;
    const [
      details,
      navigate,
      editAction,
      addAction,
      onboardAction,
      addForWalkAction,
      setProcessing,
      petId,
      onAddPetForWalkSuccess
    ] = rest;

    // console.log({ rest, details });
    const { name, gender, petBreed, dateOfBirth } = details;

    const requestOptions = {
      method: petId?"PATCH":"POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userAccessToken}`,
      },
      body: JSON.stringify(details),
    };
    console.log({petId})

    try {
      setProcessing(true);
      await request(
        `${nodeServerBaseUrl}/users/${user.data.id}/pets/${petId?petId:""}`,
        requestOptions,
        async (response) => {
          const data = response.data?.data;
  
          console.log({ data });
          !onboardAction && setProcessing(false);

          if(onboardAction){
            AppAnalytics.track("GetStarted_Clicked", {
              result: "success",  
              petName: name,
              breedName: petBreed.name,
              Age: getAgeFromEpoch(),
              Sex: gender,
              dateOfBirth: new Date(dateOfBirth),
              action: "onboard",
            });
            return;
          }
          
          //edit or add or addForWalk actions only
            dispatch(
              setActionNotif({
                open: true,
                message: editAction
                  ? "Pet Updated"
                  : `${name} Added to the Family!`,
                severity: "success",
              })
            );
  
            AppAnalytics.track("GetStarted_Clicked", {
              result: "success",
              petName: name,
              breedName: petBreed.name,
              Age: getAgeFromEpoch(),
              Sex: gender,
              dateOfBirth: new Date(dateOfBirth),
              action: editAction ? "edit" : "add",
            });
            
            if(addForWalkAction){
              onAddPetForWalkSuccess()
            } else {
              navigate(`/users/${user.data.id}/dashboard`);
            }
        }
      )
    } catch (error) {
      setProcessing(false);
      AppAnalytics.track("GetStarted_Clicked", {
        result: "failure",
        reason: JSON.stringify(error),
      });
      console.log("Pet Breeds ", error);
    }
  };

const getAgeFromEpoch = (epoch) => new Date(epoch).getFullYear() - new Date().getFullYear()