import React from "react";
import "./DownloadApp.css";

import externalLinks from "../../data/external_links.json";
import AppStore from "../../assets/images/apple-app-store-badge.webp";
import PlayStore from "../../assets/images/google-play-badge.webp";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { useMediaQuery } from "@mui/material";

const DownloadApp = ({ iconStyle = {}, wrapperStyle = {} }) => {
  const matches = useMediaQuery("(max-width:650px)");

  const trackEvent = (analyticsEvent, pixelEvent) => {
    //capture contact event
    ReactGA.event({
      category: "interaction",
      action: `${analyticsEvent}`,
      nonInteraction: false, // optional, true/false
    });

    ReactPixel.trackCustom(`${pixelEvent}`);
  };

  return (
    <div className="app-banner-desc-icons" style={matches ? wrapperStyle : {}}>
      <a
        href={externalLinks.splootIosApp}
        target="_blank"
        rel="noreferrer"
        onClick={() => trackEvent("ios_app_redirect", "IosAppRedirect")}
      >
        <img
          src={AppStore}
          alt="apple-store-icon"
          style={{ marginRight: "1rem", ...iconStyle }}
        />
      </a>
      <a
        href={externalLinks.splootAndroidPlaystore}
        target="_blank"
        rel="noreferrer"
        onClick={() => trackEvent("android_app_redirect", "AndroidAppRedirect")}
      >
        <img src={PlayStore} style={{ ...iconStyle }} alt="play-store-icon" />
      </a>
    </div>
  );
};

export default DownloadApp;
