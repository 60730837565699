import React, { useContext, useEffect, useRef, useState } from "react";
import "./SalesTrialSlotPicker.css";
import CarouselLeftArrow from "../../assets/icons/CarouselLeftArrow";
import CarouselRightArrow from "../../assets/icons/CarouselRightArrow";
import { Button, Slider } from "@mui/material";
import { SlotDurationValue, monthsOfYear } from "../../redux/constants";
import { getSlots } from "../SalesSubscribeUserCard/apis";
import { MediaUploadLoaderContext } from "../../App";

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 19,
    label: "19",
  },
  {
    value: 20,
    label: "20",
  },
];
const commonBtnStyle = {
  borderRadius: "50px",
  padding: "0.8rem 0",
  textTransform: "Capitalize",
  position: "relative",
  overflow: "hidden",
  marginTop: "0.6rem",
  width: "100%",
};

const classes = {
  enabledBtn: {
    ...commonBtnStyle,
    width: "30%",
    borderRadius: "15px",
    background: "#EF7123",
    color: "#fff",
    fontSize: 20,
    fontWeight: 700,
    padding: "0.2rem 0",
    display: "flex",
    margin: "0",
  },
};

const SalesTrialSlotPicker = function SalesTrialSlotPicker({
  accessToken,
  selectedTime,
  setScheduleTime,
  preferredTime,
  lat,
  lng,
  userId,
  slotRadius,
  setSlotRadius,
}) {
  const [slotData, setSlotData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    slotData?.length ? slotData[0] : {}
  );
  const timeScrollContainer = useRef(null);
  const morningSlotScrollContainer = useRef(null);
  const eveningSlotScrollContainer = useRef(null);
  const [isSlotSearchDirty, setIsSlotSearchDirty] = useState(true);
  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(MediaUploadLoaderContext);

  const scroll = (scrollContainer, scrollOffset) => {
    scrollContainer.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    if (!isSlotSearchDirty)
      setIsSlotSearchDirty(() => {
        setScheduleTime("");
        return true;
      });
  }, [slotRadius]);

  function valuetext(value) {
    return `${value}°km`;
  }

  const triggerGetSlots = async (slotRadius, setIsSlotSearchDirty) => {
    if (lat && lng) {
      openMediaUploadLoader()
      try {
        const data = await getSlots(
          accessToken,
          lat,
          lng,
          SlotDurationValue.MEDIUM,
          true,
          userId,
          slotRadius
        );
        setSlotData(data?.data);
      } catch (err) {
        console.log(err, "Error");
      }

      closeMediaUploadLoader()
      setIsSlotSearchDirty(false);
    }
  };

  useEffect(() => {
    slotData?.length && setSelectedDate(slotData[0]);
  }, [slotData]);

  return (
    <div style={{ width: "100%" }}>
      {preferredTime && (
        <h3>
          <p>
            Preferred Time:
            {new Date(preferredTime).toLocaleString()}
          </p>
        </h3>
      )}
      <div style={{ width: "100%", margin: "2rem 0" }}>
        <h3>Radius of Walker Search (in Kms)</h3>
        <Slider
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: "0 auto",
          }}
          aria-label="Radius"
          value={slotRadius}
          onChange={(event, newValue) => setSlotRadius(newValue)}
          getAriaValueText={valuetext}
          valueLabelDisplay="off"
          step={1}
          marks={marks}
          min={1}
          max={20}
        />
      </div>

      {isSlotSearchDirty ? (
        <Button
          style={classes.enabledBtn}
          onClick={() => triggerGetSlots(slotRadius, setIsSlotSearchDirty)}
        >
          Search
        </Button>
      ) : slotData?.length ? (
        <>
          <div className="slot-container">
            <div className="date-picker">
              <div className="date-selector">
                <h3>Select Date</h3>
                <div className="dates-container">
                  <CarouselLeftArrow
                    onclick={() => scroll(timeScrollContainer, -100)}
                  />
                  <div className="dates-scroll" ref={timeScrollContainer}>
                    {slotData?.map((slotItem) => (
                      <div
                        key={slotItem.date}
                        className={
                          selectedDate?.date === slotItem?.date &&
                          selectedDate?.month === slotItem?.month
                            ? "active-date"
                            : "date"
                        }
                        onClick={() => setSelectedDate(slotItem)}
                      >
                        <span>{`${slotItem?.day[0].toUpperCase()}${slotItem?.day
                          ?.slice(1, 3)
                          ?.toLowerCase()}`}</span>
                        <span>{slotItem?.date}</span>
                      </div>
                    ))}
                  </div>
                  <CarouselRightArrow
                    onclick={() => scroll(timeScrollContainer, 100)}
                  />
                </div>
              </div>
            </div>

            <div className="time-container">
              <h3>Select Time</h3>

              <div>
                {/* Morning Slots */}
                <div className="morning">
                  <p>Morning Slots</p>
                  <div className="morning-slot-container">
                    <CarouselLeftArrow
                      onclick={() => scroll(morningSlotScrollContainer, -350)}
                    />
                    <div
                      className="time-picker"
                      ref={morningSlotScrollContainer}
                    >
                      {selectedDate?.availableSlotsPerDay?.map((item) => {
                        const date = new Date(item);
                        const hours = date
                          .getHours()
                          .toString()
                          .padStart(2, "0");
                        if (hours < 12) {
                          const minutes = date
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");

                          return (
                            <div
                              key={item}
                              className={
                                selectedTime === item ? "active-time" : "time"
                              }
                              onClick={() => {
                                setScheduleTime(item);
                              }}
                            >
                              <p>{`${hours}:${minutes}`}</p>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <CarouselRightArrow
                      onclick={() => scroll(morningSlotScrollContainer, 350)}
                    />
                  </div>
                </div>

                {/* Evening Slots */}
                <div className="evening">
                  <p>Evening Slots</p>
                  <div className="evening-slot-container">
                    <CarouselLeftArrow
                      onclick={() => scroll(eveningSlotScrollContainer, -350)}
                    />
                    <div
                      className="time-picker"
                      ref={eveningSlotScrollContainer}
                    >
                      {selectedDate?.availableSlotsPerDay?.map((item) => {
                        const date = new Date(item);
                        const hours = date
                          .getHours()
                          .toString()
                          .padStart(2, "0");
                        if (hours >= 12) {
                          const minutes = date
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");
                          return (
                            <div
                              key={item}
                              className={
                                selectedTime === item ? "active-time" : "time"
                              }
                              onClick={() => {
                                setScheduleTime(item);
                              }}
                            >
                              <p>{`${hours}:${minutes}`}</p>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <CarouselRightArrow
                      onclick={() => scroll(eveningSlotScrollContainer, 350)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p style={{ color: "orange" }}>
          No slots found. Try increasing the search radius!
        </p>
      )}

      {/* <SalesDefaultWalkerAssignCard
        scheduleIndex={scheduleIndex}
        petIndex={petIndex}
        scheduledDateTime={scheduledDateTime}
        plan={plan}
        extend={extend}
        setAssignmentDetails={setAssignmentDetails}
        showFindWalkersOnly={true}
        disableFindWalkers={disableFindWalkers}
        hotspotId={hotspotId}
      /> */}
    </div>
  );
};

export default SalesTrialSlotPicker;
