export default function MediaColoredIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3028 2.74976H7.65076C4.63876 2.74976 2.74976 4.88376 2.74976 7.90376V16.0498C2.74976 19.0698 4.63076 21.2038 7.65076 21.2038H16.2978C19.3228 21.2038 21.2028 19.0698 21.2028 16.0498V7.90376C21.2068 4.88376 19.3258 2.74976 16.3028 2.74976Z"
          stroke="#EF7123"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7032 8.78496C10.7032 9.80496 9.87723 10.631 8.85723 10.631C7.83823 10.631 7.01123 9.80496 7.01123 8.78496C7.01123 7.76496 7.83823 6.93896 8.85723 6.93896C9.87623 6.93996 10.7022 7.76596 10.7032 8.78496Z"
          stroke="#EF7123"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.207 14.951C20.284 14.001 18.509 12.082 16.579 12.082C14.648 12.082 13.535 16.315 11.678 16.315C9.821 16.315 8.134 14.401 6.646 15.628C5.158 16.854 3.75 19.361 3.75 19.361"
          stroke="#EF7123"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
