import {
  SET_HEADER_VISIBILITY,
  SET_DASHBOARD_FORM_OPEN,
  SET_ADMIN_DASHBOARD_DATA_LOADER,
  SET_LISTING_STAGE_POINTER,
  SET_LISTING_CATEGORY,
  SET_SPLOOT_FINDER_PAGE_NUMBER,
  SET_SPLOOT_FINDER_SEARCH_RESULTS,
  SET_SPLOOT_FINDER_CATEGORY,
  SET_SPLOOT_FINDER_LOCATION,
  SET_SPLOOT_FINDER_RESULTS_CACHE_TOKEN,
  SET_SPLOOT_FINDER_INPUT_MODIFIED,
  SET_CURRENT_SEARCH_RESULT_PAGE_DATA,
  SET_TOTAL_SEARCH_RESULT_PAGES,
  SET_SPLOOT_FINDER_ADDRESS,
  TOGGLE_SEARCH_RESULTS_NAVIGATION_STATE,
  SET_SEARCH_RESULTS_LOADED,
  SET_ACTION_NOTIF,
  SET_ACCOUNT_ROLE,
  SET_INITIAL_APP_LOAD,
  SET_OPEN_IN_APP_DIALOG,
} from "../constants"

export const setHeaderVisibility = (data) => {
  return {
    type: SET_HEADER_VISIBILITY,
    payload: data,
  };
};

export const setDashboardFormOpen = (data) => {
  return {
    type: SET_DASHBOARD_FORM_OPEN,
    payload: data,
  };
};

export const setAdminDashboardDataLoader = (data) => {
  return {
    type: SET_ADMIN_DASHBOARD_DATA_LOADER,
    payload: data,
  };
};

export const setListingStagePointer = (data) => {
  return {
    type: SET_LISTING_STAGE_POINTER,
    payload: data,
  };
};

export const setListingCategory = (data) => {
  return {
    type: SET_LISTING_CATEGORY,
    payload: data,
  };
};

export const setSplootFinderPageNumber = (data) => {
  return {
    type: SET_SPLOOT_FINDER_PAGE_NUMBER,
    payload: data,
  };
};

export const setSplootFinderSearchResults = (data) => {
  return {
    type: SET_SPLOOT_FINDER_SEARCH_RESULTS,
    payload: data,
  };
};

export const setSplootFinderCategory = (data) => {
  return {
    type: SET_SPLOOT_FINDER_CATEGORY,
    payload: data,
  };
};

export const setSplootFinderLocation = (data) => {
  return {
    type: SET_SPLOOT_FINDER_LOCATION,
    payload: data,
  };
};

export const setSplootFinderResultsCacheToken = (data) => {
  return {
    type: SET_SPLOOT_FINDER_RESULTS_CACHE_TOKEN,
    payload: data,
  };
};

export const setSplootFinderInputModified = (data) => {
  return {
    type: SET_SPLOOT_FINDER_INPUT_MODIFIED,
    payload: data,
  };
};

export const setCurrentSearchResultPageData = (data) => {
  return {
    type: SET_CURRENT_SEARCH_RESULT_PAGE_DATA,
    payload: data,
  };
};

export const setTotalSearchResultPages = (data) => {
  return {
    type: SET_TOTAL_SEARCH_RESULT_PAGES,
    payload: data,
  };
};

export const setSplootFinderAddress = (data) => {
  return {
    type: SET_SPLOOT_FINDER_ADDRESS,
    payload: data,
  };
};

export const toggleSearchResultsNavigationState = (data) => {
  return {
    type: TOGGLE_SEARCH_RESULTS_NAVIGATION_STATE,
    payload: data,
  };
};

export const setSearchResultsLoaded = (data) => {
  return {
    type: SET_SEARCH_RESULTS_LOADED,
    payload: data,
  };
};

export const setActionNotif = (data) => {
  return {
    type: SET_ACTION_NOTIF,
    payload: data,
  };
};

export const setAccountRole = (data) => {
  return {
    type: SET_ACCOUNT_ROLE,
    payload: data,
  };
};

export const setInitialAppLoad = (data) => {
  return {
    type: SET_INITIAL_APP_LOAD,
    payload: data,
  };
};

export const setOpenInAppDialog = (data) => {
  return {
    type: SET_OPEN_IN_APP_DIALOG,
    payload: data,
  };
};
