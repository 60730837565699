import { getLocalStorage } from "../../utils/localStorage";
import { SET_APP_SALES_PERSON_DATA, SET_SUB_CITIES, SET_SALES_ACCESS_TOKEN, SET_DASHBOARD_ROLE, SET_CUSTOM_CITIES } from "../constants";

export default function salesReducer(
  state = {
    accessToken:"",
    data:{}, //sales person data
    subCities: [],
    customCities: [],
  },
  action = {}
) {
  switch (action.type) {
    case SET_APP_SALES_PERSON_DATA:
        return {
            ...state,
            data: action.payload,
        };
    case SET_SALES_ACCESS_TOKEN:
        return {
            ...state,
            accessToken: action.payload,
        };
    case SET_SUB_CITIES:
      return {
          ...state,
          subCities: action.payload,
      };
    case SET_CUSTOM_CITIES:
      return {
          ...state,
          customCities: action.payload,
      };
    default:
      return state;
  }
}
