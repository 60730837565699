import { setActionNotif } from "../../redux/actions";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { request } from "../../utils/apiRequest";

export const applyButtonHandler = async (
  filters,
  dispatch,
  accessToken,
  setHotspots
) => {
  try {
    if (!filters?.hubIds?.length && !filters?.cityCode) {
      dispatch(
        setActionNotif({
          open: true,
          message: `Please Select city, or hub!`,
          severity: "error",
        })
      );
      return;
    }

    const requestHeader = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(filters),
    };

    let url = `${nodeServerBaseUrl}/hotspots/metrics`;
    await request(
      url,
      requestHeader,
      (res) => {
        setHotspots(res.data.data);
      },
      (error) => {
        dispatch(
          setActionNotif({
            open: true,
            message: `Something went wrong, ${JSON.stringify(error.response.data.message)}`,
            severity: "error",
          })
        );
      }
    );
  } catch (err) {}
};
