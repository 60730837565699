import { SubscriptionStatus } from "../redux/constants";
import { AppAnalytics } from "./appAnalytics";
import { WebEngage } from "./webEngage";

export const getJsonDateFromDateTime = (dateTime) => {
    let jsonDate = new Date(
        new Date(dateTime).setMinutes((new Date(dateTime).getMinutes())
            - (new Date(dateTime).getTimezoneOffset())))
        .toISOString().replace(/.[0-9]+Z/g, '');

    jsonDate = jsonDate.substring(0, jsonDate.lastIndexOf(':'));

    return jsonDate;
}

export const getSubscriptionStatusEncodedColor = (status) => {
    switch(status){
        case SubscriptionStatus.ACTIVE:
            return "green";
        case SubscriptionStatus.CANCELLED:
            return "red";
        //case SubscriptionStatus.COMPLETED:
        case SubscriptionStatus.PENDING:
            return "gray";
        //case SubscriptionStatus.TO_BE_CANCELLED:
           // return "#EF7123";
        default:
            return "black";    
    }
}

export const trackUser = (user) => {

    let firstPet, petAnalyticsFields = {};
    let petProfiles = user.petProfiles?.filter(petProfile => !petProfile.isDeleted)
    if(petProfiles?.length){
        firstPet = petProfiles[0]
    }

    if(firstPet){
        petAnalyticsFields = {
            PetBreed:firstPet.petBreed.name,
            PetDateOfBirth:firstPet.dateOfBirth,
            PetName:firstPet.name, 
            PetSex:firstPet.gender,
            PetType:firstPet.petBreed.petType,
            PetAge:new Date(firstPet.dateOfBirth).getDate() || "",
            PetAgeInMons:new Date(firstPet.dateOfBirth).toLocaleString("en-us", { month: "short" }) || ""
        }
    }

    AppAnalytics.identify(user.id, {
        ...petAnalyticsFields,
        email:user?.email || "",
        name:user?.name || "",
        ...user?.phoneNumber && { phoneNumber:user?.phoneNumber }
    })

    WebEngage.user.login(user.id);
    WebEngage.user.setAttribute("we_whatsapp_opt_in",true); //opt in user for whatsapp campaigns
};

export function debounce(func, delay) {
    let timeout;
    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, arguments), delay);
    };
}
