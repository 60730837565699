import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./App.css";
import { HelmetProvider } from 'react-helmet-async';
AOS.init();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
      </HelmetProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
