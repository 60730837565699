import React, { memo } from "react";
import "./TextBadge.css";

const TextBadge = ({ startIcon = "", text = "", endIcon = "", style={} }) => {
  return (
    <div>
      <span
        data-aos="fade-in"
        data-aos-offset="100"
        data-aos-delay="100"
        data-aos-duration="500"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-easing="ease-in"
        style={style}
      >
        <span>{startIcon}</span>
        <span style={{ padding: "0 0.3rem" }}>{text}</span>
        <span>{endIcon}</span>
      </span>
    </div>
  );
};

export default memo(TextBadge);
