import DetailsCard from "../../common/DetailsCard/DetailsCard";
import ModalTitle from "../../common/ModalTitle/ModalTitle";
import UserDetailsCard from "../../common/UserDetailsCard/UserDetailsCard";
import { EPOCH, WalkRequestStatus, WalkRequestSystemAction, WalkRequestType, WalkRequestTypeDropdown, WalkRequestWalkerAction, WalkType, WalkingType } from "../../redux/constants";
import SalesPlanDescriptionCard from "../SalesPlanDescriptionCard/SalesPlanDescriptionCard";
import CircleIcon from '@mui/icons-material/Circle';
import "./SalesWalkRequestCard.css";
import { Button, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { calculateDistance } from "../../utils/geoLocation";
import DirectionsIcon from '@mui/icons-material/Directions';
import UpdateIcon from '@mui/icons-material/Update';

const defaultUserImage =
  process.env.PUBLIC_URL + "/assets/images/pet-parent-avatar.webp";

const defaultCapsuleStyle = {
  color: "#5B2B0D",
  backgroundColor: "#FEEBCF",
  display: "flex", 
  alignItems: "center",
  padding: "0.2rem 0.6rem",
  borderRadius:"20px",
  fontSize:"1.1rem"
}

const classes = {
  btn: {
    borderRadius: "20px",
    padding: "0.8rem 0",
    textTransform: "Capitalize",
    position: "relative",
    overflow: "hidden",
    width: "90%",
    background: "#EF7123", 
    color: "#fff",
    fontSize: 24,
    fontWeight: 700,
    display: "flex", 
    margin: "0 auto",
  },
  yellowCapsule:defaultCapsuleStyle,
  redCapsule:{
    ...defaultCapsuleStyle,
    color:"#FF512F",
    backgroundColor:"#FFDBD4"
  },
  infoIcon: {
    height: '1rem',
    width: '1rem',
    marginLeft: '0.2rem'
  },
  mediumIcon: {
    height: '1.5rem',
    width: '1.5rem',
  },
}

const Capsule = ({text, itemStyle = defaultCapsuleStyle}) => {
  return (
    <span style={itemStyle}> {text}
    </span>
  )
}

const SalesWalkRequestDetailsModal = ({ data, onClose, openRequestActionConfirmModal = () => null }) => {
  const startDate = new Date(data.startTime ?? data.schedule[0]).toLocaleDateString([],
    {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
    })
  const endDate = new Date(data.endTime ?? (data.schedule[0] + ((data?.noOfWalks ?? 1) - 1) * EPOCH.ONE_DAY_MS)).toLocaleDateString([],
    {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
    })

  const walkDetails = [
    ...!data?.trial && data?.startTime && data?.endTime ? [{
      text:"Walks range",
      description:`From ${startDate} - ${endDate}`
    }] : [],
    ...[WalkRequestType.REPLACE_SINGLE_WALK, WalkRequestType.RESCHEDULE_SINGLE_WALK, WalkRequestType.NEW].includes(data?.type) ? [{
      text:!data?.trial && data.type === WalkRequestType.NEW ? "Walks start date" : "Walk date",
      description:`${new Date(data.schedule[0]).toLocaleDateString([],
        {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        })}`
      }] : [],
      ...data?.noOfWalks ? [{
        text:"No. of Walks",
        description:data?.noOfWalks
      }] : [],
    ...WalkRequestTypeDropdown.RESCHEDULE.value.includes(data?.type) ? [{
      text:"Old Walk timings",
      description:`${new Date(data.oldSchedule[0]).toLocaleTimeString([],
        {
            hour: '2-digit',
            minute: '2-digit'
        })}`
    }] : [],
    {
      text:"Walk timings",
      description:`${new Date(data.schedule[0]).toLocaleTimeString([],
        {
            hour: '2-digit',
            minute: '2-digit'
        })}`
    },
    {
      text:"Walk Type",
      description:data?.trial ? WalkingType.TRIAL : WalkingType.SUBSCRIPTION
    },
    {
      text:"City",
      description:data?.user?.cityCode ?? ""
    },
  ]

  const selectedWalkersDetails = data.selectedWalkers?.map(selectedWalker => {
    const lat1 = data?.user?.address?.coords?.latitude, lng1 = data?.user?.address.coords?.longitude,
          lat2 = selectedWalker?.walker?.hotspot?.location?.coordinates[1], 
          lng2 = selectedWalker?.walker?.hotspot?.location?.coordinates[0]
    return {
      text:(
        <>
          <div style={{ display: "flex", gap: ".5rem", alignItems:"center"}}>
            <img
              src={selectedWalker.walker?.photoUrl || defaultUserImage}
              className="user-profile-image"
              alt="user"
            />
            <h2 className="name">{selectedWalker.walker?.name}</h2>
            <Tooltip placement="top" title={`${
              calculateDistance(lat1, lng1, lat2, lng2)}
              km away
            `}>
              <DirectionsIcon style={classes.mediumIconIcon}
                />
            </Tooltip> 
            {selectedWalker.requestSentAt && 
              <Tooltip placement="top" title={`sent at ${new Date(selectedWalker.requestSentAt).toLocaleString("en-IN",{
                hour:"2-digit",
                minute:"2-digit",
                day:"2-digit",
                month:"2-digit",
                year:"2-digit"
              })}`}>
                  <UpdateIcon style={classes.mediumIcon}
                    />
              </Tooltip> 
            }
            {data.status === WalkRequestStatus.FINDING && selectedWalker.walker?.id === data.currentWalker?.id && <span className="current-walker-marker">🟢</span>}
          </div>
        </>
      ),
      description:(
        <div style={{ display: "grid",     
          gridTemplateRows:"repeat(auto-fit, minmax(29px, 1fr))",
          borderBottom:"1px solid #dde0ea",
          background:"#1C1C1C0D", 
          justifyContent: "center",
          height: "100%",
          width: "17rem",
          borderRadius: "20px",
          padding:"0.5rem",
        }}>
        {data?.status === WalkRequestStatus.CREATED ? "Cycle not started yet" : 
        <>
          <span style={{
            ...selectedWalker.walkerAction === WalkRequestWalkerAction.DENIED ? { color:"#FF512F" } : 
            (selectedWalker.walkerAction === WalkRequestWalkerAction.ACCEPTED ? { color:"#00B53C" } : { color:"gray"})
          }}>
            Wx - {selectedWalker.walkerAction ?? WalkRequestWalkerAction.NO_ACTION}
            {selectedWalker.deniedReason && 
              <Tooltip placement="top" title={selectedWalker.deniedReason}>
                <InfoIcon style={classes.infoIcon}
                  />
              </Tooltip>}
          </span>
          {selectedWalker.systemAction
          ? <span
            style={{
              ...selectedWalker.walkerAction === WalkRequestSystemAction.RULED_OUT ? { color:"#FF512F" } : { color:"gray"}
            }}
          >System - {selectedWalker.systemAction} {(selectedWalker.skippedReason || selectedWalker.ruledOutReason) ? 
            <Tooltip placement="top" title={selectedWalker.ruledOutReason || selectedWalker.skippedReason}>
                <InfoIcon style={classes.infoIcon}
                  />
            </Tooltip> : ""
          }</span> : null}
        </>}
        </div>
      ),
      itemStyle:{
        ...selectedWalker.walker?.id === data.assignedWalker?.id && {background:"#FEEBCFA1"}
      }
    }
  })

  return (
    <div className="info-card-wrapper">
      <ModalTitle title={"Walk Request"} onClose={onClose} />
      {data?.forceStoppedAt && <Capsule 
        itemStyle={{
          ...classes.redCapsule,
          width:"fit-content",
          fontWeight:"bold",
          margin:"0.3rem"
        }}
        text={`${data.forceStopSoft ? "Gracefully" : "Hard"} Force stopped at: ${new Date(data.forceStoppedAt)?.toLocaleString([],{
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour:'2-digit',
              minute:'2-digit'
            })} ${data?.forceStopReason ? `due to ${data?.forceStopReason}`: ""}`} />}

      <DetailsCard data={selectedWalkersDetails} 
        title={
          <span style={{display:'flex', gap:"1rem"}}>
            Walker list 
            <Capsule text={`Current cycle: ${data.currentCycle}`} />
            {data.status === WalkRequestStatus.FINDING ? 
              (data.requestSentAt ? 
                <Capsule text={`Request sent: ${((new Date().getTime() - data?.requestSentAt)/EPOCH.ONE_MINUTE_MS).toFixed(1)} min before`} />
                :!data.currentWalker?.id && <Capsule text={`Under Cool off period`} />)
                 : null}
          </span>} 
        itemStyle={{borderBottom:"1px solid #dde0ea"}} />
      <DetailsCard data={walkDetails} title={
        <span style={{display:'flex', gap:"1rem"}}>
            Walk details 
            <Capsule text={`${data.type?.split("_")[0]} ${data.type?.split("_")?.length > 1 ? `(${data.type?.split("_")?.[1]})` : ""}`} />
          </span>
      } />
      {
        !data.trial && <SalesPlanDescriptionCard plan={data.plan} amountPaid={data.subscription?.amount} orderId={data?.orderId}/>
      }
      {/* TODO: change status to FINDING */}
      { [WalkRequestStatus.FINDING].includes(data.status) && <Button style={classes.btn} onClick={openRequestActionConfirmModal}>Force stop</Button>}
    </div>
  );
};

export default SalesWalkRequestDetailsModal;
