import { Autocomplete, TextField } from "@mui/material";
import React, { useMemo } from "react";

const AutoCompleteInput = ({
  optionsList,
  validationFunc,
  findlabelFunc,
  selected,
  setSelected,
  label,
  onTextFieldChange,
  disabled,
}) => {
  const renderAutocomplete = useMemo(() => {
    return (
      <Autocomplete
        style={{
          width: "100%",
          minWidth: "14.125rem",
          backgroundColor: "#F3F6F8",
        }}
        options={optionsList}
        value={selected}
        disabled={disabled}
        isOptionEqualToValue={validationFunc}
        getOptionLabel={findlabelFunc}
        onChange={(event, value) => setSelected(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onTextFieldChange}
            className="autocomplete-input"
            label={label}
          />
        )}
      />
    );
  }, [optionsList, validationFunc, findlabelFunc, selected, disabled, onTextFieldChange, label]);

  return <div className="autocomplete-container">{renderAutocomplete}</div>;
};

export default AutoCompleteInput;
