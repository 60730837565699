import { setActionNotif } from "../../redux/actions";
import { EPOCH } from "../../redux/constants";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { request } from "../../utils/apiRequest";

export const addLeaveHandler = async (data, dispatch, accessToken) => {
  const { startDate, endDate, status, walkerId, selectedSlots, reason, isPenaltyRemoved } = data;
  let slot = selectedSlots.map((schedule) => {
    return {
      start: {
        hour: schedule.start.hour,
        minute: schedule.start.minute,
        milliSeconds:
          schedule.start.hour * EPOCH.ONE_HOUR_MS +
          schedule.start.minute * EPOCH.ONE_MINUTE_MS,
      },
      end: {
        hour: schedule.end.hour,
        minute: schedule.end.minute,
        milliSeconds:
          schedule.end.hour * EPOCH.ONE_HOUR_MS +
          schedule.end.minute * EPOCH.ONE_MINUTE_MS,
      },
    };
  });

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      startDate: new Date(startDate).setHours(0, 0, 0, 0),
      endDate: new Date(endDate).setHours(23, 59, 59, 999),
      status,
      reason,
      slot,
      isPenaltyRemoved
    }),
  };

  await request(
    `${nodeServerBaseUrl}/walker/${walkerId}/leave`,
    requestOptions,
    async (response) => {
      dispatch(
        setActionNotif({
          open: true,
          message: "Leaved Added Successfully!",
          severity: "success",
        })
      );
    },
    async (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: error?.message || "Something Went Wrong!",
          severity: "error",
        })
      );
      throw error;
    }
  );
};

export const editLeaveHandler = async (data, dispatch, accessToken, leave) => {
  const { startDate, endDate, status, walkerId, selectedSlots, isPenaltyRemoved } = data;
  let slot = selectedSlots.map((schedule) => {
    return {
      start: {
        hour: schedule.start.hour,
        minute: schedule.start.minute,
        milliSeconds:
          schedule.start.hour * EPOCH.ONE_HOUR_MS +
          schedule.start.minute * EPOCH.ONE_MINUTE_MS,
      },
      end: {
        hour: schedule.end.hour,
        minute: schedule.end.minute,
        milliSeconds:
          schedule.end.hour * EPOCH.ONE_HOUR_MS +
          schedule.end.minute * EPOCH.ONE_MINUTE_MS,
      },
    };
  });

  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      startDate: new Date(startDate).setHours(0, 0, 0, 0),
      endDate: new Date(endDate).setHours(23, 59, 59, 999),
      status,
      slot,
      isPenaltyRemoved
    }),
  };

  await request(
    `${nodeServerBaseUrl}/walker/${walkerId}/leave/` + leave.id,
    requestOptions,
    async (response) => {
      dispatch(
        setActionNotif({
          open: true,
          message: "Leaved Updated Successfully!",
          severity: "success",
        })
      );
    },
    async (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: error?.message || "Something Went Wrong!",
          severity: "error",
        })
      );
      throw error;
    }
  );
};

export const getLeavesByFilter = async (data) => {
  const {
    accessToken,
    dispatch,
    walkerSelected,
    stateCode,
    districtId,
    pinCode,
    cityCode,
    hubIds,
    page,
    limit,
    filterSlotEndTime,
    filterSlotStartTime,
    status,
    filterWalkerPhoneNumber
  } = data;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      ...(walkerSelected ? { walkerId: walkerSelected.id } : {}),
      ...(filterSlotEndTime ? { startDate: filterSlotEndTime } : {}),
      ...(filterSlotStartTime ? { endDate: filterSlotStartTime } : {}),
      ...(districtId ? { districtId } : {}),
      ...(stateCode ? { stateCode } : {}),
      ...(pinCode ? { pinCode } : {}),
      ...(cityCode?.length ? { cityCode } : {}),
      ...(hubIds?.length ? { hubIds } : {}),
      ...status ? {status} : {},
      ...filterWalkerPhoneNumber ? {
        number: filterWalkerPhoneNumber
      }:{}
    }),
  };

  const requestUrl = `${nodeServerBaseUrl}/walker/leaves?page=${page}&limit=${limit}`;

  return request(
    requestUrl,
    requestOptions,
    async (response) => {
      return {
        data: response.data?.data,
        totalCount: response?.data?.totalCount,
      };
    },
    async (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: "Something went wrong!",
          severity: "error",
        })
      );
      throw error;
    }
  );
};

export const deleteLeave = async (leave, dispatch, accessToken) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  await request(
    `${nodeServerBaseUrl}/walker/${leave?.walker?.id}/leave/` + leave.id,
    requestOptions,
    async (response) => {
      dispatch(
        setActionNotif({
          open: true,
          message: "Leaved Deleted Successfully!",
          severity: "success",
        })
      );
    },
    async (error) => {
      dispatch(
        setActionNotif({
          open: true,
          message: error?.message || "Something Went Wrong!",
          severity: "error",
        })
      );
    }
  );
};
