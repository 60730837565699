import React, { useRef, useEffect, useState } from "react";
import classes from "./MarkerDetails.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { setActionNotif } from "../../redux/actions";
import AssignedWalkers from "./AssignedWalkers";
import { Modal } from "@mui/material";

const MarkerDetails = ({
  selectedMarker,
  setSelectedMarker,
  setassignAWalkCard,
  index,
  district,
}) => {
  const [assignedWalkersVisible, setAssignedWalkersVisible] = useState(false);
  const dispatch = useDispatch();

  let information = [
    {
      title: "Number of Walkers",
      answer: selectedMarker.marker.walkersAssigned,
      onClick: () => {
        setAssignedWalkersVisible(true);
      }
    },
    {
      title: "Match found",
      answer:
        selectedMarker.marker?.total - selectedMarker.marker?.notFound || 0,
    },
    {
      title: "No match found",
      answer: selectedMarker.marker?.notFound || 0,
    },
  ];
  const markerRef = useRef(null);
  const [overlap, setOverlap] = useState({ top: 0 });

  useEffect(() => {
    if (markerRef.current) {
      const markerBottom = markerRef.current.getBoundingClientRect().bottom;
      const windowBottom = window.innerHeight;
      if (markerBottom > windowBottom) {
        // Calculate how much the div is going outside
        const overflow = markerBottom - windowBottom;
        setOverlap({ top: overflow + 10 });
      } else {
        setOverlap({ top: 0 }); // No overflow
      }
    }

    return () => {
      setOverlap({ top: 0 });
    };
  }, [selectedMarker.position, markerRef]);

  const openAssignWalkerTab = () => {
    if(selectedMarker.marker.status === "INACTIVE"){
      dispatch(
        setActionNotif({
          open: true,
          message: `You have to make this hotspot active first !`,
          severity: "error",
        })
      );
      return;
    }
    setassignAWalkCard(true);
  };

  return (
    <>
    <div
      className={classes.box}
      ref={markerRef}
      style={{
        top: selectedMarker.position.y - 30 - overlap.top,
        left: selectedMarker.position.x - 30,
      }}
      key={index}
    >
      <div>
        <div>
          <h1>Hotspot {selectedMarker.marker.name ?? index}</h1>
          <h2>
            District : <span>{district}</span>
          </h2>
        </div>
        <CloseIcon
          onClick={() => setSelectedMarker(null)}
          style={{ color: "white", fontSize: "1rem" }}
        />
      </div>
      {information.map((info) => {
        return (
          <div className={classes.info} onClick={info.onClick}>
            <p>{info.title}</p>
            <p>{info.answer}</p>
          </div>
        );
      })}
      <button onClick={openAssignWalkerTab} style={{ cursor: "pointer" }}>
        Assign a Walker
      </button>
    </div>
    <Modal open={assignedWalkersVisible} onClose={() => setAssignedWalkersVisible(false)}>
        <div className="modal-default modal-sales">
          <AssignedWalkers
            onClose={() => setAssignedWalkersVisible(false)}
            walkers={selectedMarker.marker.walkers}
          />
        </div>
      </Modal>
    </>
  );
};

export default MarkerDetails;
