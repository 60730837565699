import { useState, useEffect, useContext } from "react"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EditIcon from "@mui/icons-material/Edit";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import TimerOffIcon from '@mui/icons-material/TimerOff';
import { useDispatch } from "react-redux";
import Button from "../../components/Button/Button";
import { getStatistics } from "./apis";
import { getUserByPhoneNumber } from "../../redux/dataFetchers/appUserApis";
import { setActionNotif } from "../../redux/actions";
import { MediaUploadLoaderContext } from "../../App";
import { exportHandler } from "../../redux/dataFetchers/common";

const StatisticsOverview = ({filterSlotStartTime, isStatisticsReload, filterWalkerPhoneNumber, walkingSessionsType, filterSlotEndTime, phoneNumber, walkerId, stateCode, districtId, pinCode, cityCode, hubIds, accessToken, searchWalkerWithPhoneNumber}) => {
  const [statisticsData, setStatisticsData] = useState(null);
  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(MediaUploadLoaderContext);
  const [isExporting, setIsExporting] = useState(false);
  const [exportedData, setExportedData] = useState(null);
  const dispatch = useDispatch();

  const bigboxes = [
    {
      title: "Scheduled walks",
      data: statisticsData?.scheduledWalks || 0,
      bgColor: "#F3E0D4",
      icon: <CalendarMonthIcon style={{ position: "relative", top: "7px" }} />,
    },
    {
      title: "Completed walks",
      data: statisticsData?.completedWalks || 0,
      bgColor: "#D2F6DE",
      icon: <EventAvailableIcon style={{ position: "relative", top: "7px" }} />,
    },
    {
      title: "Artificially Completed walks",
      data: statisticsData?.artificiallyCompletedWalks || 0,
      bgColor: "#D2F6DE",
      icon: <EventAvailableIcon style={{ position: "relative", top: "7px" }} />,
    },
    {
      title: "Manually Completed walks",
      data: statisticsData?.manuallyCompletedWalks || 0,
      bgColor: "#D2F6DE",
      icon: <EventAvailableIcon style={{ position: "relative", top: "7px" }} />,
    },
    {
      title: "Unattended walks",
      data: statisticsData?.unattendedWalks || 0,
      bgColor: "#FFCBAB",
      icon: (
        <InsertInvitationIcon style={{ position: "relative", top: "7px" }} />
      ),
    },
    {
      title: "Replaced walks",
      data: statisticsData?.replacedWalks || 0,
      bgColor: "#F4E4AA",
      icon: <EditIcon style={{ position: "relative", top: "7px" }} />,
    },
    {
      title: "Cancelled walks",
      data: statisticsData?.cancelledWalks || 0,
      bgColor: "#EEBBBB",
      icon: <EventBusyIcon style={{ position: "relative", top: "7px" }} />,
    },
    {
        title: "Delayed walks",
        data: statisticsData?.delayedWalks || 0,
        bgColor: "#F4E4AA",
        icon: <TimerOffIcon style={{ position: "relative", top: "7px" }} />,
      },
  ];

  const smallboxes = [
    // {
    //   title: "Subscription Cancelled",
    //   data: "20",
    // },
    // {
    //   title: "Walker Issues",
    //   data: "4",
    // },
    // {
    //   title: "Customer Cancelled",
    //   data: "20",
    // },
    // {
    //   title: "Others",
    //   data: "6",
    // },
  ];

  const gettingUserByPhoneNumber = async () => {
    return await getUserByPhoneNumber(phoneNumber, accessToken, (data) => data, (error) => {
      dispatch(setActionNotif({
          open: true,
          message: "User with Phone not found!",
          severity: "error"
      }))
      throw new Error("User with phone not found : ", error)
    })
  }

  useEffect(()=>{
    (async ()=>{
      try{
      openMediaUploadLoader();
        let user;
        if (phoneNumber) {
            user = await gettingUserByPhoneNumber()
        }
  
        let updatedWalkerId = walkerId;
        if(filterWalkerPhoneNumber){
          updatedWalkerId = searchWalkerWithPhoneNumber(filterWalkerPhoneNumber)?.id
        }
  
        const data = await getStatistics(accessToken, {
                      ...(filterSlotStartTime && filterSlotEndTime) && {
                      slotStartTime: filterSlotStartTime,
                      slotEndTime: filterSlotEndTime
                  },
                  ...(walkingSessionsType) && {
                      walkingSessionsType: walkingSessionsType.toUpperCase()
                  },
                  ...(phoneNumber && user) && {
                      userId: user.id
                  },
                  ...(updatedWalkerId) && {
                      walkerId: updatedWalkerId
                  },
                  ...stateCode && { stateCode }, 
                  ...districtId && { districtId }, 
                  ...pinCode && {pinCode},
                  ...cityCode && {cityCode},
                  ...hubIds?.length && { hubIds },
                  isUserWiseStatistics: false
        }, dispatch)
        setStatisticsData(data[0]);
        closeMediaUploadLoader()

      }catch(err){
        closeMediaUploadLoader()
      }
     
    })()
  },[isStatisticsReload])

  const exportCustomerWiseData = async () => {
      try{
        openMediaUploadLoader();
        let user;
        if (phoneNumber) {
            user = await gettingUserByPhoneNumber()
        }
  
        let updatedWalkerId = walkerId;
        if(filterWalkerPhoneNumber){
          updatedWalkerId = searchWalkerWithPhoneNumber(filterWalkerPhoneNumber)?.id
        }
  
        const data = await getStatistics(accessToken, {
            ...(filterSlotStartTime && filterSlotEndTime) && {
            slotStartTime: filterSlotStartTime,
            slotEndTime: filterSlotEndTime
        },
        ...(walkingSessionsType) && {
            walkingSessionsType: walkingSessionsType.toUpperCase()
        },
        ...(phoneNumber && user) && {
            userId: user.id
        },
        ...(updatedWalkerId) && {
            walkerId: updatedWalkerId
        },
        ...stateCode && { stateCode }, 
        ...districtId && { districtId }, 
        ...pinCode && {pinCode},
        isUserWiseStatistics: true
      },setExportedData, dispatch)
    
      let exportedArray = [];

      data.map((stats) => {
        exportedArray.push({
          "User Name": stats.user.name,
          "Phone Number": stats.user.phoneNumber,
          "artificiallyCompletedWalks": stats.artificiallyCompletedWalks,
          "cancelledWalks": stats.cancelledWalks,
          "completedWalks": stats.completedWalks,
          "delayedWalks": stats.delayedWalks,
          "manuallyCompletedWalks": stats.manuallyCompletedWalks,
          "replacedWalks": stats.replacedWalks,
          "scheduledWalks": stats.scheduledWalks,
          "unattendedWalks": stats.unattendedWalks
          })
      });
      
      exportHandler(exportedArray, `User-Wise-Statistics-Data.csv`)
      setIsExporting(false);
      closeMediaUploadLoader()
      }catch(err){
        console.log(err)
        setIsExporting(false); 
        closeMediaUploadLoader();

      }

  }

  return statisticsData && (
    <div style={{background:"white", borderRadius:"15px", padding:"10px"}}>
      <h1 style={{ fontSize: "1.5rem" }}>Statistics Overview</h1>
      <div style={{ display: "flex", color: "#1B2149", flexWrap: "wrap" }}>
        {bigboxes.map((box) => {
          return (
            <div
              style={{
                height: "8rem",
                marginBottom: "1rem",
                width: "18rem",
                backgroundColor: box.bgColor,
                marginRight: "1rem",
                borderRadius: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <h2
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "400",
                  marginBottom: 0,
                  marginTop: "1.6rem",
                }}
              >
                {box.title} {box.icon}{" "}
              </h2>
              <h3 style={{ fontSize: "1.3rem", marginTop: 0 }}>{box.data}</h3>
            </div>
          );
        })}

        {smallboxes.map((box) => {
          return (
            <div
              style={{
                height: "8rem",
                marginBottom: "1rem",
                width: "14rem",
                backgroundColor: "#F3F6F8",
                marginRight: "1rem",
                borderRadius: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <h2
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "400",
                  marginBottom: 0,
                  marginTop: "1.8rem",
                }}
              >
                {box.title} 
              </h2>
              <h3 style={{ fontSize: "1.3rem", marginTop: 0 }}>{box.data}</h3>
            </div>
          );
        })}
      </div>
      <Button variant='contained' disabled={isExporting} style={{backgroundColor:"#974EC3", color: "#fff", marginTop: "2rem", fontWeight: "bold", fontSize: "1rem", width: "5rem"}} text="Export Customer Statistics" clickAction={exportCustomerWiseData}/>
    </div>
  );
};

export default StatisticsOverview;
