import { Autocomplete, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { WalkSlotDuration, walkerFilterType } from '../../redux/constants';
import { getJsonDateFromDateTime } from '../../utils/func';
import { getWalkersByFilter } from '../SalesEditWalkCard/apis';
import { setActionNotif } from '../../redux/actions';

export default function SalesDefaultWalkerAssignCard({
    scheduledDateTime, plan, setAssignmentDetails, scheduleIndex, leftWalks, setLeftWalks, petIndex, extend, noOfWalks, walkIndex, walksPerDay, showFindWalkersOnly, disableFindWalkers, hotspotId, updateSchedule = true, schedule }) {
    const [scheduledAt, setScheduledAt] = useState(scheduledDateTime);
    const [subCitySelected, setSubCitySelected] = useState("");
    const [filteredWalkers, setFilteredWalkers] = useState([]);
    const [walkerSelected, setWalkerSelected] = useState("");
    const [walkIteration, setWalkIteration] = useState(0);
    const [totalWalksPerSchedule, setTotalWalksPerSchedule] = useState(0)
    const dispatch = useDispatch();
        
    const { accessToken } = useSelector(state => state.sales)

    const classes = {
        addWalkerBtn: {
          height: "48px",
          padding: "0 18px",
          fontSize: 16,
          display: "inline",
          backgroundColor: "#7D97FF",
          color: "white",
          fontWeight: "bold"
        }
      }

      useEffect(()=>{
        setWalkIteration(Math.floor(noOfWalks/plan?.walksPerDay));
        setTotalWalksPerSchedule(0)
      },[noOfWalks])

      const getWalksForFindWalkers = () => {
        let totalWalks = 0;
        totalWalks = walkIteration;
          let isFirstWalkBig = new Date(schedule[0]).getDate() > new Date(schedule[1]).getDate();
          if(leftWalks !== 0){
            if(isFirstWalkBig && scheduleIndex === 1){
              totalWalks++;
              setLeftWalks(leftWalks-1);
           }else{
             if(!isFirstWalkBig && scheduleIndex === 0){
               totalWalks++;
               setLeftWalks(leftWalks-1);
             }
           }
         } 
        setTotalWalksPerSchedule(totalWalks)
        return totalWalks;
      }

    useEffect(() => {
        setFilteredWalkers([]);
    }, [scheduledAt, hotspotId])
    
    const findWalkers = async () => {
        try {
            if(plan && (!noOfWalks || noOfWalks < plan?.walksPerDay)){
                return dispatch(setActionNotif({
                    open: true,
                    message: "Number of Walks Should be more than or equal to plan walks per day",
                    severity: "error",
                }));
            }

            if(!extend && (plan.walksPerDay % 2 === 0) && (noOfWalks % plan.walksPerDay !== 0)) 
                return dispatch(setActionNotif({
                    open: true,
                    message: `Only Even Number of Walks allowed When booking ${plan.walksPerDay} walks per day!`,
                    severity: "error",
                }));

            
            const filterType = plan ? walkerFilterType.DEFAULT_WALKS : walkerFilterType.SINGLE_WALK;
            const data = await getWalkersByFilter({
                accessToken,
                dispatch,
                filterType,
                ...filterType === walkerFilterType.SINGLE_WALK && { 
                    slotStartTime:scheduledAt,
                    slotDuration: WalkSlotDuration.MEDIUM
                },
                subCityId:subCitySelected?.id,
                scheduledAt,
                plan,
                noOfWalks:totalWalksPerSchedule ? totalWalksPerSchedule : getWalksForFindWalkers(),
                walksPerDay, 
                walkIndex ,
                hotspotId
            });
            if (data) setFilteredWalkers(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(()=>{
        setFilteredWalkers([]);
        setWalkerSelected("")
    },[noOfWalks])

    useEffect(() => {
        if (scheduledAt !== scheduledDateTime)
            setScheduledAt(scheduledDateTime)
    }, [scheduledDateTime])

    useEffect(() => {
        if(scheduledAt){
            filteredWalkers?.length && setFilteredWalkers([])
            walkerSelected && setWalkerSelected("")
        }
    },[scheduledAt, subCitySelected])

    return (
        <div>
            {!showFindWalkersOnly && (<TextField
                id="datetime-local"
                label={`Walk ${scheduleIndex + 1}`}
                type="datetime-local"
                value={scheduledAt ? getJsonDateFromDateTime(scheduledAt) : scheduledAt}
                required
                disabled={Boolean(extend && scheduledAt)}
                onChange={(e) => setScheduledAt(new Date(e.target.value).getTime())}
                sx={{ width: 250 }}
                style={{ margin: "0.1rem", width: "100%" }}
                InputLabelProps={{
                    shrink: true,
                }}
            />)}

            {/* <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "2rem", cursor: disableFindWalkers ? "not-allowed": "pointer"}}>
            <Button
                    onClick={findWalkers}
                    style={classes.addWalkerBtn}
                    disabled={disableFindWalkers}
            >Find Walkers
            </Button>

            <div className="form-field-wrapper">
                <Autocomplete
                    disablePortal
                    id="combo-box"
                    options={filteredWalkers}
                    sx={{ width: "100%", minWidth: "14rem" }}
                    value={walkerSelected?.name || "NO NAME"}
                    isOptionEqualToValue={(option, value) =>
                        option.name === value || value === "NO NAME"
                    }
                    onChange={(event, value) => {
                        setWalkerSelected(value);
                        value && scheduledAt && setAssignmentDetails(value, scheduledAt, scheduleIndex, petIndex, updateSchedule);
                    }}
                    getOptionLabel={(option) =>
                        option.name && option.phoneNumber
                            ? option.name + " " + option.phoneNumber
                            : option
                    }
                    renderInput={(params) => <TextField {...params} label="Walker" />}
                />
            </div>
            </div>
            */}
        </div>
    )
}
