import { setActionNotif } from "../../redux/actions";
import { SalesCity, SourceFilters, WalkingType } from "../../redux/constants";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { getUserByPhoneNumber } from "../../redux/dataFetchers/appUserApis";
import { request } from "../../utils/apiRequest"

export const getWalkRequestsByFilter = async (rest) => {
    const {
        accessToken, dispatch,
        startTime, endTime, subCityId, delayed, sortAscending,replaced,unassignedWalker,
        // filtersApplied,
        walkerId, walkingSessionsType, userPhoneNumber, cityFilter = '', status,
        page, limit, stateCode, districtId, pinCode, exportData, subscriptionType, trialType, cancelReasonFilter, isLeave, isPenalty,
        hubIds, cityCode, stage,currentCycle, type, assignedWalkerId, overdue, requestId, forceStopFilter
     } = rest;
    let user;
    // if (filtersApplied?.userPhoneNumber && userPhoneNumber) {
    if (userPhoneNumber) {
        user = await getUserByPhoneNumber(userPhoneNumber, accessToken, (data) => data, (error) => {
            dispatch(setActionNotif({
                open: true,
                message: "User with Phone not found!",
                severity: "error"
            }))
            throw new Error("User with phone not found : ", error)
        })
    }
    
    console.log({requestId})
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        body:JSON.stringify({
            filters:{
                ...(startTime && endTime) && {
                    startTime,
                    endTime
                },
                ...(walkerId) && {
                    currentWalkerId:walkerId
                },
                ...(assignedWalkerId) && {
                    assignedWalkerId
                },
                ...(walkingSessionsType) && {
                    ...walkingSessionsType === WalkingType.TRIAL ? { trial:true } : { trial:false }
                },
                ...(userPhoneNumber && user) && {
                    userId: user.id
                },
                ...(status?.length) && {
                    status
                },
                ...hubIds?.length && { hubIds },
                ...cityCode?.length && { cityCode },
                ...stage?.length && { stage },
                ...currentCycle && { currentCycle },
                ...type && { type },
                ...overdue && { overdue },
                ...requestId && { id:requestId },
                ...forceStopFilter && {
                    forceStop:true
                }
            },
            sort:{
                createdAt:-1
            }
        })
    };

    const requestUrl = `${nodeServerBaseUrl}/walk-requests?page=${page}&limit=${limit}`

    return request(requestUrl,
        requestOptions,
        async (response) => {
            return {data: response.data?.data, totalCount: response?.data?.totalCount};
        },
        async (error) => {
            dispatch(setActionNotif({
                open: true,
                message: "Something went wrong!",
                severity: "error"
            }))
            throw error;
        })
}
export const fetchDistrictsHandler = async (accessToken, value) => {
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
    };
    
    return await request(
      `${nodeServerBaseUrl}/districts?stateCode=` + value?.code,
      requestOptions,
        (response) => {
          return response?.data?.data;
      }
    );

  };

export const triggerWalkRequestAction = async (walkRequestId, action, dispatch, accessToken, body, closeWalkRequestDetailsModal, closeRequestActionConfirmModal, reloadWalkRequests) => {
    try{
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body:JSON.stringify(body)
        };

        await request(`${nodeServerBaseUrl}/walk-requests/${walkRequestId}/${action}`, requestOptions,
        (response) => {
            closeWalkRequestDetailsModal()
            closeRequestActionConfirmModal()
            reloadWalkRequests()
            return dispatch(setActionNotif({
                open: true,
                message:   `${action} Successfull!`,
                severity: "success",
            }));

        },
        (error) => {
            return dispatch(setActionNotif({
                open: true,
                message: error?.message ?? "Something went wrong !",
                severity: "error",
             }));
        })
        
      }catch(err){
        console.log(err)
        return dispatch(setActionNotif({
          open: true,
          message: "Something went wrong !",
          severity: "error",
       }));
      }
}
