import "./SalesDashboard.css";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { LogoutList, salesDashboardNavList } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderVisibility } from "../redux/actions";
import { Helmet } from "react-helmet-async";
import { ThemeProvider, createTheme, styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "./global-styles.css";
import { getSubCities } from "../redux/dataFetchers/salesDashboardApis";
import { Tooltip } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavBarListItem = ({ navItem, location, navigate, open }) => {
  return <ListItem
    key={navItem.pos}
    disablePadding
    sx={{ display: "block" }}
    onClick={() => {
      navItem.action && navItem.action(navigate)
    }}
  >
    <Tooltip placement="right" title={navItem.linkText}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {navItem?.linkTo === location.pathname
            ? navItem.activeIcon
            : navItem.Icon}
        </ListItemIcon>
        <ListItemText
          primary={navItem.linkText}
          sx={{
            opacity: open ? 1 : 0, color: navItem?.linkTo === location.pathname
              ? "#EF7123"
              : "#fff"
          }}
        />
      </ListItemButton>
    </Tooltip>
  </ListItem>
}

const classes = {
  appBar: {
    backgroundColor: "#0E111D",
  },
  navLink: {
    textDecoration: "none",
    color: "#ffffff",
  },
};

export default function SalesDashboard() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    sales: { subCities },
  } = useSelector((state) => state);
  const location = useLocation();
  const navigate = useNavigate();

  const createdTheme = createTheme({
    typography: {
      fontFamily: 'ApercuPro, Arial, sans-serif',
    },
  });

  useEffect(() => {
    let isPageAuthenticated = salesDashboardNavList.some((item) => item.linkTo === location.pathname)
    if (!isPageAuthenticated) navigate(salesDashboardNavList[0].linkTo);
  }, [navigate])


  useEffect(() => {
    dispatch(setHeaderVisibility(false));
  }, []);

  useEffect(() => {
    !subCities?.length && dispatch(getSubCities());
  }, [subCities]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const currentTab = salesDashboardNavList.find((item) => item.linkTo === location.pathname)
  return (
    <ThemeProvider theme={createdTheme}>
      <Box sx={{ display: "flex", backgroundColor: "#F3F6F8" }} >
        <CssBaseline />
        <AppBar position="fixed" open={open} style={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Typography variant="h4" noWrap component="div" style={{ margin: "auto 0", fontSize: "36px", fontWeight: 900 }}>
                {currentTab?.linkText}
              </Typography>

            </div>

          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} >
          <DrawerHeader style={{ backgroundColor: "#0E111D" }}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon style={{ color: "#ffffff" }} />
              ) : (
                <ChevronLeftIcon style={{ color: "#ffffff" }} />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List style={{ backgroundColor: "#0E111D", height: "100%", marginTop: '-2px' }}>
            {salesDashboardNavList.map((navItem, index) => (
              <Link to={navItem.linkTo} style={classes.navLink} key={navItem.pos}>
                <NavBarListItem navItem={navItem} location={location} navigate={navigate} open={open} />
              </Link>
            ))}
          </List>
          <List style={{ backgroundColor: "#0E111D", color: "#EF7123" }}>
            {LogoutList?.map(navItem => (<NavBarListItem navItem={navItem} location={location} navigate={navigate} open={open} key={navItem.pos} />))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: location.pathname === "/sales/dashboard/location" ? 0 : 3 }}>
          <DrawerHeader />
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
