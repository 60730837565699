import Geocode from "react-geocode";
import env from "../environment/production.json";
import { Polygon } from "@react-google-maps/api"

Geocode.setApiKey(env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

export const reverseGeocode = async (lat, lng, resolve, reject) => {
  Geocode.fromLatLng(lat, lng).then(
    (response) => {
      const addressComponents = response.results[0].address_components;
      let city,
        state,
        country,
        pin,
        locality = "",
        address = response.results[0].formatted_address;
      for (let component of addressComponents) {
        for (let type of component.types) {
          switch (type) {
            case "locality":
              city = component.long_name;
              break;
            case "sublocality":
            case "sublocality_level_1":
            case "sublocality_level_2":
              locality = component.long_name;
              break;
            case "administrative_area_level_1":
              state = component.long_name;
              break;
            case "country":
              country = component.long_name;
              break;
            case "postal_code":
              pin = component.long_name;
              break;
            case "street_address":
              address = component.long_name;
              break;
            default:
              break;
          }
        }
      }
      resolve({
        city,
        state,
        country,
        pin,
        address,
        locality,
        lat,
        lng
      });
    },
    (error) => reject(error)
  );
};

export const detectCurrentLocation = async () => {
  if ("geolocation" in navigator) {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        (position) => resolve({lat:position.coords.latitude, lng:position.coords.longitude}),
        (error) => reject(error),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      )
    );
  } else {
    console.log("Geolocation is not supported for this Browser/OS.");
  }
};

export const validateLocationWithinServiceArea = (latLng,polygon) => {
  console.log({latLng,polygon})
  let containsLocation = window.google.maps.geometry.poly.containsLocation;
  if(containsLocation)
    return containsLocation(latLng,polygon)
  else
    return false;
  //  containsLocation(latLng,polygon);
}

export function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadiusKm = 6371; // Earth's radius in kilometers

  const lat1Rad = degreesToRadians(lat1);
  const lat2Rad = degreesToRadians(lat2);
  const deltaLat = degreesToRadians(lat2 - lat1);
  const deltaLon = degreesToRadians(lon2 - lon1);

  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadiusKm * c;

  // Round the result to 3 decimal places
  return Math.round(distance * 100) / 100;
}