import { useEffect } from "react";
import "./NotFound.css";
import external_links from "../../data/external_links";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function NotFound() {
  const query = useLocation().search;
  const mobileDevice = useMediaQuery("(max-width:640px)");

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  useEffect(() => {
    //iOS
    if (iOS()) {
      window.location.href = external_links.splootIosApp
      return;
    }
    //Android
    if (mobileDevice) {
      let link = external_links.splootAndroidApp;
      link = `${link}${encodeURIComponent(query?.substring(1))}`

      window.location.href = link;
      return;
    }
  }, [mobileDevice])

  return (
    <div className="not-found-bg">
    </div>
  )
}
