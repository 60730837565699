import { setActionNotif } from "../../redux/actions";
import { RescheduleWalksType, SEARCH_PERIOD_IN_DAYS_AUTOMATCHING, WalkType } from "../../redux/constants";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { request } from "../../utils/apiRequest";

export const getUserByEmail = async (...rest) => {
    const [accessToken, dispatch, email] = rest;
    const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body:JSON.stringify({
            email
        })
    };

    return new Promise(async (resolve,reject) => {
      request(`${nodeServerBaseUrl}/cms/users/search`,
      requestOptions,
      async (response) => {
          const data = response.data?.data;
          resolve(data);
      },
      async (error) => {
          dispatch(setActionNotif({
              open:true,
              message:error?.message,
              severity:"error"
          }))
          reject(error)
      })
    })
}

export const getPlanOptions = async (...rest) => {
    const [accessToken] = rest;
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
    };

    return new Promise(async (resolve,reject) => {
      request(`${nodeServerBaseUrl}/plan-options`,
      requestOptions,
      async (response) => {
          const data = response.data?.data;
          resolve(data);
      },
      async (error) => {
          reject(error)
      })
    })
}

export const getPlans = async (...rest) => {
    const [accessToken] = rest;
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
    };

    return new Promise(async (resolve,reject) => {
      request(`${nodeServerBaseUrl}/plans`,
      requestOptions,
      async (response) => {
          const data = response.data?.data;
          resolve(data);
      },
      async (error) => {
          reject(error)
      })
    })
}

export const getSlots = async (accessToken, lat, lng, slotDuration, slotTrial, userId, slotRadius) => {
    const requestBody = {
        latitude: lat,
        longitude: lng,
        slotDuration: slotDuration, 
        trial: slotTrial,
        userId: userId,
        maxDistance: slotRadius*1000,
        searchPeriodInDays: SEARCH_PERIOD_IN_DAYS_AUTOMATCHING
    }
    const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }, 
        body: JSON.stringify(requestBody)
    };

    return new Promise(async (resolve,reject) => {
        request(`${nodeServerBaseUrl}/auto-matching/slots`,
        requestOptions,
        async (response) => {
            const data = response.data;
            resolve(data);
        },
        async (error) => {
            reject(error)
        })
      })
}

export const getHotspots = async (
  accessToken,
  slotRadius,
  lat,
  lng,
) => {
  const requestBody = {
    maxDistance: slotRadius*1000,
    latitude: lat,
    longitude: lng,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestBody),
  };

  return new Promise(async (resolve, reject) => {
    request(
      `${nodeServerBaseUrl}/hotspots`,
      requestOptions,
      async (response) => {
        const data = response.data?.data;
        resolve(data);
      },
      async (error) => {
        reject(error);
      }
    );
  });
};

export const getSubscriptionSlots = async (
  accessToken,
  walkType,
  subscriptionPlan,
  hotspotId, 
  walkerId,
  userId,
  subscriptionId,
  orderId,
  pets,
  slotStartTime,
  preferredTime
) => {
  let requestBody;
  console.log(walkType, 'walk type')
  if (walkType === RescheduleWalksType.DEFAULT_WALKS) {
    requestBody = {
      filterType: walkType,
      plan: subscriptionPlan,
      hotspotId: hotspotId,
      verification: "COMPLETED",
    };
  } else if (walkType === RescheduleWalksType.UPCOMING_WALKS) {
    requestBody = {
      filterType:walkType,
      walkerId: walkerId, //mongoId
      userId: userId, //mongoId
      subscriptionId: subscriptionId, // mongoId if subscriptionId exists
      orderId: orderId, // mongoId if orderId exists  
      scheduledAt: new Date(preferredTime).getTime(),
      petIds: pets, //array of mongoIds
      hotspotId:hotspotId, //mongoId
      verification:"COMPLETED"
     }
    
  } else if (walkType === RescheduleWalksType.SINGLE_WALK) {
    requestBody = {
      filterType: walkType,
      slotStartTime:slotStartTime,
      hotspotId:hotspotId, //mongoId
      verification:"COMPLETED"
     }
  }

  console.log(requestBody, 'body')
  
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestBody),
  };

  return new Promise(async (resolve, reject) => {
    request(
      `${nodeServerBaseUrl}/walkers/merged-schedules`,
      requestOptions,
      async (response) => {
        const data = response.data?.data;
        resolve(data);
      },
      async (error) => {
        reject(error);
      }
    );
  });
};

export const getWalkersFreeSlots = async (
  accessToken,
  walkType,
  subscriptionPlan,
  walkerId,
  schedule,
  slotDuration,
  pets,
  subscriptionId, 
  orderId,
  userId,
  noOfWalks,
  scheduledAt,
  startTime, 
  endTime,
) => {
  let requestBody;
  if (walkType === RescheduleWalksType.DEFAULT_WALKS) {
    let updatedSchedule = new Date(schedule)
    updatedSchedule.setHours(0, 0, 0, 0)

    requestBody = {
        "scheduledAt": updatedSchedule.getTime(),
        "filterType": walkType,
        "verification": "COMPLETED",
        "noOfWalks": noOfWalks,
        "plan": subscriptionPlan
    };
  } else if (walkType === RescheduleWalksType.UPCOMING_WALKS || walkType === RescheduleWalksType.TIME_RANGE_WALKS) {
    requestBody = {
    "filterType": walkType,
    "petIds": pets,
    "scheduledAt": scheduledAt,
    "subscriptionId": subscriptionId, // if exists in walk
    "orderId": orderId, //if exists in walk
    "userId": userId,
    "verification": "COMPLETED",
    ...orderId ? {"orderId": orderId} : {} ,
    ...subscriptionId ? {"subscriptionId": subscriptionId} : {} ,
    ...walkType === RescheduleWalksType.TIME_RANGE_WALKS && startTime && endTime ? {
      startTime,
      endTime
    }:{}
    }
    
  } else if (walkType === RescheduleWalksType.SINGLE_WALK  || walkType === RescheduleWalksType.DUPLICATE_WALKS) {
    console.log("hello hello hello helo")
    requestBody = {
      "scheduledAt": scheduledAt,
      "filterType": "SINGLE_WALK",
      "verification": "COMPLETED",
      "slotDuration": slotDuration
     }
  }
  
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestBody),
  };

  return new Promise(async (resolve, reject) => {
    request(
      `${nodeServerBaseUrl}/walkers/${walkerId}/slots`,
      requestOptions,
      async (response) => {
        const data = response.data?.data;
        resolve(data);
      },
      async (error) => {
        reject(error);
      }
    );
  });
};


export * from "../SalesEditWalkCard/apis";