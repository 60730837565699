import React, { useEffect } from "react";
import ModalTitle from "../../common/ModalTitle/ModalTitle";
import { useState } from "react";
import AutoCompleteInput from "../../common/AutoCompleteInput/AutoCompleteInput";
import { WalkerVerification, walkerFilterType } from "../../redux/constants";
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../utils/apiRequest";
import Button from "../Button/Button";
import { setActionNotif } from "../../redux/actions";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { getWalkersWithMetrics, searchWalkerByName } from "../../sales/Walkers/apis";
import { applyButtonHandler } from "../../sales/Location/apis";

const AssignAWalkerCard = ({
  onClose,
  hotspot,
  setSelectedMarker,
  setHotspots,
  filterSlotStartTime,
  filterSlotEndTime,
  filters
}) => {
  const {
    sales: { accessToken },
  } = useSelector((state) => state);
  const [walkerSelected, setWalkerSelected] = useState({});
  const [walkers, setWalkers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  let currentWalkerSearchRequest = null; // To keep track of the current request
  let abortController = new AbortController();
  let WalkerSearchTimeoutInstance = null

  const SearchWalkers = async (name, page, limit) => {

    if (currentWalkerSearchRequest) {
      abortController.abort();
      abortController = new AbortController(); // Create a new controller for the next request
    }
    
    // Create a signal from the controller
    const signal = abortController?.signal;

    try {
      currentWalkerSearchRequest = await searchWalkerByName({
        accessToken,
        page,
        limit,
        name,
        verified: true
      });
      if (signal?.aborted) {
        console.log("Request was cancelled");
        return null;
      }

      if (currentWalkerSearchRequest)
        setWalkers(
          currentWalkerSearchRequest?.flatMap((user) =>
            user?.phoneNumber
                ? {
                    name: user.name,
                    phoneNumber: user.phoneNumber,
                    id: user.id,
                  }
                : [],
            ),
          );
      } catch (error) {
        if (signal.aborted) {
          console.log("Request was cancelled");
          return null;
        }
        console.log(error);
      }
    }

    const onSearchWalkerNameChange = async (event) => {
        if(!event?.target?.value || !event?.target?.value?.length) return

        if(WalkerSearchTimeoutInstance) clearTimeout(WalkerSearchTimeoutInstance);
        WalkerSearchTimeoutInstance = setTimeout(() => SearchWalkers(event?.target?.value), 500)
    }

  const assignWalkerButtonHandler = async () => {
    try {
      if (Object.keys(walkerSelected).length === 0) {
        dispatch(
          setActionNotif({
            open: true,
            message: `Please Select Walker First !`,
            severity: "error",
          })
        );
        return;
      }

      const requestHeader = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          hotspotId: hotspot.id,
        }),
      };

      let url = `${nodeServerBaseUrl}/walkers/${walkerSelected.id}`;
      await request(
        url,
        requestHeader,
        (res) => {
          dispatch(
            setActionNotif({
              open: true,
              message: `Walker Assign Successfully`,
              severity: "success",
            })
          );
          applyButtonHandler(
            {
              startTime: filterSlotStartTime,
              endTime: filterSlotEndTime,
              ...(filters["stateCode"].code
                ? { stateCode: filters["stateCode"].code }
                : {}),
              ...(filters["districtId"].id
                ? { districtId: filters["districtId"].id }
                : {}),
              ...(filters["pinCode"] ? { pinCode: filters["pinCode"] } : {}),
            },
            dispatch,
            accessToken,
            setHotspots
          );
          onClose();
          setSelectedMarker(null);
        },
        (error) => {
          dispatch(
            setActionNotif({
              open: true,
              message: `Something went wrong, ${JSON.stringify(error)}`,
              severity: "error",
            })
          );
        }
      );
    } catch (err) {}
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "500px",
        height: "300px",
        background: "white",
      }}
    >
      <ModalTitle title={"Assign a Walker"} onClose={onClose} />

      <div style={{ margin: "1rem" }}>
        <AutoCompleteInput
          optionsList={walkers}
          selected={walkerSelected?.name || ""}
          sx={{ width: "100%" }}
          setSelected={setWalkerSelected}
          value={walkerSelected?.name || ""}
          validationFunc={(option, value) =>
            option?.name === value || value === ""
          }
          findlabelFunc={(option) => option?.name ?? option}
          label={"Search Walker"}
          onTextFieldChange={(e)=>onSearchWalkerNameChange(e, "name")}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          clickAction={assignWalkerButtonHandler}
          text="Assign Walker"
        />
      </div>
    </div>
  );
};

export default AssignAWalkerCard;
