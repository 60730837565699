const getRootHost = (host) => {
  if (!host) return "sploot.space";

  let hostArr = host.split(".");
  return hostArr.slice(hostArr.length - 2, hostArr.length).join(".");
};

export function setCookie(name, value, expires, domain) {
  domain = getRootHost(domain);
  var date = new Date();
  date.setTime(expires);
  expires = "; expires=" + date.toUTCString();

  document.cookie = `${name}=${
    value || ""
  }${expires}; domain=${domain}; path=/;`;
  // console.log(document.cookie);
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let cookieArray = document.cookie.split(";");
  for (let cookie of cookieArray) {
    while (cookie.charAt(0) === " ")
      cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) === 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
}

export function eraseCookie(name, domain) {
  domain = getRootHost(domain);
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain};`;
}
