import { setActionNotif } from "../../redux/actions";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { request } from "../../utils/apiRequest";

export const getWalkersWithMetrics = async (...rest) => {
  const [accessToken, dispatch, setLoadingWalkers, filters, page, limit, exportData] = rest;
  const { verification, addressFilter, walkerId, ratingFilter, phoneNumber, walkerTag, stateCode, districtId, pinCode, morningCheckinStatus, eveningCheckinStatus, hubIds, cityCode } = filters;
 
  const requestBody = {
    filters: {
      ...(verification && { verification }),
      ...(addressFilter && { addressFilter }),
      ...(walkerId && { walkerId }),
      ...(phoneNumber && {phoneNumber}),
      ...(ratingFilter && { ratingFilter }),
      ...(walkerTag && { walkerTag }),
      ...(stateCode && { stateCode }),
      ...(districtId && { districtId }),
      ...(pinCode && {pinCode}),
      ...(hubIds?.length && {hubIds}),
      ...(cityCode?.length && {cityCode}),
      ...morningCheckinStatus && {morningCheckinStatus},
      ...eveningCheckinStatus && {eveningCheckinStatus},
    },
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestBody)
  };

  return new Promise(async (resolve, reject) => {
    setLoadingWalkers(true)
    request(`${nodeServerBaseUrl}/walkers/metrics?page=${page}&limit=${limit}${exportData ? "&exportData=true" : ""}`,
      requestOptions,
      async (response) => {

        setLoadingWalkers(false)
        resolve({data: response.data?.data, totalCount: response?.data?.totalCount});
      },
      async (error) => {
        setLoadingWalkers(false)
        dispatch(setActionNotif({
          open: true,
          message: "Something went wrong!",
          severity: "error"
        }))
        reject(error)
      })
  })
}

export const getWalkerStatistics = async (...rest) => {
  const [accessToken, dispatch, setLoadingStatistics, filters] = rest;
  const { walkerId, cityCode, hubIds ,stateCode, districtId, pinCode, startDateTime, endDateTime } = filters;
  const requestBody = {
    ...startDateTime && {startDateTime},
    ...endDateTime && {endDateTime},
    ...(walkerId && { walkerId }),
    ...(stateCode && { stateCode }),
    ...(districtId && { districtId }),
    ...(pinCode && {pinCode}),
    ...(hubIds?.length && {hubIds}),
    ...(cityCode?.length && {cityCode}),
  };
  
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestBody)
  };

  return new Promise(async (resolve, reject) => {
    setLoadingStatistics(true)
    request(`${nodeServerBaseUrl}/walkers/statistics`,
      requestOptions,
      async (response) => {
        const data = response.data?.data;

        setLoadingStatistics(false)
        resolve(data);
      },
      async (error) => {
        setLoadingStatistics(false)
        dispatch(setActionNotif({
          open: true,
          message: "Something went wrong!",
          severity: "error"
        }))
        reject(error)
      })
  })
}

export const getWalkerCheckinHistory = async (...rest) => {
  const [accessToken, dispatch, filters, page, limit] = rest;
  const { walkerId, stateCode, districtId, pinCode, cityCode, hubIds ,startDateTime, endDateTime } = filters;
  const requestBody = {
    ...startDateTime && {startDateTime},
    ...endDateTime && {endDateTime},
    ...(walkerId && { walkerId }),
    ...(stateCode && { stateCode }),
    ...(districtId && { districtId }),
    ...(pinCode && {pinCode}),
    ...(hubIds?.length && {hubIds}),
    ...(cityCode?.length && {cityCode}),
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestBody)
  };

  return new Promise(async (resolve, reject) => {
    // setLoadingCheckinHistory(true)
    request(`${nodeServerBaseUrl}/walkers/checkin/history?page=${page}&limit=${limit}`,
      requestOptions,
      async (response) => {
        const data = response.data;

        // setLoadingCheckinHistory(false)
        resolve(data);
      },
      async (error) => {
        // setLoadingCheckinHistory(false)
        dispatch(setActionNotif({
          open: true,
          message: "Something went wrong!",
          severity: "error"
        }))
        reject(error)
      })
  })
}

export const updateWalkerCheckin = async ({checkinUpdateBody, accessToken, dispatch, walkerId}) => {
  const {kitValid, tshirtValid, checkinSlot} = checkinUpdateBody;
  const requestBody = {
    checkinSlot,
    ...(kitValid || kitValid === false) && {kitValid},
    ...(tshirtValid || tshirtValid ===false) && {tshirtValid},
  };
  
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(requestBody)
  };

  return new Promise(async (resolve, reject) => {
    request(`${nodeServerBaseUrl}/walkers/${walkerId}/checkin`,
      requestOptions,
      async (response) => {
        const data = response.data?.data;
        resolve(data);
      },
      async (error) => {
        dispatch(setActionNotif({
          open: true,
          message: error?.message || error,
          severity: "error"
        }))
        reject(error)
      })
  })
}

export const searchWalkerByName = async ({ accessToken, page, limit, name, verified }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    return await request(
      `${nodeServerBaseUrl}/walkers/search?` +
      new URLSearchParams({
        name,
        ...(verified && { verified }),
        ...(page && { page }),
        ...(limit && { limit }),
      }),
      requestOptions,
      async (response) => {
        return response?.data?.data;
      },
      async (error) => {
        throw new Error(error?.message ?? error);
      },
    );
  } catch (err) {
    throw err;
  }
}
