import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import env from '../environment/production.json';

let middleware = [thunk];

// Allow redux logger only in dev mode
if (env.NODE_ENV !== "production") {
    middleware.push(logger);
}

const store = createStore(rootReducer,applyMiddleware(...middleware));

export default store;