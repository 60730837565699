import { createContext, forwardRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import {
  HubStatus,
  WalkerLeavesStatus,
  defaultCountry,
  leaveCrudActions,
  walkerFilterType,
} from "../../redux/constants";
import DateTimePickerComponent from "../../components/DateTimePickerComponent/DateTimePickerComponent";
import { Autocomplete, Button, Modal, TextField } from "@mui/material";
import { getWalkersByFilter } from "../../components/SalesEditWalkCard/apis";
import { useSelector, useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import AutoCompleteInput from "../../common/AutoCompleteInput/AutoCompleteInput";
import { fetchDistrictsHandler } from "../Walks/apis";
import { getStates } from "../../redux/dataFetchers/appUserApis";
import SalesLeaveCard from "../../components/SalesLeaveCard/SalesLeaveCard";
import { deleteLeave, getLeavesByFilter } from "./apis";
import usePaginated from "../usePaginatedData";
import SalesLeaveDetailCard from "../../components/SalesLeaveDetailCard/SalesLeaveDetailCard";
import Loader from "../../components/Loader/Loader";
import { MediaUploadLoaderContext } from "../../App";
import ModalTitle from "../../common/ModalTitle/ModalTitle";
import { makeStyles } from "@mui/styles";
import { getCustomCities } from "../../redux/dataFetchers/salesDashboardApis";
import { getHubs } from "../apis";

const WalkerPhoneCustomInputElement = forwardRef((props, ref) => (
  <TextField
    {...props}
    required
    id={`outlined-basic-${Math.random()}`}
    label="Walker Phone"
    variant="outlined"
    fullWidth
    inputRef={ref}
  />
));


export const LeavesContext = createContext();
const classes = {
  addLeaveBtn: {
    padding: "0 20px",
    fontSize: 16.5,
    display: "inline",
    backgroundColor: "#7D97FF",
    color: "white",
    fontWeight: "bold",
    marginTop: "2rem",
  },
  tabSwitchBtn: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    padding: "0.3rem 0",
  },
  walkConfirmModal: { display: "flex", flexDirection: "column" },
};

export default function Leaves() {
  const [filterSlotStartTime, setFilterSlotStartTime] =
    useState(null);
  const [filterSlotEndTime, setFilterSlotEndTime] =
    useState(null);

  const [selectedDay, setSelectedDay] = useState({
    custom: false,
  });
  const {
    sales: { accessToken, customCities },
    user: { states },
  } = useSelector((state) => state);
  const [walkers, setWalkers] = useState([]);
  const dispatch = useDispatch();
  const [walkerSelected, setWalkerSelected] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pincodes, setPincodes] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [filterWalkerPhoneNumber, setFilterWalkerPhoneNumber] = useState("");
  const [status, setStatus] = useState("")
  const [locationFilters, setLocationFilters] = useState({});
  const [leavePageOpen, setLeavePageOpen] = useState(false);
  const [currentLeave, setCurrentLeave] = useState(null);
  const [leaveCrudAction, setLeaveCrudAction] = useState(leaveCrudActions.ADD);
  const [deleteLeaveConfirmModal, setDeleteLeaveConfirmModal] = useState(false);

  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(
    MediaUploadLoaderContext
  );

  const initGetWalkerLeaves = async (page, limit) => {
    try {
      return await getLeavesByFilter({
        accessToken,
        dispatch,
        walkerSelected,
        ...(locationFilters?.stateCode && {
          stateCode: locationFilters?.["stateCode"]?.code,
        }),
        ...(locationFilters?.districtId && {
          districtId: locationFilters?.["districtId"]?.id,
        }),
        ...(locationFilters?.pinCode && {
          pinCode: locationFilters?.["pinCode"],
        }),
        ...(locationFilters?.cities?.length && {
          cityCode: locationFilters?.cities?.map((city)=>city.code),
        }),
        ...(locationFilters?.hubs?.length && {
          hubIds: locationFilters?.hubs?.map(hub => hub.id),
        }),
        page,
        limit,
        filterSlotEndTime,
        filterSlotStartTime,
        status,
        filterWalkerPhoneNumber
      });
    } catch (error) {
      console.log(error);
    }
  };

  const {
    loading: loadingWalkerLeaves,
    hasMore,
    paginatedData: walkerLeaves,
    setLoading: setLoadingWalkerLeaves,
    totalDataCount: totalCount,
  } = usePaginated(pageNo, initGetWalkerLeaves);

  const openLeavePage = () => setLeavePageOpen(true);
  const closeLeavePage = () => setLeavePageOpen(false);
  const openDeleteLeaveConfirmModal = () => setDeleteLeaveConfirmModal(true);
  const closeDeleteLeaveConfirmModal = () => setDeleteLeaveConfirmModal(false);

  const reloadLeaves = async () => {
    setPageNo(0);
    setLoadingWalkerLeaves(true);
  };

  const contextValue = {
    setLoadingParentData: setLoadingWalkerLeaves,
    closeLeavePage,
    openLeavePage,
  };

  const useStyles = makeStyles({
    input: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    },
  });

  const UIClasses = useStyles();

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loadingWalkerLeaves) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNo((prev) => prev + 1);
          setLoadingWalkerLeaves(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingWalkerLeaves, hasMore, dispatch]
  );

  const discardButtonhandler = () => {
    setWalkerSelected(null);
    setLocationFilters({});
    setFilterSlotStartTime(null);
    setFilterSlotEndTime(null);
    reloadLeaves();
    setStatus("")
    setSelectedDay({
      custom:false
    })
  };

  useEffect(async () => {
    openMediaUploadLoader();
    try {
      const walkers = await getWalkersByFilter({
        accessToken,
        dispatch,
        filterType: walkerFilterType.SINGLE_WALK,
      });
      setWalkers(walkers);
      closeMediaUploadLoader();
    } catch (err) {
      closeMediaUploadLoader();
    }
  }, []);

  const getHubsHandler = async ({ cityCodes, status }) => {
    try{
      const data = await getHubs({ cityCodes, status })
      setHubs(data)
    } catch(error){

    }
  }

  useEffect(() => {
    if (!customCities?.length) {
      dispatch(getCustomCities());
    }
    getHubsHandler({});
  }, []);

  const deleteLeavehandler = (leave) => {
    setCurrentLeave(leave);
    openDeleteLeaveConfirmModal();
  };

  const addLeaveBtnClickHandler = () => {
    setLeaveCrudAction(leaveCrudActions.ADD);
    setCurrentLeave(null);
    openLeavePage();
  };


  const editLeaveBtnClickHandler = (leave) => {
    setLeaveCrudAction(leaveCrudActions.EDIT);
    setCurrentLeave(leave);
    openLeavePage();
  };

  const deleteLeaveButtonHandler = async () => {
    try{
      openMediaUploadLoader();
      await deleteLeave(currentLeave, dispatch, accessToken);
      setCurrentLeave(null)
      closeMediaUploadLoader()
      closeDeleteLeaveConfirmModal()
      reloadLeaves();
    }catch(err){
      console.log(err)
      closeMediaUploadLoader();
    }
  };

  return (
    <LeavesContext.Provider value={contextValue}>
      <div className="sales-container">
        <div className="sales-controls">
          <div className="filters-bar">
            <div className="walk-filters">
              <DateTimePickerComponent
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                selectedStartTime={filterSlotStartTime}
                setSelectedStartTime={setFilterSlotStartTime}
                selectedEndTime={filterSlotEndTime}
                setSelectedEndTime={setFilterSlotEndTime}
              />
              <AutoCompleteInput
                optionsList={walkers}
                selected={walkerSelected?.name || ""}
                setSelected={setWalkerSelected}
                value={walkerSelected?.name || ""}
                validationFunc={(option, value) =>
                  option.name === value || value === ""
                }
                findlabelFunc={(option) => option.name ?? option}
                label={"Search Walker"}
              />
              <div className="dash-filter filter"
                style={{width:"25rem"}}
              >
                <Autocomplete
                  disablePortal
                  multiple
                  id="custom-city"
                  options={customCities}
                  sx={{ width: "100%" }}
                  value={locationFilters?.cities ?? []}
                  isOptionEqualToValue={(option, value) =>
                    option?.code === value.code || value === ""
                  }
                  getOptionLabel={(option) => option?.name ?? option}
                  onChange={(event, value) => {
                    setLocationFilters({ cities: value });
                    let cityCodes = value?.map(city => city.code)
                    getHubsHandler({ cityCodes })
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Custom City" />
                  )}
                />
              </div>
              <div className="dash-filter filter" style={{width:"25rem"}}>
                <Autocomplete
                  id="hubs"
                  options={hubs || []}
                  multiple
                  sx={{ width: "100%" }}
                  value={locationFilters?.hubs ?? []}
                  getOptionLabel={(option) => `${option?.status === HubStatus.NON_SERVICEABLE ? "(U)" : ""} ${option?.name}`}
                  onChange={(event, value) => {
                    setLocationFilters((prev) => {
                      return { ...prev, hubs:value };
                    });
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Hubs" />
                  )}
                />
              </div>
              {/* <div className="dash-filter filter">
                <Autocomplete
                  disablePortal
                  id="districts"
                  options={districts || []}
                  sx={{ width: "100%" }}
                  value={locationFilters?.districtId?.name ?? ""}
                  disableClearable={true}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value.id || value === ""
                  }
                  getOptionLabel={(option) => option?.name ?? option}
                  onChange={(event, value) => {
                    setLocationFilters((prev) => {
                      return { ...prev, districtId: value, pinCode: "" };
                    });
                    let pincodes = districts.find(
                      (district) => district.id === value.id
                    ).pincodes;
                    setPincodes(pincodes);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="District" required />
                  )}
                />
              </div> */}

              {/* <div className="dash-filter filter">
                <Autocomplete
                  disablePortal
                  id="pinCode"
                  options={pincodes}
                  sx={{ width: "100%" }}
                  value={locationFilters?.pinCode ?? ""}
                  disableClearable={true}
                  isOptionEqualToValue={(option, value) =>
                    option === value || value === ""
                  }
                  getOptionLabel={(option) => option ?? option}
                  onChange={(event, value) => {
                    setLocationFilters((prev) => {
                      return { ...prev, pinCode: value };
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Pincode" required />
                  )}
                />
              </div> */}

              <div className="dash-filter filter">
                <Autocomplete
                  disablePortal
                  id="pinCode"
                  options={Object.keys(WalkerLeavesStatus)}
                  sx={{ width: "100%" }}
                  value={status ?? ""}
                  disableClearable={true}
                  isOptionEqualToValue={(option, value) =>
                    option === value || value === ""
                  }
                  getOptionLabel={(option) => option ?? option}
                  onChange={(event, value) => setStatus(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Status" required />
                  )}
                />
              </div>

              <div className="dash-filter filter">
                  <PhoneInput
                    key="walker-emergency-phone"
                    style={{ width: "100%" }}
                    value={filterWalkerPhoneNumber}
                    onChange={setFilterWalkerPhoneNumber}
                    inputComponent={WalkerPhoneCustomInputElement}
                    defaultCountry="IN"
                    className={UIClasses.input}
                  />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: "2rem" }}>
                <Button
                  variant="contained"
                  onClick={reloadLeaves}
                  style={{
                    backgroundColor: "#974EC3",
                    color: "#fff",
                    marginTop: "2rem",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    width: "7rem",
                  }}
                >
                  Apply
                </Button>
                <Button
                  variant="contained"
                  onClick={discardButtonhandler}
                  style={{
                    backgroundColor: "#974EC3",
                    color: "#fff",
                    marginTop: "2rem",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    width: "7rem",
                  }}
                >
                  Discard
                </Button>
                <div className="sales-controls">
                  <Button
                    style={classes.addLeaveBtn}
                    onClick={addLeaveBtnClickHandler}
                  >
                    Add Leave
                  </Button>
                </div>
              </div>
              <h3
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                Count- {totalCount}
              </h3>
            </div>
          </div>
        </div>
        <div
          className="walks-info-header notify walks-heading"
          key="walks-header"
        >
          <span>Name</span>
          <span>City</span>
          <span>Applied Date</span>
          <span>Start Date</span>
          <span>End Date</span>
          <span>Status</span>
          <span>Actions</span>
        </div>
        {walkerLeaves?.map((leave, leaveIndex) => (
          <SalesLeaveDetailCard
            leave={leave}
            ref={
              leaveIndex === walkerLeaves?.length - 3 ? lastElementRef : null
            }
            index={leaveIndex + leave.id}
            deleteLeavehandler={deleteLeavehandler}
            editLeaveBtnClickHandler={editLeaveBtnClickHandler}
          />
        ))}
        {loadingWalkerLeaves && (
          <Loader
            wrapperStyle={{ height: "20vh" }}
            loaderStyle={{ color: "#974EC3" }}
          />
        )}
        <Modal open={leavePageOpen} onClose={closeLeavePage}>
          <div
            className="modal-default modal-sales"
            style={{ overflow: "auto", maxHeight: "92vh" }}
          >
            <SalesLeaveCard
              walkers={walkers}
              data={currentLeave}
              reloadLeaves={reloadLeaves}
              action={leaveCrudAction}
              currentLeave = {currentLeave}
              onClose={closeLeavePage}
            />
          </div>
        </Modal>
        <Modal
          open={deleteLeaveConfirmModal}
          onClose={closeDeleteLeaveConfirmModal}
        >
          <div
            className="modal-default modal-sales"
            style={classes.walkConfirmModal}
          >
            <ModalTitle
              title={"Delete Leave"}
              onClose={closeDeleteLeaveConfirmModal}
            />

            <div className="card-container" style={{textAlign: "center", width: "fit-content"}}>
              <span>Are you Sure you want to Delete this Leave ?</span>
            </div>

            <Button
              variant="contained"
              style={{
                height: "3rem",
                fontSize: 18,
                fontWeight: 600,
                width: "fit-content",
                display: "flex",
                margin: "0 auto",
                marginBottom: "1rem",
              }}
              color="warning"
              onClick={deleteLeaveButtonHandler}
            >
              Confirm
            </Button>
          </div>
        </Modal>
      </div>
    </LeavesContext.Provider>
  );
}
