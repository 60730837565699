import mixpanel from "mixpanel-browser";
import env from "../environment/production.json";

mixpanel?.init(env.REACT_APP_MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

// let env_check = env.NODE_ENV === "production";
let env_check = true;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel?.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel?.alias(id);
  },
  track: (name, props = {}) => {
    if (env_check) mixpanel?.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel?.people.set(props);
    },
  },
};

export let Mixpanel = actions;
