import { Autocomplete, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { SubscriptionsContext } from "../../sales/Users/Users";
import { editSubscribedUser } from "../../redux/dataFetchers/salesDashboardApis";
import { setActionNotif } from "../../redux/actions";

export default function SalesEditSubscribedUserForm({ user }) {
  const [subCitySelected, setSubCitySelected] = useState(user?.subCityAssigned)

  const { accessToken, subCities } = useSelector(state => state.sales)

  const dispatch = useDispatch();

  const { setLoadingParentData, closeEditSubscribedUserModal } = useContext(SubscriptionsContext)

  const onUserEditSuccess = () => {
        dispatch(setActionNotif({
            open: true,
            message: "SubCity Assigned To User",
            severity: "success"
        }))
        closeEditSubscribedUserModal()
        setLoadingParentData(true);
    }

    const onUserEditFailure = () => {
        dispatch(setActionNotif({
            open: true,
            message: "Something Went Wrong!",
            severity: "error"
        }))
    }

  const updateUserSubCity = async () => {
    try {
      const details = { subCityAssigned: subCitySelected?.id }
      dispatch(editSubscribedUser(
        user?.id, details, onUserEditSuccess, onUserEditFailure))
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <>
      <div className="form-field-wrapper">
        <span>{user?.name}</span>
      </div>
      <div className="form-field-wrapper">
        <Autocomplete
          disablePortal
          id="combo-box"
          options={subCities}
          sx={{ width: "100%", minWidth: "14rem" }}
          value={subCitySelected?.name || ""}
          isOptionEqualToValue={(option, value) =>
            option.name === value || value === ""
          }
          onChange={(event, value) => {
            setSubCitySelected(value);
          }}
          getOptionLabel={(option) => option.name ?? option}
          renderInput={(params) => <TextField {...params} label="SubCity" />}
        />
      </div>
      <Button onClick={updateUserSubCity} style={{color:"#974EC3"}}>Update SubCity</Button>
    </>
  )
}