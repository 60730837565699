import CircleIcon from "@mui/icons-material/Circle";

const Information = () => {
  return (
    <div
      style={{
        background: "white",
        width: "10rem",
        height: "4rem",
        borderRadius: "0.6rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        right: "4rem",
        bottom: "1rem",
      }}
    >
      <div>
        <div style={{fontSize:"0.8rem"}}>
          <CircleIcon
            style={{
              fontSize: "0.6rem",
              position: "relative",
              top: "1px",
              left: "-10px",
              color: "#00B53C",
            }}
          />
          Active hotspot
        </div>
        <div style={{fontSize:"0.8rem"}}>
          <CircleIcon
            style={{
              fontSize: "0.6rem",
              position: "relative",
              top: "1px",
              left: "-10px",
              color: "#F43939",
            }}
          />
          Inactive hotspot
        </div>
      </div>
    </div>
  );
};

export default Information;
