import { Autocomplete, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Slider } from "@mui/material";
import { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignWalker,
  updateSelectedWalkingSessionsOfUser,
  updateWalk,
} from "../../redux/dataFetchers/salesDashboardApis";
import { WalksContext } from "../../sales/Walks/Walks";
import { getWalkersByFilter } from "./apis";
import Checkbox from "@mui/material/Checkbox";
import "./SalesEditWalkCard.css";
import { EditWalkMode, RescheduleWalksType, WalkStatus, WalkerReplacementReason, WalkerVerification, walkerFilterType } from "../../redux/constants";
import { MediaUploadLoaderContext } from "../../App";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setActionNotif } from "../../redux/actions";
import ModalTitle from "../../common/ModalTitle/ModalTitle";
import SalesSubscriptionSlotPicker from "../SalesSlotPicker/SalesSubscriptionSlotPicker";
import SalesTrialSlotPicker from "../SalesSlotPicker/SalesTrialSlotPicker";
import { useMediaQuery } from "@mui/material";
import { request } from "../../utils/apiRequest";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import WalkerSelectionCarousel from "../SalesWalkerSelectionCarousel/SalesWalkerSelectionCarousel";
import { isSameDay } from "../../redux/dataFetchers/common";

const label = { inputProps: { "aria-label": "Filter Checkbox" } };

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 19,
    label: "19",
  },
  {
    value: 20,
    label: "20",
  },
];

const commonBtnStyle = {
  borderRadius: "50px",
  padding: "0.8rem 0",
  textTransform: "Capitalize",
  position: "relative",
  overflow: "hidden",
  marginTop: "0.6rem",
  width: "100%",
};

const classes = {
  btn: {
    ...commonBtnStyle,
    width: "90%",
    borderRadius: "15px",
    opacity: 0.5,
    background: "#EF7123", 
    color: "#fff",
    fontSize: 24,
    fontWeight: 700,
    padding: "0.5rem 0",
    display: "flex", 
    margin: "0 auto", 
    cursor: "not-allowed"
  },
  enabledBtn: {
      ...commonBtnStyle,
      width: "90%",
      borderRadius: "15px",
      background: "#EF7123", 
      color: "#fff",
      fontSize: 24,
      fontWeight: 700,
      padding: "0.5rem 0",
      display: "flex", 
      margin: "0 auto", 
  },
  smallEnabledBtn:{
    ...commonBtnStyle,
    width: "30%",
    borderRadius: "15px",
    background: "#EF7123",
    color: "#fff",
    fontSize: 20,
    fontWeight: 700,
    padding: "0.2rem 0",
    display: "flex",
    margin: "0",
  },
  cautionText: { borderRadius: "15px", padding: "0.3rem", margin: "1rem 0", color: "#e00707", fontSize:"0.9rem", border:"1px solid e00707", borderStyle:"dashed" },
  textPrimary: { color:"#ef7123" },
  checkBox: { color:"#974EC3" }
};

const EditWalkCardStep = {
  CHOOSE_MODE:"CHOOSE_MODE",
  EDIT_WALK:"EDIT_WALK"
}

export default function SalesEditWalkCard({ walk,defaultEditMode, setIsBackdropDismissible = () => null, onClose, isLeaveParam, startDateParam, endDateParam }) {
  const initSlotStartTime = walk.scheduledAt || new Date().getTime();

  const [subCitySelected, setSubCitySelected] = useState(walk?.user?.subCityAssigned);
  const [walkerReplacementReason, setWalkerReplacementReason] = useState("");
  const [filterSlotStartTime, setFilterSlotStartTime] =
    useState(initSlotStartTime);
  const { subCities, accessToken } = useSelector((state) => state.sales);
  const [filteredWalkers, setFilteredWalkers] = useState([]);
  const [assignedWalker, setAssignedWalker] = useState();
  const { reloadWalks, closeEditWalkModal } = useContext(WalksContext);
  const [walkerListFilterType, setWalkerListFilterType] = useState(
    walkerFilterType.SINGLE_WALK
  );
  const [schedule, setSchedule]= useState([initSlotStartTime])
  const [currCardStep, setCurrCardStep] = useState(defaultEditMode? EditWalkCardStep.EDIT_WALK : EditWalkCardStep.CHOOSE_MODE);
  const [editMode, setEditMode] = useState(defaultEditMode?defaultEditMode:"");
  const [prevEditMode, setPrevEditMode] = useState();
  const [newScheduledAt, setNewScheduledAt] = useState(walk.scheduledAt || new Date().getTime());
  const [rescheduleWalksType, setRescheduleWalksType] = useState(RescheduleWalksType.SINGLE_WALK)
  const [selectedWalkers, setSelectedWalkers] = useState(walk?.walker?.id ? [walk.walker.id] : []);
  const [slotRadius, setSlotRadius] = useState(3);
  const [isWalkerSearchDirty, setIsWalkerSearchDirty] = useState(true);
  const [isSchedule1SlotExist, setIsSchedule1SlotExist] = useState(false);
  const [startDateEpoch, setStartDateEpoch] = useState(startDateParam || initSlotStartTime);
  const [endDateEpoch, setEndDateEpoch] = useState(endDateParam || initSlotStartTime);


  const dispatch = useDispatch();
  const disabledBtn = Boolean(!walk?.user?.id || !walk?.id || !assignedWalker || (prevEditMode !== EditWalkMode.WALK_RESCHEDULED && walk?.walker?.id && !walkerReplacementReason));
  const disabledChangeScheduleBtn = Boolean(!newScheduledAt || newScheduledAt === walk?.scheduledAt);
  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(MediaUploadLoaderContext);
  const walkerReplacementDescriptionRef = useRef();
  
  const scroll = (scrollContainer, scrollOffset) => {
    scrollContainer.current.scrollLeft += scrollOffset;
  };

  const editWalksSuccessHandler = async (response) => {
    dispatch(setActionNotif({
      open: true,
      message: editMode,
      severity: "success"
    }))

    closeEditWalkModal()
    reloadWalks()
    closeMediaUploadLoader();
  }

  const editWalksFailureHandler = (error) => {
    dispatch(setActionNotif({
      open: true,
      message: "Something Went Wrong!",
      severity: "error"
    }))
    closeMediaUploadLoader();
  }

  const handleEditWalkClick = () => {
    let walkerId = assignedWalker?.id;
    let { subscriptionId, orderId, user:{ id:userId }, id:walkId } = walk;
    openMediaUploadLoader();
    if(editMode === EditWalkMode.WALKER_REPLACED){
      if (walkerListFilterType === walkerFilterType.UPCOMING_WALKS || walkerListFilterType === walkerFilterType.TIME_RANGE_WALKS)
        dispatch(
          updateSelectedWalkingSessionsOfUser({
            updateType:editMode,
            userId,
            walkerId,
            ...walk?.subscriptionId && { subscriptionId:walk?.subscriptionId },
            ...walk?.orderId && { orderId:walk?.orderId },
            petIds:walk?.petIds,
            ...walk?.walker?.id && prevEditMode !== EditWalkMode.WALK_RESCHEDULED && { 
              replacedWalkerData:{
                walkerId:walk?.walker?.id,
                reason:walkerReplacementReason,
                ...walkerReplacementDescriptionRef.current.value && {description:walkerReplacementDescriptionRef.current.value}
              }
            },  
            scheduledAt:prevEditMode === EditWalkMode.WALK_RESCHEDULED ? newScheduledAt : walk?.scheduledAt,
            onSuccess:editWalksSuccessHandler,
            onFailure:editWalksFailureHandler,
            startDateEpoch,
            endDateEpoch,
            walkerListFilterType
          })
        );
      else
        dispatch(
          assignWalker({
            userId,
            walkId,
            walkerId,
            ...walk?.walker?.id && prevEditMode !== EditWalkMode.WALK_RESCHEDULED && { 
              replacedWalkerData:{
                walkerId:walk?.walker?.id,
                reason:walkerReplacementReason,
                ...walkerReplacementDescriptionRef.current.value && {description:walkerReplacementDescriptionRef.current.value}
              }
            },    
            closeEditWalkModal,
            reloadWalks,
            closeMediaUploadLoader,
          })
        );
    } else if(editMode === EditWalkMode.WALK_RESCHEDULED){
      if(rescheduleWalksType === RescheduleWalksType.UPCOMING_WALKS || rescheduleWalksType === RescheduleWalksType.TIME_RANGE_WALKS){
        dispatch(updateSelectedWalkingSessionsOfUser({
          updateType:editMode,
          userId,
          ...subscriptionId && { subscriptionId },
          ...orderId && { orderId },
          petIds:walk?.petIds,
          scheduledAt:walk?.scheduledAt,
          walkerId: selectedWalkers[0],
          newScheduledAt,
          onSuccess:editWalksSuccessHandler,
          onFailure:editWalksFailureHandler,
          startDateEpoch,
          endDateEpoch,
          rescheduleWalksType
        }))
      } else {
        dispatch(updateWalk({
          userId,
          walkId, 
          body: { 
            scheduledAt:walk?.scheduledAt,
            newScheduledAt,
            updateType:editMode,
            walkerId: selectedWalkers[0],
          }, 
          onSuccess:editWalksSuccessHandler,
        }))
      }
    }
  };

  const duplicateWalkClickHandler = async () => {
    try{
      openMediaUploadLoader()
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          mode: walk.mode,
          scheduledAt: schedule[0],
          trial: walk.trial,
          subscriptionId: walk.subscriptionId,
          orderId: walk.orderId,
          userId: walk.user.id,
          walkerId: selectedWalkers[0],
          planCode: walk.planCode,
          status: WalkStatus.UPCOMING,
          slotDuration: walk.slotDuration,
          petIds: walk.petIds
        }),
      };
      
      await request(
        `${nodeServerBaseUrl}/users/${walk.user.id}/walking-sessions/${walk.id}/duplicate`,
        requestOptions
      )
      closeEditWalkModal();
      reloadWalks();
      closeMediaUploadLoader();
    }catch(err){
      closeMediaUploadLoader();
      return dispatch(setActionNotif({
        open: true,
        message: "Something went wrong !",
        severity: "error",
     }));

    }
  }

  const initGetWalkersByFilter = async (slotRadius, setIsWalkerSearchDirty) => {
    if(editMode === EditWalkMode.WALK_RESCHEDULED) return;
    openMediaUploadLoader()

    try {
      let data = await getWalkersByFilter({
        accessToken,
        dispatch,
        filterType:walkerListFilterType,
        slotStartTime:prevEditMode === EditWalkMode.WALK_RESCHEDULED ? newScheduledAt : filterSlotStartTime,
        slotDuration:walk?.slotDuration,
        subCityId:subCitySelected?.id,
        ...walk?.subscriptionId && { subscriptionId:walk?.subscriptionId },
        ...walk?.orderId && { orderId:walk?.orderId },
        petIds:walk?.petIds,
        scheduledAt:prevEditMode === EditWalkMode.WALK_RESCHEDULED ? newScheduledAt : walk?.scheduledAt,
        userId:walk?.user?.id,
        ...walk?.walker?.id && prevEditMode !== EditWalkMode.WALK_RESCHEDULED && { walkerId:walk?.walker?.id },
        latitude:walk?.user?.address?.coords?.latitude, 
        longitude:walk?.user?.address?.coords?.longitude,
        maxDistance:slotRadius*1000,
        startDateEpoch,
        endDateEpoch
      });
      if (data) {
        let preferredWalkerData;
        data = data.filter((walker) => {
          if(walker?.id === walk?.walker?.id) 
            preferredWalkerData = walker;
          return (walker?.id !== walk?.walker?.id && walker?.verification === WalkerVerification.COMPLETED && !walker?.isDeleted)
        })

        if(preferredWalkerData && preferredWalkerData?.verification === WalkerVerification.COMPLETED && !preferredWalkerData?.isDeleted) {
          preferredWalkerData.preferred = true;
          data?.unshift(preferredWalkerData)
        }

        setFilteredWalkers(data);
        setIsWalkerSearchDirty(false)
        setAssignedWalker(data[0] ?? null)
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeMediaUploadLoader()
    }
  };

  const resetWalkerReplaceInputs = () => {
    setWalkerReplacementReason("")
    setAssignedWalker(null)
    if(walkerReplacementDescriptionRef.current) walkerReplacementDescriptionRef.current.value = "";
  }

  // useEffect(() => {
  //   initGetWalkersByFilter();
  // }, [
  //   accessToken,
  //   editMode
  // ]);

  useEffect(() => {
    if (!isWalkerSearchDirty) {
      setIsWalkerSearchDirty(true)
      resetWalkerReplaceInputs()
    }
  }, [slotRadius, walkerListFilterType, startDateEpoch, endDateEpoch]);

  useEffect(() => {
    editMode === EditWalkMode.WALKER_REPLACED && prevEditMode === EditWalkMode.WALK_RESCHEDULED && setIsBackdropDismissible(false);
  }, [prevEditMode, editMode])
  
  const setScheduleTime = (time, index) => {
    setSchedule((prev) => {
      let prevSchedule = [...prev];
      prevSchedule[index] = time || "";
      return prevSchedule;
    });
    // setDisableFindWalkers(false);
  };

  function valuetext(value) {
    return `${value}°km`;
  }

  const mobileBreakPoint = useMediaQuery("(max-width:650px)");

  return (
    <>
      <ModalTitle
        title={"Edit Walk"}
        onClose={onClose}
        showClose={
          prevEditMode !== EditWalkMode.WALK_RESCHEDULED && walk?.walker?.id
        }
      />
      {currCardStep === EditWalkCardStep.CHOOSE_MODE ? (
        <div className="card-container" style={{ paddingBottom: "1rem" }}>
          <div className="headline">
            <h3>Choose Edit Walk Mode</h3>
          </div>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={editMode}
            onChange={(e) => {
              setEditMode(() => {
                setPrevEditMode(editMode);
                return e.target.value;
              });
              setCurrCardStep(EditWalkCardStep.EDIT_WALK);
            }}
          >
            <FormControlLabel
              value={EditWalkMode.WALKER_REPLACED}
              control={<Radio />}
              label="Replace Walker"
            />
            {
              <FormControlLabel
                value={EditWalkMode.WALK_RESCHEDULED}
                control={<Radio />}
                label="Reschedule Walks"
              />
            }
          </RadioGroup>
        </div>
      ) : editMode === EditWalkMode.WALKER_REPLACED ? (
        <div style={{ paddingBottom: "1rem" }}>
          <div className="card-container" style={{ gap: "0.8rem" }}>
            {prevEditMode !== EditWalkMode.WALK_RESCHEDULED &&
            !walk?.trial &&
            walk?.planCode? 
            <>
            {!isLeaveParam &&  (
              <>
        <div style={{margin:"2rem", width: "85%"}}>
          <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="city"
                id="select-verification"
                value={walkerListFilterType}
                style={{ minWidth: "100%" }}
                onChange={(event) =>
                  {
                    const selectedType = event.target.value;
                    setWalkerListFilterType(selectedType);
                  }
                }
              >
                  <MenuItem value={walkerFilterType.SINGLE_WALK} >
                   Replace Single Walk
                  </MenuItem>
                  <MenuItem value={walkerFilterType.UPCOMING_WALKS}>
                  Assign To All Upcoming Sessions Of This User
                  </MenuItem>
                  <MenuItem value={walkerFilterType.TIME_RANGE_WALKS}>
                  Replace Walks In TimeRange
                  </MenuItem>
              </Select>
            </FormControl>
        </div>
        {walkerListFilterType === walkerFilterType.TIME_RANGE_WALKS  && 
          <div
            style={{
              width: "22rem",
              display: "flex",
              margin: "2rem",
              flexDirection: "row",
              justifyContent:"center",
              gap: "1rem",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{ padding: 0, height: "5px" }}
                label="Start date"
                value={startDateEpoch}
                disabled
                onChange={(newValue) => {
                  setStartDateEpoch(newValue.setHours(0, 0, 0, 0));
                }}
                inputFormat="dd-MM-yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End date"
                value={endDateEpoch}
                onChange={(newValue) => {
                  setEndDateEpoch(newValue.setHours(23, 59, 59, 999));
                }}
                inputFormat="dd-MM-yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
      }
        </>
            )}
            {
              isLeaveParam && !isSameDay(startDateParam, endDateParam) &&  (
                <div className="assign-to-all-text">
                <Checkbox
                  {...label}
                  style={classes.checkBox}
                  checked={
                    walkerListFilterType === walkerFilterType.TIME_RANGE_WALKS
                  }
                  onChange={(event) =>
                    setWalkerListFilterType((prev) =>
                      prev === walkerFilterType.SINGLE_WALK
                        ? walkerFilterType.TIME_RANGE_WALKS
                        : walkerFilterType.SINGLE_WALK
                    )
                  }
                />
                <span
                  style={{ color: "rgb(124, 119, 119)", fontWeight: "bold" }}
                >
                  Assign To All Upcoming Walks Of This User in the Leave Time Range
                </span>
              </div>
              )
            }
            </>
            : null}
            {prevEditMode === EditWalkMode.WALK_RESCHEDULED ? (
              rescheduleWalksType === RescheduleWalksType.UPCOMING_WALKS ? (
                <h4>
                  Previous walkers to this subscription are unassigned due to
                  rescheduling, Please Re Assign to all upcoming walks of this
                  subscription
                </h4>
              ) : rescheduleWalksType === RescheduleWalksType.SINGLE_WALK ? (
                <h4>
                  Previous walker to this walk is unassigned due to
                  rescheduling, Please Re Assign a walker to this walk
                </h4>
              ) : null
            ) : null}
            {prevEditMode !== EditWalkMode.WALK_RESCHEDULED &&
              walk?.walker?.id && (
                <strong>
                  Replacing Walker{" "}
                  <span style={classes.textPrimary}>{walk?.walker?.name}</span>
                </strong>
              )}
              <div style={{ width: "100%", marginBottom:"2rem" }}>
              <h3>Radius of Walker Search (in Kms)</h3>
                <Slider
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    margin: "0 auto",
                  }}
                  aria-label="Radius"
                  value={slotRadius}
                  onChange={(event, newValue) => setSlotRadius(newValue)}
                  getAriaValueText={valuetext}
                  valueLabelDisplay="off"
                  step={1}
                  marks={marks}
                  min={1}
                  max={20}
                />
              </div>
              {isWalkerSearchDirty ? (
                <Button
                  style={classes.smallEnabledBtn}
                  onClick={() => initGetWalkersByFilter(slotRadius, setIsWalkerSearchDirty)}
                >
                  Search
                </Button>
              ) :
              <>
                <WalkerSelectionCarousel
                  walkers={filteredWalkers} 
                  onClickWalker={(walker) => {
                    setAssignedWalker(walker)
                  }}
                  walkerStyle={{
                      cursor:"pointer",
                      opacity:1
                  }}
                  selectedWalkerId={assignedWalker?.id}
                  scroll={scroll}
                />
                {/* <div className="dash-filter filter" style={{ width: "100%" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box"
                    options={filteredWalkers}
                    sx={{ width: "100%", minWidth: "14rem" }}
                    value={assignedWalker?.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value || value === ""
                    }
                    onChange={(event, value) => {
                      setAssignedWalker(value);
                    }}
                    getOptionLabel={(option) =>
                      option.name && option.phoneNumber
                        ? option.name + " " + option.phoneNumber
                        : option
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Search Walker" />
                    )}
                  />
                </div> */}
                {prevEditMode !== EditWalkMode.WALK_RESCHEDULED &&
                  walk?.walker?.id && (
                    <>
                      <div className="dash-filter filter" style={{ width: "100%" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="select-input-id" required>
                            Replacement Reason
                          </InputLabel>
                          <Select
                            labelId="select-mui"
                            id="select-mui-id"
                            defaultValue=""
                            onChange={(event) => {
                              setWalkerReplacementReason(event.target.value);
                            }}
                          >
                            {Object.keys(WalkerReplacementReason)?.map(
                              (reasonKey, index) => (
                                <MenuItem
                                  value={WalkerReplacementReason[reasonKey]}
                                  key={index}
                                >
                                  {WalkerReplacementReason[reasonKey]}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="dash-filter filter" style={{ width: "100%" }}>
                        <TextField
                          id="replacement-description"
                          label="Replacement Description ( Optional )"
                          type="text"
                          inputRef={(input) =>
                            (walkerReplacementDescriptionRef.current = input)
                          }
                          sx={{ width: 250 }}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              }
          </div>

          <Button
            style={disabledBtn ? classes.btn : classes.enabledBtn}
            onClick={!disabledBtn ? handleEditWalkClick : undefined}
          >
            Assign Walker
          </Button>
        </div>
      ) : editMode === EditWalkMode.WALK_RESCHEDULED ? (
        <div style={{ paddingBottom: "1rem", borderRadius: "1rem" }}>
          <div
            className="card-container"
            style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
          >
            <div className="dash-filter filter" style={{ width: "100%" }}>
            <SalesSubscriptionSlotPicker
                  accessToken={accessToken}
                  subscriptionPlan={walk?.planCode}
                  lat={walk?.user?.address?.coords?.latitude}
                  lng={walk?.user?.address?.coords?.longitude}
                  selectedTime={newScheduledAt || initSlotStartTime}
                  pauseDate={true}
                  walkType={rescheduleWalksType}
                  walkerId={walk?.walker?.id}
                  userId={walk?.user?.id}
                  subscriptionId={walk?.subscriptionId}
                  pets={walk?.petIds}
                  setSelectedWalkers={setSelectedWalkers}
                  slotStartTime={walk?.scheduledAt}
                  setScheduleTime={(scheduleTime) => {
                    const newScheduleHours = new Date(scheduleTime).getHours(),
                      newScheduleMinutes = new Date(scheduleTime).getMinutes();
                    const tempNewScheduledAt = new Date(
                      walk?.scheduledAt
                    ).setHours(newScheduleHours, newScheduleMinutes, 0, 0);
                    setNewScheduledAt(tempNewScheduledAt);
                  }}
                  startDateParam={startDateParam}
                  endDateParam={endDateParam}
                  isLeaveParam={isLeaveParam}
                  scheduleIndex={0}
                  preferredTime={new Date(walk?.scheduledAt)}
                  preferredWalkerId={walk?.walker?.id ?? null}
                  showPreferredTime={false}
                  showRescheduleCheckBox={true}
                  walk={walk}
                  slotDuration={walk?.slotDuration}
                  scheduledAt={walk?.scheduledAt}
                  setRescheduleWalksType={setRescheduleWalksType}
                  setIsSchedule1SlotExist={setIsSchedule1SlotExist}
                  setEndDateEpoch={setEndDateEpoch}
                  setStartDateEpoch={setStartDateEpoch}
                  endDateEpoch={endDateEpoch}
                  startDateEpoch={startDateEpoch}
                  isSchedule1SlotExist={isSchedule1SlotExist}
                />
              {/* {walk?.trial ? (
                <SalesTrialSlotPicker
                  accessToken={accessToken}
                  selectedTime={newScheduledAt}
                  setScheduleTime={(time) => {
                    // const newScheduleHours = new Date(time).getHours(),
                    //   newScheduleMinutes = new Date(time).getMinutes();
                    // const tempNewScheduledAt = new Date(
                    //   walk?.scheduledAt
                    // ).setHours(newScheduleHours, newScheduleMinutes, 0, 0);
                    // console.log(tempNewScheduledAt, 'new scheduel ')
                    // setNewScheduledAt(tempNewScheduledAt);
                    setNewScheduledAt(time);
                  }}
                  preferredTime={new Date(walk?.scheduledAt).toLocaleString()}
                  lat={walk?.user?.address?.coords?.latitude}
                  lng={walk?.user?.address?.coords?.longitude}
                  slotRadius={trialSlotRadius}
                  setSlotRadius={setTrialSlotRadius}
                  isTrial={walk?.trial}
                  userId={walk?.user?.id}
                  pauseDate={true}
                  //  loadingSlots={loadingSlots}
                />
              ) : (
                <SalesSubscriptionSlotPicker
                  accessToken={accessToken}
                  subscriptionPlan={walk?.planCode}
                  lat={walk?.user?.address?.coords?.latitude}
                  lng={walk?.user?.address?.coords?.longitude}
                  selectedTime={newScheduledAt || initSlotStartTime}
                  pauseDate={true}
                  walkType={rescheduleWalksType}
                  walkerId={walk?.walker?.id}
                  userId={walk?.user?.id}
                  subscriptionId={walk?.subscriptionId}
                  pets={walk?.petIds}
                  setSelectedWalkers={setSelectedWalkers}
                  slotStartTime={walk?.scheduledAt}
                  setScheduleTime={(scheduleTime) => {
                    const newScheduleHours = new Date(scheduleTime).getHours(),
                      newScheduleMinutes = new Date(scheduleTime).getMinutes();
                    const tempNewScheduledAt = new Date(
                      walk?.scheduledAt
                    ).setHours(newScheduleHours, newScheduleMinutes, 0, 0);
                    setNewScheduledAt(tempNewScheduledAt);
                  }}
                  scheduleIndex={0}
                  preferredTime={new Date(walk?.scheduledAt)}
                  showPreferredTime={false}
                  showRescheduleCheckBox={true}
                  walk={walk}
                  slotDuration={walk?.slotDuration}
                  scheduledAt={walk?.scheduledAt}
                  setRescheduleWalksType={setRescheduleWalksType}
                  setIsSchedule1SlotExist={setIsSchedule1SlotExist}
                  isSchedule1SlotExist={isSchedule1SlotExist}
                />
              )} */}

              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="New Schedule Time"
                  value={newScheduledAt}
                  onChange={(e) => {
                    const newScheduleHours = new Date(e).getHours(),
                      newScheduleMinutes = new Date(e).getMinutes();
                    const tempNewScheduledAt = new Date(
                      walk?.scheduledAt
                    ).setHours(newScheduleHours, newScheduleMinutes, 0, 0);
                    setNewScheduledAt(tempNewScheduledAt);
                    console.log(
                      tempNewScheduledAt,
                      "scheduled at",
                      new Date(tempNewScheduledAt).toLocaleDateString("en-GB", {
                        timeZone: "UTC",
                      }),
                      newScheduleHours,
                      newScheduleMinutes
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                />
              </LocalizationProvider> */}
            </div>
            
            {rescheduleWalksType === RescheduleWalksType.UPCOMING_WALKS && (
              <h4 style={classes.cautionText}>
                <span style={{ color: "#454545" }}>&#9888;</span> This Will
                Reschedule all upcoming walks of this slot for this Subscription
                and their assigned Walkers will get un-assigned
              </h4>
            )}
          </div>

          <Button
             style={(disabledChangeScheduleBtn || (!isSchedule1SlotExist && !walk?.trial)) ? classes.btn : classes.enabledBtn}
             disabled={(disabledChangeScheduleBtn || (!isSchedule1SlotExist && !walk?.trial)) ? true: false}
            onClick={
              !disabledChangeScheduleBtn ? handleEditWalkClick : undefined
            }
          >
            Change Schedule
          </Button>
        </div>
          ) : editMode === EditWalkMode.DUPLICATE_WALK ? (
            <>
                    <div style={{ paddingBottom: "1rem", borderRadius: "1rem" }}>
              <div
                className="card-container"
                style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
              >
                <div className="dash-filter filter" style={{ width: "100%" }}></div>
                          <SalesSubscriptionSlotPicker
                            accessToken={accessToken}
                            subscriptionPlan={walk?.plan}
                            lat={walk?.user?.address?.coords?.latitude}
                            lng={walk?.user?.address?.coords?.longitude}
                            schedule={schedule}
                            selectedTime={schedule[0]}
                            setScheduleTime={(scheduleTime) =>
                              setScheduleTime(scheduleTime, 0)
                            }
                            walkType={RescheduleWalksType.DUPLICATE_WALKS}
                            slotDuration={walk.slotDuration}
                            scheduledAt = {walk?.scheduledAt}
                            preferredTime={new Date(walk?.scheduledAt)}
                            preferredWalkerId={walk?.walker?.id ?? null}
                            scheduleIndex={0}
                            extend={false}
                            setSelectedWalkers={setSelectedWalkers}
                            assignedWalker={selectedWalkers[0]}
                            noOfWalks={1}
                            walkIndex={0}
                            walksPerDay={1}
                            setIsSchedule1SlotExist={setIsSchedule1SlotExist}
                            isSchedule1SlotExist={isSchedule1SlotExist}
                          />
                        <Button
                          style={(!isSchedule1SlotExist && !walk?.trial) ? classes.btn : classes.enabledBtn}
                          disabled={(!isSchedule1SlotExist && !walk?.trial) ? true: false}
                          onClick={duplicateWalkClickHandler}
                        >
                          Duplicate
                        </Button>
                    </div>
                    </div>
            </>
          ):null}
    </>
  );
}
