import { setActionNotif, setOpenInAppDialog } from "../../redux/actions";
import { deeplinkEntities } from "../../redux/constants";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { request } from "../../utils/apiRequest";
import { AppAnalytics } from "../../utils/appAnalytics";
import { WebEngage } from "../../utils/webEngage";

export const getCMSPublicEvents = async (...rest) => {
    const [dispatch, setLoadingEvents] = rest;

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    };
    const requestUrl = `${nodeServerBaseUrl}/public/cms/events?page=0&limit=20`;

    return new Promise(async (resolve, reject) => {
        setLoadingEvents(true)
        request(requestUrl,
            requestOptions,
            async (response) => {
                const data = response.data?.data;

                setLoadingEvents(false)
                resolve(data);
            },
            async (error) => {
                setLoadingEvents(false)
                dispatch(setActionNotif({
                    open: true,
                    message: "Something went wrong!",
                    severity: "error"
                }))
                reject(error)
            })
    })
}

export const subscribeCMSEvent = async (...rest) => {
    const [dispatch, authToken, event, setIsSubscribed, userDetails] = rest;
    const { id:eventId,  } = event;

    let validNumber = userDetails?.phoneNumber;
    if (!validNumber.startsWith('+91')) {
        validNumber = `+91${validNumber}`
    }

    const requestOptions = {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({...userDetails, phoneNumber:validNumber})
    };

    let requestUrl = `${nodeServerBaseUrl}/public/cms/events/${eventId}/subscribe`;
    if(authToken){
        requestOptions.headers["Authorization"] = `Bearer ${authToken}`;
        requestUrl = `${nodeServerBaseUrl}/cms/events/${eventId}/subscribe`;
    }

    let analyticsEventData = {
        title: event?.title || "NO TITLE",
        publicProfileId: event?.expertId?.id,
        publicProfileName: event?.expertId?.name,
        link: event?.eventUrl,
        date: event?.eventDateTime ? new Date(event?.eventDateTime) : new Date().getTime(),
        source: "Web",
        mode: event?.mode,
        imageUrl: event?.imageUrl,
        shopUrl: event?.shopUrl
    }
    
    if (event?.chatGroupId) {
        analyticsEventData = {...analyticsEventData, groupId: event?.chatGroupId}
    }

    return new Promise(async (resolve, reject) => {
        request(requestUrl,
            requestOptions,
            async (response) => {
                const data = response.data?.data;
                AppAnalytics.track("EventSubscribe", {...analyticsEventData,result:"success"});
                resolve(data);
                setIsSubscribed(true);
                if (event?.chatGroupId) {
                    WebEngage?.track("JoinGroup_EventSubscribe", {...analyticsEventData, result: "success"});
                    AppAnalytics.track("JoinGroup_EventSubscribe", {...analyticsEventData, result: "success"});    
                }
               
            },
            async (error) => {
                console.log({ error });
                AppAnalytics.track("EventSubscribe", { ...analyticsEventData, result: "failure", reason: error?.message || "Something went wrong!" });
                if (event?.chatGroupId) {
                    WebEngage?.track("JoinGroup_EventSubscribe", {...analyticsEventData, reason: "error", result: "failure"});
                    AppAnalytics.track("JoinGroup_EventSubscribe", {...analyticsEventData, reason: "error", result: "failure"});    
                }
                dispatch(setActionNotif({
                    open: true,
                    message: "Something went wrong!",
                    severity: "error"
                }))
                reject(error)
            })
    })
}
