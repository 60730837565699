import {
  SET_DASHBOARD_FORM_OPEN,
  SET_HEADER_VISIBILITY,
  SET_ADMIN_DASHBOARD_DATA_LOADER,
  SET_ACTION_NOTIF,
  SET_INITIAL_APP_LOAD,
  SET_OPEN_IN_APP_DIALOG,
  deeplinkEntities,
} from "../constants";
import env from "../../environment/production.json";

const subdomain = window.location.host?.split(".")[0] || "", pathPrefix = window.location.pathname.split('/')[1];

export default function uiReducer(
  state = {
    headerVisibility: true,
    dashboardFormOpen: false,
    adminDashboardDataLoader: false,
    actionNotif: {
      open: false,
      message: "",
      severity: "",
    },
    openInAppDialog:{
      btnText:"Open in app",
      openStore:false,
      open: false,
      deeplinkEntity:deeplinkEntities.FEED
    },
    initialAppLoad: Boolean(subdomain === env.REACT_APP_SUBDOMAIN || pathPrefix === "admin" || pathPrefix === "sales"),
  },
  action = {}
) {
  switch (action.type) {
    case SET_HEADER_VISIBILITY:
      return {
        ...state,
        headerVisibility: action.payload,
      };
    case SET_DASHBOARD_FORM_OPEN:
      return {
        ...state,
        dashboardFormOpen: action.payload,
      };
    case SET_ADMIN_DASHBOARD_DATA_LOADER:
      return {
        ...state,
        adminDashboardDataLoader: action.payload,
      };
    case SET_ACTION_NOTIF:
      return {
        ...state,
        actionNotif: action.payload,
      };
    case SET_INITIAL_APP_LOAD:
      return {
        ...state,
        initialAppLoad: action.payload,
      };
    case SET_OPEN_IN_APP_DIALOG:
      return {
        ...state,
        openInAppDialog: action.payload,
      };
    default:
      return state;
  }
}
