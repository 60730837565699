import { nodeServerBaseUrl } from "./apiUrls";
import { setInitialAppLoad, setActionNotif } from "../actions/index";
import env from "../../environment/production.json";
import {
  setAppUserData,
  setCities,
  setCountries,
  setStates,
  setUserProfileCompletionSteps,
} from "../actions/user";
import { userProfileCompletionSteps } from "../constants";
import { request } from "../../utils/apiRequest";
import { eraseCookie } from "../../utils/cookies";
import { trackUser } from "../../utils/func";
import { AppAnalytics } from "../../utils/appAnalytics";
import { trackIdentifyUser } from "../../components/EventRegistrationCard/EventRegistrationCard";

export const onGetAppUserSuccessAtPageLoad = (user, dispatch, getState, navigate, location) => {
  user = {
    ...user,
    petProfiles: user?.petProfiles?.filter(pet => !pet?.isDeleted)
  }
  dispatch(setAppUserData(user));

  //track user on page refresh or login
  trackUser(user);
  trackIdentifyUser(user);


  getState().ui.initialAppLoad && dispatch(setInitialAppLoad(false));

  let updatedProfileCompletionSteps = {};
  updatedProfileCompletionSteps[userProfileCompletionSteps[0]] = Boolean(
    user.name && user.address
  ); //name and location
  updatedProfileCompletionSteps[userProfileCompletionSteps[1]] = Boolean(
    user.photoUrl
  ); //add profile pic
  updatedProfileCompletionSteps[userProfileCompletionSteps[2]] = Boolean(
    user.petProfiles.length
  ); //create a pet profile
  updatedProfileCompletionSteps[userProfileCompletionSteps[3]] =
    user.hasPosted; //post on feed
  // console.log({updatedProfileCompletionSteps})
  dispatch(setUserProfileCompletionSteps(updatedProfileCompletionSteps));

  AppAnalytics.track("AppOpen");

  console.log({ user });
  user.onboardComplete
    ? (location.pathname === "/onboarding"
      ? navigate("/posts")
      : navigate(`${location.pathname}${location.search}`))
    :
    (location.pathname !== "/book-dog-walking" && !location.pathname.startsWith("/events") && navigate("/onboarding"))
}

export const onGetAppUserFailureAtPageLoad = (error, dispatch) => {
  eraseCookie(
    "userAccessToken",
    document.domain.replace(`${env.REACT_APP_SUBDOMAIN}.`, "")
  );

  window.location.href = `${window.location.protocol
    }//${window.location.host.replace(
      `${env.REACT_APP_SUBDOMAIN}.`,
      env.NODE_ENV === "staging" ? "staging." : ""
    )}/login`;
  dispatch(setActionNotif({ open: true, message: error.message, severity: "error" }));
}

export const getAppUserData =
  (accessToken, navigate, location, onSuccess, onFailure) =>
    async (dispatch, getState) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      await request(
        `${nodeServerBaseUrl}/user`,
        requestOptions,
        async (response) => {
          let userData = response.data?.data;

          onSuccess(userData, dispatch, getState, navigate, location);
        },
        async (error) => {
          onFailure(error, dispatch);
        }
      )
    };

export const saveUserProfile =
  (...rest) =>
    async (dispatch, getState) => {
      const [details, onSuccess, onFailure, successArgs, failureArgs] = rest;
      console.log({ userDetails: details });

      const userId = getState().user.data.id;

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().user.accessToken}`,
        },
        body: JSON.stringify(details),
      };

      await request(
        `${nodeServerBaseUrl}/users/${userId}`,
        requestOptions,
        async (response) => {
          onSuccess(successArgs ?? response.data?.data);
        },
        async (error) => {
          onFailure({ failureArgs, error })
        }
      )
    };

export const getCountries = () => async (dispatch, getState) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await request(
    `${nodeServerBaseUrl}/countries`,
    requestOptions,
    async (response) => {
      let data = response.data?.data;
      console.log(data);
      dispatch(setCountries(data));
    })
};

export const getStates =
  (...info) =>
    async (dispatch, getState) => {
      const [countryCode] = info;
      if (!countryCode) return;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      await request(
        `${nodeServerBaseUrl}/countries/${countryCode}/states`,
        requestOptions,
        async (response) => {
          let data = response.data?.data;
          console.log({ states: data });

          dispatch(setStates(data));
        }
      )
    };

export const getCities =
  (...info) =>
    async (dispatch, getState) => {
      const [countryCode, stateCode] = info;
      if (!countryCode || !stateCode) return;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      await request(
        `${nodeServerBaseUrl}/countries/${countryCode}/states/${stateCode}/cities`,
        requestOptions,
        async (response) => {
          let data = response.data?.data;
          console.log({ cities: data });

          dispatch(setCities(data));
        }
      )
    };

export const getUserByPhoneNumber = async (phoneNumber, accessToken, successHandler, failureHandler) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  try {
    return await request(`${nodeServerBaseUrl}/users/search/phone?` + new URLSearchParams({
      phoneNumber
    }), requestOptions,
      async (response) => {
        return successHandler(response?.data.data)
      },
      async (error) => {
        failureHandler(error)
      })
  } catch (error) { throw error }

}

export const searchUsersByName = async ({ accessToken, dispatch, page, limit, name }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  try {
    return await request(`${nodeServerBaseUrl}/users/search?` + new URLSearchParams({
      name,
      ...page && {page},
      ...limit && {limit}
    }), requestOptions,
      async (response) => {
        return response?.data.data
      },
      async (error) => {
        throw new Error(error?.message ?? error)
      })
  } catch (error) { throw error }
}

export const syncAnonymousUser = async ({
  dispatch, userDetails
}) => {

  let validNumber = userDetails?.phoneNumber;

  const requestOptions = {
      method: "PATCH",
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ ...userDetails, phoneNumber: validNumber })
  };

  let requestUrl = `${nodeServerBaseUrl}/public/users/sync-details`;

  return new Promise(async (resolve, reject) => {
      request(requestUrl,
          requestOptions,
          async (response) => resolve(response.data?.data),
          async (error) => {
              console.log({ error });
              dispatch(setActionNotif({
                  open: true,
                  message: "Something went wrong!",
                  severity: "error"
              }))
              reject(error)
          })
  })
}
