import { setActionNotif } from "../../redux/actions";
import { WalkerVerification, walkerFilterType } from "../../redux/constants";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { request } from "../../utils/apiRequest";

export const getWalkersByFilter = async (getWalkersByFilterDto) => {
    const {accessToken, dispatch, filterType, slotStartTime, slotDuration,
        subCityId, subscriptionId, petIds, orderId, scheduledAt, userId, plan, verification, walkerId, hotspotId, noOfWalks,
        walksPerDay, walkIndex, latitude, longitude, maxDistance, startDateEpoch,
        endDateEpoch
    } = getWalkersByFilterDto;

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            ...slotStartTime && { slotStartTime },
            ...slotDuration && { slotDuration },
            ...subCityId && { subCityId },
            ...scheduledAt && { scheduledAt },
            ...petIds?.length && { petIds },
            ...userId && { userId },
            ...subscriptionId && { subscriptionId },
            ...orderId && { orderId },
            ...plan && { plan },
            ...verification ? { verification } : { verification: WalkerVerification.COMPLETED },
            ...walkerId && { walkerId },
            ...noOfWalks && {noOfWalks},
            ...walksPerDay && {walksPerDay},
            ...(walkIndex || walkIndex === 0) && {walkIndex},
            ...hotspotId && {hotspotId},
            ...latitude && {latitude},
            ...longitude && {longitude},
            ...maxDistance && {maxDistance},
            ...startDateEpoch && endDateEpoch && filterType === walkerFilterType.TIME_RANGE_WALKS && {
                startTime: startDateEpoch,
                endTime: endDateEpoch
            },
            filterType,
        })
    };

    return new Promise(async (resolve, reject) => {
        request(`${nodeServerBaseUrl}/filtered-walkers`,
            requestOptions,
            async (response) => {
                const data = response.data?.data;
                resolve(data);
            },
            async (error) => {
                dispatch(setActionNotif({
                    open: true,
                    message: "Unable to get Walkers at the moment!",
                    severity: "error"
                }))
                reject(error)
            })
    })
}
