import CheckCircleIcon from "../../assets/icons/CheckCircleIcon";
import ServiceReview from "../../components/ServiceReview/ServiceReview";

export const freshFoodBenefits = [
  {
    icon: <CheckCircleIcon wrapperStyle={{ padding: "0 0.3rem" }} />,
    text: "Balanced fresh meals",
  },
  {
    icon: <CheckCircleIcon wrapperStyle={{ padding: "0 0.3rem" }} />,
    text: "Vegitarian and Non-vegetarian meals",
  },
  {
    icon: <CheckCircleIcon wrapperStyle={{ padding: "0 0.3rem" }} />,
    text: "Formulated by expert nutritionists",
  },
];

export const dogWalkingBenefits = [
  {
    icon: <CheckCircleIcon wrapperStyle={{ padding: "0 0.3rem" }} />,
    text: "Dog walkers you can trust!",
  },
  {
    icon: <CheckCircleIcon wrapperStyle={{ padding: "0 0.3rem" }} />,
    text: "We scoop the poop",
  },
  {
    icon: <CheckCircleIcon wrapperStyle={{ padding: "0 0.3rem" }} />,
    text: "Location & stats",
  },
];

export const communityBenefits = [
  {
    icon: <CheckCircleIcon wrapperStyle={{ padding: "0 0.3rem" }} />,
    text: "Get answers to your questions",
  },
  {
    icon: <CheckCircleIcon wrapperStyle={{ padding: "0 0.3rem" }} />,
    text: "Never lose medical records",
  },
  {
    icon: <CheckCircleIcon wrapperStyle={{ padding: "0 0.3rem" }} />,
    text: "Never miss an important task",
  },
];

export const appReviews = [
  {
    renderComponent: ServiceReview,
    props: {
      review: {
        stars: [1, 2, 3, 4, 5],
        text: "A great app where I can discuss issues about my dog, as well as keep track of their info, love it!",
        author: "Devanshu",
      },
    },
  },
  {
    renderComponent: ServiceReview,
    props: {
      review: {
        stars: [1, 2, 3, 4, 5],
        text: "Feels like a Google for dog parents. It’s my safe space to ask all dog related questions and add reminders.",
        author: "Isha",
      },
    },
  },
  {
    renderComponent: ServiceReview,
    props: {
      review: {
        stars: [1, 2, 3, 4, 5],
        text: "I’ve been struggling for ages with a platform to maintain my doggy’s records. In love with the easy and beautiful design.",
        author: "Shouryg",
      },
    },
  },
];
