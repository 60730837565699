import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "./MotionCarousel.css";

export default function MotionCarousel({ slides }) {
  const [carouselWidth, setCarouselWidth] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    setCarouselWidth(
      carouselRef.current?.scrollWidth - carouselRef.current?.offsetWidth
    );
  }, [carouselRef.current]);

  return (
    <div className="motion-carousel">
      <motion.div className="carousel" ref={carouselRef}>
        <motion.div
          className="inner-carousel"
          drag="x"
          dragConstraints={{
            right: carouselWidth,
            left: -carouselWidth,
          }}
          animate
        >
          {slides.map((slide, i) => {
            return <slide.renderComponent key={i} {...slide.props} />;
          })}
        </motion.div>
      </motion.div>
      <div className="dots-wrap">
        {[1, 2, 3].map((carousel, i) => (
          <span className={`dot`} key={i}>
            {""}
          </span>
        ))}
      </div>
    </div>
  );
}
