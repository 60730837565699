import React from "react";
import "./Button.css";

export default function Button({
  text,
  startIcon = null,
  endIcon = null,
  btnStyle,
  clickAction,
  disabled = false,
  textStyle = {}
}) {
  return (
    <div
      className={`img-btn ${disabled ? "disabled" : ""}`}
      style={btnStyle}
      onClick={
        clickAction && !disabled ? clickAction : (e) => e.preventDefault()
      }
    >
      {startIcon}
      <span style={textStyle}>{" " + text + " "}</span>
      {endIcon}
    </div>
  );
}
