import {
  SET_TITLE,
  SET_DESCRIPTION,
  SET_TAG,
  SET_IMG_URL,
  SET_REDIRECT_URI,
  SET_CMS_TYPE,
  SET_STATUS,
  SET_DASHBOARD_FORM_DATA,
  SET_CMS_EXPERIENCES_LIST,
  SET_CMS_SHOWANDTELL_LIST,
  SET_CMS_TOPICS_LIST,
  SET_CMS_BANNERS_LIST,
  SET_CURRENT_DASHBOARD_ACTION,
  SET_ADMIN_AUTH_TOKEN,
  SET_ADMIN_ROLE,
  SET_IMG,
  SET_CMS_FEED,
  SET_CMS_FEED_TOTAL,
  SET_CMS_FEED_PAGE,
  SET_CMS_FEED_DELETION_IDS,
  SET_CMS_COMMENTS_DELETION_IDS,
  SET_CMS_COMMENT,
  SET_CMS_FEED_FORM_DATA,
  SET_SEARCH_RESULTS_USER,
  SET_CMS_TOPIC_POSTS_LIST,
  SET_CMS_PINNED_POSTS,
  SET_ADMIN_USER_DATA,
  SET_CMS_SCHEDULED_POSTS,
  SET_CMS_GROUP_CHATS,
  SET_CMS_OPEN_CHAT_MEMBERS,
  SET_CMS_OPEN_CHAT,
  SET_BUSINESS_LISTINGS_LIST,
  SET_BUSINESS_CATEGORIES_LIST,
  SET_SHOP_DISCOUNTS_LIST,
  SET_SHOP_SECTION_LIST,
  SET_SCRAPED_DATA_LIST,
  SET_BOTS_LIST,
  SET_LEARN_CATEGORIES_LIST,
  SET_LEARN_COURSES_LIST,
  SET_LEARN_CHAPTERS_LIST,
  SET_LEARN_WHAT_TO_SHOW,
  SET_LEARN_CATEGORY_ID,
  SET_LEARN_COURSE_ID,
  SET_LEARN_CHAPTER_ID,
  SET_LEARN_QUIZ,
  SET_LEARN_TASK,
  SET_EVENT_WHAT_TO_SHOW,
  SET_EVENT_TAGS_LIST,
  SET_EVENT_VIDEOS_LIST,
} from "../constants";

export const setTitle = (data) => {
  return {
    type: SET_TITLE,
    payload: data,
  };
};

export const setDescription = (data) => {
  return {
    type: SET_DESCRIPTION,
    payload: data,
  };
};

export const setTag = (data) => {
  return {
    type: SET_TAG,
    payload: data,
  };
};

export const setImageUrl = (data) => {
  return {
    type: SET_IMG_URL,
    payload: data,
  };
};

export const setRedirectUri = (data) => {
  return {
    type: SET_REDIRECT_URI,
    payload: data,
  };
};

export const setCMSType = (data) => {
  return {
    type: SET_CMS_TYPE,
    payload: data,
  };
};

export const setStatus = (data) => {
  return {
    type: SET_STATUS,
    payload: data,
  };
};

export const setDashboardFormData = (data) => {
  return {
    type: SET_DASHBOARD_FORM_DATA,
    payload: data,
  };
};

export const setCMSExperiencesList = (data) => {
  return {
    type: SET_CMS_EXPERIENCES_LIST,
    payload: data,
  };
};

export const setCMSPinnedPosts = (data) => {
  return {
    type: SET_CMS_PINNED_POSTS,
    payload: data,
  };
};

export const setCMSScheduledPosts = (data) => {
  return {
    type: SET_CMS_SCHEDULED_POSTS,
    payload: data,
  };
};

export const setCMSBannersList = (data) => {
  return {
    type: SET_CMS_BANNERS_LIST,
    payload: data,
  };
};

export const setLearnCategoriesList= (data) => {
  return {
    type: SET_LEARN_CATEGORIES_LIST,
    payload: data,
  };
};
export const setLearnWhatToShow= (data) => {
  console.log('learn what to show', data)
  return {
    type: SET_LEARN_WHAT_TO_SHOW,
    payload: data,
  };
};
export const setLearnCategoryId= (data) => {
  return {
    type: SET_LEARN_CATEGORY_ID,
    payload: data,
  };
};
export const setLearnCourseId= (data) => {
  return {
    type: SET_LEARN_COURSE_ID,
    payload: data,
  };
};
export const setLearnChapterId= (data) => {
  return {
    type: SET_LEARN_CHAPTER_ID,
    payload: data,
  };
};
export const setLearnQuiz= (data) => {
  console.log(data, 'check data quiz')
  return {
    type: SET_LEARN_QUIZ,
    payload: data,
  };
};
export const setLearnTask= (data) => {
  return {
    type: SET_LEARN_TASK,
    payload: data,
  };
};
export const setLearnCoursesList= (data) => {
  return {
    type: SET_LEARN_COURSES_LIST,
    payload: data,
  };
};
export const setLearnChaptersList= (data) => {
  return {
    type: SET_LEARN_CHAPTERS_LIST,
    payload: data,
  };
};
export const setEventWhatToShow= (data) => {
  return {
    type: SET_EVENT_WHAT_TO_SHOW,
    payload: data,
  };
};
export const setEventTagsList= (data) => {
  return {
    type: SET_EVENT_TAGS_LIST,
    payload: data,
  };
};
export const setEventVideosList= (data) => {
  return {
    type: SET_EVENT_VIDEOS_LIST,
    payload: data,
  };
};
export const setBusinessCategoriesList = (data) => {
  return {
    type: SET_BUSINESS_CATEGORIES_LIST,
    payload: data,
  };
};

export const setBusinessListingsList = (data) => {
  return {
    type: SET_BUSINESS_LISTINGS_LIST,
    payload: data,
  };
};

export const setShopSectionList = (data) => {
  return {
    type: SET_SHOP_SECTION_LIST,
    payload: data,
  };
};

export const setScrapedDataList = (data) => {
  return {
    type: SET_SCRAPED_DATA_LIST,
    payload: data,
  };
};

export const setBotsList = (data) => {
  return {
    type: SET_BOTS_LIST,
    payload: data,
  };
};

export const setShopDiscountsList = (data) => {
  return {
    type: SET_SHOP_DISCOUNTS_LIST,
    payload: data,
  };
};

export const setCMSTopicsList = (data) => {
  return {
    type: SET_CMS_TOPICS_LIST,
    payload: data,
  };
};
export const setCMSTopicPostsList = (data) => {
  return {
    type: SET_CMS_TOPIC_POSTS_LIST,
    payload: data,
  };
};
export const setPromptsList = (data) => {
  return {
    type: SET_CMS_SHOWANDTELL_LIST,
    payload: data,
  };
};

export const setCMSComment = (data) => {
  return {
    type: SET_CMS_COMMENT,
    payload: data,
  };
};

export const setCMSFeedFormData = (data) => {
  return {
    type: SET_CMS_FEED_FORM_DATA,
    payload: data,
  };
};

export const setCurrentDashboardAction = (data) => {
  return {
    type: SET_CURRENT_DASHBOARD_ACTION,
    payload: data,
  };
};

export const setAdminAuthToken = (data) => {
  return {
    type: SET_ADMIN_AUTH_TOKEN,
    payload: data,
  };
};

export const setAdminUserData = (data) => {
  return {
    type: SET_ADMIN_USER_DATA,
    payload: data,
  };
};

export const setAdminRole = (data) => {
  return {
    type: SET_ADMIN_ROLE,
    payload: data,
  };
};

export const setImage = (data) => {
  return {
    type: SET_IMG,
    payload: data,
  };
};

export const setCmsFeed = (data) => {
  return {
    type: SET_CMS_FEED,
    payload: data,
  };
};

export const setCmsFeedPageNo = (data) => {
  return {
    type: SET_CMS_FEED_PAGE,
    payload: data,
  };
};

export const setCmsFeedTotal = (data) => {
  return {
    type: SET_CMS_FEED_TOTAL,
    payload: data,
  };
};

export const setCmsFeedDeletionIds = (data) => {
  return {
    type: SET_CMS_FEED_DELETION_IDS,
    payload: data,
  };
};

export const setCmsCommentsDeletionIds = (data) => {
  return {
    type: SET_CMS_COMMENTS_DELETION_IDS,
    payload: data,
  };
};

export const setExpertUserProfile = (data) => {
  return {
    type: SET_SEARCH_RESULTS_USER,
    payload: data,
  };
};

export const setCmsGroupChats = (data) => {
  return {
    type: SET_CMS_GROUP_CHATS,
    payload: data,
  };
};

export const setCmsOpenChat = (data) => {
  return {
    type: SET_CMS_OPEN_CHAT,
    payload: data,
  };
};

export const setCmsOpenChatMembers = (data) => {
  return {
    type: SET_CMS_OPEN_CHAT_MEMBERS,
    payload: data,
  };
};
