import React, { useEffect, useRef, useState } from "react";
import "./SalesSubscriptionSlotPicker.css";
import CarouselLeftArrow from "../../assets/icons/CarouselLeftArrow";
import CarouselRightArrow from "../../assets/icons/CarouselRightArrow";
import { Button, FormControl, InputLabel, MenuItem, Select, Slider, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { EPOCH, RescheduleWalksType, WalkerVerification, daysOfWeek } from "../../redux/constants";
import { isSameDay } from "../../redux/dataFetchers/common";
import {
  getHotspots,
  getWalkersFreeSlots,
} from "../SalesSubscribeUserCard/apis";
import { useDispatch } from "react-redux";
import StarIcon from '@mui/icons-material/Star';
import { setActionNotif } from "../../redux/actions";
import SalesDefaultWalkerAssignCard from "../SalesDefaultWalkerAssignCard/SalesDefaultWalkerAssignCard";
import { isEpoch } from "../SalesSubscribeUserCard/SalesSubscribeUserCard";
import Checkbox from "@mui/material/Checkbox";
import WalkerSelectionCarousel from "../SalesWalkerSelectionCarousel/SalesWalkerSelectionCarousel";

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 19,
    label: "19",
  },
  {
    value: 20,
    label: "20",
  },
];

const commonBtnStyle = {
  borderRadius: "50px",
  padding: "0.8rem 0",
  textTransform: "Capitalize",
  position: "relative",
  overflow: "hidden",
  marginTop: "0.6rem",
  width: "100%",
};

const classes = {
  enabledBtn: {
    ...commonBtnStyle,
    width: "30%",
    borderRadius: "15px",
    background: "#EF7123",
    color: "#fff",
    fontSize: 20,
    fontWeight: 700,
    padding: "0.2rem 0",
    display: "flex",
    margin: "0",
  },
};

function SalesSubscriptionSlotPicker({
  accessToken,
  subscriptionPlan,
  lat,
  lng,
  schedule,
  selectedTime,
  assignedWalker,
  pauseDate = false,
  setScheduleTime,
  preferredTime,
  showPreferredTime = true,
  walkType = RescheduleWalksType.DEFAULT_WALKS,
  walkerId,
  userId,
  subscriptionId,
  orderId,
  noOfWalks,
  walkIndex,
  walksPerDay,
  pets,
  slotStartTime,
  scheduleIndex,
  scheduledDateTime,
  plan,
  setAssignmentDetails,
  extend,
  petIndex,
  setSelectedWalkers,
  showRescheduleCheckBox,
  walk,
  setRescheduleWalksType,
  leftWalks,
  setLeftWalks,
  slotDuration,
  scheduledAt,
  setIsSchedule1SlotExist, 
  setIsSchedule2SlotExist,
  // showFindWalkersOnly,
  isSchedule1SlotExist,
  isSchedule2SlotExist,
  preferredWalkerId,
  startDateParam, 
  endDateParam, 
  setStartDateEpoch,
  setEndDateEpoch,
  startDateEpoch,
  endDateEpoch,
  isLeaveParam,
}) {
  const [slotData, setSlotData] = useState([]);
  const [hotspotData, setHotspotData] = useState([]);
  const [walkers, setWalkers] = useState([]);
  const [selectedWalker, setSelectedWalker] = useState(assignedWalker || null)
  const [selectedHotspot, setSelectedHotspot] = useState([]);
  const initialRenderRef = useRef(true);
  
  let today =
    pauseDate && isEpoch(selectedTime) ? new Date(selectedTime) : new Date();
  let date = today.getDate();
  let day = daysOfWeek[new Date(date)?.getDay()];
  let dateTime = today.getTime();


  let dateArray = [];
  dateArray = [{ date: date, day: day, dateTime: dateTime }];

  const [selectedDate, setSelectedDate] = useState({
    date: date,
    day: day,
    dateTime: dateTime,
  });

  const timeScrollContainer = useRef(null);
  const morningSlotScrollContainer = useRef(null);
  const eveningSlotScrollContainer = useRef(null);
  const [slotRadius, setSlotRadius] = useState(3);
  const [isSlotSearchDirty, setIsSlotSearchDirty] = useState(true);
  const [disableFindWalkers, setDisableFindWalkers] = useState(true);

  const [totalWalksPerSchedule, setTotalWalksPerSchedule] = useState(0)

  const dispatch = useDispatch();

  const scroll = (scrollContainer, scrollOffset) => {
    scrollContainer.current.scrollLeft += scrollOffset;
  };

  const triggerGetHotspots = (slotRadius, lat, lng) => {
    if (lat && lng) {
      getHotspots(accessToken, slotRadius, lat, lng)
        .then((data) => {
          let hotspotWithWalkersAssigned = data?.filter(
            (item) => item?.walkersAssigned > 0
          );
          setHotspotData(hotspotWithWalkersAssigned);
          setSelectedHotspot(hotspotWithWalkersAssigned[0]);
          let preferredWalkerData;
          let walkers = hotspotWithWalkersAssigned.flatMap((hotspot) => {
            return hotspot.walkers.map((walker) => {
              walker.distance = hotspot.distance;
              if(preferredWalkerId && walker?.id === preferredWalkerId) 
                preferredWalkerData = walker;
              return walker;
            });
          });

          walkers = walkers.filter((walker) => (walker?.id !== preferredWalkerId && walker?.verification === WalkerVerification.COMPLETED && !walker?.isDeleted))
          if(preferredWalkerData && preferredWalkerData?.verification === WalkerVerification.COMPLETED && !preferredWalkerData?.isDeleted) {
            preferredWalkerData.preferred = true;
            walkers?.unshift(preferredWalkerData)
          }
          setWalkers(walkers);
                 
          if(!extend){
            initialRenderRef.current = false;
            if(selectedWalker === walkers[0]?.id) walkerFreeSlotsApi(selectedWalker)
            setSelectedWalker(walkers[0]?.id);
            setSelectedWalkers((prev) => {
              let prevWalkers = [...prev];
              prevWalkers[scheduleIndex] = walkers[0]?.id;
              return prevWalkers;
            });
          }else{
            walkerFreeSlotsApi(selectedWalker);
          }
         
          setIsSlotSearchDirty(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(()=>{
    setTotalWalksPerSchedule(0)
  },[noOfWalks])

  const searchBtnBlocked = () => {
    dispatch(
      setActionNotif({
        open: true,
        message: "Select Plans to Select Slots",
        severity: "warning",
      })
    );
  };

  const getWalksForFindWalkers = () => {
    let totalWalks = 0;
    totalWalks = Math.floor(+noOfWalks/(+subscriptionPlan?.walksPerDay || 1));
    if(extend){
      let isFirstWalkBig = new Date(schedule[0]).getDate() > new Date(schedule[1]).getDate();
      if(leftWalks !== 0){
        if(isFirstWalkBig && scheduleIndex === 1){
          totalWalks++;
          setLeftWalks(leftWalks-1);
       }else{
         if(!isFirstWalkBig && scheduleIndex === 0){
           totalWalks++;
           setLeftWalks(leftWalks-1);
         }
       }
     } 
    }
    setTotalWalksPerSchedule(totalWalks)
    return totalWalks;
  }

  const isSlotExist = (data, scheduleTime) => {
    try{
      let isScheduleExist = false;
    
      let scheduledDate = new Date(scheduleTime);
      var formattedTime = scheduledDate.toLocaleTimeString('en-US', { hour12: false });
      var [hours, minutes] = formattedTime.split(':').map(Number);
      let scheduleTimeInMilliseconds = (hours * EPOCH.ONE_HOUR_MS) + (minutes * EPOCH.ONE_MINUTE_MS);
      if(data && data.find((timeInMilliseconds)=> timeInMilliseconds === scheduleTimeInMilliseconds) ) isScheduleExist = true;
  
      return isScheduleExist;
    }catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{
    if(Array.isArray(slotData)){
      let slotExist = isSlotExist(slotData, selectedTime);
      scheduleIndex === 0 ? setIsSchedule1SlotExist && setIsSchedule1SlotExist(slotExist) : setIsSchedule2SlotExist && setIsSchedule2SlotExist(slotExist)
    }
  },[slotData, selectedTime])

  const walkerFreeSlotsApi = (walker) =>{
    getWalkersFreeSlots(
      accessToken,
      walkType,
      subscriptionPlan,
      walker,
      isEpoch(selectedTime)? selectedTime: selectedDate?.dateTime,
      slotDuration,
      pets,
      subscriptionId, 
      orderId,
      userId,
      totalWalksPerSchedule ? totalWalksPerSchedule : noOfWalks && getWalksForFindWalkers(),
      scheduledAt,
      startDateEpoch, 
      endDateEpoch, 
    )
      .then((data) => {
        setSlotData(data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (!initialRenderRef.current) {
      lat &&
      lng &&
      selectedWalker &&
      walkerFreeSlotsApi(selectedWalker)
    } else {
      initialRenderRef.current = false;
    }
  }, [selectedWalker, new Date(selectedTime).getDate()]);

  useEffect(() => {
    if (!isSlotSearchDirty)
      setIsSlotSearchDirty(() => {
        !extend && setSelectedWalker(null);
        setSlotData([]);
        return true;
      });
  }, [slotRadius, walkType, subscriptionPlan, lat, lng, noOfWalks, slotDuration, startDateEpoch,endDateEpoch]);

  function valuetext(value) {
    return `${value}°km`; 
  }

  useEffect(() => {
    slotData?.map((item) => {
      const selectedSchedule = new Date(selectedTime).setHours(0, 0, 0, item);
      if (selectedSchedule === selectedTime) setDisableFindWalkers(false);
    });
  }, [selectedTime, slotData, setDisableFindWalkers]);

  return (
    <div style={{ width: "100%" }}>
      {showPreferredTime && (
        <h3>
          <p>
            Preferred Time:
            {new Date(preferredTime).toLocaleString()}
          </p>
        </h3>
      )}
      <div style={{ width: "100%", margin: "2rem 0" }}>
        <h3>Radius of Walker Search (in Kms)</h3>
        <Slider
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: "0 auto",
          }}
          aria-label="Radius"
          value={slotRadius}
          onChange={(event, newValue) => setSlotRadius(newValue)}
          getAriaValueText={valuetext}
          valueLabelDisplay="off"
          step={1}
          marks={marks}
          min={1}
          max={20}
        />
      </div>
          
      {/* Reschedule Walks */}
      {showRescheduleCheckBox && !walk?.trial && !isLeaveParam && (
        <>
        <div style={{margin:"2rem"}}>
          <FormControl sx={{ width: "100%" }}>
              <Select
                labelId="city"
                id="select-verification"
                value={walkType}
                style={{ minWidth: "9rem" }}
                onChange={(event) =>
                  {
                    const selectedType = event.target.value;
                    setRescheduleWalksType(selectedType)
                  }
                }
              >
                  <MenuItem value={RescheduleWalksType.SINGLE_WALK} >
                 Reschedule Single Walk
                  </MenuItem>
                  <MenuItem value={RescheduleWalksType.UPCOMING_WALKS}>
                  Reschedule All Upcoming Walks
                  </MenuItem>
                  <MenuItem value={RescheduleWalksType.TIME_RANGE_WALKS}>
                  Reschedule Walks In TimeRange
                  </MenuItem>
              </Select>
            </FormControl>
        </div>
        {walkType === RescheduleWalksType.TIME_RANGE_WALKS  && 
          <div
            style={{
              width: "22rem",
              display: "flex",
              margin: "2rem",
              flexDirection: "row",
              justifyContent:"center",
              gap: "1rem",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{ padding: 0, height: "5px" }}
                label="Start date"
                value={startDateEpoch}
                onChange={(newValue) => {
                  setStartDateEpoch(newValue.setHours(0, 0, 0, 0));
                }}
                disabled
                inputFormat="dd-MM-yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End date"
                value={endDateEpoch}
                onChange={(newValue) => {
                  setEndDateEpoch(newValue.setHours(23, 59, 59, 999));
                }}
                inputFormat="dd-MM-yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
      }
        </>
          )}

          {
              isLeaveParam && !isSameDay(startDateParam, endDateParam) &&  (
                <div className="assign-to-all-text">
                <Checkbox
                  inputProps= { {"aria-label": "Filter Checkbox" }}
                  style={classes.checkBox}
                  checked={
                    walkType === RescheduleWalksType.TIME_RANGE_WALKS
                  }
                  onChange={(event) =>
                    setRescheduleWalksType((prev) =>
                      prev === RescheduleWalksType.SINGLE_WALK
                        ? RescheduleWalksType.TIME_RANGE_WALKS
                        : RescheduleWalksType.SINGLE_WALK
                    )
                  }
                />
                <span
                  style={{ color: "rgb(124, 119, 119)", fontWeight: "bold" }}
                >
                  Assign To All Upcoming Walks Of This User in the Leave Time Range
                </span>
              </div>
              )
            }

      {isSlotSearchDirty ? (
          <Button
            style={classes.enabledBtn}
            onClick={() =>
              walkType === RescheduleWalksType?.DEFAULT_WALKS &&
              !subscriptionPlan
                ? searchBtnBlocked()
                : triggerGetHotspots(slotRadius, lat, lng)
            }
            sx={{
              cursor:
                walkType === RescheduleWalksType?.DEFAULT_WALKS &&
                !subscriptionPlan
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            Search
          </Button>
      ) : walkers?.length !== 0 ? (
        <div className="slot-container">
          <WalkerSelectionCarousel walkers={walkers} 
            onClickWalker={(walker) => {
                setSelectedWalker(walker.id)
                setSelectedWalkers((prev) => {
                  let prevWalkers = [...prev];
                  prevWalkers[scheduleIndex] = walker.id;
                  return prevWalkers;
                });
              }}
            walkerStyle={{
                cursor:"pointer",
            }}
            selectedWalkerId={selectedWalker}
            scroll={scroll}
          />

          <div className="slot-container">
            <div className="date-picker">
              <div className="date-selector">
                <h3 style={{ color: "#000" }}>Select Date</h3>
                <div className="dates-container">
                  <div className="dates-scroll" ref={timeScrollContainer}>
                    {(walkType === RescheduleWalksType?.DEFAULT_WALKS || walkType === RescheduleWalksType?.DUPLICATE_WALKS )? (
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            sx={{ padding: 0, height: "5px" }}
                            label="Start date"
                            disabled={extend || (walkType === RescheduleWalksType.DUPLICATE_WALKS) ? true : false}
                            value={
                              isEpoch(selectedTime)
                                ? selectedTime
                                : selectedDate?.dateTime
                            }
                            onChange={(newValue) => {
                              let preferredDate = new Date(preferredTime);

                              preferredTime
                                ? setScheduleTime(
                                    new Date(newValue).setHours(
                                      preferredDate?.getHours(),
                                      preferredDate?.getMinutes(),
                                      0,
                                      0
                                    )
                                  )
                                : setScheduleTime(
                                    new Date(newValue).setHours(
                                      0,
                                      0,
                                      0,
                                      slotData?.[0] ? slotData?.[0] : 0,
                                    )
                                  );
                            }}
                            inputFormat="dd-MM-yyyy"
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                    ) : (
                      dateArray?.map((slotItem) => (
                        <div
                          key={slotItem.date}
                          className={
                            selectedDate?.date === slotItem?.date &&
                            selectedDate?.day === slotItem?.day
                              ? "active-date"
                              : "date"
                          }
                          onClick={() => {
                            setSelectedDate(slotItem)}}
                        >
                          <span>{`${slotItem?.day?.[0].toUpperCase()}${slotItem?.day
                            ?.slice(1, 3)
                            ?.toLowerCase()}`}</span>
                          <span>{slotItem?.date}</span>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="time-container">
              <h3 style={{ color: "#000" }}>Select Time</h3>
              <div>
                {/* Morning Slots */}
                <div className="morning">
                  <p>Morning Slots</p>
                  <div className="morning-slot-container">
                    <CarouselLeftArrow
                      onclick={() => scroll(morningSlotScrollContainer, -350)}
                    />
                    <div
                      className="time-picker"
                      ref={morningSlotScrollContainer}
                    >
                      {/* {dateArray?.map((dateItem) => */}
                      {slotData?.map((item, indx) => {
                        const date = new Date(
                          isEpoch(selectedTime)
                            ? selectedTime
                            : selectedDate?.dateTime
                        ).setHours(0, 0, 0, item);

                        let hours = new Date(date)
                          .getHours()
                          .toString()
                          .padStart(2, "0");
                        let minutes = new Date(date)
                          .getMinutes()
                          .toString()
                          .padStart(2, "0");

                        if (hours < 12) {
                          return (
                            <div
                              key={date}
                              style={{
                                cursor:extend? 'not-allowed':"pointer",
                                opacity:extend?0.6:1

                              }}
                              className={
                                selectedTime === date ? "active-time" : "time"
                              }
                              onClick={() => {
                                if(extend) return;
                                setScheduleTime(date);
                              }}
                            >
                              <p>{`${hours}:${minutes}`}</p>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                    <CarouselRightArrow
                      onclick={() => scroll(morningSlotScrollContainer, 350)}
                    />
                  </div>
                </div>

                {/* Evening Slots */}
                <div className="evening">
                  <h3 style={{ color: "#000" }}>Evening Slots</h3>
                  <div className="evening-slot-container">
                    <CarouselLeftArrow
                      onclick={() => scroll(eveningSlotScrollContainer, -350)}
                    />
                    <div
                      className="time-picker"
                      ref={eveningSlotScrollContainer}
                    >
                      {slotData?.map((item) => {
                        const date = new Date(
                          isEpoch(selectedTime)
                            ? selectedTime
                            : selectedDate?.dateTime
                        ).setHours(0, 0, 0, item);
                        // let slotHours = new Date(slotItem)?.getHours();
                        // let slotMinutes = new Date(slotItem)?.getMinutes();
                        // date = new Date(selectedDate?.dateTime)?.setHours(
                        //   slotHours,
                        //   slotMinutes,
                        //   0,
                        //   0
                        // );
                        let hours = new Date(date)
                          .getHours()
                          .toString()
                          .padStart(2, "0");
                        let minutes = new Date(date)
                          .getMinutes()
                          .toString()
                          .padStart(2, "0");

                        if (hours >= 12) {
                          return (
                            <div
                              key={date}
                              style={{
                                cursor:extend? 'not-allowed':"pointer",
                                opacity:extend?0.6:1
                              }}
                              className={
                                selectedTime === date ? "active-time" : "time"
                              }
                              onClick={() => {
                                if(extend) return;
                                setScheduleTime(date);
                              }}
                            >
                              <p>{`${hours}:${minutes}`}</p>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                    <CarouselRightArrow
                      onclick={() => scroll(eveningSlotScrollContainer, 350)}
                    />
                  </div>
                </div>
              </div>
              {
                extend && ((scheduleIndex === 0 && !isSchedule1SlotExist) || (scheduleIndex === 1 && !isSchedule2SlotExist)) ?
                <p style={{ color: "orange" }}>
                The Walker is not free in the walk schedule
              </p>:<></>
              }
            </div>
          </div>
        </div>
      ) : (
        <p style={{ color: "orange" }}>
          No Walkers found. Try increasing the search radius!
        </p>
      )}

      {plan ? (
        <SalesDefaultWalkerAssignCard
          scheduleIndex={scheduleIndex}
          petIndex={petIndex}
          scheduledDateTime={selectedTime}
          schedule={schedule}
          plan={plan}
          setAssignmentDetails={setAssignmentDetails}
          extend={extend}
          showFindWalkersOnly={true}
          disableFindWalkers={disableFindWalkers}
          hotspotId={selectedHotspot?.id}
          updateSchedule={false}
          noOfWalks={noOfWalks}
          walkIndex={walkIndex}
          walksPerDay={walksPerDay}
          leftWalks={leftWalks} 
          setLeftWalks={setLeftWalks}
        />
      ) : null}
    </div>
  );
}

export default SalesSubscriptionSlotPicker;
