import "./SalesPlanDescriptionCard.css";

export default function SalesPlanDescriptionCard({ plan, amountPaid, orderId }) {
    const price = plan?.price ? plan.price/100 : 0;
    const discountedPrice = plan?.discountedPrice ? plan.discountedPrice/100 : plan?.price/100;
    const amountPaidDisplay = amountPaid ? amountPaid/100 : discountedPrice;
    return (
    <>
        {/* <div className="subs-info-header">
            Plan Details
        </div>
        <div className='subs-info-card'>
            {plan?.name}
        </div>
        <div className='subs-info-card mini-plan-info-detail'>
            {plan?.description}
        </div>
        {!orderId && 
        <>
            <div className='subs-info-card'>
                Price : Rs. {price}
            </div>
            <div className='subs-info-card'>
                Discounted Price : Rs. {discountedPrice}
            </div>
        </>
        }
        <div className='subs-info-card'>
            Amount Paid : Rs. {amountPaidDisplay}
        </div>
        <div className='subs-info-card'>
            Frequency : {plan?.billingCycle}
        </div>
        <div className='subs-info-card'>
            Category : {plan?.category}
            </div> */}
            



            <div className="card-container">
      <div className="headline">
        <h3>Plan Details</h3>
      </div>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 16 }}>
        <div className="user-info-card">
          <span className="label">Validity:</span>
          <span className="value">{plan?.description}</span>
        </div>
        <div className="user-info-card">
          <span className="label">Price:</span>{" "}
          <span className="value">₹ {price}</span>
        </div>
        <div className="user-info-card">
          <span className="label">Discounted Price:</span>{" "}
          <span className="value">₹ {amountPaidDisplay}</span>
        </div>
        <div className="user-info-card">
          <span className="label">Amount Paid:</span>{" "}
          <span className="value">₹ {amountPaidDisplay}</span>
        </div>
        <div className="user-info-card">
          <span className="label">Frequency</span>{" "}
          <span className="value">{plan?.billingCycle}</span>
        </div>
        <div className="user-info-card">
          <span className="label">Category</span>{" "}
          <span className="value">{plan?.category}</span>
        </div>
      </div>
    </div>
    </>
  )
}
