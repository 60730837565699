export default function MenuIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          d="M6.78923 2.87306H17.9541C18.4649 2.87306 18.8784 2.45955 18.8784 1.94874C18.8784 1.43793 18.4649 1.02441 17.9541 1.02441H6.78923C6.27842 1.02441 5.86491 1.43793 5.86491 1.94874C5.86491 2.45955 6.27842 2.87306 6.78923 2.87306Z"
          fill="#121212"
          stroke="#121212"
        />
        <path
          d="M1.97301 10.9004H17.9541C18.4649 10.9004 18.8784 10.4869 18.8784 9.97608C18.8784 9.46527 18.4649 9.05176 17.9541 9.05176H1.97301C1.4622 9.05176 1.04869 9.46527 1.04869 9.97608C1.04869 10.4869 1.4622 10.9004 1.97301 10.9004Z"
          fill="#121212"
          stroke="#121212"
        />
        <path
          d="M6.78923 18.9268H17.9541C18.4649 18.9268 18.8784 18.5133 18.8784 18.0024C18.8784 17.4916 18.4649 17.0781 17.9541 17.0781H6.78923C6.27842 17.0781 5.86491 17.4916 5.86491 18.0024C5.86491 18.5133 6.27842 18.9268 6.78923 18.9268Z"
          fill="#121212"
          stroke="#121212"
        />
      </svg>
    </div>
  );
}
