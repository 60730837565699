import {
  SET_USER_ACCESS_TOKEN,
  SET_APP_USER_DATA,
  SET_PET_BREEDS,
  SET_USER_MEMORIES,
  SET_USER_POSTS,
  SET_SAVED_POSTS,
  SET_USER_ACTIVITY_PAGE_NO,
  SET_USER_PROFILE_COMPLETION_STEPS,
  userProfileCompletionSteps,
  SET_CITIES,
  SET_STATES,
  SET_COUNTRIES,
} from "../constants";

let initialProfileCompletionSteps = {};
for (let step of userProfileCompletionSteps)
  initialProfileCompletionSteps[step] = false;

export default function userReducer(
  state = {
    accessToken: "",
    data: {
      name:null,
      email: null,
      photoUrl: "",
      provider: null,
      providerId: null,
      state: null,
      city: null,
      country: null,
      pinCode: null,
      address: null,
      id: null,
      phoneNumber: "",
      hasPosted: true,
      hasSharedPetProfile: false,
      hasAllPetImages: null,
      onboardComplete: false,
      petProfiles: [],
    },
    petBreeds: {
      cat: [],
      dog: [],
    },
    countries: [],
    states: [],
    cities: [],
    memories: [],
    savedPosts: [],
    posts: [],
    pageNo: 0,
    profileCompletionSteps: initialProfileCompletionSteps,
  },
  action = {}
) {
  switch (action.type) {
    case SET_USER_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_APP_USER_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_PET_BREEDS:
      return {
        ...state,
        petBreeds: action.payload,
      };
    case SET_USER_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case SET_USER_MEMORIES:
      return {
        ...state,
        memories: action.payload,
      };
    case SET_SAVED_POSTS:
      return {
        ...state,
        savedPosts: action.payload,
      };
    case SET_USER_ACTIVITY_PAGE_NO:
      return {
        ...state,
        pageNo: action.payload,
      };
    case SET_USER_PROFILE_COMPLETION_STEPS:
      return {
        ...state,
        profileCompletionSteps: action.payload,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_STATES:
      return {
        ...state,
        states: action.payload,
      };
    case SET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    default:
      return state;
  }
}
