import externalLinks from "./external_links.json";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import ChatBubbleIcon from "../assets/icons/ChatBubbleIcon";
import LogoutIcon from "../assets/icons/LogoutIcon";
import SaveCurvedIcon from "../assets/icons/SaveCurvedIcon";
import PostIcon from "../assets/icons/PostIcon";
import ChatBubbleColoredIcon from "../assets/icons/ChatBubbleColoredIcon";
import MediaColoredIcon from "../assets/icons/MediaColoredIcon";
import LogoutColoredIcon from "../assets/icons/LogoutColoredIcon";
import SaveCurvedColoredIcon from "../assets/icons/SaveCurvedColoredIcon";
import PostColoredIcon from "../assets/icons/PostColoredIcon";
import ProfileIcon from "../assets/icons/ProfileIcon";
import ProfileColoredIcon from "../assets/icons/ProfileColoredIcon";
import MediaOutlinedIcon from "../assets/icons/MediaOutlinedIcon";
import DinnerDiningOutlinedIcon from "@mui/icons-material/DinnerDiningOutlined";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SaveIcon from "../assets/icons/SaveIcon";
import SaveColoredIcon from "../assets/icons/SaveColoredIcon";
import ShareIcon from "../assets/icons/ShareIcon";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import UpvoteIcon from "../assets/icons/UpvoteIcon";
import UpvoteColoredIcon from "../assets/icons/UpvoteColoredIcon";
import StorefrontIcon from '@mui/icons-material/Storefront';
import CommentIcon from "../assets/icons/CommentIcon";
import { userNavActions } from "../redux/constants";
import PetsIcon from '@mui/icons-material/Pets';
import GroupIcon from '@mui/icons-material/Group';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { Logout } from "@mui/icons-material";
import PaidIcon from '@mui/icons-material/Paid';
import EventIcon from '@mui/icons-material/Event';
import GoogleSocialIcon from "../assets/icons/GoogleSocialIcon";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import UpdateIcon from '@mui/icons-material/Update';

export const userNavlist = {
  left: [
    {
      pos: 0,
      linkTo: "/community",
      linkText: "Join the Community",
      Icon: <AppShortcutIcon style={{ color: "rgb(255,88,5)" }} />,
      follow: true,
      external: false,
      inWebapp: false,
    },
    // {
    //   pos: 1,
    //   linkTo: "/fresh-food",
    //   linkText: "Fresh Food",
    //   Icon: <DinnerDiningOutlinedIcon style={{ color: "rgb(255,88,5)" }} />,
    //   follow: true,
    //   external: false,
    //   inWebapp: true,
    // },
    // {
    //   pos: 2,
    //   linkTo: "/dog-walking",
    //   linkText: "Dog Walking",
    //   Icon: <PetsOutlinedIcon style={{ color: "rgb(255,88,5)" }} />,
    //   follow: true,
    //   external: false,
    //   inWebapp: true,
    // },
    {
      pos: 1,
      linkTo: externalLinks.splootStore,
      linkText: "Shop",
      Icon: <StorefrontIcon style={{ color: "rgb(255,88,5)" }} />,
      follow: true,
      external: true,
      inWebapp: true,
    },
    {
      pos: 2,
      linkTo: "/events",
      linkText: "Events",
      Icon: <EventIcon style={{ color: "rgb(255,88,5)" }} />,
      follow: true,
      external: false,
      inWebapp: true,
    },
    {
      pos: 3,
      linkTo: "/blogs",
      linkText: "Blogs",
      Icon: <ArticleOutlinedIcon style={{ color: "rgb(255,88,5)" }} />,
      follow: true,
      external: false,
      inWebapp: true,
    },
  ],
  right: [
    {
      pos: 4,
      linkTo: "/posts",
      linkText: "Discuss",
      Icon: <ChatBubbleColoredIcon style={{ color: "rgb(255,88,5)" }} />,
      follow: true,
      external: false,
      inWebapp: true,
    },
  ],
};

export const adminDashboardNavList = {
  left: [
    {
      pos: 0,
      linkTo: "/admin/dashboard/events",
      linkText: "Events",
      follow: true,
    },
    {
      pos: 1,
      linkTo: "/admin/dashboard/learn",
      linkText: "Learn",
      follow: true,
    },
    {
      pos: 2,
      linkTo: "/admin/dashboard/prompts",
      linkText: "Show & Tell",
      follow: true,
    },
    {
      pos: 3,
      linkTo: "/admin/dashboard/topics",
      linkText: "Topics",
      follow: true,
    },
    {
      pos: 4,
      linkTo: "/admin/dashboard/banners",
      linkText: "Banners",
      follow: true,
    },
    {
      pos: 5,
      linkTo: "/admin/dashboard/quests",
      linkText: "Quests",
      follow: true,
    },
    {
      pos: 6,
      linkTo: "/admin/dashboard/feed",
      linkText: "Feed",
      follow: true,
    },
    {
      pos: 7,
      linkTo: "/admin/dashboard/expert-profile",
      linkText: "User Role",
      follow: true,
    },
    {
      pos: 8,
      linkTo: "/admin/dashboard/business-listings",
      linkText: "Businesses",
      follow: true,
    },
    {
      pos: 9,
      linkTo: "/admin/dashboard/chats",
      linkText: "Chats",
      follow: true,
    },
    {
      pos: 10,
      linkTo: "/admin/dashboard/shop",
      linkText: "Shop",
      follow: true,
    },
    {
      pos: 11,
      linkTo: "/admin/dashboard/scraped-posts",
      linkText: "Scraped Posts",
      follow: true,
    },
  ],
  right: [],
};

let roles = {
  AQUISITION: "AQUISITION",
  OPERATIONS: "OPERATIONS"
}

export const salesDashboardNavList = [ {
    pos: 0,
    linkTo: "/sales/dashboard/walks",
    linkText: "Walks",
    Icon: <PetsIcon style={{ color: "#ffff" }} />,
    activeIcon: <PetsIcon style={{ color: "#EF7123" }} />,
    follow: true,
  },
  {
    pos: 1,
    linkTo: "/sales/dashboard/walkers",
    linkText: "Walkers",
    Icon: <DirectionsWalkIcon style={{ color: "#ffff" }} />,
    activeIcon: <DirectionsWalkIcon style={{ color: "#EF7123" }} />,
    follow: true,
  },
  {
    pos: 2,
    linkTo: "/sales/dashboard/users",
    linkText: "Trials Pending",
    Icon: <GroupIcon style={{ color: "#ffff" }} />,
    activeIcon: <GroupIcon style={{ color: "#EF7123" }} />,
    follow: true,
  },
  {
    pos: 3,
    linkText: "Subscriptions",
    linkTo: "/sales/dashboard/subscriptions",
    follow: true,
    Icon: <PaidIcon style={{ color: "#ffff" }} />,
    activeIcon: <PaidIcon style={{ color: "#EF7123" }} />,
  },
  {
    pos: 4,
    linkText: "Location",
    linkTo: "/sales/dashboard/location",
    follow: true,
    Icon: <LocationOnIcon style={{ color: "#ffff" }} />,
    role: [roles.OPERATIONS],
    activeIcon: <LocationOnIcon style={{ color: "#EF7123" }} />,
  },
  {
    pos: 5,
    linkText: "Analytics-ontime-walks",
    linkTo: "/sales/dashboard/analytics-ontime-walks",
    follow: true,
    Icon: <AnalyticsIcon style={{ color: "#ffff" }} />,
    role:[roles.OPERATIONS],
    activeIcon: <AnalyticsIcon style={{ color: "#EF7123" }} />,
  },
  {
    pos: 6,
    linkText: "Leave",
    linkTo: "/sales/dashboard/leaves",
    follow: true,
    Icon: <EventBusyIcon style={{ color: "#ffff" }} />,
    role:[roles.OPERATIONS],
    activeIcon: <EventBusyIcon style={{ color: "#EF7123" }} />,
  },
  {
    pos: 7,
    linkText: "Walk Requests",
    linkTo: "/sales/dashboard/walk-requests",
    follow: true,
    Icon: <UpdateIcon style={{ color: "#ffff" }} />,
    role:[roles.OPERATIONS],
    activeIcon: <UpdateIcon style={{ color: "#EF7123" }} />,
  }
  // {
  //   pos: 4,
  //   linkText: "Logout",
  //   linkTo: "",
  //   follow: true,
  //   Icon: <Logout style={{ color: "#EF7123" }}/>,
  //   action: (navigate = () => null) => {
  //     window.localStorage.removeItem("salesAccessToken");
  //     navigate("/sales/login");
  //   }
  // }
]

export const LogoutList = [{
  pos: 1,
  linkText: "Logout",
  linkTo: "",
  follow: true,
  Icon: <Logout style={{ color: "#EF7123" }} />,
  action: (navigate = () => null) => {
    window.localStorage.removeItem("salesAccessToken");
    navigate("/sales/login");
  }
}]

export const getProfileMobNavList = (id) => {
  return {
    left: [
      {
        pos: 0,
        linkTo: `/users/${id}/dashboard`,
        linkText: "My Profile",
        Icon: <ProfileColoredIcon />,
        follow: true,
        external: false,
      },
      {
        pos: 1,
        linkTo: `/users/${id}/posts`,
        linkText: "My Posts",
        Icon: <PostColoredIcon />,
        follow: true,
        external: false,
        eventToTrack: "My Posts",
      },
      {
        pos: 2,
        linkTo: `users/${id}/saved-posts`,
        linkText: "Saved Posts",
        Icon: <SaveCurvedColoredIcon />,
        follow: true,
        external: false,
        eventToTrack: "Saved Posts",
      },
      {
        pos: 3,
        linkTo: `users/${id}/memories`,
        linkText: "My Memories",
        Icon: <MediaColoredIcon />,
        follow: true,
        external: false,
        eventToTrack: "My memories",
      },
      {
        pos: 4,
        linkTo: "/fresh-food",
        linkText: "Fresh Food",
        Icon: <DinnerDiningOutlinedIcon style={{ color: "#EF7123" }} />,
        follow: true,
        external: false,
      },
      {
        pos: 5,
        linkTo: "/events",
        linkText: "Events",
        Icon: <EventIcon style={{ color: "#EF7123" }} />,
        follow: true,
        external: false,
      },
      {
        pos: 6,
        linkTo: "/dog-walking",
        linkText: "Dog Walking",
        Icon: <PetsOutlinedIcon style={{ color: "#EF7123" }} />,
        follow: true,
        external: false,
      },
      {
        pos: 7,
        linkTo: "/blogs",
        linkText: "Blogs",
        Icon: <ArticleOutlinedIcon style={{ color: "#EF7123" }} />,
        follow: true,
        external: false,
      },
      {
        pos: 8,
        linkTo: "",
        linkText: "Get In Touch",
        Icon: <ChatBubbleColoredIcon />,
        follow: true,
        external: false,
        actionType: userNavActions.CONTACT,
        eventToTrack: "Get In Touch",
        action: () => {
          window.open(
            `https://mail.google.com/mail/?view=cm&fs=1&to=${externalLinks.contactMailAddress}`,
            "_blank"
          );
        },
      },
      {
        pos: 9,
        linkTo: "",
        linkText: "Logout",
        Icon: <LogoutColoredIcon />,
        follow: true,
        external: false,
        actionType: userNavActions.LOGOUT,
        eventToTrack: "Logout_Cicked",
        action: (openSignoutModal = () => null) => {
          openSignoutModal();
        },
      },
    ],
    right: [],
  };
};

export const getProfileSideNavList = (id) => {
  return {
    left: [
      {
        pos: 0,
        linkTo: `/users/${id}/dashboard`,
        linkText: "My Profile",
        Icon: <ProfileIcon />,
        activeIcon: <ProfileColoredIcon />,
        follow: true,
        external: false,
        notIn: [
          `/users/${id}/posts`,
          `/users/${id}/saved-posts`,
          `/users/${id}/memories`,
        ],
      },
      {
        pos: 1,
        linkTo: `/users/${id}/posts`,
        linkText: "My Posts",
        Icon: <PostIcon />,
        activeIcon: <PostColoredIcon />,
        follow: true,
        external: false,
        eventToTrack: "My Posts",
      },
      {
        pos: 2,
        linkTo: `/users/${id}/saved-posts`,
        linkText: "Saved Posts",
        Icon: <SaveCurvedIcon />,
        activeIcon: <SaveCurvedColoredIcon />,
        follow: true,
        external: false,
        eventToTrack: "Saved Posts",
      },
      {
        pos: 3,
        linkTo: `/users/${id}/memories`,
        linkText: "My Memories",
        Icon: <MediaOutlinedIcon />,
        activeIcon: <MediaColoredIcon />,
        follow: true,
        external: false,
        eventToTrack: "My memories",
      },
      {
        pos: 4,
        linkTo: "/profile/contact",
        linkText: "Get In Touch",
        Icon: <ChatBubbleIcon />,
        activeIcon: <ChatBubbleColoredIcon />,
        follow: true,
        external: false,
        actionType: userNavActions.CONTACT,
        eventToTrack: "Get In Touch",
        action: () => {
          //       let win = window, h = win.top.outerHeight, w = window.top.outerWidth;
          //       const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
          // const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
          // return win.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${externalLinks.contactMailAddress}`, "Get In Touch - sploot", `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
          window.open(
            `https://mail.google.com/mail/?view=cm&fs=1&to=${externalLinks.contactMailAddress}`,
            "_blank"
          );
        },
      },
      {
        pos: 5,
        linkTo: "/",
        linkText: "Logout",
        Icon: <LogoutIcon />,
        activeIcon: <LogoutColoredIcon />,
        follow: true,
        external: false,
        actionType: userNavActions.LOGOUT,
        eventToTrack: "Logout_Clicked",
        action: (openSignoutModal = () => null) => {
          openSignoutModal();
        },
      },
    ],
    right: [],
  };
};

export //Schema.org for react app
  const ldJson = [
    {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      name: "sploot",
      url: "https://www.sploot.space",
    },
    {
      "@context": "https://schema.org",
      "@type": "Corporation",
      name: "sploot",
      alternateName: "Sploot Private Limited",
      url: "https://www.sploot.space/",
      logo: "https://sploot.space/assets/images/sploot-logo.webp",
      sameAs: [
        "https://www.instagram.com/wesploot/",
        "https://www.youtube.com/channel/UC6noWKUXrbBQ7buxULWsSgg",
        "https://www.linkedin.com/company/wesploot/",
        "https://twitter.com/wesploot",
        "https://www.facebook.com/wesploot",
        "https://www.sploot.store/",
      ],
    },
  ];

export const discussActionsData = [
  {
    name: "Upvote",
    action: "UPVOTE",
    iconDefault: <UpvoteIcon />,
    iconActive: <UpvoteColoredIcon />,
  },
  {
    name: "Comment",
    action: "COMMENT",
    iconDefault: <CommentIcon />,
    iconActive: <CommentIcon />,
  },
  {
    name: "Share",
    action: "SHARE",
    iconDefault: <ShareIcon />,
    iconActive: <ShareIcon />,
  },
  {
    name: "Save",
    action: "SAVE",
    iconDefault: <SaveIcon />,
    iconActive: <SaveColoredIcon />,
  },
];

export const socialBtns = [
  {
    type: "google",
    icon: (
      <GoogleSocialIcon
        style={{ maxHeight: "1.2rem", display: "flex", alignItems: "center" }}
      />
    ),
    style: {
      borderRadius: "50px",
      margin: "0.5rem 0",
      padding: "0.7rem 0",
      fontWeight: "bold",
      textTransform: "Capitalize",
      backgroundColor: "#F2F3F5",
      color: "GrayText",
      width: "100%",
    },
  },
  // {
  //   type: "facebook",
  //   icon: (
  //     <FbSocialIcon
  //       style={{ maxHeight: "1.2rem", display: "flex", alignItems: "center" }}
  //     />
  //   ),
  //   style: {
  //     backgroundColor: "#1877F2",
  //     borderRadius: "50px",
  //     padding: "0.8rem 0",
  //     width: "25%",
  //   },
  // },
  // {
  //   type:'ios',
  //   icon: (
  //     <AppleSocialIcon
  //       style={{ maxHeight: "1.2rem", display: "flex", alignItems: "center" }}
  //     />
  //   ),
  //   style: {
  //     backgroundColor: "black",
  //     borderRadius: "50px",
  //     padding: "0.8rem 0",
  //     width: "25%",
  //   },
  // },
];
