import { request } from "../utils/apiRequest";
import { nodeServerBaseUrl } from "../redux/dataFetchers/apiUrls";
import { setActionNotif } from "../redux/actions";

export const getHubs = async ({ status, cityCodes, accessToken, dispatch }) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...status && { status },
        ...cityCodes?.length && { cityCodes }
      })
    };
    return new Promise((resolve, reject) => {
      return request(`${nodeServerBaseUrl}/hubs`,
        requestOptions,
        async (response) => {
          const data = response.data?.data;
          console.log("response from backend",data);
          resolve(data)
          },
        async (error) => {
          dispatch(setActionNotif({
              open: true,
              message: "Something went wrong!",
              severity: "error"
          }))
          reject(error);
        })  
    })
  };