export default function SaveColoredIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <g clipPath="url(#clip0_434_1463)">
          <path
            d="M18.2339 3.0083H6.51611C6.17177 3.0083 5.89282 3.26404 5.89282 3.57973V18.4205C5.89282 18.6423 6.03299 18.844 6.25177 18.9381C6.47072 19.032 6.72948 19.0014 6.91521 18.8592L12.3748 14.6833L17.8343 18.8592C17.9486 18.9467 18.0903 18.992 18.2338 18.992C18.3234 18.992 18.4136 18.9741 18.4978 18.9381C18.7169 18.8442 18.8567 18.6423 18.8567 18.4205V3.57973C18.8571 3.26404 18.5781 3.0083 18.2339 3.0083Z"
            fill="#EF7123"
          />
        </g>
        <defs>
          <clipPath id="clip0_434_1463">
            <rect
              width="13"
              height="16"
              fill="white"
              transform="translate(5.875 3)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
