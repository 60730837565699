import env from "../../environment/production.json";

export const javaServerBaseUrl =
  env.NODE_ENV === "production"
    ? env.REACT_APP_JAVA_SERVER_PROD_URL
    : env.REACT_APP_JAVA_SERVER_STAGING_URL;
export const nodeServerBaseUrl =
  env.NODE_ENV === "production"
    ? env.REACT_APP_NODE_SERVER_PROD_URL + "/api/v2"
    : env.REACT_APP_NODE_SERVER_STAGING_URL + "/api/v2"
