import { accountRoles, SET_ACCOUNT_ROLE } from "../constants";

export default function universalReducer(
  state = {
    accountRole: accountRoles.GUEST,
  },
  action = {}
) {
  switch (action.type) {
    case SET_ACCOUNT_ROLE:
      return {
        ...state,
        accountRole: action.payload,
      };
    default:
      return state;
  }
}
