export default function SaveCurvedIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7388 6.15344C19.7388 3.40256 17.8581 2.2998 15.1503 2.2998H8.79143C6.16687 2.2998 4.19995 3.32737 4.19995 5.96998V20.6938C4.19995 21.4196 4.9809 21.8767 5.61348 21.5219L11.9954 17.9419L18.3223 21.5158C18.9558 21.8727 19.7388 21.4156 19.7388 20.6888V6.15344Z"
          stroke="#32312F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.27124 9.02762H15.5895"
          stroke="#32312F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
