import React from "react";
import "./UserDetailsCard.css";

const defaultUserImage =
  process.env.PUBLIC_URL + "/assets/images/pet-parent-avatar.webp";

function UserDetailsCard({
  showName = false,
  photoUrl,
  name,
  phoneNumber,
  email,
  address,
  pinCode,
  latitude,
  longitude,
  mapLink,
  walkTogether,
}) {
  return (
    <div className="card-container">
      <div className="headline">
        <h3>Session Details</h3>
      </div>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 16 }}>
        {!showName && (
          <div style={{ display: "flex", gap: ".5rem" }}>
            <img
              src={photoUrl || defaultUserImage}
              className="user-profile-image"
              alt="user"
            />
            <h2 className="name">{name}</h2>
          </div>
        )}
        {showName && (
          <div className="user-info-card">
            <span className="label">Name:</span>
            <span className="value">{name}</span>
          </div>
        )}
        <div className="user-info-card">
          <span className="label">Phone:</span>
          <span className="value">{phoneNumber}</span>
        </div>
        {email && (
          <div className="user-info-card">
            <span className="label">Email:</span>
            <span className="value">{email}</span>
          </div>
        )}
        <div className="user-info-card">
          <span className="label">Address:</span>
          <span className="value">{address}</span>
        </div>
        {pinCode && (
          <div className="user-info-card">
            <span className="label">Pincode:</span>
            <span className="value">{pinCode}</span>
          </div>
        )}
        <div className="user-info-card">
          <span className="label">Latitude</span>
          <span className="value">{latitude}</span>
        </div>
        <div className="user-info-card">
          <span className="label">Longitude</span>
          <span className="value">{longitude}</span>
        </div>
        <div className="user-info-card">
          <span className="label">Map link:</span>
          <a target="_blank" rel="noreferrer" href={mapLink?mapLink : latitude && longitude && `https://www.google.com/maps/?q=${latitude},${longitude}`}>
           <span className="value">{mapLink?mapLink : latitude && longitude && `https://www.google.com/maps/?q=${latitude},${longitude}`}</span>
           </a>
        </div>
        {walkTogether && (
          <div className="user-info-card">
            <span className="label">Walk Together</span>
            <span className="value">{walkTogether}</span>
          </div>
        )}
         {(latitude == "-" || longitude=="-")?  <p style={{ color: "red" }}>
                lat and long is not present here
          </p>:<></> }
      </div>
    </div>
  );
}

export default UserDetailsCard;
