import React from "react";
import MobHeader from "./MobHeader/MobHeader";
import DesktopHeader from "./DesktopHeader/DesktopHeader";
import "./Header.css";
import { useLocation } from "react-router-dom";

export default function Header({ navList, openSignoutModal }) {
  const location = useLocation();
  const onboardingRoute = location.pathname === "/onboarding";
  return (
    !onboardingRoute && (
      <div className="headers-wrapper">
        <DesktopHeader navList={navList} />
        <MobHeader navList={navList} openSignoutModal={openSignoutModal} />
      </div>
    )
  );
}
