import React from "react";
import "./ModalTitle.css"

function ModalTitle({ title, onClose, showClose = true, style }) {
  return (
    <div className="title-container" style={{...style}}>
      <h3>{title}</h3>
      {showClose && (<svg
        onClick={onClose}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        style={{ cursor: "pointer" }}
      >
        <path
          d="M19.9997 37.1666C29.1663 37.1666 36.6663 29.6666 36.6663 20.4999C36.6663 11.3333 29.1663 3.83325 19.9997 3.83325C10.833 3.83325 3.33301 11.3333 3.33301 20.4999C3.33301 29.6666 10.833 37.1666 19.9997 37.1666Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2832 25.2168L24.7165 15.7834"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.7165 25.2168L15.2832 15.7834"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>)}
    </div>
  );
}

export default ModalTitle;
