import { Autocomplete, Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { getCities, getCountries, getStates, syncAnonymousUser } from "../../redux/dataFetchers/appUserApis";
import { SubscriptionsContext } from "../../sales/Users/Users";
import "react-phone-number-input/style.css";
import { setActionNotif } from "../../redux/actions";
import { defaultCountry } from "../../redux/constants";
import { getPetBreeds } from "../../redux/dataFetchers/petApis";
import ModalTitle from "../../common/ModalTitle/ModalTitle";

const useStyles = makeStyles(() => ({
    miniBox: {
        padding: "0.6rem",
        margin: "0.6rem 0",
        boxShadow: "0px 2px 4px 0px grey",
        fontWeight: "bold",
        borderRadius:"5px"
    },
    header: {
        color: "#EF7123"
    },
    petOptions:{
        display: "flex",
        width: "fit-content",
        height: "42px",
        padding: "6px 16px",
        justifyContent: "space-between",
        alignItems: "center", 
        borderRadius: 10,
        
        color: "#1B2149",
        textAlign: "center",
        fontSize: 16,
        fontWeight: 600,
        marginTop: "0.5rem"
    }, 
    btn: {
        width: "90%",
        borderRadius: "15px",
        background: "#EF7123", 
        color: "#fff",
        fontSize: 18,
        fontWeight: 700,
        padding: "0.5rem 0",
        display: "flex", 
        margin: "0 auto", 
        cursor: "pointer", 
        marginBottom: "1rem"
    },

}))

const PhoneCustomInputElement = forwardRef((props, ref) => (
    <TextField
        {...props}
        required
        id="outlined-basic"
        label="Phone"
        variant="outlined"
        fullWidth
        inputRef={ref}
    />
));

const emptyPetProfile = { name:"", petBreed:null }

const SalesAddUserDetails = ({onClose}) => {
    const {
        cities, states, petBreeds
    } = useSelector((state) => state.user);
    const [phoneNumber, setPhoneNumber] = useState("")
    const [address, setAddress] = useState({ 
        country:defaultCountry
    })
    const [petProfiles, setPetProfiles] = useState([emptyPetProfile]);

    const classes = useStyles();
    const nameRef = useRef(null);

    const dispatch = useDispatch();
    const { closeAddUserModal, setLoadingParentData } = useContext(SubscriptionsContext);

    useEffect(() => {
        (!petBreeds.cat?.length || !petBreeds.dog?.length) &&
            dispatch(getPetBreeds());

        dispatch(getCountries());
    }, [])

    useEffect(() => {
        dispatch(getStates(address?.country?.code))
    }, [address?.country])

    useEffect(() => {
        dispatch(getCities(address?.country?.code, address?.state?.code))
    }, [address?.state])

    const formatUserDetails = () => {
        let name = nameRef?.current?.value, errorMessage = "";
        if (!name) errorMessage = "Please provide user name";
        if (states?.length && !address?.state) errorMessage = "Please provide user state";
        if (cities?.length && !address?.city) errorMessage = "Please provide user city";
        if (!isValidPhoneNumber(phoneNumber)) errorMessage = "Please provide valid phone number";
        if ((address?.coords?.latitude && !address?.coords?.longitude)
            || (address?.coords?.longitude && !address?.coords?.latitude)) errorMessage = "Enter Both Latitude & Longitude"
        const isPetDetailsMissing = petProfiles?.find(petDetail => !petDetail.name || !petDetail.petBreed)
        errorMessage = isPetDetailsMissing ? "Pet details missing!" : errorMessage;

        if (errorMessage) {
            dispatch(setActionNotif({
                open: true,
                message: errorMessage,
                severity: "warning"
            }))
            throw new Error(errorMessage)
        }
        
        const updatedAddress = {
                ...address,
                ...(address?.coords) && {
                    coords: {
                        ...(address.coords.mapLink) && {
                            mapLink: address.coords.mapLink
                        },
                        ...(address.coords.latitude) && {
                            latitude: parseFloat(address.coords.latitude)
                        },
                        ...(address.coords.longitude) && {
                            longitude: parseFloat(address.coords.longitude)
                        }
                    }
                }
            }

        if (!updatedAddress?.coords?.latitude &&
            !updatedAddress?.coords?.longitude && !updatedAddress?.coords?.mapLink)
            delete updatedAddress.coords;

        return {
            phoneNumber,
            address:updatedAddress,
            name,
            petProfiles
        }
    }

    const addUserDetails = async () => {
        try {
            const userDetails = formatUserDetails();
            const data = await syncAnonymousUser({
                dispatch,
                userDetails
            });

            if(data){
                closeAddUserModal();
                setLoadingParentData(true);
            }

        } catch (error) {
            console.log({ error })
        }
    }

    const handleCoordsChange = (coords, type) => {
        const regex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
        if (coords === '' || regex.test(coords)) {
            setAddress(prev =>
            ({
                ...prev,
                coords: {
                    ...prev?.coords, [type]: coords
                }
            }))
        }
    }

    const handlePetProfileChange = (index, newPetData) => {
        setPetProfiles((prev) => {
            const clonePetProfiles = [...prev];
            clonePetProfiles[index] = { ...clonePetProfiles[index], ...newPetData };
            return clonePetProfiles;
        })  
    }

    return (
        <>
        <ModalTitle title={"User Details" } onClose={onClose}/>
            {/* Profile */}
            <div className="card-container" style={{paddingBottom: "1rem"}}>
            <div className="headline">
                <h3>Basic Info</h3>
            </div>

                <div style={{marginTop: "1rem", display: "flex", flexDirection: "column", gap: "0.8rem", width: "100%"}}>
                <div className="dash-filter filter" style={{width: "100%"}}>
                <TextField
                    id="outlined-basic"
                    label="Name"
                    inputRef={nameRef}
                    variant="outlined"
                    required
                    fullWidth
                    inputProps={{ style: { fontSize: 15 } }}
                />
            </div>
                
                <div className="dash-filter filter" style={{width: "100%"}}>
                <PhoneInput
                    key="user-phone"
                    style={{ width: "100%" }}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    inputComponent={PhoneCustomInputElement}
                    defaultCountry="IN"
                        />
                        </div>
                </div>
            
            
            </div>
            

            {/* Address */}
            <div className="card-container" style={{padding: "1rem"}}>
            <div className="headline">
                <h3>Address</h3>
                </div>
                <div style={{marginTop: "1rem", display: "flex", flexDirection: "column", gap: "0.8rem", width: "100%"}}>
                <div className="dash-filter filter" style={{width: "100%"}}>
                    <TextField
                        id="outlined-basic"
                        label="Street Address"
                        onChange={e => setAddress(prev => ({...prev, address:e.target.value}))}
                        variant="outlined"
                        fullWidth
                        inputProps={{ style: { fontSize: 15 } }}
                    />
                </div>
                <div className="dash-filter filter" style={{width: "100%"}}>
                    <Autocomplete
                        disablePortal
                        id="user-state"
                        options={states}
                        sx={{ width: "100%" }}
                        value={address?.state?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                            option.name === value || value === ""
                        }
                        disableClearable={true}
                        getOptionLabel={(option) => option.name ?? option}
                        onChange={(event, value) => {
                            setAddress((prev) => ({
                                ...prev,
                                city: "",
                                state: value,
                            }));
                        }}
                        renderInput={(params) => (
                            <form autoComplete="off">
                                <TextField {...params} label="State" required />
                            </form>
                        )}
                    />
                </div>
                <div className="dash-filter filter" style={{width: "100%"}}>
                    <Autocomplete
                        disablePortal
                        id="user-city"
                        options={cities}
                        sx={{ width: "100%" }}
                        value={address?.city?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                            option.name === value || value === ""
                        }
                        disableClearable={true}
                        getOptionLabel={(option) => option.name ?? option}
                        onChange={(event, value) => {
                            setAddress((prev) => ({ ...prev, city: value }));
                        }}
                        renderInput={(params) => (
                            <form autoComplete="off">
                                <TextField {...params} label="City/District" required />
                            </form>
                        )}
                    />
                </div>
                <div className="dash-filter filter" style={{width: "100%"}}>
                    <TextField
                        id="outlined-basic"
                        label="Pincode"
                        value={address?.pinCode || ''}
                        onChange={(event) =>
                            setAddress(prev =>
                                ({ ...prev, pinCode: event.target.value }))}
                        variant="outlined"
                        fullWidth
                        inputProps={{ style: { fontSize: 15 } }}
                    />
                </div>
                <div className="dash-filter filter" style={{width: "100%"}}>
                    <TextField
                        id="outlined-basic"
                        label="Latitude"
                        value={address?.coords?.latitude || ''}
                        onChange={(event) =>
                            handleCoordsChange(event.target.value, 'latitude')
                        }
                        variant="outlined"
                        fullWidth
                        inputProps={{ style: { fontSize: 15 } }}
                    />
                </div>
                <div className="dash-filter filter" style={{width: "100%"}}>
                    <TextField
                        id="outlined-basic"
                        label="Longitude"
                        value={address?.coords?.longitude || ''}
                        onChange={(event) =>
                            handleCoordsChange(event.target.value, 'longitude')
                        }
                        variant="outlined"
                        fullWidth
                        inputProps={{ style: { fontSize: 15 } }}
                    />
                </div>
                <div className="dash-filter filter" style={{width: "100%"}}>
                    <TextField
                        id="outlined-basic"
                        label="Map link"
                        value={address?.coords?.mapLink || ''}
                        onChange={(event) =>
                            setAddress(prev =>
                            ({
                                ...prev,
                                coords: {
                                    ...prev.coords,
                                    mapLink: event.target.value
                                }
                            }))}
                        variant="outlined"
                        fullWidth
                        inputProps={{ style: { fontSize: 15 } }}
                    />
                </div>
                </div>
                
            </div>

            {/* Pets */}
            <div className="card-container" style={{padding: "1rem"}}>
            <div className="headline">
                <h3>Pet&apos;s Info</h3>
                </div>
                {petProfiles?.map(({name, petBreed},index) => 
                    <div key={index} style={{marginTop: "1rem", display: "flex", flexDirection: "column", gap: "0.8rem", width: "100%"}}>
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <TextField
                                id="outlined-basic"
                                label="Dog's name"
                                defaultValue={name}
                                onChange={(e) => handlePetProfileChange(index,{ name:e.target.value })}
                                variant="outlined"
                                fullWidth
                                autoComplete="off"
                                inputProps={{ style: { fontSize: 15 }, autoComplete: "off" }}
                                InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
                            />
                        </div>
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box"
                                options={petBreeds?.dog || null}
                                sx={{ width: "100%" }}
                                value={petBreed?.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value || value === ""
                                }
                                onChange={(event, value) => {
                                    console.log(value)
                                    handlePetProfileChange(index, { petBreed:value });
                                                                 
                                }}
                                renderInput={(params) => <TextField {...params} label="Breed" />}
                            />
                        </div>
                        {index !== petProfiles?.length-1 && <><hr style={{borderColor:"black", borderStyle:"dashed"}} /></>}
                    </div>
                )}
                
                <div style={{display: "flex"}}>
                <div className={classes.petOptions} style={{backgroundColor: "#00B53C",border: "1px solid #00B53C"}}>
                    <Button style={{color: "#fff",fontSize: 16,fontWeight: 600,}} onClick={() => setPetProfiles(prev => [...prev,emptyPetProfile])}>Add Pet +</Button>
                </div>
                {!!petProfiles?.length && petProfiles?.length > 1 &&
                    (<div className={classes.petOptions} style={{ backgroundColor: "#D32222", border: "1px solid #D32222",}}>
                        <Button style={{ color: "red" }} onClick={() => setPetProfiles(prev => [...prev.slice(0, prev.length - 1)])}>Remove Pet -</Button>
                    </div>)}
                </div>
                
            </div>
                <Button className={classes.btn} onClick={addUserDetails}>Add user details</Button>
            
        </>
    )
}

export default SalesAddUserDetails