import { SET_SPLOOT_FINDER_SEARCH_RESULTS,SET_SPLOOT_FINDER_PAGE_NUMBER, SET_SPLOOT_FINDER_CATEGORY, SET_SPLOOT_FINDER_LOCATION, listingCategories, SET_SPLOOT_FINDER_RESULTS_CACHE_TOKEN, SET_SPLOOT_FINDER_INPUT_MODIFIED, SET_CURRENT_SEARCH_RESULT_PAGE_DATA, SET_TOTAL_SEARCH_RESULT_PAGES, SET_SPLOOT_FINDER_ADDRESS, TOGGLE_SEARCH_RESULTS_NAVIGATION_STATE, SET_SEARCH_RESULTS_LOADED } from "../constants"

export default function splootFinderReducer(state={
    pageNo:1,
    searchResults:[],
    location:null,
    address:"",
    totalPages:1,
    category:listingCategories.BOARDING_CENTER,
    resultsCacheToken:"",
    inputModified:false,
    currentSearchResultPageData:{},
    navigationState:false,
    searchResultsLoaded:false,
},action={}){
    switch(action.type){
        case SET_SPLOOT_FINDER_PAGE_NUMBER:
            return{
                ...state,
                pageNo:action.payload
            }
        case SET_SPLOOT_FINDER_SEARCH_RESULTS:
            return{
                ...state,
                searchResults:action.payload
            }
        case SET_SPLOOT_FINDER_CATEGORY:
            return{
                ...state,
                category:action.payload
            }
        case SET_SPLOOT_FINDER_LOCATION:
            return{
                ...state,
                location:action.payload
            }  
        case SET_SPLOOT_FINDER_RESULTS_CACHE_TOKEN:
            return{
                ...state,
                resultsCacheToken:action.payload
            }
        case SET_SPLOOT_FINDER_INPUT_MODIFIED:
            return{
                ...state,
                inputModified:action.payload
            } 
        case SET_CURRENT_SEARCH_RESULT_PAGE_DATA:
            return{
                ...state,
                currentSearchResultPageData:action.payload
            }
        case SET_TOTAL_SEARCH_RESULT_PAGES:
            return{
                ...state,
                totalPages:action.payload
            }
        case SET_SPLOOT_FINDER_ADDRESS:
            return{
                ...state,
                address:action.payload
            }    
        case TOGGLE_SEARCH_RESULTS_NAVIGATION_STATE:
            return{
                ...state,
                navigationState:action.payload
            }
        case SET_SEARCH_RESULTS_LOADED:
            return{
                ...state,
                searchResultsLoaded:action.payload
            }
        default:
            return state
    }
}