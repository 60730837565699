import React, { useState } from "react";
import MenuIcon from "../../../assets/icons/MenuIcon";
import SideBar from "../../SideBar/SideBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { accountRoles } from "../../../redux/constants";
import { getProfileMobNavList } from "../../../data";
import "../Header.css";
import "./MobHeader.css";

export default function MobHeader({ navList, openSignoutModal }) {
  const navigate = useNavigate();
  const {
    universal: { accountRole },
    user: {
      data: { id },
    },
  } = useSelector((state) => state);
  const [drawerState, setDrawerState] = useState({
    left: false,
  });

  navList =
    accountRole === accountRoles.REGISTERED_USER
      ? getProfileMobNavList(id)
      : navList;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  return (
    <div className={`mob-header`}>
      <div className="find-logo-mob">
        <img
          alt="sploot-logo"
          src={process.env.PUBLIC_URL + "/assets/images/sploot-logo.webp"}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="menu-icon" onClick={toggleDrawer("left", true)}>
        <MenuIcon />
      </div>
      <SideBar
        toggleDrawer={toggleDrawer}
        drawerState={drawerState}
        navList={navList}
        openSignoutModal={openSignoutModal}
      />
    </div>
  );
}
