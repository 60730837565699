export default function CommentIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          d="M13.8712 2.53477L9.88072 2.52515H9.8788C5.6709 2.52515 2.375 5.82201 2.375 10.0309C2.375 13.9733 5.44001 16.9632 9.55652 17.121V20.8036C9.55652 20.9075 9.59885 21.0788 9.67197 21.1913C9.80857 21.4078 10.0414 21.5252 10.28 21.5252C10.4127 21.5252 10.5464 21.4886 10.6667 21.4116C10.9207 21.25 16.8939 17.4289 18.4476 16.1147C20.2773 14.5659 21.3721 12.2955 21.375 10.0424V10.0261C21.3692 5.8249 18.0752 2.53477 13.8712 2.5338V2.53477ZM17.5144 15.0142C16.4235 15.9377 12.837 18.2899 10.9996 19.4809V16.4149C10.9996 16.0166 10.6773 15.6934 10.278 15.6934H9.89708C6.37607 15.6934 3.819 13.3114 3.819 10.0309C3.819 6.63107 6.48189 3.96819 9.87976 3.96819L13.8693 3.97781H13.8712C17.2691 3.97781 19.932 6.63877 19.9339 10.0347C19.931 11.8722 19.0277 13.7328 17.5154 15.0142H17.5144Z"
          fill="#121212"
        />
      </svg>
    </div>
  );
}
