import React from "react";
import "./DetailsCard.css";

const defaultUserImage =
  process.env.PUBLIC_URL + "/assets/images/pet-parent-avatar.webp";

function DetailsCard({
  title,
  data,
  itemStyle = {}
}) {
  return (
    <div className="card-container">
      <div className="headline">
        <h3>{title}</h3>
      </div>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 16, width:"100%" }}>
        {/* {!showName && (
          <div style={{ display: "flex", gap: ".5rem" }}>
            <img
              src={photoUrl || defaultUserImage}
              className="user-profile-image"
              alt="user"
            />
            <h2 className="name">{name}</h2>
          </div>
        )} */}
        {
          data?.map((value, index) => 
            <div className="user-info-card" style={value?.itemStyle ?? itemStyle}>
              <span className="label">{value.text}</span>
              <span className="value">{value.description}</span>
              {/* <span className="label">
              </span>
              <span className="value">{value.description}</span> */}
            </div>
          )
        }
      </div>
    </div>
  );
}

export default DetailsCard;
