import { forwardRef, useMemo, useRef, useState } from 'react';
import './SalesWalkRequestCard.css';
import { accountRoles, EPOCH, WalkActionStatus, WalkRequestStage, WalkRequestStatus, WalkSlotDuration, WalkStatus } from "../../redux/constants";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton, Tooltip } from '@mui/material';
import DogWalkingIcon from '../../assets/icons/DogWalkingIcon';
import { useNavigate } from 'react-router-dom';

const classes = {
    walkerInfoWrap: { display: 'flex', alignItems: 'center' },
    cancelBtn: {
        backgroundColor: "antiquewhite",
        marginRight: "0.5rem"
    },
    notifyIcon: {
        height: '1.2rem',
        width: '1.2rem'
    },
    infoIcon: {
        height: '1rem',
        width: '1rem',
        marginLeft: '0.2rem'
    },
    cancelBtnCol: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" },
    infoIcon: {
        height: '1rem',
        width: '1rem',
        margin: '0.4rem',
        color:"gray",
    },
}

const SalesWalkRequestCard = forwardRef(({ 
    data,
    onClick = () => null,
}, lastElementRef) => {
    const navigate = useNavigate();

    const WalkRequestStageStyles = {
        [WalkRequestStage.IN_PROGRESS]:{
            color:"#5B2B0D",
            backgroundColor:"#FEEBCF"
        },
        [WalkRequestStage.ASSIGNED]:{
            color:"#127834",
            backgroundColor:"#D1F4DC"
        },
        [WalkRequestStage.CANCELLED]:{
            color:"#FF512F",
            backgroundColor:"#FFDBD4"
        }
    }

    const WalkRequestStatusStyles = {
        [WalkRequestStatus.CREATED]:{
            color:"",
        },
        [WalkRequestStatus.FINDING]:{
            color:"",
        },
        [WalkRequestStatus.MANUALLY_ASSIGNED]:{
            color:"#127834",
        },
        [WalkRequestStatus.SYSTEM_ASSIGNED]:{
            color:"#127834",
        },
        [WalkRequestStatus.MANUALLY_UNASSIGNED]:{
            color:"#FF512F",
        },
        [WalkRequestStatus.SYSTEM_UNASSIGNED]:{
            color:"#FF512F"
        },
        [WalkRequestStatus.CANCELLED]:{
            color:"#FF512F"
        }
    }

    return (
        <div className='walks-info-header info-header-data info-card notify' onClick={onClick} key={data.id}  ref={lastElementRef}>
            <span>
                <p className="user-pet-name">{data?.user?.name}</p>
            </span>

            <span className="user-pet-name">
                <p>
                    {data.trial ? 'Trial' : 'Subscription'}
                </p>
                <IconButton
                    onClick={() =>
                        navigate(
                        `/sales/dashboard/walks?requestId=${data?.id}`
                        )
                    }
                    >
                    <DogWalkingIcon />
                </IconButton>
            </span>

            <span className="user-pet-name">
                <p>
                    {data.type?.split("_")[0]} {data.type?.split("_")?.length > 1 ? `(${data.type?.split("_")?.[1]})` : null}
                </p>
            </span>
            
            <span className="user-pet-name">
                <p>
                    {new Date(data?.createdAt).toLocaleString("en-IN",{
                        month:"2-digit",
                        year:"2-digit",
                        hour:"2-digit",
                        minute:"2-digit",
                        day:"2-digit"
                    })} - {data?.createdBy?.type === accountRoles.SALES ? "Dashboard" : "User"}
                </p>
            </span>

            <span >
            <p className="user-pet-name">{data?.user?.cityCode}</p>
            </span>

            <div style={{display: 'flex', alignItems: "center", flexWrap:"wrap", width:"max-content"}}>
                <span style={{
                        color: WalkRequestStageStyles[data.stage]?.color,
                        backgroundColor: WalkRequestStageStyles[data.stage].backgroundColor,
                        display: "flex", 
                        alignItems: "center",
                        padding: "0.3rem 0.6rem",
                        borderRadius:"20px",
                }}>
                    {data?.stage ?? "-"} 
                </span>
                    {
                        data?.overdue &&
                            <Tooltip placement="top" title="overdue!">
                                <WarningAmberIcon />
                            </Tooltip>
                    }
            </div>

            <div style={{display: 'flex', gap: "1.5rem", alignItems: "center", flexWrap:"wrap", width:"max-content"}}>
                <span style={{
                        color: WalkRequestStatusStyles[data.status]?.color,
                        display: "flex", alignItems: "center"
                }}>
                    {data?.status ?? "-"}
                </span>
            </div>

            <span className="user-pet-name" style={{justifyContent:"center"}}>
                <p>
                    {data.currentCycle}
                </p>
            </span>

        </div>
    )
})

export default SalesWalkRequestCard;
