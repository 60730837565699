import React, { useCallback, useMemo, useState } from "react";
import "./DateTimePickerComponent.css";
import { Menu, TextField, Button } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { monthsOfYear } from "../../redux/constants";

function DateTimePickerComponent({
  selectedDay,
  setSelectedDay,
  selectedStartTime,
  setSelectedStartTime,
  selectedEndTime,
  setSelectedEndTime,
}) {
  const [openCustomCalendar, setOpenCustomCalendar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [customStartTime, setCustomStartTime] = useState(null);
  const [customEndTime, setCustomEndTime] = useState(null);


  let oneDayInMilliSeconds = 24 * 60 * 60 * 1000;

  const classes = {
    actionBtn: {
      width: "fit-content",
      marginLeft: "6rem",
      marginTop: "1rem",
      height: "48px",
      padding: "0 20px",
      fontSize: 16,
      display: "block",
      backgroundColor: "#7D97FF",
      color: "white",
      fontWeight: "bold",
    },
  };

  const calculateMTD = () => {
    let today = new Date().setHours(23, 59, 59, 999);
    const monthStartDate = new Date().setDate(1);
    setSelectedStartTime(new Date(monthStartDate).setHours(0, 0, 0, 0));
    setSelectedEndTime(today);
    console.log("setting mtd true");
    setSelectedDay(() => {
      return {
        mtd: true,
        ...(selectedDay?.today !== undefined ? { today: false } : {}),
        ...(selectedDay?.yesterday !== undefined ? { yesterday: false } : {}),
        ...(selectedDay?.tomorrow !== undefined ? { tomorrow: false } : {}),
        ...(selectedDay?.custom !== undefined ? { custom: false } : {}),
      };
    });
  }

  const calculateToday = () => {
    let today = new Date().setHours(0, 0, 0, 0);
    setSelectedStartTime(today);
    setSelectedEndTime(new Date().setHours(0, 0, 0, 0) + oneDayInMilliSeconds);
    setSelectedDay((prev) => {
      return {
        today: true,
        ...(selectedDay?.yesterday !== undefined ? { yesterday: false } : {}),
        ...(selectedDay?.tomorrow !== undefined ? { tomorrow: false } : {}),
        ...(selectedDay?.custom !== undefined ? { custom: false } : {}),
        ...(selectedDay?.mtd !== undefined ? { mtd: false } : {})
      };
    });
  };

  const calculateYesterday = () => {
    setSelectedStartTime(
      new Date().setHours(0, 0, 0, 0) - oneDayInMilliSeconds
    );
    setSelectedEndTime(new Date().setHours(0, 0, 0, 0));
    setSelectedDay((prev) => {
      return {
        yesterday: true,
        ...(selectedDay?.today !== undefined ? { today: false } : {}),
        ...(selectedDay?.tomorrow !== undefined ? { tomorrow: false } : {}),
        ...(selectedDay?.custom !== undefined ? { custom: false } : {}),
        ...(selectedDay?.mtd !== undefined ? { mtd: false } : {})
      };
    });
  };

  const calculateTomorrow = () => {
    setSelectedStartTime(
      new Date().setHours(0, 0, 0, 0) + oneDayInMilliSeconds
    );
    setSelectedEndTime(
      new Date().setHours(0, 0, 0, 0) + 2 * oneDayInMilliSeconds
    );
    setSelectedDay((prev) => {
      return {
        tomorrow: true,
        ...(selectedDay?.today !== undefined ? { today: false } : {}),
        ...(selectedDay?.yesterday !== undefined ? { yesterday: false } : {}),
        ...(selectedDay?.custom !== undefined ? { custom: false } : {}),
        ...(selectedDay?.mtd !== undefined ? { mtd: false } : {})
      };
    });
  };

  const handleControlsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCustomCalendarClose = () => {
    setOpenCustomCalendar(false);
  };

  const handleCustomDateTime = () => {
    if (customStartTime < customEndTime) {
      handleCustomCalendarClose();
      setSelectedStartTime(customStartTime);
      setSelectedEndTime(customEndTime);
      setSelectedDay((prev) => {
        return {
          ...(selectedDay?.today !== undefined ? { today: false } : {}),
          ...(selectedDay?.yesterday !== undefined ? { yesterday: false } : {}),
          ...(selectedDay?.tomorrow !== undefined ? { tomorrow: false } : {}),
          ...(selectedDay?.mtd !== undefined ? { mtd: false } : {}),
          custom: true,
        };
      });
    }
  };

  return (
    <div className="picker-container">
      {selectedDay?.yesterday !== undefined && <span
        className={selectedDay?.yesterday ? "active-yesterday" : "day"}
        onClick={calculateYesterday}
      >
        <p>Yesterday</p>
      </span>}
      {selectedDay?.today !== undefined && <span
        className={selectedDay?.today ? "active-day" : "day"}
        onClick={calculateToday}
      >
        <p>Today</p>
      </span>}
      {selectedDay?.tomorrow !== undefined && <span
        className={selectedDay?.tomorrow ? "active-day" : "day"}
        onClick={calculateTomorrow}
      >
        <p>Tomorrow</p>
      </span>}

      <span
        className={selectedDay?.custom ? "active-custom-day" : "day"}
        onClick={() => setOpenCustomCalendar(true)}
      >
        {selectedDay?.custom ? (
          <span>
            <p>{`${monthsOfYear[new Date(selectedStartTime).getMonth()]
              } ${new Date(selectedStartTime).getDate()}, ${new Date(
                selectedStartTime
              ).getFullYear()}`}</p>
            &nbsp;<p>{"-"}</p>&nbsp;
            <p>{`${monthsOfYear[new Date(selectedEndTime).getMonth()]
              } ${new Date(selectedEndTime).getDate()}, ${new Date(
                selectedEndTime
              ).getFullYear()}`}</p>
          </span>
        ) : (
          <p>Custom</p>
        )}
      </span>

      {selectedDay?.mtd !== undefined && <span
        className={selectedDay?.mtd ? "active-mtd" : "day"}
        onClick={calculateMTD}
      >
        <p>MTD</p>
      </span>}

      <Menu
        anchorEl={anchorEl}
        open={openCustomCalendar}
        onClick={handleControlsClick}
        onClose={handleCustomCalendarClose}
        className="menu-container"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            overflow: "hidden",
            marginTop: "35%",
            marginLeft: "25%",
          },
        }}
      >
        <div className="menu-container">
          <div
            style={{
              width: "22rem",
              height: "4rem",
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={{ padding: 0, height: "5px" }}
                label="Start date"
                value={customStartTime}
                onChange={(newValue) => {
                  setCustomStartTime(newValue.setHours(0, 0, 0, 0));
                }}
                inputFormat="dd-MM-yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End date"
                value={customEndTime}
                onChange={(newValue) => {
                  setCustomEndTime(newValue.setHours(23, 59, 59, 999));
                }}
                inputFormat="dd-MM-yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <Button style={classes.actionBtn} onClick={handleCustomDateTime}>
            Add Date Filter
          </Button>
        </div>
      </Menu>
    </div>
  );
}

export default DateTimePickerComponent;
