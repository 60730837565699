import { setActionNotif } from "../../redux/actions";
import { UserProductCancellationReason, UserProductCreditStatus } from "../../redux/constants";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { request } from "../../utils/apiRequest";

export const getUserProductCredits = async (...rest) => {
    const [accessToken, dispatch, setLoadingSubscriptions, isTrial, filters = {}, filterSlotStartTime, filterSlotEndTime, exportData, cancelledUpcs] = rest;

    const { addressFilter: {cityName, stateName, stateCode, districtId, pinCode, cityCode, hubIds }, sourceFilter } = filters;
   
    let requestFilters = {
        status: cancelledUpcs? UserProductCreditStatus.CANCELLED : UserProductCreditStatus.PENDING, 
        walkType: isTrial ? "TRIAL" : "SUBSCRIPTION",
        ...(sourceFilter && { source: sourceFilter }),
        startTime: filterSlotStartTime,
        endTime: filterSlotEndTime,
        ...(stateCode && { stateCode: stateCode }), 
        ...(districtId && { districtId: districtId }), 
        ...(pinCode && {pinCode: pinCode}),
        ...(cityCode?.length && {cityCode}),
        ...(hubIds?.length && {hubIds}),
        exportData
    }

    if (cityName) {
        requestFilters.cityName = cityName
    } else if(stateName) {
        requestFilters.stateName = stateName
    }

    let requestBody = { filters: requestFilters }
    
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        }, 
        body: JSON.stringify(requestBody)
    };
    return new Promise(async (resolve, reject) => {
        setLoadingSubscriptions(true)
        request(`${nodeServerBaseUrl}/user-product-credits`,
            requestOptions,
            async (response) => {
                const data = response.data?.data;

                setLoadingSubscriptions(false)
                resolve(data);
            },
            async (error) => {
                setLoadingSubscriptions(false)
                dispatch(setActionNotif({
                    open: true,
                    message: "Something went wrong!",
                    severity: "error"
                }))
                reject(error)
            })
    })
}

export const cancelTrial = async (...rest) => {
    const [accessToken, closeCancelWalkConfirmModal, dispatch, walkCancellationDescription, walkCancellationReason, currUpc, initGetUserProductCredits] = rest;
    try{
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          status: UserProductCreditStatus.CANCELLED,
          cancellationReason: walkCancellationReason,
          ...(walkCancellationReason === UserProductCancellationReason.OTHER && {
            cancellationDescription: walkCancellationDescription
          })
        }),
      };
      
      await request(
        `${nodeServerBaseUrl}/users/${currUpc.user.id}/user-product-credits/${currUpc.id}`,
        requestOptions
      )
      closeCancelWalkConfirmModal();
      initGetUserProductCredits();
    }catch(err){
      return dispatch(setActionNotif({
        open: true,
        message: "Something went wrong !",
        severity: "error",
     }));

    }
  }