import ModalTitle from "../../common/ModalTitle/ModalTitle";
import StarIcon from '@mui/icons-material/Star';

const AssignedWalkers = ({ onClose, walkers }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "500px",
        height: "300px",
        background: "white",
      }}
    >
      <ModalTitle title={"Assigned Walkers"} onClose={onClose} />
      <div
        className="walkers-info-header walks-heading"
        style={{
          paddingBottom: "0.8rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "auto",
          width: "94%",
        }}
      >
        <span>Walker</span>
        <span>State/City</span>
        <span>Rating</span>
      </div>
      <div style={{overflowY:"auto", height:"60%"}}>
      {walkers.map((walker) => {
        let walkerRating =
          walker.rating?.sessionCount &&
          walker.rating?.value / walker.rating?.sessionCount;
        walkerRating = walkerRating?.toFixed(2);
        return (
          <div
            style={{
              paddingBottom: "0.8rem",
              display: "flex",
              justifyContent: "space-between",
              margin: "auto",
              width: "94%",
              alignItems:"center"
            }}
          >
            <span style={{width: "100%"}}> {walker.name}</span>
            <span style={{width: "100%", textAlign: "center"}} className="city-name">
              {walker?.address?.state && walker?.address?.city
                ? `${walker?.address?.state?.name}/${walker?.address?.city?.name}`
                : "-"}
            </span>
            <span style={{width: "100%", textAlign: "right"}}>
              {walkerRating ? (
                <span className="rating" style={{ display: "flex" }}>
                  {walkerRating}
                  <StarIcon style={{ color: "#FF8C44" }} />
                </span>
              ) : (
                "-"
              )}
            </span>
          </div>
        );
      })}
      </div>
    </div>
  );
};

export default AssignedWalkers;
