import Button from "../Button/Button";
import { Button as MuiButton } from "@mui/material";
import "./EventRegistrationCard.css";
import VerifiedIcon from '@mui/icons-material/Verified';
import TextField from "@mui/material/TextField"
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef, forwardRef } from "react";
import { subscribeCMSEvent } from "../../pages/Events/apis";
import { setActionNotif } from "../../redux/actions";
import env from "../../environment/production.json";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber} from "react-phone-number-input";
import { accountRoles, defaultCountry } from "../../redux/constants";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { getPetBreeds } from "../../redux/dataFetchers/petApis";
import Autocomplete from "@mui/material/Autocomplete";
import { getCities, getStates } from "../../redux/dataFetchers/appUserApis";
import { syncAnonymousUser } from "../../redux/dataFetchers/appUserApis";
import "react-phone-number-input/style.css";
import { useGoogleLogin } from "@react-oauth/google";
import { googleLogin } from "../UserSignUpCard/apis";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setAppUserData } from "../../redux/actions/user";
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import { WebEngage } from "../../utils/webEngage";
import { Mixpanel } from "../../utils/mixPanel";

const classes = {
    buttonStyle: {
        width: '100%',
        margin: '1rem 0 0',
        boxShadow: 'none',
    },
    reigsterBtn: {
        backgroundColor: '#0B6FF9',
        padding: "0.9rem 0"
    },
    shareBtn: {
        backgroundColor: '#F1F1F1',
        color: 'black',
        padding: "0.8rem 0"
    },
    googleLoginBtn: {
        borderRadius: "50px",
        margin: "0.5rem 0",
        padding: "0.7rem 0",
        fontWeight: "bold",
        textTransform: "Capitalize",
        backgroundColor: "#F2F3F5",
        color: "darkslategray",
        width: "100%",
    },
    registeredIconStyle: {
        width: '2rem',
        color: 'white'
    },
    addToCalendar: {
        width: '80%',
        maxWidth: "20rem",
        padding: "0.7rem 1.5rem",
        boxShadow: 'none',
        marginBottom: '0',
        backgroundColor: 'white',
        color: 'black',
    },
    addToCalendarText: {
        fontWeight: '100',
        marginTop: '0.2rem'
    },
    textFieldStyle: {
        width: '100%',
        margin: "0.3rem 0"
    },
}

const PhoneCustomInputElement = forwardRef((props, ref) => (
    <TextField
        {...props}
        required
        id={`outlined-basic-${Math.random()}`}
        label="Phone"
        variant="outlined"
        fullWidth
        inputRef={ref}
    />
));



export const trackIdentifyUser = (user) => {

    let currentDate = new Date();
    
    let birthDate = new Date(user?.petProfiles?.[0]?.dateOfBirth);

    // Difference in years, months
    let yearsDifference = currentDate.getFullYear() - birthDate.getFullYear();
    let monthsDifference = currentDate.getMonth() - birthDate.getMonth();

    // Calculating age in months
    let ageInMonths = yearsDifference * 12 + monthsDifference;

    try {
        WebEngage?.user?.login(user?.phoneNumber)
        WebEngage?.user?.setAttribute({
            "we_first_name": user?.name,
            "we_phone": user?.phoneNumber, 
            "city": user?.address?.city?.name,
            "PetAgeInMonths": ageInMonths,
            "PetDateOfBirth": new Date(user?.petProfiles?.[0]?.dateOfBirth).toUTCString(),
            "PetBreed": user?.petProfiles?.[0]?.petBreed?.name,
            "PetName": user?.petProfiles?.[0]?.name,
            "PetSex": user?.petProfiles?.[0]?.gender,
            'we_whatsapp_opt_in': true,
        })

        Mixpanel?.identify(user?.id);
        Mixpanel?.people.set({
            "$user_id": user?.id, 
            "$name": user?.name, 
            "$phone": user?.phoneNumber, 
            "city": user?.address?.city?.name,
            "PetAgeInMonths": ageInMonths,
            "PetDateOfBirth": new Date(user?.petProfiles?.[0]?.dateOfBirth).toUTCString(),
            "PetBreed": user?.petProfiles?.[0]?.petBreed?.name,
            "PetName": user?.petProfiles?.[0]?.name,
            "PetSex": user?.petProfiles?.[0]?.gender,
        })
    
    } catch (err) {
        console.log(err, 'Error in tracking');
    }
}

const syncUser = async (dispatch, userDetails) => {
    const data = await syncAnonymousUser({
        dispatch,
        userDetails
    });

    return data;
}

export default function EventRegistrationCard({ event, openSignupModal, offlineEvent = false }) {
    const { universal: { accountRole }, user: { petBreeds, states, cities, data, accessToken } } = useSelector(state => state);
    const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber ?? '');
    const [isSubscribed, setIsSubscribed] = useState(event?.subscribers?.includes(data?.id) || false);
    const [breed, setBreed] = useState((data?.petProfiles?.length && data?.petProfiles[0]?.petBreed) || null);
    const [state, setState] = useState(data?.address?.state || null)
    const [city, setCity] = useState(data?.address?.city || null)
    const nameRef = useRef(null);
    const petNameRef = useRef(null);
    const dispatch = useDispatch();

    // const getUserData = () => {
    
    //         let userData = {
    //             name: nameRef.current.value,
    //             phone: phoneNumber,
    //             petName: petNameRef.current.value,
    //             breed: breed,
    //             city: city,
    //             state: state
    //         }
        
    //         return userData;
    // }

    const googleOAuth2Success = (response) => {
        if (!response.access_token) {
            Mixpanel.track("Social_Verification", {
                source: "google",
                result: "failure",
                reason: "Access Token Not Returned by Google",
            });
            return;
        }

        const redirect = window.location.pathname?.substring(1, window.location.pathname?.length);
        googleLogin(response.access_token, dispatch, redirect);
    };

    const googleOAuth2Failure = (error) => {
        Mixpanel.track("Social_Verification", {
            source: "google",
            result: "failure",
            reason: JSON.stringify(error),
        });

        dispatch(
            setActionNotif({
                open: true,
                message: "Google login failed! Please Try later",
                severity: "error",
            })
        );
    };

    const login = useGoogleLogin({
        onSuccess: googleOAuth2Success,
        onError: googleOAuth2Failure,
    });

    useEffect(() => {
        (!petBreeds?.cat?.length || !petBreeds?.dog?.length) &&
            dispatch(getPetBreeds());

        !states?.length && dispatch(getStates(defaultCountry.code));
    }, []);

    useEffect(() => {
        if (state) {
            state.code === data?.address?.code && !data?.address?.city && setCity(null);
            dispatch(getCities(defaultCountry.code, state?.code));
        }
    }, [state]);

    const formatUserDetails = () => {
        let petName = petNameRef?.current?.value, name = nameRef?.current?.value, errorMessage = "";
        if (!data?.name && !name) errorMessage = "Please provide Name";
        if (!data?.petProfiles?.length && (!petName || !breed)) errorMessage = "Please provide pet details";
        if (states?.length && !state) errorMessage = "Please provide your state";
        if (cities?.length && !city) errorMessage = "Please provide your city";
        if (!isValidPhoneNumber(phoneNumber)) errorMessage = "Please provide valid phone number";
        if (errorMessage) {
            dispatch(setActionNotif({
                open: true,
                message: errorMessage,
                severity: "warning"
            }))
            throw new Error(errorMessage)
        }

        return {
            phoneNumber,
            address: {
                ...data?.address,
                country: defaultCountry,
                ...state && { state },
                ...city && { city },
            },
            ...!data?.name && { name: nameRef.current.value },
            ...(!data?.petProfiles?.length) && {
                petProfiles: [{
                    name: petNameRef.current.value,
                    petBreed: breed
                }]
            }
        }
    }

    

    const subscribeUserToEvent = async () => {

        const analyticsEventData = {
            title: event?.title || "NO TITLE",
            publicProfileId: event?.expertId?.id,
            publicProfileName: event?.expertId?.name,
            link: event?.eventUrl,
            date: event?.eventDateTime ? new Date(event?.eventDateTime) : new Date().getTime(),
            source: "Web",
            groupId: event?.chatGroupId,
            mode: event?.mode,
            imageUrl: event?.imageUrl,
            shopUrl: event?.shopUrl, 
            isLive: event?.isLive, 
            eventStartDate: new Date(event?.eventDateTime),
            eventEndDateTime: new Date(event?.eventEndDateTime)
        }
        let userDetails = {};
        try {
            userDetails = formatUserDetails();
            const userData = await syncUser(dispatch, userDetails);
            trackIdentifyUser(userData);
            WebEngage?.track("RegisterEvent_Clicked", {...analyticsEventData, result: "success"});
            Mixpanel.track("RegisterEvent_Clicked", { ...analyticsEventData, result: "success" });   
        } catch (error) {
            console.log("Event Error", { error: error?.message })
            WebEngage?.track("RegisterEvent_Clicked", {...analyticsEventData, result: "Validation Failed At Frontend"})
            Mixpanel.track("RegisterEvent_Clicked", { ...analyticsEventData, reason: error?.message, result: "Validation Failed At Frontend" });
            return;
        }

        try {
            await subscribeCMSEvent(
                dispatch, accessToken, event, setIsSubscribed, userDetails);
        } catch (error) {
        }

    }

    const syncAnonymousUserHandler = async () => {

        const analyticsEventData = {
            title: event?.title || "NO TITLE",
            publicProfileId: event?.expertId?.id,
            publicProfileName: event?.expertId?.name,
            link: event?.eventUrl,
            date: event?.eventDateTime ? new Date(event?.eventDateTime) : new Date().getTime(),
            source: "Web",
            groupId: event?.chatGroupId,
            mode: event?.mode,
            imageUrl: event?.imageUrl,
            shopUrl: event?.shopUrl, 
            isLive: event?.isLive, 
            eventStartDate: new Date(event?.eventDateTime),
            eventEndDateTime: new Date(event?.eventEndDateTime)
        }

        let userDetails = {};
        try {
            userDetails = formatUserDetails();
            trackIdentifyUser(userDetails);
            Mixpanel.track("RegisterEvent_Clicked", {...analyticsEventData, source: "Offline" });
        } catch (error) {
            Mixpanel.track("RegisterEvent_Clicked", {...analyticsEventData, source: "Offline", result: "failure", reason: error?.message || "Something went wrong!" });
            console.log(error)
            return;
        }

        try {
            const data = await syncUser(dispatch, userDetails);
            if(data){
                Mixpanel.identify(data?.id, {
                    email: data.email,
                    name: data.name,
                    phoneNumber: data.phoneNumber
                });
                Mixpanel.track("EventSubscribed", { source: "Offline", result: "success" });
                if (setIsSubscribed) setIsSubscribed(true)

                let user = {
                    ...data,
                    petProfiles: data?.petProfiles?.filter(pet => !pet?.isDeleted)
                }
                dispatch(setAppUserData(user));
            }
        } catch (error) {
            console.log(error)
            Mixpanel.track("EventSubscribed", { source: "Offline", result: "failure", reason: error?.message || "Something went wrong!" });
        }


    }

    const copyLinkToClipboard = () => {
        const elem = document.createElement("textarea");
        elem.value = `https://${env.NODE_ENV !== "production" ? `staging.` : ""}sploot.space/events/${event?.id}/${event?.slug ? event?.slug : ''}`;
        document.body.appendChild(elem);
        elem.select();
        elem.setSelectionRange(0, 99999); /* For mobile devices */

        // Copy the text inside the text field
        navigator.clipboard?.writeText(elem.value);
        document.body.removeChild(elem);

        dispatch(
            setActionNotif({
                open: true,
                message: "Link copied to Clipboard!",
                severity: "info",
            })
        );
    }

    const addToCalendar = () => {
        let link = 'https://calendar.google.com/calendar/r/eventedit?'
        //Title
        link += `text=${encodeURI(event?.title)}`;
        //Start date and end date
        const startTime = (new Date(event?.eventDateTime)
            .toISOString()).replace(/-/g, '').replace(/:/g, '');
        const endTime = (new Date(event?.eventEndDateTime)
            .toISOString()).replace(/-/g, '').replace(/:/g, '');
        link += `&dates=${startTime}/${endTime}`;
        //Description
        link += `&details=${encodeURI(event?.description)}`;
        //Location
        link += `&location=${encodeURI(event?.eventUrl)}`;

        window.open(link, '_blank');
    }

    const shareEvent = () => {
        copyLinkToClipboard();
    }

    const joinGroupClickHandler = () => {
        window.location.href = `https://${env.REACT_APP_SUBDOMAIN}.sploot.space/groups?id=${event?.chatGroupId}`
    }

    return (
        <div className='register-form' style={offlineEvent ? { margin: 'auto', maxWidth: "30rem" } : null}>
            {!(event?.subscribers?.includes(data?.id) || isSubscribed) && <h1>Register</h1>}
            {event?.subscribers?.includes(data?.id) || isSubscribed ?
                <span className='registered-view'>
                    <span className='registered-view-text'>
                        <VerifiedIcon style={classes.registeredIconStyle} />
                        {offlineEvent ? "Thankyou for registering!" : "Thankyou for registering for our event!"}
                    </span>
                    {offlineEvent ?
                        <MuiButton
                            variant="contained"
                            style={classes.googleLoginBtn}
                            onClick={() => window.location.reload()}
                            startIcon={<ArrowBackIcon style={{ marginRight: '0.5rem' }} />}
                        >
                            Register New!
                        </MuiButton>
                        :
                        <Button btnStyle={classes.addToCalendar}
                            textStyle={classes.addToCalendarText}
                            clickAction={addToCalendar}
                            startIcon={<CalendarMonthIcon style={{ marginRight: '0.5rem' }} />}
                            text={"Add to calendar"} />
                    }
                </span>
                :
                <>
                    <p>To join the event, please register below</p>
                
                    {!Boolean(data?.name) && <TextField
                        style={classes.textFieldStyle}
                        defaultValue={data?.name || ""}
                        type='text'
                        placeholder='Name'
                        inputRef={nameRef}
                    />}
                    <div style={{ padding: "0.3rem 0" }}>
                        <PhoneInput
                            key="walker-emergency-phone"
                            style={{ width: "100%" }}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            inputComponent={PhoneCustomInputElement}
                            defaultCountry="IN"
                            disabled={accountRole === accountRoles.REGISTERED_USER && isValidPhoneNumber(data?.phoneNumber ?? '')}
                        />
                    </div>
                   
                    {(!Boolean(data?.petProfiles?.length) &&
                        <TextField
                            style={classes.textFieldStyle}
                            defaultValue={data?.petProfiles?.length ? data?.petProfiles[0]?.name : ""}
                            type='text'
                            placeholder='Pet Name'
                            inputRef={petNameRef}
                        />
                    )}
                    {(!Boolean(data?.petProfiles?.length) &&
                        <Autocomplete
                            disablePortal
                            id="combo-box"
                            options={petBreeds?.dog || []}
                            sx={{ width: "100%" }}
                            value={breed?.name || ""}
                            isOptionEqualToValue={(option, value) =>
                                option.name === value || value === ""
                            }
                            onChange={(event, value) => {
                                setBreed(value);
                            }}
                            renderInput={(params) => <TextField {...params} label="Breed" style={classes.textFieldStyle} />}
                        />
                    )}
                    <Autocomplete
                        id="state"
                        disablePortal
                        options={states}
                        sx={{ width: "100%" }}
                        value={state?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                            option.name === value || value === ""
                        }
                        disableClearable
                        getOptionLabel={(option) => option.name ?? option}
                        onChange={(event, value) => {
                            setState(value);
                        }}
                        renderInput={(params) => (
                            <form autoComplete="off">
                                <TextField {...params} label="State" style={classes.textFieldStyle} />
                            </form>
                        )}
                    />
                    <Autocomplete
                        id="city"
                        disablePortal
                        options={cities}
                        sx={{ width: "100%" }}
                        value={city?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                            option.name === value || value === ""
                        }
                        disableClearable
                        getOptionLabel={(option) => option.name ?? option}
                        onChange={(event, value) => {
                            setCity(value);
                        }}
                        renderInput={(params) => (
                            <form autoComplete="off">
                                <TextField {...params} label="City/District" style={classes.textFieldStyle} />
                            </form>
                        )}
                    />
                    <Button
                        btnStyle={{ ...classes.buttonStyle, ...classes.reigsterBtn }}
                        text={"Register"}
                        clickAction={offlineEvent ? syncAnonymousUserHandler : subscribeUserToEvent} />
                </>
            }
            {!offlineEvent ?
            (!isSubscribed ? <Button
                clickAction={shareEvent}
                btnStyle={{ ...classes.buttonStyle, ...classes.shareBtn }}
                text={"Share This Event"} startIcon={<ShareOutlinedIcon />} />
                : 
                <Button
                    clickAction={joinGroupClickHandler}
                    btnStyle={{ ...classes.buttonStyle, ...classes.shareBtn }}
                    text={"Join Group"} startIcon={<ChatRoundedIcon />} />) 
                :
                null
            }
        </div>
    )
}