export default function ChatBubbleColoredIcon({
  wrapperStyle = {},
  style = {},
}) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2909 6.2065C15.6824 2.59768 9.81898 2.59768 6.21055 6.20744L6.21039 6.20759C3.38444 9.03293 2.77077 13.22 4.35684 16.6474C4.36223 16.6591 4.36733 16.6709 4.37212 16.6828L4.38464 16.7139C4.58793 17.2196 4.81713 17.7898 4.81713 18.3766C4.81713 18.7857 4.7199 19.1857 4.61728 19.5263C4.56497 19.6998 4.50698 19.8712 4.45312 20.0293L4.43921 20.0701C4.3894 20.2162 4.34384 20.3498 4.30317 20.4801C4.21123 20.7746 4.16929 20.9779 4.16929 21.1234C4.16929 21.2423 4.19554 21.2686 4.21358 21.2867C4.23158 21.3047 4.25825 21.3305 4.37604 21.3304C4.52107 21.3303 4.72388 21.2883 5.01735 21.1962C5.14952 21.1547 5.28533 21.108 5.43398 21.0569L5.46647 21.0457C5.62385 20.9916 5.79456 20.9333 5.96726 20.8806C6.30595 20.7772 6.70396 20.6784 7.11079 20.676L7.11524 20.676C7.69482 20.676 8.26777 20.9071 8.76186 21.1063C8.78015 21.1137 8.79833 21.121 8.81639 21.1283C8.82808 21.133 8.83966 21.138 8.85109 21.1433C12.2793 22.7287 16.4649 22.1158 19.2909 19.2896C22.8997 15.6803 22.906 9.82208 19.2909 6.2065ZM5.14969 5.14697C9.34394 0.951188 16.1573 0.951188 20.3516 5.1459C24.5532 9.34794 24.5454 16.1559 20.3516 20.3502C17.0701 23.632 12.2143 24.3426 8.23903 22.5129C7.66473 22.2819 7.3657 22.1766 7.11738 22.176C6.93117 22.1775 6.70012 22.2252 6.40523 22.3152C6.26052 22.3594 6.11257 22.4098 5.95392 22.4643L5.91782 22.4767C5.77346 22.5264 5.61912 22.5794 5.4668 22.6273C5.14661 22.7278 4.76194 22.8302 4.37693 22.8304C3.96546 22.8307 3.51911 22.7131 3.15321 22.3476L3.15286 22.3473C2.78701 21.9814 2.66929 21.5349 2.66929 21.1234C2.66929 20.7384 2.77131 20.3534 2.87132 20.0331C2.91814 19.8831 2.97004 19.7309 3.01866 19.5884C3.02357 19.574 3.02845 19.5597 3.03329 19.5455C3.08741 19.3867 3.13739 19.2384 3.18106 19.0935C3.27039 18.797 3.31713 18.5644 3.31713 18.3766C3.31713 18.1256 3.21622 17.8301 2.98727 17.2595C1.15678 13.2847 1.86841 8.42767 5.14969 5.14697Z"
          fill="#EF7123"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6895 13.1631C15.6895 12.6108 16.1372 12.1631 16.6895 12.1631H16.6985C17.2507 12.1631 17.6985 12.6108 17.6985 13.1631C17.6985 13.7154 17.2507 14.1631 16.6985 14.1631H16.6895C16.1372 14.1631 15.6895 13.7154 15.6895 13.1631Z"
          fill="#EF7123"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6802 13.1631C11.6802 12.6108 12.1279 12.1631 12.6802 12.1631H12.6892C13.2415 12.1631 13.6892 12.6108 13.6892 13.1631C13.6892 13.7154 13.2415 14.1631 12.6892 14.1631H12.6802C12.1279 14.1631 11.6802 13.7154 11.6802 13.1631Z"
          fill="#EF7123"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.67139 13.1631C7.67139 12.6108 8.1191 12.1631 8.67139 12.1631H8.68039C9.23267 12.1631 9.68039 12.6108 9.68039 13.1631C9.68039 13.7154 9.23267 14.1631 8.68039 14.1631H8.67139C8.1191 14.1631 7.67139 13.7154 7.67139 13.1631Z"
          fill="#EF7123"
        />
      </svg>
    </div>
  );
}
