import { SET_APP_SALES_PERSON_DATA, SET_SUB_CITIES, SET_SALES_ACCESS_TOKEN, SET_DASHBOARD_ROLE, SET_CUSTOM_CITIES } from "../constants";

export const setAppSalesPersonData = (data) => {
    return {
      type: SET_APP_SALES_PERSON_DATA,
      payload: data,
    };
};

export const setSalesAccessToken = (data) => {
    return {
      type: SET_SALES_ACCESS_TOKEN,
      payload: data,
    };
};

export const setSubCities = (data) => {
  return {
    type: SET_SUB_CITIES,
    payload: data,
  };
};

export const setCustomCities = (data) => {
  return {
    type: SET_CUSTOM_CITIES,
    payload: data,
  };
};

