export default function ProfileIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4839 15.9856C8.45512 15.9856 5.01465 16.5947 5.01465 19.0342C5.01465 21.4737 8.4333 22.1046 12.4839 22.1046C16.5127 22.1046 19.9521 21.4945 19.9521 19.056C19.9521 16.6175 16.5345 15.9856 12.4839 15.9856Z"
          stroke="#32312F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.484 12.506C15.1279 12.506 17.2707 10.3622 17.2707 7.71835C17.2707 5.0745 15.1279 2.93164 12.484 2.93164C9.84017 2.93164 7.69632 5.0745 7.69632 7.71835C7.68739 10.3533 9.81636 12.4971 12.4503 12.506H12.484Z"
          stroke="#32312F"
          strokeWidth="1.42857"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
