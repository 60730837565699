import ModalTitle from "../../common/ModalTitle/ModalTitle";
import UserDetailsCard from "../../common/UserDetailsCard/UserDetailsCard";
import "./SalesWalkCard.css";

const defaultUserImage =
  process.env.PUBLIC_URL + "/assets/images/pet-parent-avatar.webp";

const SalesWalkUserDetailsModal = ({ walk, onClose }) => {
  return (
    <div className="info-card-wrapper">
      <ModalTitle title={"User Details"} onClose={onClose} />
      <UserDetailsCard
        photoUrl={walk?.user.photoUrl || defaultUserImage}
        name={walk?.user.name}
        email={walk?.user.email}
        phoneNumber={walk?.user.phoneNumber}
        address={walk?.user.address.address ?? "-"}
        pinCode={walk?.user.address.pinCode ?? "-"}
        latitude={walk?.user.address.coords?.latitude ?? "-"}
        longitude={walk?.user.address.coords?.longitude ?? "-"}
        mapLink={walk?.user.address?.coords?.mapLink}
      />
    </div>
  );
};

export default SalesWalkUserDetailsModal;
