import { Autocomplete, Button, TextField } from "@mui/material";
import { forwardRef, useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { getCities, getCountries, getStates, getUserByPhoneNumber } from "../../redux/dataFetchers/appUserApis";
import { editSubscribedUser } from "../../redux/dataFetchers/salesDashboardApis";
import { SubscriptionsContext } from "../../sales/Users/Users";
import "react-phone-number-input/style.css";
import { setActionNotif } from "../../redux/actions";
import ModalTitle from "../../common/ModalTitle/ModalTitle";

const classes = {
    btn: {
        width: "90%",
        borderRadius: "15px",
        background: "#EF7123",
        color: "#fff",
        fontSize: 18,
        fontWeight: 700,
        padding: "0.5rem 0",
        display: "flex",
        margin: "0 auto",
    }
}

const PhoneCustomInputElement = forwardRef((props, ref) => (
    <TextField
        {...props}
        required
        id="outlined-basic"
        label="Phone"
        variant="outlined"
        fullWidth
        inputRef={ref}
    />
));

const SalesEditUserDetails = ({onClose}) => {
    const {
        sales: { accessToken, subCities },
        user: { countries, cities, states },
    } = useSelector((state) => state);
    const [subCitySelected, setSubCitySelected] = useState({})
    const [user, setUser] = useState({});
    const [phoneNumber, setPhoneNumber] = useState("")
    const [address, setAddress] = useState({})

    const dispatch = useDispatch();
    const { closeEditUserModal, setLoadingParentData } = useContext(SubscriptionsContext)
    useEffect(() => {
        if (user?.id) {
            dispatch(getCountries());
            setSubCitySelected(user.subCityAssigned);
            setAddress(user.address);
        }
    }, [user])

    useEffect(() => {
        dispatch(getStates(address?.country?.code))
    }, [address?.country])

    useEffect(() => {
        dispatch(getCities(address?.country?.code, address?.state?.code))
    }, [address?.state])

    const searchUserSuccessHandler = (user) => {
        user?.id && setUser(user);
    }
    
    const searchUserFailureHandler = (error) => {
        dispatch(setActionNotif({
            open:true,
            message:error?.message || "Failed to search User",
            severity:"warning"
        }))
        console.log({ error });
    }
    
    const searchUserClickHandler = async () => {
        try {
          if(!phoneNumber) return;
            await getUserByPhoneNumber(phoneNumber,accessToken,searchUserSuccessHandler, searchUserFailureHandler);
        } catch (error) {
            console.log({ error });
        }
    };

    const onUserEditSuccess = () => {
        dispatch(setActionNotif({
            open: true,
            message: "User updated!",
            severity: "success"
        }))
        closeEditUserModal()
        setLoadingParentData(true);
    }

    const onUserEditFailure = () => {
        dispatch(setActionNotif({
            open: true,
            message: "Something Went Wrong!",
            severity: "error"
        }))
    }

    const updateUserDetails = async () => {
        try {
            if ((address?.coords?.latitude && !address?.coords?.longitude)
                || (address?.coords?.longitude && !address?.coords?.latitude)) {
                dispatch(setActionNotif({
                    open: true,
                    message: "Enter Latitude Longitude as well",
                    severity: "error"
                }));
                return;
            }

            const updatedAddress = {
                ...address,
                ...(address?.coords) && {
                    coords: {
                        ...(address.coords.mapLink) && {
                            mapLink: address.coords.mapLink
                        },
                        ...(address.coords.latitude) && {
                            latitude: parseFloat(address.coords.latitude)
                        },
                        ...(address.coords.longitude) && {
                            longitude: parseFloat(address.coords.longitude)
                        }
                    }
                }
            }

            if (!updatedAddress?.coords?.latitude &&
                !updatedAddress?.coords?.longitude && !updatedAddress?.coords?.mapLink)
                delete updatedAddress.coords;

            const details = {
                subCityAssigned: subCitySelected?.id,
                address: updatedAddress,
            }
            dispatch(editSubscribedUser(
                user?.id, details, onUserEditSuccess, onUserEditFailure))
        } catch (error) {
            console.log({ error })
        }
    }

    const handleCoordsChange = (coords, type) => {
        const regex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
        if (coords === '' || regex.test(coords)) {
            setAddress(prev =>
            ({
                ...prev,
                coords: {
                    ...prev?.coords, [type]: coords
                }
            }))
        }
    }

    return (
        <div style={{paddingBottom: '1rem'}}>
            <ModalTitle title={"User Details"} onClose={onClose} />
            
            <div className="card-container" style={{padding: "1rem 1rem"}}>
        
            <div className="dash-filter filter" style={{width: "100%"}}>
                <PhoneInput
                    key="user-phone"
                    style={{ width: "100%" }}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    inputComponent={PhoneCustomInputElement}
                    defaultCountry="IN"
                    disabled={Boolean(user?.id)}
                />
                    </div>
            </div>

            {!user?.id && <Button variant='contained' style={{backgroundColor:"#974EC3", color: "#fff", marginTop: "1rem", fontWeight: "bold", fontSize: "1rem", width: "7rem", display: "flex", margin: "0 auto", marginBottom: "1rem"}} onClick={searchUserClickHandler}>Search</Button>}
            {user?.id &&
                <div className="card-container" style={{display: "flex", flexDirection: "column", gap: "0.8rem"}}>
                      <div className="dash-filter filter" style={{width: "100%"}}>
                            <span>{user?.name}</span>
                        </div>
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <TextField
                                id="outlined-basic"
                                label="Address"
                                value={address?.address || ''}
                                onChange={(event) =>
                                    setAddress(prev =>
                                        ({ ...prev, address: event.target.value }))}
                                variant="outlined"
                                fullWidth
                                inputProps={{ style: { fontSize: 15 } }}
                            />
                        </div>
                        {/* <div className="dash-filter filter" style={{width: "100%"}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box"
                                options={subCities}
                                sx={{ width: "100%", minWidth: "14rem" }}
                                value={subCitySelected?.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value || value === ""
                                }
                                onChange={(event, value) => {
                                    setSubCitySelected(value);
                                }}
                                getOptionLabel={(option) => option.name ?? option}
                                renderInput={(params) => <TextField {...params} label="SubCity" />}
                            />
                        </div> */}
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <Autocomplete
                                disablePortal
                                id="user-country"
                                options={countries}
                                sx={{ width: "100%" }}
                                value={address?.country?.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value || value === ""
                                }
                                disableClearable={true}
                                getOptionLabel={(option) => option.name ?? option}
                                onChange={(event, value) => {
                                    setAddress((prev) => ({
                                        ...prev,
                                        city: "",
                                        state: "",
                                        country: value,
                                    }));
                                }}
                                renderInput={(params) => (
                                    <form autoComplete="off">
                                        <TextField {...params} label="Country" required />
                                    </form>
                                )}
                            />
                        </div>
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <Autocomplete
                                disablePortal
                                id="user-state"
                                options={states}
                                sx={{ width: "100%" }}
                                value={address?.state?.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value || value === ""
                                }
                                disableClearable={true}
                                getOptionLabel={(option) => option.name ?? option}
                                onChange={(event, value) => {
                                    setAddress((prev) => ({
                                        ...prev,
                                        city: "",
                                        state: value,
                                    }));
                                }}
                                renderInput={(params) => (
                                    <form autoComplete="off">
                                        <TextField {...params} label="State" required />
                                    </form>
                                )}
                            />
                        </div>
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <Autocomplete
                                disablePortal
                                id="user-city"
                                options={cities}
                                sx={{ width: "100%" }}
                                value={address?.city?.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                    option.name === value || value === ""
                                }
                                disableClearable={true}
                                getOptionLabel={(option) => option.name ?? option}
                                onChange={(event, value) => {
                                    setAddress((prev) => ({ ...prev, city: value }));
                                }}
                                renderInput={(params) => (
                                    <form autoComplete="off">
                                        <TextField {...params} label="City/District" required />
                                    </form>
                                )}
                            />
                        </div>
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <TextField
                                id="outlined-basic"
                                label="Pincode"
                                value={address?.pinCode || ''}
                                onChange={(event) =>
                                    setAddress(prev =>
                                        ({ ...prev, pinCode: event.target.value }))}
                                variant="outlined"
                                fullWidth
                                inputProps={{ style: { fontSize: 15 } }}
                            />
                        </div>
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <TextField
                                id="outlined-basic"
                                label="Latitude"
                                value={address?.coords?.latitude || ''}
                                onChange={(event) =>
                                    handleCoordsChange(event.target.value, 'latitude')
                                }
                                variant="outlined"
                                fullWidth
                                inputProps={{ style: { fontSize: 15 } }}
                            />
                        </div>
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <TextField
                                id="outlined-basic"
                                label="Longitude"
                                value={address?.coords?.longitude || ''}
                                onChange={(event) =>
                                    handleCoordsChange(event.target.value, 'longitude')
                                }
                                variant="outlined"
                                fullWidth
                                inputProps={{ style: { fontSize: 15 } }}
                            />
                        </div>
                        <div className="dash-filter filter" style={{width: "100%"}}>
                            <TextField
                                id="outlined-basic"
                                label="Map link"
                                value={address?.coords?.mapLink || ''}
                                onChange={(event) =>
                                    setAddress(prev =>
                                    ({
                                        ...prev,
                                        coords: {
                                            ...prev.coords,
                                            mapLink: event.target.value
                                        }
                                    }))}
                                variant="outlined"
                                fullWidth
                                inputProps={{ style: { fontSize: 15 } }}
                            />
                        </div>
                </div>}
            

                {user?.id && <Button  style={classes.btn} onClick={updateUserDetails}>Update user details</Button>}
        </div>
    )
}

export default SalesEditUserDetails