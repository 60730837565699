import { Button } from "@mui/material";
import { useContext } from "react";
import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultPetImage from "../../assets/images/pet-avatar.webp";
import { TextField } from "@mui/material";
import { setActionNotif } from "../../redux/actions";
import { bookSubscription, bookWalkingSessionForSubscription, extendUserSubscription } from "../../redux/dataFetchers/salesDashboardApis";
import { SubscriptionsContext } from "../../sales/Users/Users";
import { MediaUploadLoaderContext } from "../../App";
import UserDetailsCard from "../../common/UserDetailsCard/UserDetailsCard";
import SalesTrialSlotPicker from "../SalesSlotPicker/SalesTrialSlotPicker";
import { EPOCH, SlotDurationValue, SourceFilters } from "../../redux/constants";
import { getSlots } from "../SalesSubscribeUserCard/apis";
import SalesSubscriptionSlotPicker from "../SalesSlotPicker/SalesSubscriptionSlotPicker";
import { isEpoch } from "../SalesSubscribeUserCard/SalesSubscribeUserCard";

const profilePicPlaceholder = {
    src: defaultPetImage,
    alt: "pet profile",
};

const commonBtnStyle = {
    borderRadius: "50px",
    padding: "0.8rem 0",
    textTransform: "Capitalize",
    position: "relative",
    overflow: "hidden",
    marginTop: "0.6rem",
    width: "100%"
};

const classes = {
    btn: {
        ...commonBtnStyle,
        width: "90%",
        borderRadius: "15px",
        opacity: 0.5,
        background: "#EF7123", 
        color: "#fff",
        fontSize: 24,
        fontWeight: 700,
        padding: "0.5rem 0",
        display: "flex", 
        margin: "0 auto", 
        cursor: "not-allowed"
    },
    enabledBtn: {
        ...commonBtnStyle,
        width: "90%",
        borderRadius: "15px",
        background: "#EF7123", 
        color: "#fff",
        fontSize: 24,
        fontWeight: 700,
        padding: "0.5rem 0",
        display: "flex", 
        margin: "0 auto", 
    },
    sessionPets: {
        display: "flex",
        flexDirection: 'column',
        flexFlow: "row-wrap",
        width: "100%",
    },
    sessionPet: {
        display: "flex",
        alignItems: "center",
        padding: "0.3rem 0.5rem",
        margin: "0.1rem 0.4rem"
    }
}

// const PetWalkDetails = () => {
//     return (
//         <div>
//             <div className="headline">
//                 <h3>Session Details</h3>
//             </div>
//             <div>
//                 <div>
//                     <div className="pet-profile">
//                         <img src={photo} className="pet-profile-image" alt="pet" />
//                         <h2 className="pet-name">{name}</h2>
//                     </div>
//                 </div>

//                 <div>

                    
//                 </div>
//             </div>
//         </div>
//     )
// }

const getBookBtnStatus = ({session, walkPetsInfo, selectedWalkers, plan, schedule}) => {
    const walksPerDay = plan ? plan?.walksPerDay : 1;
  if (session?.walkTogether) {
    return Boolean(selectedWalkers?.length !== walksPerDay || selectedWalkers?.includes(undefined) || schedule?.length !== walksPerDay || schedule?.includes(undefined))
    }
    else {
        let condition = false;
        for (let info of walkPetsInfo) {
            condition ||= Boolean(info?.assignedWalkers?.length !== walksPerDay || info?.assignedWalkers?.includes(undefined) || info?.bookingSchedule?.length !== walksPerDay);
            return condition;
        }
    }
}

export default function SalesBookPlanCard({
    subscriptionId, session, subscribedUser, plan, userProductCreditId, noOfWalks, subscriptionEnd, extend = false,
    subscriptionStatus = '', extendModalClose = () => { }, reloadSubscriptions = () => { }, orderId , isTrial}) {
    const { sales: { accessToken, data } } = useSelector((state) => state);
    const [schedule, setSchedule] = useState([]);
    const [selectedWalkers, setSelectedWalkers] = useState(session?.walkers || []);
    const [walkPetsInfo, setWalkPetsInfo] = useState(session.pets?.map(petObj => ({ ...petObj, ...petObj.assignedWalkers?.length && { assignedWalkers: [] } })));
    const [extendedWalks, setExtendedWalks] = useState(noOfWalks);
    const [existNoOfWalks, setExistNoOfWalks] = useState(noOfWalks);
    const [leftWalks, setLeftWalks] = useState(noOfWalks % plan?.walksPerDay);
    const [slotData, setSlotData] = useState([]);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [leftWalkForFindWalkers, setLeftWalkForFindWalkers] = useState(noOfWalks % plan?.walksPerDay);
    const [trialSlotRadius, setTrialSlotRadius] = useState(3);

    const [isSchedule1SlotExist, setIsSchedule1SlotExist] = useState(false);
    const [isSchedule2SlotExist, setIsSchedule2SlotExist] = useState(session?.schedule?.length > 1 ? false : true);

    const dispatch = useDispatch()
    const sessionPets = useMemo(() => {
        let petsMeta = session.pets, viewResult = [];
        for (let petInfo of petsMeta) {
            const petProfile = subscribedUser?.petProfiles?.
                find(pet => pet.id === petInfo.id || pet._id === petInfo.id)
            petProfile && viewResult.push(petProfile)
        }
        return viewResult?.length ? viewResult : [];
    }, [session.pets?.length]);
    const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(MediaUploadLoaderContext);

    const { setLoadingParentData, closeEditSubscriptionModal } = useContext(SubscriptionsContext);
    const disabledBtn = isTrial
      ? schedule
          .map((item) => {
            if (!item) return false;
          })
          .includes(false)
      : getBookBtnStatus({
          session,
          walkPetsInfo,
          selectedWalkers,
          plan,
          schedule,
        });

 

    const onSubscribeUserSuccess = (response) => {
        dispatch(setActionNotif({
            open: true,
            message: "User Subscribed!",
            severity: "success"
        }))
        reloadSubscriptions()
        extendModalClose();
        closeMediaUploadLoader();
    }
    
    const onSubscribeUserFailure = (error) => {
        dispatch(setActionNotif({
            open: true,
            message: "Something Went Wrong!",
            severity: "error"
        }))
        closeMediaUploadLoader();
    }
  
  // useEffect(() => {
  //   if(!(schedule?.length > 1))setSelectedWalkers([]);
  // }, [schedule]);

    const extendUserClickHandler = () => {
        if (!extend || extendedWalks === 0) return;

        const seedSession = {
            ...session,
            schedule,
            pets: walkPetsInfo,
            subscriptionId,
            ...session.walkTogether && {
                walkers: selectedWalkers
            },
            noOfWalks: extendedWalks
        }

        if (!session.walkTogether) delete seedSession.schedule;

        const subscription = {
            planCode: plan.code,
            seedSession,
            status: subscriptionStatus
        }
        openMediaUploadLoader();
        dispatch(extendUserSubscription(
            subscription,
            subscribedUser?.id, subscriptionId, onSubscribeUserSuccess, onSubscribeUserFailure,
            ))
    }

    //update details of walker assigned and schedule selected per pet, per walk
  const setAssignmentDetails = (walkerSelected, scheduledAt, scheduleIndex, petIndex, updateSchedule) => {
    if (session.walkTogether) {
            setSelectedWalkers(prev => {
              let prevWalkers = [...prev];
                prevWalkers[scheduleIndex] = walkerSelected?.id || "";
                return prevWalkers;
            })
            updateSchedule && setSchedule(prev => {
                let prevSchedule = [...prev];
                prevSchedule[scheduleIndex] = scheduledAt || "";
                return prevSchedule;
            })
    } else {
            setWalkPetsInfo(prev => {
              let prevArr = [...prev];
              let prevDetail = prevArr[petIndex];
                if (!prevDetail.hasOwnProperty("assignedWalkers")) prevDetail.assignedWalkers = [];
                if (!prevDetail.hasOwnProperty("bookingSchedule")) prevDetail.bookingSchedule = [];
                prevDetail.assignedWalkers[scheduleIndex] = walkerSelected?.id || "";
                prevDetail.bookingSchedule[scheduleIndex] = scheduledAt || "";
                prevArr[petIndex] = prevDetail;
                return prevArr;
            })
        }
    }
  
  // const setAutomatchingAssignmentDetails = (walkerSelected, scheduleIndex, petIndex) => {
  //   if (session.walkTogether) {
  //           setSelectedWalkers(prev => {
  //               let prevWalkers = [...prev];
  //               prevWalkers[scheduleIndex] = walkerSelected?.id || "";
  //               return prevWalkers;
  //           })
  //           setSchedule(prev => {
  //               let prevSchedule = [...prev];
  //               prevSchedule[scheduleIndex] = scheduledAt || "";
  //               return prevSchedule;
  //           })
  //       } else {
  //           setWalkPetsInfo(prev => {
  //               console.log({ prevWalkPetsInfo: prev })
  //               let prevArr = [...prev];
  //               let prevDetail = prevArr[petIndex];
  //               if (!prevDetail.hasOwnProperty("assignedWalkers")) prevDetail.assignedWalkers = [];
  //               if (!prevDetail.hasOwnProperty("bookingSchedule")) prevDetail.bookingSchedule = [];
  //               prevDetail.assignedWalkers[scheduleIndex] = walkerSelected?.id || "";
  //               prevDetail.bookingSchedule[scheduleIndex] = scheduledAt || "";
  //               prevArr[petIndex] = prevDetail;
  //               console.log({ prevDetail })
  //               return prevArr;
  //           })
  //       }
  // }
  

    const isScheduleValid = (walkSchedule) => {
        if(new Date(walkSchedule).getTime() > new Date().getTime()){
            if(new Date(walkSchedule).getMinutes() === 0 || new Date(walkSchedule).getMinutes() === 30){
                return true;
            }
            else{
                dispatch(setActionNotif({
                    open: true,
                    message: "Walk Minutes should be 0 or 30",
                    severity: "warning"
                }))
            }
        }
        else{
            dispatch(setActionNotif({
                open: true,
                message: "Walk cannot be in past",
                severity: "warning"
            }))
        } 
    }

    const bookSessionsClickHandler = () => {
        // if(!extend){
        //     for(let walkSchedule of schedule){
        //         if(!isScheduleValid(walkSchedule)) return;
        //     }
        // }
        
        const sessionDetails = {
            ...session,
            schedule,
            pets: walkPetsInfo,
            ...subscriptionId && {subscriptionId},
            ...orderId && {orderId},
            userProductCreditId,
            ...session.walkTogether && {
                walkers: selectedWalkers
            },
            ...existNoOfWalks && { noOfWalks:existNoOfWalks }
        }

        let subscriptionDetails = {};
        if (isTrial) {

            let seedSession = session;
            if (!seedSession?.slotDuration) {
                seedSession.slotDuration = SlotDurationValue.MEDIUM
            }

            subscriptionDetails = {
                creatorType: data?.type,
                trial: isTrial,
                amount: 0,
                userId: subscribedUser?.id,
                ...userProductCreditId && { userProductCreditId },
                ...subscriptionId && { subscriptionId },
                seedSession: { ...seedSession, schedule },
                ...isTrial && {
                  latitude:subscribedUser.address.coords.latitude,
                  longitude:subscribedUser.address.coords.longitude,
                  maxDistance:trialSlotRadius*1000
                }
            }
        }
        

        if (!session.walkTogether) delete sessionDetails.schedule;

        openMediaUploadLoader();
        isTrial
          ? dispatch(
              bookSubscription(
                subscriptionDetails,
                  () => {
                    closeEditSubscriptionModal()
                    setLoadingParentData(true);
                    closeMediaUploadLoader();
                },
                  () => {
                      closeMediaUploadLoader();
                }
              )
            )
          : dispatch(
              bookWalkingSessionForSubscription(
                sessionDetails,
                subscribedUser.id,
                closeEditSubscriptionModal,
                setLoadingParentData,
                closeMediaUploadLoader
              )
            );
    }

    const getExtendedScheduleDateTime = (dateTime, noOfWalks, scheduleIndex, walksPerDay, isFirstWalkBig) => {
        const oneDay = (24 * 3600 * 1000)
        const hours = new Date(dateTime).getHours();
        const minutes = new Date(dateTime).getMinutes();

        let totalWalks = Math.floor(noOfWalks/walksPerDay);

        if(leftWalks !== 0){
          if(isFirstWalkBig && scheduleIndex === 1){
              totalWalks++;
              setLeftWalks(leftWalks-1);
          }else{
            if(!isFirstWalkBig && scheduleIndex === 0){
              totalWalks++;
              setLeftWalks(leftWalks-1);
            } 
          }
       } 
     
        //Existing subs valid upto date
        let validUpto = new Date(dateTime + ((totalWalks - 1) * EPOCH.ONE_DAY_MS)).setHours(hours, minutes, 0, 0);
        //Today's date with time 00:00
        let todayDate = new Date(new Date().toDateString()).getTime();
        let newSubscriptionTime;

        if (validUpto > todayDate) {
            //Adding 1 day to current validUpto 
            newSubscriptionTime = validUpto + oneDay;
        }
        else {
            //Getting hours and minutes for the walk schedule
            if ((new Date().getHours()) > hours ||
                ((new Date().getHours()) === hours && (new Date().getMinutes()) >= minutes))
                //Adding hours and minutes to today's date + 1 day since current time is past duration time
                newSubscriptionTime = new Date(todayDate + (oneDay))
                    .setHours(hours, minutes)
            else
                newSubscriptionTime = new Date(todayDate).setHours(hours, minutes)
        }

        return newSubscriptionTime;
    }

    const getExtendedSchedule = () => {
        if (!extend || (extend && !session)) return [''];
      
        const seedSession = session;
        let scheduleArr = [];
        let isFirstWalkBig = plan.walksPerDay > 1 ? new Date(seedSession.schedule[0]).getDate() > new Date(seedSession.schedule[1]).getDate(): true;

        //if walk togethere and schedule exists
        if (seedSession.walkTogether && seedSession.schedule) {
            //Mapping on all schedule slots
            seedSession.schedule.map((dateTime, scheduleIndex) => {
                const newSubscriptionTime = getExtendedScheduleDateTime(dateTime, seedSession.noOfWalks, scheduleIndex, seedSession.schedule?.length, isFirstWalkBig);
                if (newSubscriptionTime)
                    scheduleArr.push(newSubscriptionTime)
            })
            setSchedule(scheduleArr);
        }
        //if walk together true and booking schedule should exists
        else if (!seedSession.walkTogether && seedSession.pets[0].bookingSchedule) {
            //Mapping on each pets
            let newSchedule = JSON.parse(JSON.stringify(walkPetsInfo));
            seedSession.pets?.map((pet, petIndex) => {
                pet.bookingSchedule?.map((dateTime, scheduleIndex) => {
                    const newSubscriptionTime = getExtendedScheduleDateTime(dateTime, seedSession.noOfWalks, scheduleIndex, pet.bookingSchedule.length, isFirstWalkBig);
                    if (newSubscriptionTime) {
                        newSchedule[petIndex].bookingSchedule[scheduleIndex] = newSubscriptionTime;
                    }
                })
            })
            setWalkPetsInfo(newSchedule)
        }
    }
    const initSchedule = () => {
        let tempSchedule = [];
        if (extend) {
            getExtendedSchedule();
            return;
        }
        else if (session.trial) {
            // tempSchedule = session?.schedule?.length ? session.schedule : [""]
            tempSchedule = new Array(session?.schedule?.length).fill(null);

        } else {
            for (let walk = 0; walk < plan?.walksPerDay; walk++) {
                //this is preferred schedule entered when subscription was created
                session?.schedule?.length >= walk + 1 ? tempSchedule.push(session.schedule[walk]) : tempSchedule.push("")
            }
        }

        setSchedule(tempSchedule)
    }
    
    useEffect(() => {
        initSchedule()
    }, [session?.schedule?.length])

  const setScheduleTime = (time, index) => {
    setSchedule((prev) => {
      let prevSchedule = [...prev];
      prevSchedule[index] = time || "";
      return prevSchedule;
    });
    // setDisableFindWalkers(false);
  };

    return (
      <div style={{ paddingBottom: "1rem" }}>
        <UserDetailsCard
          showName={true}
          name={subscribedUser?.name || subscribedUser?.email}
          phoneNumber={session?.phoneNumber}
          address={
            subscribedUser?.address?.address
              ? subscribedUser?.address?.address
              : session?.address?.address ??
                session?.address?.address?.city?.name
          }
          latitude={
            (subscribedUser?.address?.coords?.latitude ??
              session?.address?.coords?.latitude) ||
            "-"
          }
          longitude={
            (subscribedUser?.address?.coords?.longitude ??
              session?.address?.coords?.longitude) ||
            "-"
          }
          mapLink={
            (subscribedUser?.address?.coords?.mapLink ??
              session?.address?.coords?.mapLink) 
          }
          pinCode={
            (subscribedUser?.address?.pinCode ?? session?.address?.pinCode) ||
            "-"
          }
          walkTogether={
            session?.walkTogether ? "Walk Pets Together" : "Walk Pets Separate"
          }
        />

        <div className="card-container">
        {!isTrial && 
        <>
         {
                  !extend && (plan?.walksPerDay % 2 === 0) && <p style={{color:"red"}}>* Only Even Number of Walks allowed When booking Even walks per day!</p>
                   
              }
            <div className="user-info-card">      
            <span className="label">No Of Walks:</span>
            <TextField
                      id="outlined-basic"
                      label="Number of Walks"
                      value={extend? extendedWalks : existNoOfWalks}
                      type="text"
                      onChange={(event) =>{
                        const value = event.target.value;
                        const numericValue = +value.replace(/\D/g, ''); 
                        if(numericValue >= 0 && numericValue <= 200)
                          extend? setExtendedWalks(numericValue) : setExistNoOfWalks(numericValue)
                        
                        setLeftWalkForFindWalkers((numericValue) % plan?.walksPerDay);
                      }}
                      variant="outlined"
                      fullWidth
                      inputProps={{ 
                          style: { fontSize: 15 },
                          'min': 0, // Ensuring the value is not negative
                          'max':200
                      }}
                      // Additional styles to hide arrows in number input
                      InputProps={{
                          style: {
                              MozAppearance: 'textfield', // For Firefox
                          },
                      }}
            />
          </div>
          </>
          }
          {session?.walkTogether ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "2rem",
                  gap: "1rem",
                }}
              >
                {sessionPets?.map((pet) => (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                    key={pet.id}
                  >
                    <img
                      src={pet.profilePicUrl || profilePicPlaceholder.src}
                      alt={profilePicPlaceholder.alt}
                      className="profile-picture"
                    />
                    <span className="name">{pet.name}</span>
                  </span>
                ))}
              </div>
              <div className="subs-info-card">
                {schedule?.map((dateTime, index) =>
                  isTrial ? (
                    <SalesTrialSlotPicker
                      key={index}
                      accessToken={accessToken}
                      selectedTime={schedule[index]}
                      setScheduleTime={(time) => setScheduleTime(time, index)}
                      preferredTime={session?.schedule?.[index]}
                      lat={subscribedUser?.address?.coords?.latitude ??
                        session?.address?.coords?.latitude}
                      lng={subscribedUser?.address?.coords?.longitude ??
                        session?.address?.coords?.longitude}
                      isTrial={isTrial}
                      userId={subscribedUser?.id}
                      loadingSlots={loadingSlots}
                      slotRadius={trialSlotRadius}
                      setSlotRadius={setTrialSlotRadius}
                      // noOfWalks={extend? extendedWalks : existNoOfWalks}
                      // walkIndex={index}
                      // walksPerDay={schedule.length}
                    />
                  ) : (
                    <div>
                      <SalesSubscriptionSlotPicker
                        key={index}
                        accessToken={accessToken}
                        subscriptionPlan={plan}
                        lat={
                          subscribedUser?.address?.coords?.latitude ??
                          session?.address?.coords?.latitude
                        }
                        lng={
                          subscribedUser?.address?.coords?.longitude ??
                          session?.address?.coords?.longitude
                        }
                        schedule={schedule}
                        selectedTime={schedule[index]}
                        setScheduleTime={(scheduleTime) =>
                          setScheduleTime(scheduleTime, index)
                        }
                        preferredTime={session?.schedule?.[index]}
                        preferredWalkerId={session?.walkers?.[index] ?? null}
                        scheduleIndex={index}
                        leftWalks={leftWalkForFindWalkers}
                        setLeftWalks={setLeftWalkForFindWalkers}
                        scheduledDateTime={dateTime}
                        plan={plan}
                        setAssignmentDetails={setAssignmentDetails}
                        extend={extend}
                        setSelectedWalkers={setSelectedWalkers}
                        assignedWalker={selectedWalkers[index]}
                        noOfWalks={extend? extendedWalks : existNoOfWalks}
                        walkIndex={index}
                        walksPerDay={schedule.length}
                        setIsSchedule1SlotExist={setIsSchedule1SlotExist}
                        setIsSchedule2SlotExist={setIsSchedule2SlotExist}
                        isSchedule1SlotExist={isSchedule1SlotExist}
                        isSchedule2SlotExist={isSchedule2SlotExist}
                      />
                      {/* <SalesDefaultWalkerAssignCard
                        key={`${dateTime}${index}`}
                        scheduleIndex={index}
                        scheduledDateTime={dateTime}
                        plan={plan}
                        setAssignmentDetails={setAssignmentDetails}
                        extend={extend}
                        showFindWalkersOnly={true}
                          disableFindWalkers={disableFindWalkers}
                      /> */}
                    </div>
                  )
                )}
              </div>
            </div>
          ) : (
            <>
              {sessionPets?.map((pet, petIndex) => (
                <div
                  className="subs-info-card"
                  key={pet.id}
                  style={{ width: "100%" }}
                >
                  <span style={classes.sessionPet}>
                    <img
                      src={pet.profilePicUrl || profilePicPlaceholder.src}
                      alt={profilePicPlaceholder.alt}
                      className="mini-profile-pic"
                    />
                    <span>{pet.name}</span>
                  </span>
                  {extend
                    ? walkPetsInfo[petIndex].bookingSchedule?.map(
                        (dateTime, scheduleIndex) => {
                          return (
                        <SalesSubscriptionSlotPicker
                              key={`${dateTime}${scheduleIndex}`}
                              accessToken={accessToken}
                              subscriptionPlan={plan}
                              lat={
                                subscribedUser?.address?.coords?.latitude ??
                                session?.address?.coords?.latitude
                              }
                              lng={
                                subscribedUser?.address?.coords?.longitude ??
                                session?.address?.coords?.longitude
                              }
                              setScheduleTime={(scheduleTime) =>
                                setScheduleTime(scheduleTime, scheduleIndex)
                              }
                              leftWalks={leftWalkForFindWalkers}
                              setLeftWalks={setLeftWalkForFindWalkers}
                              preferredTime={session?.schedule?.[scheduleIndex]}
                              preferredWalkerId={walkPetsInfo[petIndex].assignedWalkers?.[scheduleIndex]}
                              scheduleIndex={scheduleIndex}
                              petIndex={petIndex}
                              scheduledDateTime={dateTime}
                              schedule={schedule}
                              plan={plan}
                              extend={extend}
                              setAssignmentDetails={setAssignmentDetails}
                              setSelectedWalkers={setSelectedWalkers}
                              assignedWalker={selectedWalkers[scheduleIndex]}
                              selectedTime={schedule[scheduleIndex]}
                              noOfWalks={extendedWalks}
                              walkIndex={scheduleIndex}
                              walksPerDay={walkPetsInfo[petIndex].bookingSchedule.length}
                            />
                        )}
                      )
                    : schedule?.map((dateTime, scheduleIndex) =>
                        isTrial ? (
                          <SalesTrialSlotPicker
                            key={scheduleIndex}
                            accessToken={accessToken}
                            selectedTime={schedule[scheduleIndex]}
                            setScheduleTime={(time) =>
                              setScheduleTime(time, scheduleIndex)
                            }
                            preferredTime={session?.schedule?.[scheduleIndex]}
                            lat={subscribedUser?.address?.coords?.latitude ??
                              session?.address?.coords?.latitude}
                            lng={subscribedUser?.address?.coords?.longitude ??
                              session?.address?.coords?.longitude}
                            slotRadius={trialSlotRadius}
                            setSlotRadius={setTrialSlotRadius}
                            isTrial={isTrial}
                            userId={subscribedUser?.id}
                            loadingSlots={loadingSlots}
                          />
                        ) : (
                          <div>
                            <SalesSubscriptionSlotPicker
                              key={`${dateTime}${scheduleIndex}`}
                              accessToken={accessToken}
                              subscriptionPlan={plan}
                              lat={
                                subscribedUser?.address?.coords?.latitude ??
                                session?.address?.coords?.latitude
                              }
                              lng={
                                subscribedUser?.address?.coords?.longitude ??
                                session?.address?.coords?.longitude
                              }
                              selectedTime={schedule[scheduleIndex]}
                              setScheduleTime={(scheduleTime) =>
                                setScheduleTime(scheduleTime, scheduleIndex)
                              }
                              leftWalks={leftWalkForFindWalkers}
                              setLeftWalks={setLeftWalkForFindWalkers}
                              preferredTime={session?.schedule?.[scheduleIndex]}
                              preferredWalkerId={session?.walkers?.[scheduleIndex]}
                              scheduleIndex={scheduleIndex}
                              petIndex={petIndex}
                              scheduledDateTime={dateTime}
                              plan={plan}
                              schedule={schedule}
                              extend={extend}
                              setAssignmentDetails={setAssignmentDetails}
                              noOfWalks={existNoOfWalks}
                              walkIndex={scheduleIndex}
                              setSelectedWalkers={setSelectedWalkers}
                              assignedWalker={selectedWalkers[scheduleIndex]}
                              walksPerDay={schedule.length}
                            />
                          </div>
                        )
                      )}
                </div>
              ))}
            </>
          )}
        </div>
        <Button
          style={(disabledBtn || ((!isSchedule2SlotExist || !isSchedule1SlotExist) && !isTrial)) ? classes.btn : classes.enabledBtn}
          disabled={(disabledBtn || ((!isSchedule2SlotExist || !isSchedule1SlotExist) && !isTrial))  ? true: false}
          onClick={
            !disabledBtn
              ? extend
                ? extendUserClickHandler
                : bookSessionsClickHandler
              : undefined
          }
        >
          Book
        </Button>
      </div>
    );
}
