export default function GoogleSocialIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          d="M23.5799 12.2998C23.5799 11.3105 23.4996 10.5885 23.3259 9.83984H12.0305V14.3052H18.6607C18.527 15.4149 17.8052 17.086 16.2011 18.209L16.1786 18.3585L19.75 21.1257L19.9974 21.1504C22.2699 19.0513 23.5799 15.963 23.5799 12.2998Z"
          fill="#4285F4"
        />
        <path
          d="M12.0306 24.0648C15.2788 24.0648 18.0057 22.9952 19.9975 21.1503L16.2012 18.2089C15.1853 18.9175 13.8218 19.4122 12.0306 19.4122C8.84917 19.4122 6.14899 17.3133 5.18645 14.4121L5.04536 14.4241L1.33177 17.2985L1.2832 17.4335C3.26155 21.3641 7.32523 24.0648 12.0306 24.0648Z"
          fill="#34A853"
        />
        <path
          d="M5.18648 14.4118C4.93251 13.6632 4.78552 12.8609 4.78552 12.0321C4.78552 11.2031 4.93251 10.401 5.17312 9.65232L5.16639 9.49287L1.40626 6.57227L1.28323 6.63079C0.467862 8.26188 0 10.0935 0 12.0321C0 13.9706 0.467862 15.8022 1.28323 17.4333L5.18648 14.4118Z"
          fill="#FBBC05"
        />
        <path
          d="M12.0308 4.65252C14.2899 4.65252 15.8137 5.62848 16.6826 6.44407L20.0779 3.12844C17.9927 1.18988 15.279 0 12.0308 0C7.32548 0 3.26179 2.7006 1.28345 6.63117L5.17333 9.6527C6.14923 6.75154 8.84942 4.65252 12.0308 4.65252Z"
          fill="#EB4335"
        />
      </svg>
    </div>
  );
}
