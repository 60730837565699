import { useEffect, useState, useContext } from "react";
import { MediaUploadLoaderContext } from "../../App";
import ModalTitle from "../../common/ModalTitle/ModalTitle";
import {
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  FormControlLabel,
  Typography,
  Checkbox,
} from "@mui/material";
import {
  EPOCH,
  SlotType,
  TodayStartEpoch,
  TomorrowStartEpoch,
  WalkerLeavesStatus,
  WeekDay,
  leaveCrudActions,
} from "../../redux/constants";
import {
  addSlots,
  isSlotSelected,
  removeSlots,
} from "../SalesEditWalkerScheduleModal/salesEditWalkerScheduleFunctions";
import { useDispatch, useSelector } from "react-redux";
import { addLeaveHandler, editLeaveHandler } from "../../sales/Leaves/apis";
import { formatDate, isSameDay } from "../../redux/dataFetchers/common";

const commonBtnStyle = {
  borderRadius: "50px",
  padding: "0.8rem 0",
  textTransform: "Capitalize",
  position: "relative",
  overflow: "hidden",
  marginTop: "0.6rem",
  width: "100%",
};

const classes = {
  btn: {
    ...commonBtnStyle,
    width: "90%",
    borderRadius: "15px",
    opacity: 0.5,
    background: "#EF7123",
    color: "#fff",
    fontSize: 24,
    fontWeight: 700,
    padding: "0.5rem 0",
    display: "flex",
    margin: "0 auto",
    cursor: "not-allowed",
  },
  enabledBtn: {
    ...commonBtnStyle,
    width: "90%",
    borderRadius: "15px",
    background: "#EF7123",
    color: "#fff",
    fontSize: 24,
    fontWeight: 700,
    padding: "0.5rem 0",
    display: "flex",
    margin: "0 auto",
  },
  walkerImgWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  squareBtn: {
    boxShadow: "0px 3px 2px 1px gainsboro",
    backgroundColor: "#974EC3",
    color: "white",
    fontWeight: "bold",
    width: "100%",
  },
  profileImgCover: {
    display: "flex",
    justifyContent: "center",
  },
  filledImage: {
    opacity: 1,
  },
};

export default function SalesLeaveCard({
  action,
  onClose,
  walkers,
  reloadLeaves,
  currentLeave,
}) {
  const [walker, setWalker] = useState(currentLeave?.walker ?? null);
  const [reason, setReason] = useState(currentLeave?.reason ?? "");
  const [slotType, setSlotType] = useState("");
  const {
    sales: { accessToken },
  } = useSelector((state) => state);
  let [selectedSlots, setSelectedSlots] = useState(currentLeave?.slot ?? []);
  const [isPenaltyRemoved, setisPenaltyRemoved] = useState(
    currentLeave?.isPenaltyRemoved ?? false
  );
  const [startDate, setStartDate] = useState(
    new Date(
      currentLeave?.startDate
        ? currentLeave?.startDate -
          new Date().getTimezoneOffset() * (60 * EPOCH.ONE_SECOND_MS)
        : TodayStartEpoch -
          new Date().getTimezoneOffset() * (60 * EPOCH.ONE_SECOND_MS)
    )
      .toISOString()
      .slice(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date(
      currentLeave?.endDate
        ? currentLeave?.endDate -
          new Date().getTimezoneOffset() * (60 * EPOCH.ONE_SECOND_MS)
        : TomorrowStartEpoch -
          new Date().getTimezoneOffset() * (60 * EPOCH.ONE_SECOND_MS)
    )
      .toISOString()
      .slice(0, 10)
  );
  const [isLeaveOnSameDay, setIsLeaveOnSameDay] = useState(false);
  const LeavesStatus = Object.values(WalkerLeavesStatus);
  const addAction = action === leaveCrudActions.ADD,
    editAction = action === leaveCrudActions.EDIT;
  const dispatch = useDispatch();
  const [status, setStatus] = useState(
    currentLeave?.status ?? WalkerLeavesStatus.PENDING
  );
  
  const checkSameDay = (start, end) => {
    setIsLeaveOnSameDay(isSameDay(start, end));
  };

  useEffect(() => {
    checkSameDay(startDate, endDate);
  }, [startDate, endDate]);

  const removeSlotHandler = (slotIndex) => {
    let data = removeSlots(slotIndex, selectedSlots);
    if (data) setSelectedSlots(data);
  };

  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(
    MediaUploadLoaderContext
  );

  const submitButtonHandler = async () => {
    try {
      openMediaUploadLoader();
      let slot = selectedSlots;

      if (
        !isLeaveOnSameDay ||
        (isLeaveOnSameDay && (!slot || slot.length === 0))
      ) {
        if (slotType === SlotType[0].value)
          slot = [
            {
              start: {
                hour: 5,
                minute: 30,
              },
              end: {
                hour: 14,
                minute: 30,
              },
            },
          ];

        if (slotType === SlotType[1].value && slot.length === 0)
          slot = [
            {
              start: {
                hour: 14,
                minute: 30,
              },
              end: {
                hour: 22,
                minute: 30,
              },
            },
          ];
        if (slot.length === 0)
          slot = [
            {
              start: {
                hour: 5,
                minute: 30,
              },
              end: {
                hour: 22,
                minute: 30,
              },
            },
          ];
      }

      if (addAction) {
        try {
          await addLeaveHandler(
            {
              startDate,
              endDate,
              status,
              walkerId: walker?.id,
              isLeaveOnSameDay,
              selectedSlots: slot,
              reason,
              isPenaltyRemoved,
            },
            dispatch,
            accessToken
          );
          onClose();
          closeMediaUploadLoader();
          reloadLeaves();
        } catch (err) {
          closeMediaUploadLoader();
        }
      } else {
        try {
          await editLeaveHandler(
            {
              startDate,
              endDate,
              status,
              walkerId: walker?.id,
              isLeaveOnSameDay,
              selectedSlots: slot,
              reason,
              isPenaltyRemoved,
            },
            dispatch,
            accessToken,
            currentLeave
          );
          onClose();
          closeMediaUploadLoader();
          reloadLeaves();
        } catch (err) {
          closeMediaUploadLoader();
        }
      }
    } catch (err) {
      closeMediaUploadLoader();
      console.log(err);
    }
  };

  const addSlotHandler = (slot) => {
    let data = addSlots(slot, dispatch, selectedSlots);
    if (data) setSelectedSlots(data);
  };

  function splitTimeRange(start, end) {
    const interval = 1;
    const result = [];
    let i = start.hour;

    while (i < end.hour) {
      let isFive30Time = i === 5 && start.minute === 30;
      let isTen30Time = i === 21 && end.minute === 30;

      const startHour = i;
      const endHour = isFive30Time
        ? Math.min(i + interval, end.hour) + 1
        : Math.min(i + interval, end.hour);

      result.push({
        start: {
          hour: startHour,
          minute: isFive30Time ? start.minute : 0,
        },
        end: {
          hour: endHour,
          minute: isTen30Time ? end.minute : 0,
        },
      });

      i = i + (isFive30Time ? interval + 1 : interval);
    }

    return result;
  }

  const getWalkerSchedule = () => {
    let schedule = [];
    let walkerSchedule =
      (walker?.schedule || []).find((schedule) => schedule.day === WeekDay.MONDAY)
        ?.data || [];

    walkerSchedule.forEach((time) => {
      schedule.push(...splitTimeRange(time.start, time.end));
    });
    schedule = schedule.map((sch)=>{
      return {
        start:sch.start.hour,
        startMinute:sch.start.minute,
        end:sch.end.hour,
        endMinute:sch.end.minute
      }
    })

    return schedule;
  }

  useEffect(()=>{
   if(addAction) setSelectedSlots([])
  },[walker])

  return (
    <>
      <ModalTitle title={"Add/Edit Leave"} onClose={onClose} />
      <div
        className="card-container"
        style={{
          padding: "1rem 1rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
        }}
      >
        {!editAction && (
          <>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <Autocomplete
                disablePortal
                id="walker-state"
                options={walkers}
                sx={{ width: "100%" }}
                value={walker?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value || value === ""
                }
                disableClearable={true}
                getOptionLabel={(option) => option.name ?? option}
                onChange={(event, value) => setWalker(value)}
                renderInput={(params) => (
                  <form autoComplete="off">
                    <TextField {...params} label="Select Walker" required />
                  </form>
                )}
              />
            </div>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <TextField
                id="reasonId"
                label="Add Reason"
                type="text"
                disabled={editAction}
                style={{ width: "100%" }}
                defaultValue={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </>
        )}
        {currentLeave?.status !== WalkerLeavesStatus.APPROVED && !addAction && (
          <div className="dash-filter filter" style={{ width: "100%" }}>
            <Autocomplete
              disablePortal
              id="combo-box"
              options={LeavesStatus}
              sx={{ width: "100%" }}
              value={status || ""}
              isOptionEqualToValue={(option, value) =>
                option === value || value === ""
              }
              onChange={(event, value) => {
                setStatus(value);
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Select Status" />
              )}
            />
          </div>
        )}
        <div className="dash-filter filter" style={{ width: "100%" }}>
          <TextField
            id="startDate"
            label="Start Date"
            type="date"
            style={{ width: "100%" }}
            defaultValue={startDate}
            onChange={(e) => {
              setSlotType("");
              setStartDate(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: formatDate(new Date()), 
              max: endDate ? formatDate(new Date(endDate)) : null,
            }}
          />
        </div>
        <div className="dash-filter filter" style={{ width: "100%" }}>
          <TextField
            id="endDate"
            label="End Date"
            type="date"
            style={{ width: "100%" }}
            defaultValue={endDate}
            onChange={(e) => {
              setSlotType("");
              setEndDate(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: startDate ? formatDate(new Date(startDate).setDate(new Date(startDate).getDate())) : formatDate(new Date()),
            }}
          />
        </div>
        {isLeaveOnSameDay ? (
          <>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              {addAction && (
                <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel id="select-input-id">Select Slot Type</InputLabel>
                  <Select
                    value={slotType}
                    labelId="colorSelectLabel"
                    disabled={!addAction}
                    id="colorSelect"
                    style={{ width: "100%" }}
                    onChange={(event) => {
                      setSelectedSlots([]);
                      setSlotType(event.target.value);
                    }}
                  >
                    {SlotType?.map((type) => {
                      return (
                        <MenuItem value={type.value}>{type.label}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </div>
            {slotType === SlotType[3]?.value && (
              <div className="dash-filter filter" style={{ width: "100%" }}>
                <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
                  <InputLabel id="select-input-id">Select Slot</InputLabel>
                  <Select
                    value=""
                    labelId="colorSelectLabel"
                    disabled={!addAction}
                    id="colorSelect"
                    style={{ width: "100%" }}
                    onChange={(event) => {
                      addSlotHandler(event.target.value);
                    }}
                  >
                    {getWalkerSchedule().map((slot) => {
                      return (
                        !isSlotSelected(slot, selectedSlots) && (
                          <MenuItem value={slot}>{`${slot.start}:${
                            slot.startMinute === 0 ? "00" : slot.startMinute
                          } - ${slot.end}:${
                            slot.endMinute === 0 ? "00" : slot.endMinute
                          }`}</MenuItem>
                        )
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            )}
          </>
        ) : (
          <></>
        )}{" "}
        {isLeaveOnSameDay && (
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              flexWrap: "wrap",
              margin: "5px",
              marginLeft: "2rem",
            }}
          >
            {selectedSlots.map((slot, index) => (
              <Chip
                label={`${slot.start.hour}:${
                  slot.start.minute === 0 ? "00" : slot.start.minute
                } - ${slot.end.hour}:${
                  slot.end.minute === 0 ? "00" : slot.end.minute
                } ${addAction ? "✖" : ""}`}
                onClick={() => addAction && removeSlotHandler(index)}
                color="success"
                style={{
                  margin: "5px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  minWidth: "0",
                  background:
                    "linear-gradient(90deg, #ff512f 0%, #f09819 100%)",
                }}
              />
            ))}
          </div>
        )}
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPenaltyRemoved}
                onChange={(e) => {
                  setisPenaltyRemoved(e.target.checked);
                }}
              />
            }
            label="Want to Remove Penalty ?"
          />
        </div>
        <Button
          style={
            !walker || !status || !startDate || !endDate || !reason
              ? classes.btn
              : classes.enabledBtn
          }
          disabled={!walker || !status || !startDate || !endDate || !reason}
          onClick={submitButtonHandler}
        >
          {addAction ? "Create" : "Update"}
        </Button>
      </div>
    </>
  );
}
