import { Helmet } from 'react-helmet-async'

export default function HomeMeta() {
  return (
    <>
        <Helmet>
        <title>Sploot - Home</title>
        <meta name="description" content="Parenting dogs can be tough, but it’s the best journey to undertake. Join the sploot community to get answers from experienced dog parents and canine experts. 
            If you’re looking for a no-stress solution to dog food or dog walking - we got you covered for that too. Join us in helping everyone become a better dog parent one step at a time!" />
      <meta name="keywords"
        content="dog parenting, dog care, dog community, pet care, pet parent, pet community, dog training, dog nutrition, dog queries, dog resources, dog services, dog walking, dog food, dog boarding, pet boarding"
        data-rh="true" />
      </Helmet>
    </>
  )
}
