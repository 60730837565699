import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import external_links from "../../data/external_links";
import "./OpenInAppDialog.css";
import { deeplinkEntities } from "../../redux/constants";
import { useSelector } from "react-redux";

const classes = {
  muiBtn: {
    borderRadius: "50px",
    padding: "0.7rem 1rem",
    textTransform: "Capitalize",
    overflow: "hidden",
    margin: 0,
    color: "white",
    backgroundColor: "#0B6FF9",
  },
};

export default function OpenInAppDialog({ dialogBtnText = "Open in app", openStore = false, deeplinkEntity }) {
  const mobileDevice = useMediaQuery("(max-width:768px)");
  const [dialogOpen, setDialogOpen] = useState(false);
  const { discuss:{ separatePostID }, events:{separateEventData} } = useSelector(state => state)
  const location = useLocation();

  const openAppInStore = () => {
    function iOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    }

    if (iOS()) {
      window.location.href = external_links.splootIosApp;
      return;
    }

    //UTM
    const query = encodeURIComponent(location.search?.substring(1));

    window.location.href = `${external_links.splootAndroidApp}${query}`;
  };

  const openEntityInApp = () => {
    switch(deeplinkEntity){
      case deeplinkEntities.FEED:
        window.location.href = `https://app.sploot.space/posts/${separatePostID}`;
        break;
      case deeplinkEntities.EVENT:
        window.location.href = `https://app.sploot.space/events/${separateEventData.id}/${separateEventData.slug}`;
        break;
      default:
    } 
  };

  useEffect(() => {
    mobileDevice && setDialogOpen(true);
  }, [mobileDevice, location]);

  return (
    dialogOpen && (
      <div className="open-app-dialog">
        <div className="open-app-dialog-bg"></div>
        <h3>There is more in the app</h3>
        <Button style={classes.muiBtn} onClick={openStore ? openAppInStore : openEntityInApp}>
          {dialogBtnText}
        </Button>
        {/* <div className="bottom-actions" onClick={openPostInApp}>
          <span>I already have the app</span>
          <span onClick={() => setDialogOpen(false)}>Stay in browser</span>
        </div> */}
      </div>
    )
  );
}