import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../utils/cookies";
import { setAccountRole } from "../redux/actions";
import { setUserAccessToken } from "../redux/actions/user";
import { accountRoles } from "../redux/constants";
import env from "../environment/production.json";

// return [redirect?,navigate?,auth]
const response = (
  redirectRequired,
  redirectPath,
  customRenderRequired,
  customRenderText,
  navigateRequired,
  path,
  auth
) => [
  {
    required: redirectRequired,
    path: redirectPath,
  },
  {
    required: customRenderRequired,
    text: customRenderText,
  },
  {
    required: navigateRequired,
    path,
  },
  auth,
];

const getRootHost = (host) => {
  if (!host) return "sploot.space";

  let hostArr = host.split(".");
  return hostArr.slice(hostArr.length - 2, hostArr.length).join(".");
};

export default function useMixedAuth(subdomain, path) {
  const { onboardComplete } = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const [authResponse, setAuthResponse] = useState(
    response(false, "", false, "", false, "", false)
  );

  useEffect(() => {
    const host = getRootHost(window.location.host);

    //IF User is Authenticated
    let userAccessToken = getCookie("userAccessToken");
    if (userAccessToken) {
      if (subdomain === env.REACT_APP_SUBDOMAIN) {
        dispatch(setUserAccessToken(userAccessToken));
        dispatch(setAccountRole(accountRoles.REGISTERED_USER));
        setAuthResponse(
          response(
            false,
            "",
            !onboardComplete,
            "",
            false,
            "",
            true
          )
        );
        return;
      }

      // redirect to app if any other subdomain including invalid ones
      setAuthResponse(
        response(
          false,
          `${window.location.protocol}//${env.REACT_APP_SUBDOMAIN}.${host}${path}${window.location.search}`,
          true,
          "",
          false,
          "",
          true
        )
      );
      return;
    }

    // fails authentication with any other subdomain including invalid ones
    if (subdomain === env.REACT_APP_SUBDOMAIN) {
      setAuthResponse(
        response(
          false,
          `${window.location.protocol}//${window.location.host}${path}${window.location.search}`,
          false,
          "",
          false,
          "",
          false
        )
      );
      return;
    }

    // fails authentication
    setAuthResponse(response(false, "", false, "", false, "", false));
  }, []);

  return authResponse;
}
