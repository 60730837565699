import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { useContext } from 'react';
import { SubscriptionsContext } from '../../sales/Users/Users';
import { UserProductCancellationReason, UserProductCreditStatus, WalkSlotDuration } from '../../redux/constants';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export default function SalesSubscriptionDisplay({ subscription, onClick, cancelTrialClickHandler = () => null }) {
  const { subscriptionUserClickHandler } = useContext(SubscriptionsContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const ITEM_HEIGHT = 38;
  const controlsOpen = Boolean(anchorEl);

  const handleControlsClick = (event) => {
    setAnchorEl(event.currentTarget);
    event?.stopPropagation()
  };
  const handleCloseControls = (event) => {
    setAnchorEl(null);
    event?.stopPropagation()
  };

  const handleWalkControlSelect = (event, control) => {
    event.stopPropagation()
    handleCloseControls(event);
    
    switch(control){
        case UserProductCreditStatus.CANCELLED:
            cancelTrialClickHandler(subscription);
          break;
        default:
          console.log("option invalid!")
    }
  }

  const formattedSlotDuration = (subscription?.seedSession?.slotDuration / (60*1000)) || (WalkSlotDuration.MEDIUM / (60*1000));

  const getWalkDate = () => {
    const { seedSession } = subscription;
    if (!seedSession || !seedSession.schedule?.length) return "-";

    const lowestTimeStamp = Math.min(...seedSession.schedule);

    return (new Date(lowestTimeStamp).toDateString().slice(3))
  }
  
  return (
    <div className='users-info-header info-card pending-row' onClick={onClick} key={subscription.id}>
      <span className='subscription-type'>{subscription.plan?.name || (subscription.trial ? "Trial" : "-")} | {`${formattedSlotDuration} m`}</span>
      <span className='walker-name' >{(new Date(subscription.createdAt).toDateString()).slice(3)}</span>
      <span className='walker-name' >{getWalkDate()}</span>
      <span className='walker-name' >{subscription.user?.name || subscription.user?.email || "Anonymous"}</span>
      {/* <span className='walker-name' >{subscription.subscription?.creatorType === accountRoles.SALES ? "Dashboard" : "AutoPay"}</span> */}
      <span className='walker-name' >{subscription?.source ? subscription?.source : "-"}</span>
      <span className='walker-name' style={{ display: 'flex', alignItems: 'center' }}><EditIcon style={{ color: '#FF5F81' }}
        onClick={(e) => {
          subscriptionUserClickHandler(subscription.user)
          e.stopPropagation()
        }} /> {subscription.user?.subCityAssigned?.name || "-"}</span>
      <span className='walker-name' >{subscription.trial ? (subscription?.orderId ? 'ORDER_TRIAL' : 'FREE_TRIAL') : (subscription?.orderId ? 'ORDER' : 'SUBSCRIBED')}</span>
      <span>
     {subscription.cancellationReason ?  <Tooltip placement="top" title={subscription.cancellationReason === UserProductCancellationReason.OTHER ? subscription.cancellationReason  + " - " + subscription.cancellationDescription: subscription.cancellationReason}>
                    <InfoIcon />
                </Tooltip>: <div className="edit-btn" onClick={handleControlsClick}>
                <p>Edit</p>
      </div>}
      </span>
      <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={controlsOpen}
            onClose={handleCloseControls}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
         <MenuItem
                key={UserProductCreditStatus.CANCELLED}
                onClick={(event) => handleWalkControlSelect(event,UserProductCreditStatus.CANCELLED)}
              >
                Cancel
              </MenuItem>
          </Menu>
    </div>
  )
}
