import { useEffect, useState } from "react";
import "./SalesWalkCard.css";
import ModalTitle from "../../common/ModalTitle/ModalTitle";
import WalkDetailsCard from "../../common/WalkDetailsCard/WalkDetailsCard";

const defaultUserImage =
  process.env.PUBLIC_URL + "/assets/images/pet-parent-avatar.webp";

const SalesWalkDetailsModal = ({ walk, onClose }) => {
  const [pets, setPets] = useState([]);

  useEffect(() => {
    walk?.petIds.map((petId) => {
      let petDetails = walk.user.petProfiles.filter(
        (pet) => pet._id === petId
      )[0];
      setPets((prevState) => [...prevState, petDetails]);
    });
  }, [walk]);

  return (
    <>
      <div className="info-card-wrapper">
        <ModalTitle title={"Walk Details"} onClose={onClose} />
        <WalkDetailsCard
          plan={walk?.planCode || "TRIAL"}
          status={walk?.status}
          walkSnapshortUrl={walk?.snapshotUrl}
          walkDistance={walk?.distance / 1000 + "Km"}
          walkStartTime={new Date(walk?.startTime).toLocaleString([], {
            hour: "2-digit",
            minute: "2-digit",
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })}
          walkEndTime={new Date(walk?.endTime).toLocaleString([], {
            hour: "2-digit",
            minute: "2-digit",
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })}
          trial={walk?.trial}
          otp={walk?.otpForTrial}
          feedback={walk?.feedback}
          feedbackContent={walk?.feedback?.content || "-"}
          walkRating={walk?.feedback?.rating || "-"}
          walkerPositiveQuality={walk?.feedback?.walkerPositiveQuality}
          walkerNegativeQuality={walk?.feedback?.walkerNegativeQuality}
          pets={pets}
        />
      </div>
    </>
  );
};

export default SalesWalkDetailsModal;
