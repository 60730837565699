import { setActionNotif } from "../../redux/actions";
import { SalesCity, SourceFilters } from "../../redux/constants";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { getUserByPhoneNumber } from "../../redux/dataFetchers/appUserApis";
import { request } from "../../utils/apiRequest"

export const getWalksByFilter = async (rest) => {
    console.log("this is what i wanted to look at",rest);
    const {
        accessToken, dispatch,
        slotStartTime, slotEndTime, subCityId, delayed, sortAscending,replaced,unassignedWalker,
        // filtersApplied,
        walkerId, walkingSessionsType, userPhoneNumber, cityFilter = '', status,
        page, limit, stateCode, districtId, pinCode, exportData, subscriptionType, trialType, cancelReasonFilter, isLeave, isPenalty,
        hubIds, cityCode, requestId
     } = rest;
    let user;
    // if (filtersApplied?.userPhoneNumber && userPhoneNumber) {
    if (userPhoneNumber) {
        user = await getUserByPhoneNumber(userPhoneNumber, accessToken, (data) => data, (error) => {
            dispatch(setActionNotif({
                open: true,
                message: "User with Phone not found!",
                severity: "error"
            }))
            throw new Error("User with phone not found : ", error)
        })
    }
    
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        body:JSON.stringify({
            // ...(filtersApplied.slot && slotStartTime && slotEndTime) && {
                ...(slotStartTime && slotEndTime) && {
                slotStartTime,
                slotEndTime
            },
            ...subscriptionType && {subscriptionType},
            ...trialType && {trialType},
            ...unassignedWalker && {unassignedWalker},
            ...(delayed === true) && {
                delayed: true
            },
            ...(cancelReasonFilter?.length && {
                cancelReasonFilter
            }),
            ...(replaced === true) && {
                replaced:true   
            },
            ...isLeave && {
                isLeave
            },
            ...isPenalty && {
                isPenalty
            },
            ...(subCityId) && {
                subCityId
            },
            ...(walkerId) && {
                walkerId
            },
            ...(walkingSessionsType) && {
                walkingSessionsType
            },
            ...(userPhoneNumber && user) && {
                userId: user.id
            },
            ...(cityFilter) && {
                ...(cityFilter === SalesCity.DELHI) && {
                    stateName: cityFilter
                },
                ...(cityFilter && cityFilter !== SalesCity.DELHI) && {
                    cityName: cityFilter
                },
            },
            ...(status) && {
                status
            },
            source: SourceFilters.DASHBOARD,
            sortAscending: sortAscending && true,
            ...stateCode && { stateCode }, 
            ...districtId && { districtId }, 
            ...pinCode && {pinCode},
            ...hubIds?.length && { hubIds },
        ...cityCode?.length && { cityCode },
            ...requestId && { requestId }
        })
    };

    const requestUrl = `${nodeServerBaseUrl}/walking-sessions?page=${page}&limit=${limit}` + (exportData ? "&exportData=true" : "")

    return request(requestUrl,
        requestOptions,
        async (response) => {
            return {data: response.data?.data, totalCount: response?.data?.totalCount};
        },
        async (error) => {
            dispatch(setActionNotif({
                open: true,
                message: "Something went wrong!",
                severity: "error"
            }))
            throw error;
        })
}
export const fetchDistrictsHandler = async (accessToken, value) => {
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
    };
    
    return await request(
      `${nodeServerBaseUrl}/districts?stateCode=` + value?.code,
      requestOptions,
        (response) => {
          return response?.data?.data;
      }
    );

  };

export const getOnTimeAnalyticsUsers = async ( accessToken, dispatch, page, limit, start, end) => {
    try{
        let responseData = null;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        await request(`${nodeServerBaseUrl}/walking-sessions/on-time-walks/users?page=${page}&limit=${limit}&start=${start}&end=${end}`, requestOptions,
        (response) => {
            responseData =  response.data;
        })
        return responseData;
      }catch(err){
        console.log(err)
        return dispatch(setActionNotif({
          open: true,
          message: "Something went wrong !",
          severity: "error",
       }));
      }
}

export const getOnTimeAnalyticsData = async (accessToken, dispatch) => {
    try{
        let responseData = null;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        await request(`${nodeServerBaseUrl}/walking-sessions/on-time-walks/statistics`, requestOptions,
        (response) => {
            responseData =  response.data.data;
        })
        return responseData;
      }catch(err){
        console.log(err)
        return dispatch(setActionNotif({
          open: true,
          message: "Something went wrong !",
          severity: "error",
       }));
      }
}

export const getStatistics = async (accessToken, data, dispatch) => {
    try{
        let responseData = null;
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body:JSON.stringify(data)
        };

        await request(`${nodeServerBaseUrl}/walking-sessions/statistics`, requestOptions,
        (response) => {
            responseData =  response.data.data;
        })
        return responseData;
      }catch(err){
        console.log(err)
        return dispatch(setActionNotif({
          open: true,
          message: "Something went wrong !",
          severity: "error",
       }));
      }
}
export const updateWalkerPenalty = async (dispatch, accessToken, walkerId, isPenaltyAdded, sessionId) => {
    try{
        const requestOptions = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body:JSON.stringify({
                isPenaltyAdded,
                walkerId
            })
        };

        await request(`${nodeServerBaseUrl}/walking-sessions/${sessionId}/Penalty`, requestOptions,
        (response) => {
           
        })
        return dispatch(setActionNotif({
            open: true,
            message:   `Penalty ${isPenaltyAdded?"Added":"Remove"} Successfully!`,
            severity: "success",
         }));
      }catch(err){
        console.log(err)
        return dispatch(setActionNotif({
          open: true,
          message: "Something went wrong !",
          severity: "error",
       }));
      }
}

export const callMaskingExotel = async(rest)=>{
        const {from,to,accessToken,dispatch} = rest;

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body:JSON.stringify({from,to})
        
        };
        const requestUrl = `${nodeServerBaseUrl}/exotel/call-masking`;

        return request(requestUrl,
        requestOptions,
        async (response) => {
            return `Made an Exotel call with ${response}` 
        },
        async (error) => {
            dispatch(setActionNotif({
                open: true,
                message: "Something went wrong!",
                severity: "error"
            }))
            throw error;
        })
}