import { notificationsType } from "../../sales/Walks/Walks";
import { request } from "../../utils/apiRequest";
import { setAccountRole, setActionNotif, setInitialAppLoad } from "../actions";
import { setAppSalesPersonData, setSubCities, setSalesAccessToken, setCustomCities } from "../actions/sales";
import { accountRoles, EditWalkMode, RescheduleWalksType, SubscriptionCancellationMode, SubscriptionsFilters, walkerFilterType } from "../constants";
import { nodeServerBaseUrl } from "./apiUrls";

export const getAppSalesPersonData = (salesAccessToken, navigate) =>
  async (dispatch, getState) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${salesAccessToken}`,
      },
    };

    try {
      await request(
        `${nodeServerBaseUrl}/user`,
        requestOptions,
        async (response) => {
          let userData = response.data?.data;
          userData = {
            ...userData,
            petProfiles: userData?.petProfiles?.filter(pet => !pet?.isDeleted)
          }

          dispatch(setSalesAccessToken(salesAccessToken))
          dispatch(setAppSalesPersonData(userData));
          dispatch(setAccountRole(accountRoles.SALES))
          dispatch(setInitialAppLoad(false))

          console.log({ pathname: window.location.pathname })
          if (window.location.pathname.split("/")[2] === "login")
            navigate("/sales/dashboard");
        }
      )
    } catch (error) {
      console.log("Application Error ", { error })
    }
  };

export const getSubCities =
  () =>
    async (dispatch, getState) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`
        },
      };

      await request(
        `${nodeServerBaseUrl}/subcities`,
        requestOptions,
        async (response) => {
          let data = response.data?.data;
          console.log({ subCities: data });

          dispatch(setSubCities(data));
        }
      )
    };

export const addWalker =
  (...info) =>
    async (dispatch, getState) => {
      const [details, closeWalkerProfile, setLoadingWalkers] = info;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify(details),
      };

      await request(
        `${nodeServerBaseUrl}/walkers`,
        requestOptions,
        async (response) => {
          dispatch(setActionNotif({
            open: true,
            message: "Walker Created!",
            severity: "success"
          }))

          closeWalkerProfile()
          setLoadingWalkers(true)
        },
        async (error) => {
          dispatch(setActionNotif({
            open: true,
            message: error?.message || "Something Went Wrong!",
            severity: "error"
          }))
        }
      )
    };

export const updateWalker =
  (...info) =>
    async (dispatch, getState) => {
      const [walkerId, details, closeWalkerProfile, setLoadingWalkers] = info;

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify(details),
      };

      await request(
        `${nodeServerBaseUrl}/walkers/${walkerId}`,
        requestOptions,
        async (response) => {

          dispatch(setActionNotif({
            open: true,
            message: "Walker Updated!",
            severity: "success"
          }))

          closeWalkerProfile()
          setLoadingWalkers(true)
        },
        async (error) => {
          dispatch(setActionNotif({
            open: true,
            message: error?.message || "Something Went Wrong!",
            severity: "error"
          }))
        }
      )
    };

export const assignWalker =
  ({
    userId, walkId, walkerId, replacedWalkerData, closeEditWalkModal, reloadWalks, closeMediaUploadLoader
  }) =>
    async (dispatch, getState) => {

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify({
          walkerId,
          ...replacedWalkerData && { replacedWalkerData }
        }),
      };

      await request(
        `${nodeServerBaseUrl}/users/${userId}/walking-sessions/${walkId}?updateType=WALKER_REPLACED`,
        requestOptions,
        async (response) => {

          dispatch(setActionNotif({
            open: true,
            message: "Walker Assigned!",
            severity: "success"
          }))

          closeEditWalkModal()
          reloadWalks()
          closeMediaUploadLoader();
        },
        async (error) => {
          dispatch(setActionNotif({
            open: true,
            message: "Something Went Wrong!",
            severity: "error"
          }))
          closeMediaUploadLoader()
        }
      )
    };

export const updateSelectedWalkingSessionsOfUser =
  ({ updateType, userId, walkerId, subscriptionId,rescheduleWalksType, orderId, replacedWalkerData, petIds, scheduledAt, newScheduledAt, onSuccess, onFailure, startDateEpoch, endDateEpoch, walkerListFilterType }) =>
    async (dispatch, getState) => {
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify({
          walkerId,
          ...subscriptionId && { subscriptionId },
          ...orderId && { orderId },
          petIds,
          scheduledAt,
          ...replacedWalkerData && { replacedWalkerData },
          ...(updateType === EditWalkMode.WALK_RESCHEDULED) && {newScheduledAt},
          ...(startDateEpoch && endDateEpoch && (walkerListFilterType === walkerFilterType.TIME_RANGE_WALKS || rescheduleWalksType === RescheduleWalksType.TIME_RANGE_WALKS) ? {
              startTime: startDateEpoch,
              endTime: endDateEpoch
          }:{})
        }),
      };

      console.log({requestOptions})
      await request(
        `${nodeServerBaseUrl}/users/${userId}/walking-sessions?updateType=${updateType}`,
        requestOptions,
        onSuccess,
        onFailure
      )
    };

export const editSubscribedUser =
  (...info) =>
    async (dispatch, getState) => {
      const [userId, details, onSuccess, onFailure] = info;
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify(details),
      };

      await request(
        `${nodeServerBaseUrl}/users/${userId}`,
        requestOptions,
        async (response) => {
          onSuccess()
        },
        async (error) => {
          console.log({ error })
          onFailure()
        }
      )
    };

export const createUserSubscription =
  (...info) =>
    async (dispatch, getState) => {
      console.log('create subscription called');
      const [subscription, userId, onSubscribeUserSuccess, onSubscribeUserFailure] = info;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify(subscription),
      };

      await request(
        `${nodeServerBaseUrl}/users/${userId}/subscriptions`,
        requestOptions,
        async (response) => {
          onSubscribeUserSuccess(response)
        },
        async (error) => {
          console.log(error)
          onSubscribeUserFailure(error)
        }
      )
    };

    export const bookSubscription =
  (...info) =>
    async (dispatch, getState) => {
      const [subscription, onSubscribeUserSuccess, onSubscribeUserFailure] = info;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify(subscription),
      };

      await request(
        `${nodeServerBaseUrl}/auto-matching/book`,
        requestOptions,
        async (response) => {
          onSubscribeUserSuccess(response)
          dispatch(setActionNotif({
            open: true,
            message: "Sessions Booked",
            severity: "success"
          }))
        },
        async (error) => {
          onSubscribeUserFailure(error);
          dispatch(setActionNotif({
            open: true,
            message: "Something Went Wrong!",
            severity: "error"
          }))
        }
      )
    };

export const extendUserSubscription =
  (...info) =>
    async (dispatch, getState) => {
      const [subscription, userId, subscriptionId, onSubscribeUserSuccess,
        onSubscribeUserFailure] = info;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify(subscription),
      };

      await request(
        `${nodeServerBaseUrl}/users/${userId}/subscriptions/${subscriptionId}/extend`,
        requestOptions,
        async (response) => {
          onSubscribeUserSuccess(response);
        },
        async (error) => {
          onSubscribeUserFailure(error)
        }
      )
    };

export const bookWalkingSessionForSubscription =
  (...info) =>
    async (dispatch, getState) => {
      const [sessionDetails, userId, closeEditSubscriptionModal, setLoadingSubscriptions, closeMediaUploadLoader] = info;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify(sessionDetails),
      };

      await request(
        `${nodeServerBaseUrl}/users/${userId}/walking-sessions`,
        requestOptions,
        async (response) => {

          dispatch(setActionNotif({
            open: true,
            message: "Sessions Booked",
            severity: "success"
          }))
          closeEditSubscriptionModal()
          setLoadingSubscriptions(true);
          closeMediaUploadLoader();
        },
        async (error) => {
          dispatch(setActionNotif({
            open: true,
            message: "Something Went Wrong!",
            severity: "error"
          }))
          closeMediaUploadLoader();
        }
      )
    };

export const updateWalk =
  (updateWalkDto) =>
    async (dispatch, getState) => {
      const { userId, walkId, body, onSuccess } = updateWalkDto;

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify(body),
      };

      await request(
        `${nodeServerBaseUrl}/users/${userId}/walking-sessions/${walkId}?${body.updateType ? 'updateType=' + body.updateType : ''}`,
        requestOptions,
        async (response) => {

          dispatch(setActionNotif({
            open: true,
            message: "Walk Updated!",
            severity: "success"
          }))

          onSuccess()
        },
        async (error) => {
          dispatch(setActionNotif({
            open: true,
            message: "Something Went Wrong!",
            severity: "error"
          }))
        }
      )
    };

export const salesTriggerNotifications = (walk, selectedNotification) =>
  async (dispatch, getState) => {
    const { scheduledAt, ...walkDetails } = walk;

    let url, body;

    switch (selectedNotification) {
      case notificationsType.USER_UNREACHABLE:
        url = `${nodeServerBaseUrl}/walking-sessions/${walk.id}/notification/customer-unreachable`;
        body = {
          userId: walk.user.id,
          walkerName: walk.walker.name
        };
        break;
      case notificationsType.WALKER_LATE:
        url = `${nodeServerBaseUrl}/walking-sessions/${walk.id}/notification/walker-late`;
        body = {
          userId: walk.user.id,
          walkerName: walk.walker.name
        };
        break;
      case notificationsType.WALK_REMINDER:
        url = `${nodeServerBaseUrl}/walking-sessions/${walk.id}/notification`;
        body = {
          scheduledAt,
          userName: walkDetails.user.name,
          walkerId: walkDetails.walker.id
        };
        break;
      default:
        return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().sales.accessToken}`,
      },
      body: JSON.stringify(body),
    };

    await request(
      url,
      requestOptions,
      async (response) => {

        dispatch(setActionNotif({
          open: true,
          message: `Notification triggered for ${selectedNotification}`,
          severity: "success"
        }))
      },
      async (error) => {
        dispatch(setActionNotif({
          open: true,
          message: "Something Went Wrong!",
          severity: "error"
        }))
      }
    )
  }

export const cancelSubscription =
  (info) =>
    async (dispatch, getState) => {
      const {userId, subscriptionId, cancellationMode, cancellationDescription, cancellationReason, reloadSubscriptions, closeCancelSubscriptionModal, setCancellationProcessing} = info;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify({
          mode: cancellationMode,
          reason: cancellationReason,
          ...(cancellationDescription ? {description: cancellationDescription} : {})
        }),
      };

      setCancellationProcessing(true)

      await request(
        `${nodeServerBaseUrl}/users/${userId}/subscriptions/${subscriptionId}/cancel`,
        requestOptions,
        async (response) => {

          dispatch(setActionNotif({
            open: true,
            message: cancellationMode === SubscriptionCancellationMode.CANCEL_AT_CYCLE_END ? "Subscription Cancellation Scheduled!" : "Subscription Cancelled!",
            severity: "success"
          }))

          closeCancelSubscriptionModal()
          setCancellationProcessing(false)
          reloadSubscriptions();
        },
        async (error) => {
          setCancellationProcessing(false)
          dispatch(setActionNotif({
            open: true,
            message: "Something Went Wrong!",
            severity: "error"
          }))
        }
      )
    };

export const updateSubscription =
  (...info) =>
    async (dispatch, getState) => {
      const [userId, subscriptionId, updateSubscriptionBody, reloadSubscriptions, closeCompleteSubscriptionModal, setProcessing] = info;

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().sales.accessToken}`,
        },
        body: JSON.stringify({
          status: updateSubscriptionBody.status
        })
      };

      setProcessing(true)

      await request(
        `${nodeServerBaseUrl}/users/${userId}/subscriptions/${subscriptionId}`,
        requestOptions,
        async (response) => {

          dispatch(setActionNotif({
            open: true,
            message: "Subscription Completed!",
            severity: "success"
          }))

          closeCompleteSubscriptionModal()
          setProcessing(false)
          reloadSubscriptions();
        },
        async (error) => {
          setProcessing(false)
          dispatch(setActionNotif({
            open: true,
            message: "Something Went Wrong!",
            severity: "error"
          }))
        }
      )
    };

export const getWalkingSessionsOfWalker = async ({ walkerId, status, accessToken, dispatch }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      status
    })
  };

  return new Promise((resolve, reject) => {
    return request(`${nodeServerBaseUrl}/walkers/${walkerId}/walking-sessions?page=0&limit=1`,
      requestOptions,
      async (response) => {
        const data = response.data?.data;
        resolve(data)
        },
      async (error) => {
        dispatch(setActionNotif({
            open: true,
            message: "Something went wrong!",
            severity: "error"
        }))
        reject(error);
      })  
  })
};

export const getCustomCities = () => async (dispatch, getState) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return request(`${nodeServerBaseUrl}/custom-cities`,
    requestOptions,
    async (response) => {
      const data = response.data?.data;
      dispatch(setCustomCities(data));
      },
    async (error) => {
      dispatch(setActionNotif({
          open: true,
          message: "Something went wrong!",
          severity: "error"
      }))
  })
};

