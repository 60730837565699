import React, { useCallback } from "react";
import { FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./SearchBar.css";
import Button from "../Button/Button";
import SearchIcon from "@mui/icons-material/Search";
import LocationSearch from "../LocationSearch/LocationSearch";
import {
  setSplootFinderLocation,
  toggleSearchResultsNavigationState,
} from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

// const useStyles = makeStyles(() => ({
//   underline: {
//     color: 'green'
//   }
// }))

export default function SearchBar({
  locationInputPlaceholder,
  wrapperStyle = {},
  toolStyle = {},
  inputStyle = {},
  isLoaded = false,
}) {
  const dispatch = useDispatch();
  const splootFinder = useSelector((state) => state.splootFinder);
  // const classes = useStyles()
  const navigate = useNavigate();

  const markSearchedLocation = useCallback((latLng) => {
    dispatch(setSplootFinderLocation(latLng));
  }, []);

  const getSearchResults = (event) => {
    if (!splootFinder.location) {
      event.preventDefault();
      return;
    }

    //capture search event
    ReactGA.event({
      category: "interaction",
      action: "search_btn_hit",
      label: "Search", // optional
      nonInteraction: false, // optional
    });

    ReactPixel.trackCustom("SearchBtnHit");

    const pageNo = 1,
      location = splootFinder.address,
      category = splootFinder.category;
    navigate(
      `/near-you/listings?pageNo=${pageNo}&location=${location}&category=${category}`
    );
    dispatch(toggleSearchResultsNavigationState(!splootFinder.navigationState));
  };

  return (
    <div
      className="search-bar-wrapper"
      style={wrapperStyle}
      data-aos="fade-in"
      data-aos-once="true"
      data-aos-easing="ease-in-out"
    >
      <div className="search-tool" style={toolStyle}>
        <FormControl
          fullWidth
          style={{
            justifySelf: "center",
            alignSelf: "center",
            width: "100%",
          }}
        >
          {isLoaded ? (
            <LocationSearch
              markSearchedLocation={markSearchedLocation}
              inputStyle={inputStyle}
              splootFinderTool={true}
              placeholder={locationInputPlaceholder}
            />
          ) : (
            <input
              type="search"
              value=""
              disabled={true}
              placeholder={locationInputPlaceholder}
              style={inputStyle}
              className="search-input"
            />
          )}
        </FormControl>
        <Button
          btnStyle={{
            borderRadius: "50px",
            margin: "0.1rem 0",
            padding: "0.7rem 0.8rem",
            boxShadow: "0px 0px 2px 2px rgb(221, 63, 6)",
            zIndex: "1",
            aspectRatio: "1/1",
          }}
          text=""
          endIcon={
            <SearchIcon
              style={{
                color: "white",
                width: "1.2rem",
                fontWeight: "1.5rem",
                fontSize: "1.5rem",
              }}
            />
          }
          clickAction={getSearchResults}
        />
      </div>
    </div>
  );
}
