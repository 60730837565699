import { forwardRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DogWalkingIcon from "../../../src/assets/icons/DogWalkingIcon";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EPOCH, WalkerLeavesStatus } from "../../redux/constants";
import InfoIcon from '@mui/icons-material/Info';
import "../SalesWalkCard/SalesWalkCard.css";

const classes = {
  walkerInfoWrap: { display: "flex", alignItems: "center" },
  cancelBtn: {
    backgroundColor: "antiquewhite",
    marginRight: "0.5rem",
  },
  notifyIcon: {
    height: "1.2rem",
    width: "1.2rem",
  },
  infoIcon: {
    height: "1rem",
    width: "1rem",
    marginLeft: "0.2rem",
  },
  cancelBtnCol: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  infoIcon: {
    height: "1rem",
    width: "1rem",
    margin: "0.4rem",
    color: "gray",
  },
};

const SalesLeaveDetailCard = forwardRef(
  (
    {
      leave,
      onClick = () => null,
      deleteLeavehandler = () => null,
      editLeaveBtnClickHandler = () => null,
    },
    lastElementRef
  ) => {
    const navigate = useNavigate();

    return (
      <div
        className="walks-info-header info-header-data info-card notify"
        onClick={onClick}
        key={leave.id}
        ref={lastElementRef}
      >
        <span className="scheduled-at">{leave?.walker?.name}</span>
        <span className="scheduled-at">
          {leave.walker?.address?.city?.name || ""}
        </span>
        <span className="scheduled-at">
          {new Date(leave?.createdAt).toLocaleDateString("en-us", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </span>
        <span className="scheduled-at">
          {new Date(leave?.startDate).toLocaleDateString("en-us", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </span>
        <span className="scheduled-at">
          {new Date(leave?.endDate).toLocaleDateString("en-us", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </span>
        <span
          style={{
            ...classes.walkStatus,
            ...(leave.status === WalkerLeavesStatus.APPROVED
              ? { color: "#00B53C" }
              : leave.status === WalkerLeavesStatus.PENDING
              ? { color: "#9707CA" }
              : { color: "#D32222" }),
            display: "flex",
            alignItems: "center",
          }}
        >
          {leave?.status}
          <Tooltip placement="top" title={leave.reason}>
            <InfoIcon
              style={classes.infoIcon}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Tooltip>
        </span>
        <div className="scheduled-at">
          {leave?.status === WalkerLeavesStatus.APPROVED && (
            <IconButton
              onClick={() =>
                navigate(
                  `/sales/dashboard/walks?walkerId=${leave.walker.id}&startDate=${leave?.startDate}&endDate=${leave?.endDate}&isLeave=true`
                )
              }
            >
              <DogWalkingIcon />
            </IconButton>
          )}
          <IconButton onClick={() => editLeaveBtnClickHandler(leave)}>
            <EditIcon />
          </IconButton>
          {(leave?.status !== WalkerLeavesStatus.APPROVED || (leave.startDate > new Date().getTime() && (leave.startDate - new Date().getTime() >= 2 * EPOCH.ONE_HOUR_MS))) && (
            <IconButton onClick={() => deleteLeavehandler(leave)}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
);

export default SalesLeaveDetailCard;
