import React from "react";
import { Link } from "react-router-dom";
import "./LinksList.css";

export default function LinksList({ Links, Heading }) {
  return (
    <div className="links-list">
      <h3 className="links-heading">{Heading}</h3>
      <ul>
        {Links?.map((link, i) => {
          return (
            <li className="link" key={i}>
              {link.external ? (
                link.follow ? (
                  <a
                    href={link.link}
                    style={{
                      textDecoration: "none",
                      color: "rgb(85, 84, 84 )",
                    }}
                  >
                    {link.text}
                  </a>
                ) : (
                  <a
                    href={link.link}
                    rel="nofollow"
                    style={{
                      textDecoration: "none",
                      color: "rgb(85, 84, 84 )",
                    }}
                  >
                    {link.text}
                  </a>
                )
              ) : (
                <Link
                  to={link.link}
                  style={{ textDecoration: "none", color: "rgb(85, 84, 84 )" }}
                >
                  {link.text}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
