import React from "react";
import "./ServiceReview.css";
import StarIcon from "@mui/icons-material/Star";
import { motion } from "framer-motion";

export default function ServiceReview({ review }) {
  return (
    <motion.div className="review">
      <div>
        {review.stars.map((star, i) => (
          <StarIcon key={i} style={{ color: "#ecae0e" }} />
        ))}
      </div>
      <p>{review.text}</p>
      <span className="review-by">
        <strong>-{review.author}</strong>
      </span>
    </motion.div>
  );
}
