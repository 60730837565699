export default function MediaOutlinedIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0937 8.22829V16.7189C22.0937 19.8647 20.125 22.0835 16.9791 22.0835H7.96873C4.8229 22.0835 2.86456 19.8647 2.86456 16.7189V8.22829C2.86456 5.08245 4.83331 2.86475 7.96873 2.86475H16.9791C20.125 2.86475 22.0937 5.08245 22.0937 8.22829Z"
          stroke="#32312F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.50134 17.1154L7.09301 15.4352C7.64613 14.8487 8.56801 14.8206 9.15551 15.3727C9.17322 15.3904 10.1316 16.3644 10.1316 16.3644C10.7097 16.9529 11.6545 16.9623 12.243 16.3852C12.2816 16.3477 14.6743 13.4456 14.6743 13.4456C15.2909 12.6967 16.3982 12.5894 17.1482 13.2071C17.1982 13.2487 19.4586 15.5685 19.4586 15.5685"
          stroke="#32312F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7422 9.51361C10.7422 10.523 9.92453 11.3407 8.91516 11.3407C7.90578 11.3407 7.08807 10.523 7.08807 9.51361C7.08807 8.50423 7.90578 7.68652 8.91516 7.68652C9.92453 7.68757 10.7422 8.50423 10.7422 9.51361Z"
          stroke="#32312F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
