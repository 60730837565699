import { useRef } from "react";
import StarIcon from '@mui/icons-material/Star';
import CarouselLeftArrow from "../../assets/icons/CarouselLeftArrow";
import CarouselRightArrow from "../../assets/icons/CarouselRightArrow";
import "./SalesWalkerSelectionCarousel.css"

export default function WalkerSelectionCarousel({ walkers, walkerStyle, selectedWalkerId, onClickWalker, scroll }) {
    const walkerScrollContainer = useRef(null);

    return (
        <div className="date-picker">
            <div className="date-selector">
                <h3 style={{ color: "#000" }}>Select Walker</h3>
                <div className="dates-container">
                    <CarouselLeftArrow
                        onclick={() => scroll(walkerScrollContainer, -100)}
                    />
                    <div className="hotspot-scroll" ref={walkerScrollContainer}>
                        {walkers?.map((walker) => {
                            let rating = walker?.rating?.value / walker?.rating?.sessionCount;
                            return (
                                <div
                                    key={walker.id}
                                    style={walkerStyle}
                                    className={
                                        selectedWalkerId === walker?.id
                                            ? "active-hotspot"
                                            : "hotspot"
                                    }
                                    onClick={() => onClickWalker(walker)}
                                >
                                    {walker?.preferred && <em className="preferred-walker">
                                        preferred
                                    </em>}
                                    {walker?.name && (
                                        <>
                                            <span
                                                style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                                            >
                                                {`${walker.name}`}
                                            </span>
                                            <div style={{ display: "flex", justifyContent: "center" }}>{
                                                (walker.distance / 1000).toFixed(1)
                                            } Km - {2 * (walker.distance / 1000).toFixed(1)} Km</div>
                                            {rating && rating > 0 ? <div style={{ display: "flex", justifyContent: "center" }}>
                                                {
                                                    Array.from({ length: rating }, (_, index) => (
                                                        <StarIcon key={index} style={{ fontSize: "20px" }} />
                                                    ))
                                                }
                                            </div> : <></>}
                                        </>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <CarouselRightArrow
                        onclick={() => scroll(walkerScrollContainer, 100)}
                    />
                </div>
            </div>
        </div>
    )
}
