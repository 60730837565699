import React, { useState } from "react";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { getOnTimeAnalyticsData } from "../Walks/apis";
import { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MediaUploadLoaderContext } from "../../App";
import UsersData from "./UsersData";

const OntimeWalks = () => {
  const {
    sales: { accessToken },
  } = useSelector((state) => state);
  const [onTimeAnalyticsData, setOnTimeAnalyticsData] = useState(null);
  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(
    MediaUploadLoaderContext
  );
  const [clickedBox, setClickedBox] = useState(null);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const getUserLevelPercentage = (number) => {
    return (
      onTimeAnalyticsData?.userLevelData?.find((data) => data._id === number)
        ?.count || 0
    );
  };

  const bigboxes = [
    {
      title: "More than 95%",
      data: getUserLevelPercentage(95),
      bgColor: "#F3E0D4",
      icon: (
        <AnalyticsIcon
          style={{ position: "relative", top: "7px", left: "5px" }}
        />
      ),
      onClick: () => {
        setClickedBox({
          start: 95,
          end: 101,
        });
      },
    },
    {
      title: "90% to 95%",
      data: getUserLevelPercentage(90),
      bgColor: "#F4E4AA",
      icon: (
        <AnalyticsIcon
          style={{ position: "relative", top: "7px", left: "5px" }}
        />
      ),
      onClick: () => {
        setClickedBox({
          start: 90,
          end: 95,
        });
      },
    },
    {
      title: "less than 90%",
      data: getUserLevelPercentage(0),
      bgColor: "#EEBBBB",
      icon: (
        <AnalyticsIcon
          style={{ position: "relative", top: "7px", left: "5px" }}
        />
      ),
      onClick: () => {
        setClickedBox({
          start: 0,
          end: 90,
        });
      },
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        openMediaUploadLoader();
        const data = await getOnTimeAnalyticsData(accessToken, dispatch);
        setOnTimeAnalyticsData(data[0]);
        const endOfDay = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
        endOfDay.setHours(0, 0, 0, 0);

        const startOfDay = new Date(endOfDay);
        startOfDay.setDate(endOfDay.getDate() - 30);

        setStart(startOfDay.toLocaleString('en-us', {day:"numeric", year:"numeric", month: "long"}));
        setEnd(endOfDay.toLocaleString('en-us', {day:"numeric", year:"numeric", month: "long"}));
        closeMediaUploadLoader();
      } catch (err) {
        closeMediaUploadLoader();
      }
    })();
  }, []);

  return (
    onTimeAnalyticsData && (
      <div style={{ minHeight: "100vh" }}>
        <h2 style={{ fontSize: "1.2rem", color: "#1B2149" }}>
          ** There are{" "}
          <span style={{ color: "#FF5733", fontSize: "3rem" }}>
            {(
              ((onTimeAnalyticsData.totalCompletedWalks -
                onTimeAnalyticsData.totalDelayedWalks) /
                onTimeAnalyticsData.totalScheduledWalks) *
              100
            ).toFixed(1)} %
          </span>{" "}
           On-time Complete Walks in last 30 days ( {start} - {end} ).
        </h2>
        <table
          style={{
            width: "100%",
            marginTop: "1rem",
            borderCollapse: "collapse",
            border: "1px solid #1B2149",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "1.3rem",
                  color: "#1B2149",
                  textAlign: "left",
                  border: "1px solid #1B2149",
                  padding: "8px",
                }}
              >
                Metric
              </th>
              <th
                style={{
                  fontSize: "1.3rem",
                  color: "#1B2149",
                  textAlign: "left",
                  border: "1px solid #1B2149",
                  padding: "8px",
                }}
              >
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  fontSize: "1.2rem",
                  color: "#1B2149",
                  border: "1px solid #1B2149",
                  padding: "8px",
                }}
              >
                Total Scheduled Walks:
              </td>
              <td
                style={{
                  fontSize: "1.2rem",
                  color: "#1B2149",
                  border: "1px solid #1B2149",
                  padding: "8px",
                }}
              >
                {onTimeAnalyticsData.totalScheduledWalks}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "1.2rem",
                  color: "#1B2149",
                  border: "1px solid #1B2149",
                  padding: "8px",
                }}
              >
                Total Completed Walks:
              </td>
              <td
                style={{
                  fontSize: "1.2rem",
                  color: "#1B2149",
                  border: "1px solid #1B2149",
                  padding: "8px",
                }}
              >
                {onTimeAnalyticsData.totalCompletedWalks}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "1.2rem",
                  color: "#1B2149",
                  border: "1px solid #1B2149",
                  padding: "8px",
                }}
              >
                Total Delayed Walks:
              </td>
              <td
                style={{
                  fontSize: "1.2rem",
                  color: "#1B2149",
                  border: "1px solid #1B2149",
                  padding: "8px",
                }}
              >
                {onTimeAnalyticsData.totalDelayedWalks}
              </td>
            </tr>
          </tbody>
        </table>
        <h1 style={{ fontSize: "1.5rem" }}>
          Users with walks completed (including manually & artificially
          completed) without delay
        </h1>
        <div style={{ display: "flex", color: "#1B2149", flexWrap: "wrap" }}>
          {bigboxes.map((box, index) => {
            return (
              <div
                key={index}
                style={{
                  height: "8rem",
                  marginBottom: "1rem",
                  width: "18rem",
                  backgroundColor: box.bgColor,
                  marginRight: "1rem",
                  borderRadius: "1rem",
                  paddingLeft: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => box.onClick()}
              >
                <h2
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: "400",
                    marginBottom: 0,
                    marginTop: "1.6rem",
                  }}
                >
                  {box.title} {box.icon}{" "}
                </h2>
                <h3 style={{ fontSize: "1.3rem", marginTop: 0 }}>
                  {box.data} <span style={{ fontSize: "1rem" }}>Users</span>
                </h3>
              </div>
            );
          })}
        </div>
        {clickedBox !== null && (
          <UsersData
            setClickedBox={setClickedBox}
            clickedBox={clickedBox}
            accessToken={accessToken}
            dispatch={dispatch}
            closeMediaUploadLoader={closeMediaUploadLoader}
            openMediaUploadLoader={openMediaUploadLoader}
          />
        )}
      </div>
    )
  );
};

export default OntimeWalks;
