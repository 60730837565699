import { CircularProgress, Modal } from '@mui/material'
import React from 'react'
import "./MediaUploadLoader.css"

export default function MediaUploadLoader({open, closeLoader}) {
  return (
    <Modal open={open} onClose={closeLoader}>
        <div className='loader-bg'>
            <CircularProgress style={{color:"#974EC3"}} />
            <h3 className='uploading-text'>Uploading...</h3>
        </div>
    </Modal>
  )
}
