export default function SaveCurvedColoredIcon({
  wrapperStyle = {},
  style = {},
}) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7388 6.15368C19.7388 3.40281 17.8581 2.30005 15.1503 2.30005H8.79143C6.16687 2.30005 4.19995 3.32762 4.19995 5.97022V20.694C4.19995 21.4198 4.9809 21.877 5.61348 21.5221L11.9954 17.9422L18.3223 21.5161C18.9558 21.873 19.7388 21.4158 19.7388 20.689V6.15368Z"
          stroke="#EF7123"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.27124 9.02811H15.5895"
          stroke="#EF7123"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
