import env from "../../environment/production.json";
import Filters from "../../components/SalesLocation/Filters";
import Information from "../../components/SalesLocation/Information";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useCallback, useRef } from "react";
import { useState, useEffect } from "react";
import MarkerDetails from "../../components/SalesLocation/MarkerDetails";
import AssignAWalkerCard from "../../components/SalesLocation/AssignAWalkerCard";
import SelectHotspots from "../../components/SalesLocation/SelectHotspots";
import { Modal } from "@mui/material";

const commonBtnStyle = {
  borderRadius: "50px",
  padding: "0.5rem 0.8rem",
  textTransform: "Capitalize",
  position: "relative",
  overflow: "hidden",
  margin: 0,
  fontWeight: "normal",
};

const libraries = ["geometry"];

const classes = {
  btn: {
    ...commonBtnStyle,
    color: "#121212",
    padding: "0.5rem 1.9rem",
  },
  proceedBtn: {
    ...commonBtnStyle,
    backgroundColor: "#F2F3F5",
    color: "GrayText",
    width: "45%",
  },
  enabledProceedBtn: {
    ...commonBtnStyle,
    backgroundColor: "#0B6FF9",
    color: "white",
    width: "100%",
  },
  header: {
    textAlign: "center",
    fontWeight: "normal",
  },
  petMeta: {
    display: "flex",
    fontSize: "0.9rem",
    alignItems: "center",
  },
  mapContainerStyle: {
    width: "100%",
    height: "92vh",
    cursor: "pointer",
  },
};

const initSlotStartTime = new Date().setHours(0, 0, 0, 0),
  initSlotEndTime = new Date().setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000;

const Location = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [filters, setFilters] = useState({});
  const [assignAWalkCard, setassignAWalkCard] = useState(false);
  const [hotspots, setHotspots] = useState([]);
  const [servicableAreaHotspots, setServicableAreaHotspots] = useState([]);
  const [isAddServicableArea, setIsAddServicableArea] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [pincodes, setPincodes] = useState([]);
  const [filterSlotStartTime, setFilterSlotStartTime] =
    useState(initSlotStartTime);
  const [filterSlotEndTime, setFilterSlotEndTime] = useState(initSlotEndTime);
  const mapRef = useRef(null);
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    // mapRef.current.planTo({lat: 28.458487416246758, long: 76.85782425105572})
  }, []);

  useEffect(() => {
    setTimeout(() => {
      panTo({ lat: 28.4595, lng: 77.0266 });
    }, 1000);
  }, []);

  useEffect(() => {
    if (hotspots.length !== 0) {
      panTo({
        lat: hotspots[0]?.location?.coordinates[1] || 28.4595,
        lng: hotspots[0]?.location?.coordinates[0] || 77.0266,
      });
    }
  }, [hotspots]);

  const addServicableAreaHandler = (e, hotspot) => {
    if (isAddServicableArea) {
      servicableAreaHotspots.some((hotspotId) => hotspot.id === hotspotId)
        ? setServicableAreaHotspots(
            servicableAreaHotspots.filter(
              (hotspotId) => hotspot.id !== hotspotId
            )
          )
        : setServicableAreaHotspots([...servicableAreaHotspots, hotspot.id]);
    } else {
      const markerElement = e.domEvent.target;
      const rect = markerElement.getBoundingClientRect();

      setSelectedMarker({
        marker: hotspot,
        position: {
          x: rect.x,
          y: rect.y,
        },
      });
    }
  };

  return (
    <>
      {!isLoaded && <div>Loading Maps...</div>}
      {loadError ? (
        <div>Unable to load maps!</div>
      ) : (
        isLoaded && (
          <GoogleMap
            mapContainerStyle={classes.mapContainerStyle}
            zoom={14}
            onClick={() => setSelectedMarker(null)}
            onDragStart={() => {
              setSelectedMarker(null);
            }}
            onLoad={onMapLoad}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              fullscreenControl: true,
            }}
          >
            {hotspots?.map((marker, index) => {
              let isActive = marker.status === "ACTIVE" ? true : false;

              let isSelectedMarker = selectedMarker?.marker?.id === marker?.id;
              let isServicableArea = servicableAreaHotspots.some(
                (hotspot) => hotspot === marker.id
              );

              return (
                (!isAddServicableArea ||
                  (isAddServicableArea && !isActive)) && (
                  <>
                    <Marker
                      key={index}
                      position={{
                        lat: marker.location.coordinates[1],
                        lng: marker.location.coordinates[0],
                      }}
                      icon={{
                        url:
                          isSelectedMarker || isServicableArea
                            ? "/black-marker.png"
                            : isActive
                            ? "/green-marker.png"
                            : "/red-marker.png",
                      }}
                      onClick={(e) => addServicableAreaHandler(e, marker)}
                      label={{
                        text: `${marker.walkersAssigned.toString()}W`,
                        color: "white",
                      }}
                    />
                    {selectedMarker &&
                      selectedMarker.marker.id === marker.id && (
                        <MarkerDetails
                          district={filters?.districtId?.name || ""}
                          selectedMarker={selectedMarker}
                          setSelectedMarker={setSelectedMarker}
                          index={index + 1}
                          setassignAWalkCard={setassignAWalkCard}
                        />
                      )}
                  </>
                )
              );
            })}
          </GoogleMap>
        )
      )}
      {!isAddServicableArea && (
        <Filters
          setHotspots={setHotspots}
          hotspots={hotspots}
          setIsAddServicableArea={setIsAddServicableArea}
          filters={filters}
          setFilters={setFilters}
          districts={districts}
          setDistricts={setDistricts}
          hubs={hubs}
          setHubs={setHubs}
          pincodes={pincodes}
          setPincodes={setPincodes}
          setSelectedMarker={setSelectedMarker}
          filterSlotStartTime={filterSlotStartTime}
          setFilterSlotStartTime={setFilterSlotStartTime}
          filterSlotEndTime={filterSlotEndTime}
          setFilterSlotEndTime={setFilterSlotEndTime}
        />
      )}
      {!isAddServicableArea ? (
        <Information />
      ) : (
        <SelectHotspots
          setIsAddServicableArea={setIsAddServicableArea}
          servicableAreaHotspots={servicableAreaHotspots}
          setHotspots={setHotspots}
          filterSlotStartTime={filterSlotStartTime}
          filterSlotEndTime={filterSlotEndTime}
          filters={filters}
          setServicableAreaHotspots={setServicableAreaHotspots}
        />
      )}
      <Modal open={assignAWalkCard} onClose={() => setassignAWalkCard(false)}>
        <div className="modal-default modal-sales">
          <AssignAWalkerCard
            hotspot={selectedMarker?.marker}
            onClose={() => setassignAWalkCard(false)}
            setSelectedMarker={setSelectedMarker}
            setHotspots={setHotspots}
            filterSlotStartTime={filterSlotStartTime}
            filterSlotEndTime={filterSlotEndTime}
            filters={filters}
          />
        </div>
      </Modal>
    </>
  );
};

export default Location;
