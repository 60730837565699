import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMixedAuth from "../hooks/useMixedAuth";
import { setInitialAppLoad } from "../redux/actions/index";
import { useDispatch } from "react-redux";

export default function MixedRoute({ children, ...rest }) {
  const { subdomain, path } = rest;
  // returns [redirect,customRender,navigateToPath,auth]
  const [redirect, customRender, navigateToPath, auth] = useMixedAuth(
    subdomain,
    path
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (redirect.required) {
      window.location = redirect.path;
      dispatch(setInitialAppLoad(true));
    }
    if (navigateToPath.required) navigate(`${navigateToPath.path}`);
  }, [redirect.required, navigateToPath.required]);

  return children;
}
