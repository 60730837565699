import { useState, useEffect } from "react";

export default function usePaginated(pageNo, getNextPageData, loadingDefault=true) {
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(loadingDefault);
  const [hasMore, setHasMore] = useState(false);
  const defaultLimit = 30;

  const updatePaginatedData = async () => {
    try {
      pageNo === 0 && paginatedData?.length && setPaginatedData([]);
      setError(false);

      let newPageNo = pageNo;

      //set paginated results from filtered content
      const nextPageData = await getNextPageData(
        pageNo, defaultLimit
      );

      if (!nextPageData?.data?.length) {
        setHasMore(false);
        setTotalDataCount(nextPageData?.totalCount);
      } else {
        setPaginatedData((prev) => {
          const newData =
            newPageNo === 0 ? nextPageData?.data : [...prev, ...nextPageData?.data];
          setHasMore(true);

          return newData;
        });
        setTotalDataCount(nextPageData?.totalCount);
      }

    setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    loading && updatePaginatedData();
  }, [loading]);

  return { loading, error, paginatedData, hasMore, setLoading, totalDataCount };
}
