import React, {
  lazy,
  Suspense,
  useEffect,
  useLayoutEffect,
  useState,
  forwardRef,
  useCallback,
} from "react";
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import Header from "./components/Header/Header";
import Loader from "./components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setActionNotif, setHeaderVisibility } from "./redux/actions";
import { activityCategories, discussActions } from "./redux/constants";
import FindHome from "./pages/SplootFinder/SplootFinder";
import AppLoader from "./components/AppLoader/AppLoader";
import { useLoadScript } from "@react-google-maps/api";
import env from "./environment/production.json";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import Home from "./pages/Home/Home";
import ScrollToTop from "./ScrollToTop";
import { userNavlist, ldJson, salesDashboardNavList } from "./data";
// import { hot } from "react-hot-loader";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SignoutCard from "./components/SignoutCard/SignoutCard";
import { accountRoles, profileActions } from "./redux/constants";
import BottomNavBar from "./components/BottomNavBar.js/BottomNavBar";
import { useMediaQuery } from "@mui/material";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import MixedRoute from "./routes/MixedRoute";
import { setInitialAppLoad } from "./redux/actions";
import { getAppUserData, onGetAppUserFailureAtPageLoad, onGetAppUserSuccessAtPageLoad } from "./redux/dataFetchers/appUserApis";
import { getCookie, eraseCookie } from "./utils/cookies";
import "./App.css";
import MediaUploadLoader from "./components/MediaUploadLoader/MediaUploadLoader";
import { createContext } from "react";
import OpenInAppDialog from "./components/OpenInAppDialog/OpenInAppDialog";
import NotFound from "./pages/NotFound/NotFound";
import HomeMeta from "./meta/HomeMeta";
import { getAppAdminData } from "./redux/dataFetchers/adminCMSDashboardApis";
import { getAppSalesPersonData } from "./redux/dataFetchers/salesDashboardApis";
import SalesDashboard from "./sales/SalesDashboard";
import { AppAnalytics } from "./utils/appAnalytics";
import EventRegistrationCard from "./components/EventRegistrationCard/EventRegistrationCard";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { WebEngage } from "./utils/webEngage";
import Location from "./sales/Location/Location";
import AnalyticsOnTimeWalks from "./sales/Analytics/OntimeWalks"
import Leaves from "./sales/Leaves/Leaves";
import WalkRequests from "./sales/WalkRequests/WalkRequests";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const advancedMatching = {};

const options = {
  autoConfig: true, // set pixel's autoConfig.
  debug: false, // enable logs
};

const SearchResultPage = lazy(() =>
  import("./components/SearchResultPage/SearchResultPage")
);
const BusinessListings = lazy(() =>
  import("./admin/AdminDashboard/BusinessListings/BusinessListings")
);
const ScrapedPosts = lazy(() =>
  import("./admin/AdminDashboard/ScrapedPosts/ScrapedPosts")
);
// const ContactUs = lazy(() => import("./Components/ContactUs/ContactUs"));
const AdminDashboard = lazy(() =>
  import("./admin/AdminDashboard/AdminDashboard")
);
const AdminLogin = lazy(() => import("./admin/Login/Login"));
const AdminEvents = lazy(() => import("./admin/AdminDashboard/Experiences/Experiences"));
const Shop = lazy(() => import("./admin/AdminDashboard/Shop/Shop"));
const ShowAndTell = lazy(() => import("./admin/AdminDashboard/ShowAndTell/ShowAndTell"));
const Topics = lazy(() =>
  import("./admin/AdminDashboard/Topics/Topics")
);
const Banners = lazy(() => import("./admin/AdminDashboard/Banners/Banners"));
const Learn = lazy(() => import("./admin/AdminDashboard/Learn/Learn"));
// const Quests = lazy(() => import("./admin/AdminDashboard/Quests/Quests"));
const ExpertProfile = lazy(() =>
  import("./admin/AdminDashboard/ExpertProfile/ExpertProfile")
);
const Chats = lazy(() => import("./admin/AdminDashboard/Chats/Chats"));
const Feed = lazy(() => import("./admin/AdminDashboard/Feed/Feed"));
const FindTool = lazy(() => import("./components/FindTool/FindTool"));
const FreshFood = lazy(() => import("./pages/FreshFood/FreshFood"));
const Events = lazy(() => import("./pages/Events/Events"));
const Download = lazy(() => import("./pages/Download/Download"));
const Blogs = lazy(() => import("./pages/Blogs/Blogs"));
const EventCard = lazy(() => import("./components/EventsFullView/EventsFullView"));
const DogWalking = lazy(() => import("./pages/DogWalking/DogWalking"));
const Community = lazy(() => import("./pages/Community/Community"));
const UserSignUp = lazy(() => import("./pages/UserSignUp/UserSignUp"));
const Onboarding = lazy(() => import("./pages/Onboarding/Onboarding"));
const Discuss = lazy(() => import("./pages/Discuss/Discuss"));
const UserProfile = lazy(() => import("./pages/UserProfile/UserProfile"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const BusinessListingReview = lazy(() => import("./pages/BusinessListingReview/BusinessListingReview"));
const UserProfileDisplay = lazy(() =>
  import("./components/UserProfileDisplay/UserProfileDisplay")
);
const ProfileSteps = lazy(() =>
  import("./components/ProfileSteps/ProfileSteps")
);
const PetProfileForm = lazy(() =>
  import("./components/PetProfileForm/PetProfileForm")
);
const UserEditProfileCard = lazy(() =>
  import("./components/UserEditProfileCard/UserEditProfileCard")
);
const UserActivity = lazy(() =>
  import("./components/UserActivity/UserActivity")
);
const Walkers = lazy(() => import("./sales/Walkers/Walkers"));
const Walks = lazy(() => import("./sales/Walks/Walks"));
const Users = lazy(() => import("./sales/Users/Users"))
const Subscriptions = lazy(() => import("./sales/Subscriptions/Subscriptions"))
const BookDogWalkingWrapper = lazy(() => import("./pages/BookDogWalking/BookDogWalking"))

const libraries = ["places"];
export const MediaUploadLoaderContext = createContext();

const initUserTracking = () => {
  //initialize meta pixel
  ReactPixel.init(env.REACT_APP_META_PIXEL_ID, advancedMatching, options);

  //initialize google analytics
  ReactGA.initialize(env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMEND_ID, {
    nonce: env.NONCE,
  });
  //send page_view event
  ReactGA.send("pageview");
  ReactPixel.pageView(); // For tracking page view
  AppAnalytics.page({
    name: "HomePage"
  })
  WebEngage.init(env.REACT_APP_WEBENGAGE_LICENSE_CODE);
};

function App() {
  const [currNavList, setCurrNavList] = useState(userNavlist);
  const [signoutModalOpen, setSignoutModalOpen] = useState(false);
  const {
    ui,
    admin,
    user,
    universal: { accountRole },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const mobileMatches = useMediaQuery("(max-width:1030px)");
  const bottomNavVisibility =
    !admin.isAdmin &&
    mobileMatches &&
    accountRole === accountRoles.REGISTERED_USER &&
    (location.pathname.startsWith("/users") ||
      location.pathname.startsWith("/posts"));
  const pathArray = location.pathname.split("/");
  const pathPrefix = pathArray[1], pathSuffix = pathArray[pathArray?.length - 1];
  const subdomain = window.location.host?.split(".")[0] || "";
  const [mediaUploadLoaderOpen, setMediaUploadLoaderOpen] = useState(false);

  const openSignoutModal = () => setSignoutModalOpen(true);

  const closeSignoutModal = () => setSignoutModalOpen(false);

  const closeMediaUploadLoader = useCallback((e, reason) => {
    if (reason !== 'backdropClick') {
      setMediaUploadLoaderOpen(false)
    }
  }, [mediaUploadLoaderOpen]);
  const openMediaUploadLoader = useCallback(() => setMediaUploadLoaderOpen(true), [mediaUploadLoaderOpen]);

  const uplaodLoaderContextValue = {
    openMediaUploadLoader,
    closeMediaUploadLoader
  }

  const signoutUser = () => {
    eraseCookie(
      "userAccessToken",
      document.domain.replace(`${env.REACT_APP_SUBDOMAIN}.`, "")
    );

    WebEngage.user.logout(user.data.id);
    setSignoutModalOpen(false);
    window.location.href = `${window.location.protocol
      }//${window.location.host.replace(
        `${env.REACT_APP_SUBDOMAIN}.`,
        env.NODE_ENV === "staging" ? "staging." : ""
      )}/login`;
  };

  const handleActionNotifClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setActionNotif({
        open: false,
        message: ui.actionNotif.message,
        severity: ui.actionNotif.severity,
      })
    );
  };

  useLayoutEffect(() => {
    const userAccessToken = getCookie("userAccessToken"),
      adminAuthToken = window.localStorage.getItem("authToken"),
      authTokenExpires = window.localStorage.getItem("authTokenExpires"),
      salesAccessToken = window.localStorage.getItem("salesAccessToken"),
      salesTokenExpires = window.localStorage.getItem("salesTokenExpires");

    if(!["admin","sales"].includes(pathPrefix)) dispatch(setHeaderVisibility(false))

    //fetch user details & update store
    if (ui.initialAppLoad
      ) {
        switch (pathPrefix) {
          case "admin":
          //fetch admin details
          if (adminAuthToken && authTokenExpires > new Date().getTime()){
            dispatch(
              getAppAdminData(adminAuthToken, navigate)
            )
          }
          else if (pathSuffix !== "login") {
            window.location = `${window.location.protocol}//${window.location.host}/admin/login`
          }
          else {
            dispatch(setInitialAppLoad(false))
          }
          break;
        case "sales":
          if (salesAccessToken && salesTokenExpires > new Date().getTime()){
            dispatch(
              getAppSalesPersonData(salesAccessToken, navigate)
            )
          }
          else if (pathSuffix !== "login") window.location = `${window.location.protocol}//${window.location.host}/sales/login`
          else dispatch(setInitialAppLoad(false))
          break;
        default:
          if (userAccessToken) {
            dispatch(
              getAppUserData(userAccessToken, navigate, location, onGetAppUserSuccessAtPageLoad, onGetAppUserFailureAtPageLoad)
            );
          } else {
            //App not loading in other cases
            dispatch(setInitialAppLoad(false));
          }
        }
    }

  }, [ui.initialAppLoad, dispatch, navigate, subdomain]);

  useEffect(() => {
    //avoid tracking organization admin traffic
    if (window.location.pathname.split("/")[1] !== "admin") {
      initUserTracking();
      return;
    }
  }, []);

  return ui.initialAppLoad ? (
    <AppLoader />
  ) : (
    <div
      className="App"
      data-aos="fade-in"
      data-aos-once="true"
      data-aos-easing="ease-in"
    >
      <Helmet>
        {ldJson.map((org, i) => (
          <script type="application/ld+json" nonce={env.NONCE} key={i}>
            {JSON.stringify(org)}
          </script>
        ))}
      </Helmet>
      <HomeMeta />
      {ui.headerVisibility && (
        <Header navList={currNavList} openSignoutModal={openSignoutModal} />
      )}
      <ScrollToTop>
        <Routes>
          {/* <Route
            exact
            path="/"
            element={
              <PublicRoute subdomain={subdomain} path="/">
                <Home />
              </PublicRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/community"
            element={
              <PublicRoute subdomain={subdomain} path="/community">
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Community />
                </Suspense>
              </PublicRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/download"
            element={
              <PublicRoute subdomain={subdomain} path="/download">
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Download />
                </Suspense>
              </PublicRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/near-you"
            element={<FindHome isLoaded={isLoaded} />}
          /> */}
          {/* <Route
            exact
            path="/fresh-food"
            element={
              <MixedRoute subdomain={subdomain} path="/fresh-food">
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <FreshFood />
                </Suspense>
              </MixedRoute>
            }
          />
          <Route
            exact
            path="/dog-walking"
            element={
              <MixedRoute path="/dog-walking" subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <DogWalking />
                </Suspense>
              </MixedRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/pages/privacy-policy"
            element={
              <MixedRoute path="/pages/privacy-policy" subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <PrivacyPolicy />
                </Suspense>
              </MixedRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/book-dog-walking"
            element={
              <MixedRoute path="/book-dog-walking" subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue}>
                    <BookDogWalkingWrapper />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              </MixedRoute>
            }
          /> */}
          <Route
            exact
            path="/events"
            element={
              <MixedRoute subdomain={subdomain} path="/events">
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Events />
                </Suspense>
              </MixedRoute>
            }
          />
          <Route
            path="/business/:listingId"
            element={
              <MixedRoute path={location.pathname} subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <BusinessListingReview />
                </Suspense>
              </MixedRoute>
            }
          />
          {/* <Route
            path="/events/:eventId"
            element={
              <MixedRoute path={location.pathname} subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <EventCard />
                </Suspense>
              </MixedRoute>
            }
          /> */}
          <Route
            path="/events/:eventId/:eventSlug"
            element={
              <MixedRoute path={location.pathname} subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <EventCard />
                </Suspense>
              </MixedRoute>
            }
          />
          {accountRole === accountRoles.GUEST && <Route
            exact
            path="/offline-event"
            element={
              <MixedRoute subdomain={subdomain} path="/offline-event">
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <GoogleOAuthProvider clientId={env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID}>
                    <EventRegistrationCard offlineEvent={true} />
                  </GoogleOAuthProvider>
                </Suspense>
              </MixedRoute>
            }
          />}
          <Route
            exact
            path="/blogs"
            element={
              <MixedRoute subdomain={subdomain} path="/blogs">
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Blogs />
                </Suspense>
              </MixedRoute>
            }
          />
          <Route
            path="/blogs/:topic"
            element={
              <MixedRoute path={location.pathname} subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Blogs />
                </Suspense>
              </MixedRoute>
            }
          />
          {/* <Route
            exact
            path="/signup"
            element={
              <PublicRoute path="/signup" subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <UserSignUp />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <PublicRoute path="/login" subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <UserSignUp />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/onboarding"
            element={
              <PrivateRoute path="/onboarding" subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue}>
                    <Onboarding />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/posts"
            element={
              <MixedRoute path="/posts" subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue}>
                    <Discuss />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              </MixedRoute>
            }
          />
          <Route
            path="/posts/:postId/:postSlug"
            element={
              <MixedRoute path={location.pathname} subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue}>
                    <Discuss />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              </MixedRoute>
            }
          />
          <Route
            path="/posts/:postId"
            element={
              <MixedRoute path={location.pathname} subdomain={subdomain}>
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue}>
                    <Discuss />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              </MixedRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/near-you/listings/:slug"
            element={
              <Suspense
                fallback={
                  <Loader
                    wrapperStyle={{ height: "100vh" }}
                    loaderStyle={{ color: "rgb(255,88,5)" }}
                  />
                }
              >
                <SearchResultPage />
              </Suspense>
            }
          /> */}
          {/* <Route
            exact
            path="/near-you/listings"
            element={
              isLoaded && (
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <FindTool isLoaded={isLoaded} />
                </Suspense>
              )
            }
          /> */}
          {/* <Route path="/list-business" element={<ContactUs />} /> */}

          {/* Admin Routes */}
          <Route
            path="/admin/dashboard"
            element={
              <Suspense
                fallback={
                  <Loader
                    wrapperStyle={{ height: "100vh" }}
                    loaderStyle={{ color: "rgb(255,88,5)" }}
                  />
                }
              >
                <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                  <AdminDashboard setCurrNavList={setCurrNavList} />
                </MediaUploadLoaderContext.Provider>
              </Suspense>
            }
          >
            <Route
              path="events"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <AdminEvents />
                </Suspense>
              }
            />
              <Route
              path="learn"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Learn />
                </Suspense>
              }
            />

            <Route
              path="shop"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Shop />
                </Suspense>
              }
            />
            <Route
              path="prompts"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <ShowAndTell />
                </Suspense>
              }
            />
            {/* <Route
              path="quests"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Quests />
                </Suspense>
              }
            /> */}
            <Route
              path="topics"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Topics />
                </Suspense>
              }
            />
            <Route
              path="banners"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Banners />
                </Suspense>
              }
            />
            <Route
              path="feed"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Feed />
                </Suspense>
              }
            />
            <Route
              path="expert-profile"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <ExpertProfile />
                </Suspense>
              }
            />
            <Route
              path="chats"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Chats />
                </Suspense>
              }
            />
            <Route
              path="business-listings"
              element={
                <BusinessListings />
              }
              />
              
              <Route
              path="scraped-posts"
              element={
                <ScrapedPosts />
              }
              />

            </Route>
            
          <Route
            path="/admin/login"
            element={
              <Suspense
                fallback={
                  <Loader
                    wrapperStyle={{ height: "100vh" }}
                    loaderStyle={{ color: "rgb(255,88,5)" }}
                  />
                }
              >
                <AdminLogin pathPrefix={pathPrefix} />
              </Suspense>
            }
          />

          <Route
            path="/sales/dashboard"
            element={
              <Suspense
                fallback={
                  <Loader
                    wrapperStyle={{ height: "100vh" }}
                    loaderStyle={{ color: "rgb(255,88,5)" }}
                  />
                }
              >
                <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                  <SalesDashboard />
                </MediaUploadLoaderContext.Provider>
              </Suspense>
            }
            >
              <Route index element={<Navigate to="walks" />} />
              
            <Route
              path="walkers"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <Walkers />
                </Suspense>
              }
            />
            <Route
              path="subscriptions"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                    <Subscriptions />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              }
            />

            <Route
              path="location"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                    <Location />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              }
            />

            <Route
              path="leaves"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                    <Leaves />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              }
            />

            <Route
              path="analytics-ontime-walks"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                    <AnalyticsOnTimeWalks />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              }
            />

            <Route
              path="walks"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                    <Walks />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              }
            />
            <Route
              path="users"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                    <Users />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              }
            />
            <Route
              path="walk-requests"
              element={
                <Suspense
                  fallback={
                    <Loader
                      wrapperStyle={{ height: "100vh" }}
                      loaderStyle={{ color: "rgb(255,88,5)" }}
                    />
                  }
                >
                  <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                    <WalkRequests />
                  </MediaUploadLoaderContext.Provider>
                </Suspense>
              }
            />
          </Route>

          {/* Sales Routes */}
          <Route
            path="/sales/login"
            element={
              <Suspense
                fallback={
                  <Loader
                    wrapperStyle={{ height: "100vh" }}
                    loaderStyle={{ color: "rgb(255,88,5)" }}
                  />
                }
              >
                <AdminLogin pathPrefix={pathPrefix} />
              </Suspense>
            }
          />


          {/* User Profile Routes */}
          {/* <Route
            path="/users"
            element={
              <Suspense
                fallback={
                  <Loader
                    wrapperStyle={{ height: "100vh" }}
                    loaderStyle={{ color: "rgb(255,88,5)" }}
                  />
                }
              >
                <MediaUploadLoaderContext.Provider value={uplaodLoaderContextValue} >
                  <UserProfile openSignoutModal={openSignoutModal} />
                </MediaUploadLoaderContext.Provider>
              </Suspense>
            }
          >
            {
              <Route
                path=":userId/dashboard"
                element={
                  <PrivateRoute path={location.pathname} subdomain={subdomain}>
                    <Suspense
                      fallback={
                        <Loader
                          wrapperStyle={{ height: "100vh" }}
                          loaderStyle={{ color: "rgb(255,88,5)" }}
                        />
                      }
                    >
                      <UserProfileDisplay />
                    </Suspense>
                  </PrivateRoute>
                }
              />
            }
            {
              <Route
                path=":userId/complete"
                element={
                  <PrivateRoute path={location.pathname} subdomain={subdomain}>
                    <Suspense
                      fallback={
                        <Loader
                          wrapperStyle={{ height: "100vh" }}
                          loaderStyle={{ color: "rgb(255,88,5)" }}
                        />
                      }
                    >
                      <ProfileSteps />
                    </Suspense>
                  </PrivateRoute>
                }
              />
            }
            <Route
              path=":userId/pets/add"
              element={
                <PrivateRoute path={location.pathname} subdomain={subdomain}>
                  <Suspense
                    fallback={
                      <Loader
                        wrapperStyle={{ height: "100vh" }}
                        loaderStyle={{ color: "rgb(255,88,5)" }}
                      />
                    }
                  >
                    <PetProfileForm action={profileActions.ADD} />
                  </Suspense>
                </PrivateRoute>
              }
            />
            {
              <Route
                path=":userId/pets/:petId/edit"
                element={
                  <PrivateRoute path={location.pathname} subdomain={subdomain}>
                    <Suspense
                      fallback={
                        <Loader
                          wrapperStyle={{ height: "100vh" }}
                          loaderStyle={{ color: "rgb(255,88,5)" }}
                        />
                      }
                    >
                      <PetProfileForm action={profileActions.EDIT} />
                    </Suspense>
                  </PrivateRoute>
                }
              />
            }
            {
              <Route
                path=":userId/edit"
                element={
                  <PrivateRoute path={location.pathname} subdomain={subdomain}>
                    <Suspense
                      fallback={
                        <Loader
                          wrapperStyle={{ height: "100vh" }}
                          loaderStyle={{ color: "rgb(255,88,5)" }}
                        />
                      }
                    >
                      <UserEditProfileCard />
                    </Suspense>
                  </PrivateRoute>
                }
              />
            }
            <Route
              path=":userId/posts"
              element={
                <PrivateRoute path={location.pathname} subdomain={subdomain}>
                  <Suspense
                    fallback={
                      <Loader
                        wrapperStyle={{ height: "100vh" }}
                        loaderStyle={{ color: "rgb(255,88,5)" }}
                      />
                    }
                  >
                    <UserActivity
                      activityData={user.posts}
                      activityPageNo={user.pageNo}
                      fallbackAction={{
                        label: "Create a post",
                        linkTo: `/posts?action=${discussActions.ADD}`,
                        value: activityCategories.POSTS,
                      }}
                    />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path=":userId/saved-posts"
              element={
                <PrivateRoute path={location.pathname} subdomain={subdomain}>
                  <Suspense
                    fallback={
                      <Loader
                        wrapperStyle={{ height: "100vh" }}
                        loaderStyle={{ color: "rgb(255,88,5)" }}
                      />
                    }
                  >
                    <UserActivity
                      activityData={user.savedPosts}
                      activityPageNo={user.pageNo}
                    />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path=":userId/memories"
              element={
                <PrivateRoute path={location.pathname} subdomain={subdomain}>
                  <Suspense
                    fallback={
                      <Loader
                        wrapperStyle={{ height: "100vh" }}
                        loaderStyle={{ color: "rgb(255,88,5)" }}
                      />
                    }
                  >
                    <UserActivity
                      activityData={user.memories}
                      activityPageNo={user.pageNo}
                      fallbackAction={{
                        label: "Create a memory",
                        linkTo: `/posts?action=${discussActions.ADD}&isMemory=true`,
                        value: activityCategories.MEMORIES,
                      }}
                    />
                  </Suspense>
                </PrivateRoute>
              }
            />
          </Route> */}

          <Route
            path="*"
            element={
              <NotFound />
            }
          />
        </Routes>
      </ScrollToTop>

      <SignoutCard
        closeSignoutModal={closeSignoutModal}
        signoutModalOpen={signoutModalOpen}
        signoutUser={signoutUser}
      />

      {/* {bottomNavVisibility && <BottomNavBar />} */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={ui.actionNotif.open}
        onClose={handleActionNotifClose}
        key={"bottom center"}
        autoHideDuration={3500}
      >
        <Alert
          onClose={handleActionNotifClose}
          severity={ui.actionNotif.severity}
          sx={{ width: "100%" }}
        >
          {ui.actionNotif.message}
        </Alert>
      </Snackbar>

      <MediaUploadLoader open={mediaUploadLoaderOpen} closeLoader={closeMediaUploadLoader} />
      {ui.openInAppDialog?.open && <OpenInAppDialog dialogBtnText={ui.openInAppDialog?.btnText} openStore={ui.openInAppDialog?.openStore} deeplinkEntity={ui.openInAppDialog?.deeplinkEntity} />}
    </div>
  );
}

export default App;
