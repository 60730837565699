import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableHead,
  TableBody,
  IconButton,
  TableRow,
  TableCell,
  Button,
  TableContainer,
  Paper,
} from "@mui/material";
import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { getOnTimeAnalyticsUsers } from "../Walks/apis";
import { exportHandler } from "../../redux/dataFetchers/common";

const UsersData = ({
  setClickedBox,
  clickedBox,
  accessToken,
  dispatch,
  closeMediaUploadLoader,
  openMediaUploadLoader,
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit] = useState(10);

  useEffect(() => {
    (async () => {
      try {
        openMediaUploadLoader();
        const data = await getOnTimeAnalyticsUsers(
          accessToken,
          dispatch,
          page,
          limit,
          clickedBox.start,
          clickedBox.end
        );
        setUsersData(data);
        closeMediaUploadLoader();
      } catch (err) {
        closeMediaUploadLoader();
      }
    })();
  }, [clickedBox, page]);

  const exportButtonHandler = async () => {
    setIsExporting(true);
    openMediaUploadLoader();
    try{
      const data = await getOnTimeAnalyticsUsers(
        accessToken,
        dispatch,
        0,
        100000,
        clickedBox.start,
        clickedBox.end
      );
      let exportedArray = data.data.map((data) => {
        return {
          Name: data?.user?.name,
          "Phone Number": data?.user?.phoneNumber,
          "Scheduled Walks": data?.totalScheduledWalks,
          "Completed Walks": data?.totalCompletedWalks,
          "Delayed Walks": data?.totalDelayedWalks,
          "On-Time Walks Percentage": data?.onTimePercentage,
        };
      });
      exportHandler(exportedArray, "EXPORTED - Ontime Completed Walks Users Data - " + clickedBox.start + "-" + clickedBox.end + ".csv")
      setIsExporting(false);
      closeMediaUploadLoader();
    }catch(err){
      console.log(err);
      closeMediaUploadLoader();
    }
    
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <h2
            style={{
              fontSize: "1.5rem",
              marginTop: "2rem",
              marginRight: "1rem",
            }}
          >
            Users Data {clickedBox.start + " % - " + (clickedBox.end === 101? 100: clickedBox.end) + "%"}
          </h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              disabled={isExporting}
              variant="contained"
              color="primary"
              onClick={exportButtonHandler}
            >
              {isExporting ? "Exporting..." : "Export"}
            </Button>
          </div>
        </div>
        <div>
          <span>Total Count - {usersData?.totalCount || 0}</span>
          <IconButton>
            <CloseIcon
              onClick={() => {
                setClickedBox(null);
                setUsersData([])
              }}
            />
          </IconButton>
        </div>
      </div>
      <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Scheduled Walks</TableCell>
              <TableCell>Completed Walks</TableCell>
              <TableCell>Delayed Walks</TableCell>
              <TableCell>On-Time Walks Percentage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersData?.data?.map((user, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{user?.user?.name}</TableCell>
                  <TableCell>{user?.user?.phoneNumber}</TableCell>
                  <TableCell>{user?.totalScheduledWalks}</TableCell>
                  <TableCell>{user?.totalCompletedWalks}</TableCell>
                  <TableCell>{user?.totalDelayedWalks}</TableCell>
                  <TableCell>{user?.onTimePercentage} %</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        style={{ marginTop: "1rem" }}
        page={page + 1}
        onChange={(_, page) => {
          setPage(page - 1);
        }}
        count={Math.round((usersData.totalCount || 0) / limit)}
        color="primary"
      />
    </>
  );
};

export default UsersData;
