import defaultPetImage from "../../assets/images/pet-avatar.webp";
import { forwardRef, useContext, useMemo, useRef, useState } from 'react';
import {  Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import './SalesWalkCard.css';
import { EPOCH, MakeCallTo, WalkActionStatus, WalkRequestStatus, WalkSlotDuration, WalkStatus } from "../../redux/constants";
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard } from "../../utils/clipBoard";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useNavigate } from "react-router-dom";
import { callMaskingExotel } from "../../sales/Walks/apis";
import { useDispatch, useSelector } from 'react-redux';
import { setActionNotif } from "../../redux/actions";
import { MediaUploadLoaderContext } from "../../App";

const classes = {
    walkerInfoWrap: { display: 'flex', alignItems: 'center' },
    cancelBtn: {
        backgroundColor: "antiquewhite",
        marginRight: "0.5rem"
    },
    notifyIcon: {
        height: '1.2rem',
        width: '1.2rem'
    },
    infoIcon: {
        height: '1rem',
        width: '1rem',
        marginLeft: '0.2rem'
    },
    cancelBtnCol: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" },
    infoIcon: {
        height: '1rem',
        width: '1rem',
        margin: '0.4rem',
        color:"gray",
    },
}

const SalesWalkCard = forwardRef(({ walk,
    PenaltyAppliedToWalkerWalks,
    walkerIdParam,
    isLeaveParam,
    onClick = () => null,
    cancelWalkClickHandler = () => null,
    completeWalkClickHandler = () => null,
    openTriggerNotificationModal = () => null,
    walkDetailsClickHandler = () => null,
    userDetailsClickHandler = () => null,
    duplicateWalkClickHandler = () => null,
    handleLiveViewClick = () => null,
    unassignedWalkerClickHandler = () => null,
    walkerReplacementHistoryClickHandler = () => null,
    convertToSubscriptionClickHandler = () => null
}, lastElementRef) => {

    const { sales: { accessToken, customCities }, user: { states } } = useSelector(state => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [callAchorEl,setCallAchorEl] = useState(null);
    const [phoneError, setPhoneError] = useState(false);
    const controlsOpen = Boolean(anchorEl);
    const controlsOpenCall = Boolean(callAchorEl);
    const [teamPhoneNumber,setTeamPhoneNumber ] = useState("");
    const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(MediaUploadLoaderContext);
    const handleControlsClick = (event) => {
    setAnchorEl(event.currentTarget);
    event?.stopPropagation()
  };
  const handleCallControlsClick = (event) => {
    setCallAchorEl(event.currentTarget);
    event?.stopPropagation()
  };


  const handleCloseControls = (event) => {
    event?.stopPropagation()
    setAnchorEl(null);
  };
  
  const handleCloseControlsCall = (event) => {
    event?.stopPropagation()
    setCallAchorEl(null);
  };

  const handlePhoneNumberChange = (event) =>{
    setTeamPhoneNumber(event.target.value);
    let reg = /^(\+91[\-\s]?)?[6-9]\d{9}$/;
    if(reg.test(event.target.value))
        {
            setPhoneError(false);
        }
    else {setPhoneError(true);}
  }

  const makeExotelCall = async (from,to) => {
    openMediaUploadLoader();
    const response = await callMaskingExotel({from,to,accessToken,dispatch})
    dispatch(setActionNotif({
        open: true,
        message: "Pleae pick up your phone",
        severity: "success",
    }))
    console.log("Respone from makeExotelCall",response);    
    closeMediaUploadLoader()
  }

  const handleWalkControlSelect = (event, control) => {
    event.stopPropagation()
    handleCloseControls(event);
    
    switch(control){
        case WalkStatus.CANCELLED:
            cancelWalkClickHandler(walk)
          break;
        case WalkStatus.COMPLETED:
            completeWalkClickHandler(walk)
            break;
        case WalkActionStatus.DUPLICATE:
            duplicateWalkClickHandler(walk)
          break;
        case WalkActionStatus.UNASSIGNED:
            unassignedWalkerClickHandler(walk)
          break;
        case WalkActionStatus.CONVERT_TO_SUBSCRIPTION:
            convertToSubscriptionClickHandler(walk)
          break;
        case WalkActionStatus.COPY:
            handleCopy(walk)
          break;
        case MakeCallTo.CUSTOMER:
            const customerPhoneNumber = walk?.user?.phoneNumber;   
            makeExotelCall(teamPhoneNumber,customerPhoneNumber);
            break;
        case MakeCallTo.WALKER:
            const walkerPhoneNumber = walk?.phoneNumber;   
            makeExotelCall(teamPhoneNumber,walkerPhoneNumber);
            break;
        default:
          console.log("option invalid!")
    }
  }
    
const ITEM_HEIGHT = 38;
    const profilePicRef = useRef();
    const scheduledAt = `${new Date(walk.scheduledAt).toLocaleString([],
        {
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        })}`
    const formattedSlotDuration = (walk?.slotDuration / (60*1000)) || (WalkSlotDuration.MEDIUM / (60*1000));
    const mixedName = useMemo(() => {
        let name = walk?.user?.name;
        walk?.petIds.map(petId => {
            let petDetails = walk.user.petProfiles.filter(pet => pet._id === petId)[0];
            name = `${name} | ${petDetails?.name}`
        })
        return name;
    }, [walk?.user?.petProfiles])
    
    const duration = walk?.endTime - walk?.startTime;
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

    let durationString = "";

    if (hours > 0) {
        if (hours > 1) {
            durationString = `${hours} hrs `;
        } else {
            durationString = `${hours} hr `
        }
    }

    if (minutes > 0) {
        if (minutes > 1) {
            durationString = durationString + `${minutes} mins`;
        } else {
            durationString = durationString + `${minutes} min`;
        }
    }

    const getUnassignedReason = (walk) => {
        const unassignedWalkers = walk.replacedWalkers
            .slice() 
            .reverse() 
            .find((replacedWalker) => replacedWalker?.reason === WalkActionStatus?.UNASSIGNED);
        
        return unassignedWalkers?.description || "";
    };

    const checkWalkerPenalty = () => {
        return walk?.replacedWalkers?.some((walker)=>{
            if(walker.walker.id == walkerIdParam && walker.isPenalty)return true;
            return false;
        });
    }

  const handleCopy = () => {
    let copyData = `Cx Name:  ${walk?.user?.name}
        Cx Phone Number:  ${walk?.user.phoneNumber}
        Dog Name:   ${mixedName}
        Wx Name:  ${walk?.walker?.name}
        Walk type:  ${walk?.trial ? "trial" : "subscription"}
        Date of walk:   ${new Date(walk?.scheduledAt).toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })}
        Walk status:  ${walk?.status}`;
    copyToClipboard(copyData);
  }

    return (
        <div className='walks-info-header info-header-data info-card notify' onClick={onClick} key={walk.id}  ref={lastElementRef}>
            <span>
                <p className="user-pet-name">{mixedName}</p>
                <Tooltip placement="top" title="User details">
                    <InfoIcon style={classes.infoIcon}
                        onClick={(e) => {
                            userDetailsClickHandler(walk)
                            e.stopPropagation()
                        }} />
                </Tooltip>
                {walk.isDuplicate && <ContentCopyIcon />}
            </span>
            <span className="walk-type">
                <p>{walk.trial ? (walk?.orderId ? 'ORDER_TRIAL' : 'FREE_TRIAL') : (walk?.orderId ? 'ORDER' : 'SUBS - ' + walk?.subscriptionDetails?.type)} | {`${formattedSlotDuration} m`}</p>
                <Tooltip placement="top" title="Walk details">
                    <InfoIcon style={classes.infoIcon}
                        onClick={(e) => {
                            walkDetailsClickHandler(walk)
                            e.stopPropagation()
                        }} />
                </Tooltip>
            </span>
            <span className="scheduled-at">{scheduledAt}
            {walk.replacedWalkers?.length ? <span style={{marginLeft:"4px", backgroundColor:"rgb(211, 34, 34)", color:"white", borderRadius:"20px", width:"20px", display:"flex", margin:"auto",marginRight:"3px", justifyContent:"center"}} > R </span> : null}
            </span>
            {walk?.walker ?
            <>
                <span style={classes.walkerInfoWrap}>
                    <img src={walk.walker.photoUrl || defaultPetImage} ref={profilePicRef} onError={() =>
                        (profilePicRef.current.src = defaultPetImage)
                    } alt="walker profile" className='mini-profile-pic' />
                    <Tooltip title={walk.walker?.phoneNumber ?? "Mob: -"} placement="top">
                        <span style={{ display: "flex", alignItems: "center", flexWrap: 'wrap' }} className="walker-name">
                            {walk.walker?.name || "-"}
                            {
                                walk.feedback &&
                                <>
                                    ({walk.feedback.rating}
                                    <StarIcon style={{ color: "#EF7123", fontSize: '1rem' }} />)
                                </>
                            }
                    {walk.startTime >= (walk.scheduledAt + (15 * EPOCH.ONE_MINUTE_MS)) && <TimerOffIcon style={{marginLeft:"5px", color:"rgb(211, 34, 34)", width:"20px"}} />}                    
                        </span>
                    </Tooltip>
                </span>
                </>
                 : walk.isWalkerUnassigned ?<span style={{color:"#D32222", display: "flex", justifyContent:"center", alignItems: "center"}}>UNASSIGNED 
                    <Tooltip placement="top" title={getUnassignedReason(walk)}>
                        <InfoIcon style={classes.infoIcon} />
                    </Tooltip>
                 </span> : <span style={classes.walkerInfoWrap}>-</span>
            }
            <div style={{display: 'flex', gap: "1.5rem", alignItems: "center", flexWrap:"wrap", width:"max-content"}}>
            <span style={{
                    ...classes.walkStatus,
                    ...(walk.status === WalkStatus.COMPLETED) ||
                       (walk.status === WalkStatus.ARTIFICIALLY_COMPLETED) ||
                        (walk.status === WalkStatus.MANUALLY_COMPLETED) ? { color: "#00B53C" } :
                        (walk.status === WalkStatus.UPCOMING) || 
                            (walk.status === WalkStatus.ONGOING) ? { color: "#9707CA" } : { color: "#D32222" },
                    display: "flex", alignItems: "center",gap:"0.3rem"
            }}>
                {
                walk.status === WalkStatus.ONGOING && 
                <div className="live-icon" onClick={(event) => {
                    handleLiveViewClick(walk)
                    event?.stopPropagation()
                            }}
                    ><p>Live</p>
                </div>
                }
                {walk?.status ?? "-"}
                {walk?.currentRequest?.status === WalkRequestStatus.FINDING ?
                    <TravelExploreIcon onClick={
                    (event) =>{
                        navigate(
                        `/sales/dashboard/walk-requests?requestId=${walk?.currentRequest?.id}`
                        )
                        event.stopPropagation()
                    }
                    } style={{color:"#127834", cursor:"pointer"}} /> : null}
                {walk.status === WalkStatus.CANCELLED && walk.cancellationReason && 
                    <Tooltip placement="top" title={`${walk?.cancellationSource} - ${walk?.cancellationReason} ${walk.cancelledAt ? new Date(walk.cancelledAt).toLocaleString():""}`}>
                        <InfoIcon style={classes.infoIcon} />
                    </Tooltip>
                }

                {walk.status === WalkStatus.MANUALLY_COMPLETED && walk.completionReason && 
                    <Tooltip placement="top" title={`${walk?.completionType} - ${walk?.completionReason}`}>
                        <InfoIcon style={classes.infoIcon}/>
                    </Tooltip>
                }
                
            </span>
            </div>
            {
                isLeaveParam && 
                (PenaltyAppliedToWalkerWalks || checkWalkerPenalty())? 
                <span style={{color:"#00B53C"}}>Yes</span> :isLeaveParam && <span style={{color:"#D32222"}}>No</span>
            }
            {(walk.status === WalkStatus.COMPLETED) && (walk?.startTime) && (walk?.endTime) ? (<span>
                <p className="user-pet-name">{walk?.distance < 1000 ? `${walk?.distance} m` : `${(walk?.distance / 1000).toFixed(1)} km`}</p>
                <p>&nbsp;-&nbsp;</p>
                <p className="user-pet-name">{durationString}</p>
            </span>) : <span>{""}</span>}

            <span  style={{marginLeft: "1.5rem", cursor: "pointer", display:"flex", justifyContent:"space-around"}}>
                {(walk.status === WalkStatus.UPCOMING || walk.status === WalkStatus.ONGOING
                || walk.status === WalkStatus.UNATTENDED) ? <NotificationsActiveIcon
                    style={walk.walker ? {
                    color: '#EF7123',
                        width: 24,
                        height: 24,
                        ...classes.notifyIcon
                    } : classes.notifyIcon}
                    onClick={(e) => {
                        if (walk.walker) openTriggerNotificationModal(walk)
                        else alert('No walker assigned!')

                        e.stopPropagation()
                    }} />
                    :
                    <div></div>
                    }


                {(walk?.trial || !([WalkStatus.MANUALLY_COMPLETED, WalkStatus.ARTIFICIALLY_COMPLETED].includes(walk.status))) &&
                    <>
                    <div className="edit-btn" onClick={handleControlsClick}>
                        <p>Menu</p>
                    </div>
                  
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorEl}
                        open={controlsOpen}
                        onClose={handleCloseControls}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                            },
                        }}
                    >
                        {[WalkStatus.UPCOMING, WalkStatus.ONGOING, WalkStatus.COMPLETED, WalkStatus.UNATTENDED].includes(walk.status) ?
                            <MenuItem
                                key={WalkStatus.CANCELLED}
                                onClick={(event) => handleWalkControlSelect(event, WalkStatus.CANCELLED)}
                            >
                                Cancel
                            </MenuItem> : null}
                        {walk.status === WalkStatus.UNATTENDED &&
                            <MenuItem
                                key={WalkStatus.COMPLETED}
                                onClick={(event) => {
                                    handleWalkControlSelect(event, WalkStatus.COMPLETED
                                    )
                                }}
                            >
                                Complete
                            </MenuItem>}
                        {(walk.status === WalkStatus.UNATTENDED || walk.status === WalkStatus.CANCELLED) &&
                            <MenuItem
                                key={WalkActionStatus.DUPLICATE}
                                onClick={(event) => handleWalkControlSelect(event, WalkActionStatus.DUPLICATE)}
                            >
                                Duplicate
                            </MenuItem>}
                        {(walk.status === WalkStatus.UPCOMING) && walk.walker &&
                            <MenuItem
                                key={WalkActionStatus.UNASSIGNED}
                                onClick={(event) => handleWalkControlSelect(event, WalkActionStatus.UNASSIGNED)}
                            >
                                Unassign Walker
                            </MenuItem>}
                        {walk?.trial && [WalkStatus.ARTIFICIALLY_COMPLETED, WalkStatus.COMPLETED, WalkStatus.MANUALLY_COMPLETED].includes(walk?.status) &&
                            <MenuItem
                                key={WalkActionStatus.CONVERT_TO_SUBSCRIPTION}
                                onClick={(event) => handleWalkControlSelect(event, WalkActionStatus.CONVERT_TO_SUBSCRIPTION)}
                            >
                                Convert To Subscription
                            </MenuItem>}
                            <MenuItem
                                key={'copy-menu'}
                                onClick={(event) => handleWalkControlSelect(event, WalkActionStatus.COPY)}
                            >
                                Copy
                            </MenuItem>
                            
                    </Menu>

                    <div className="edit-btn" onClick={handleCallControlsClick}>
                        <p>Call</p>
                    </div>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            "aria-labelledby": "long-button",
                        }}
                        anchorEl={callAchorEl}
                        open={controlsOpenCall}
                        onClose={handleCloseControlsCall}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 5,
                                width: "20ch",
                            },
                        }}
                        
                    >
                        <MenuItem
                            key= {MakeCallTo.TEAM} 
                            onClick={(event)=>{handleWalkControlSelect(event,MakeCallTo.TEAM)}}
                        >
                            <TextField 
                                required 
                                id="outlined-basic" 
                                label="Add Phone Number" 
                                size="small"
                                variant="outlined" 
                                value ={teamPhoneNumber}
                                error={phoneError}
                                onChange={(event)=>{handlePhoneNumberChange(event)}}

                                />
                        </MenuItem>
                        <MenuItem 
                            key={MakeCallTo.CUSTOMER}
                            onClick={(event) => handleWalkControlSelect(event,MakeCallTo.CUSTOMER)}
                        >
                            Call Customer 
                        </MenuItem>
                        <MenuItem
                            key={MakeCallTo.WALKER}
                            onClick={(event) => handleWalkControlSelect(event,MakeCallTo.WALKER)}
                        >
                            Call Walker 
                        </MenuItem>
                    </Menu>
                    </>
                }
            </span>

        </div>
    )
})

export default SalesWalkCard;