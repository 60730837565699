import { decodeToken } from "react-jwt";
import { request } from "../../utils/apiRequest"
import { setAccountRole, setActionNotif } from "../actions";
import { setAdminAuthToken, setAdminRole } from "../actions/admin";
import { accountRoles } from "../constants";
import { getAppAdminData } from "./adminCMSDashboardApis";
import { nodeServerBaseUrl } from "./apiUrls";
import { getAppSalesPersonData } from "./salesDashboardApis";
import * as XLSX from "xlsx";


export const getPresignedUrl = (requestUrl, accessToken, petId = "") => {
  return new Promise(async (resolve, reject) => {
    let requestBody = {};
    if (petId) requestBody.petId = petId;
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestBody)
      }

      await request(requestUrl, requestOptions,
        async (response) => resolve(response.data?.data),
        async (error) => reject(error)
      )
    } catch (error) {
      console.log({ error })
    }
  })
}

export const uploadMediaUsingPresignedUrl = (presignedUrl, file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const requestOptions = {
        method: 'PUT',
        body: file
      }

      //upload
      await fetch(presignedUrl, requestOptions);
      resolve();
    } catch (error) {
      console.log({ error })
      reject(error)
    }
  })
}

export const loginModerator = (adminDetails, navigate) => async (dispatch, getState) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(adminDetails),
  };

  await request(`${nodeServerBaseUrl}/auth/signin`,
    requestOptions,
    async (response) => {
      const data = response.data.data;

      if (data.authToken) {
        const decodedToken = decodeToken(data.authToken);
        switch (data?.userRole) {
          case accountRoles.ADMIN:

            window.localStorage.setItem("authToken", data.authToken);
            window.localStorage.setItem("authTokenExpires", decodedToken.exp * 1000);

            dispatch(setAdminRole(true));
            dispatch(setAdminAuthToken(data.authToken));
            dispatch(setAccountRole(accountRoles.ADMIN));
            dispatch(getAppAdminData(data.authToken))

            navigate("/admin/dashboard/feed");
            break;
          case accountRoles.SALES:
            window.localStorage.setItem("salesTokenExpires", decodedToken.exp * 1000);
            window.localStorage.setItem("salesAccessToken", data.authToken);

            dispatch(getAppSalesPersonData(data.authToken, navigate))
            break;
          default:
            dispatch(
              setActionNotif({
                open: true,
                message: "Not a Moderator! Stay Away from sploot's dashboard!!",
                severity: "error",
              })
            );
        }
      }
    }
  )
};

export const exportHandler = (exportedArray, fileName) => {
  const ws = XLSX.utils.json_to_sheet(exportedArray);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); 
  XLSX.writeFile(wb, fileName);
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
          .toISOString()
          .split("T")[0];
};

export const isSameDay = (start, end) => {
  if (start && end) {
    const startD = new Date(start);
    const endD = new Date(end);
    // Ensure to compare only the date parts (year, month, day)
    const isSameDay =
      startD.getFullYear() === endD.getFullYear() &&
      startD.getMonth() === endD.getMonth() &&
      startD.getDate() === endD.getDate();

    if (isSameDay) return (true);
    return false;
  }
}