import React from "react";
import "./WalkDetailsCard.css";
import { WalkStatus } from "../../redux/constants";
import StarIcon from "@mui/icons-material/Star";

const defaultUserImage =
  process.env.PUBLIC_URL + "/assets/images/pet-parent-avatar.webp";

const PetCard = ({ photo, name, breed, behaviour }) => {
  return (
    <div className="pet-detail-container">
      <div className="pet-profile">
        <img src={photo} className="pet-profile-image" alt="pet" />
        <h2 className="pet-name">{name}</h2>
      </div>

      <div className="pet-info-container">
        <div className="user-info-card">
          <span className="label">Pet Breed :</span>
          <span className="value">{breed}</span>
        </div>
        <div className="user-info-card">
          <span className="label">Pet Behaviour :</span>
          {/* <span className="value">{behaviour}</span> */}
          <ul>
            {behaviour?.length
              ? behaviour?.map((petBehaviour, id) => (
                  <li className="value" key={id}>
                    {petBehaviour}
                  </li>
                ))
              : "-"}
          </ul>
        </div>
      </div>
    </div>
  );
};

function WalkDetailsCard({
  plan,
  status,
  walkSnapshortUrl,
  walkDistance,
  walkStartTime,
  walkEndTime,
  feedback,
  feedbackContent,
  walkRating,
  walkerPositiveQuality,
  walkerNegativeQuality,
  pets,
  trial, 
  otp
}) {
  return (
    <div>
      <div className="card-container">
        <div className="headline">
          <h3>{"Walk Details"}</h3>
        </div>
        <div>
          <div className="user-info-card">
            <span className="label">Plan Code:</span>
            <span className="value">{plan}</span>
          </div>

          {trial && <div className="user-info-card">
            <span className="label">OTP:</span>
            <span className="value">{otp}</span>
          </div>}

          {status === WalkStatus.COMPLETED ? (
            <>
              <img
                alt="walk path"
                src={walkSnapshortUrl}
                style={{ width: "100%" }}
              />
              <div className="user-info-card">
                <span className="label">Distance:</span>
                <span className="value">{walkDistance}</span>
              </div>
              <div className="user-info-card">
                <span className="label">Start Time:</span>
                <span className="value">{walkStartTime}</span>
              </div>
              <div className="user-info-card">
                <span className="label">End Time:</span>
                <span className="value">{walkEndTime}</span>
              </div>
              {feedback && (
                <>
                  <div className="user-info-card">
                    {/* Feedback: {walk.feedback.content || "-"} */}
                    <span className="label">Feedback:</span>
                    <span className="value">{feedbackContent}</span>
                  </div>
                  <div
                    className="user-info-card"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* Rating: {walk.feedback.rating || "-"} */}
                    <span className="label">Rating:</span>
                    <span className="value">
                      {walkRating}{" "}
                      <StarIcon
                        style={{ color: "#EF7123", fontSize: "1rem" }}
                      />{" "}
                    </span>
                  </div>
                  <div className="user-info-card">
                    Positive Qualties:
                    <ul>
                      {walkerPositiveQuality?.map((quality, id) => (
                        <li className="value" key={id}>
                          {quality}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="user-info-card">
                    Negative Qualties:
                    <ul>
                      {walkerNegativeQuality?.map((quality, id) => (
                        <li className="value" key={id}>
                          {quality}
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="user-info-card">
              <span className="label">Status:</span>
              <span className="value">{"Walk not completed yet"}</span>
            </div>
          )}
        </div>
      </div>

      <div className="card-container">
        <div className="headline">
          <h3>{"Pet Details"}</h3>
        </div>

        <div style={{width: "100%"}}>
          {pets.map((pet, id) => (
            <PetCard
              photo={pet.profilePicUrl || defaultUserImage}
              name={pet.name}
              breed={pet.petBreed?.name}
              behaviour={pet?.behaviour}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default WalkDetailsCard;
