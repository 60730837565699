import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import { HubStatus, defaultCountry } from "../../redux/constants";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import DateTimePickerComponent from "../DateTimePickerComponent/DateTimePickerComponent";
import { useSelector } from "react-redux";
import { getStates } from "../../redux/dataFetchers/appUserApis";
import { nodeServerBaseUrl } from "../../redux/dataFetchers/apiUrls";
import { request } from "../../utils/apiRequest";
import { setActionNotif } from "../../redux/actions";
import { applyButtonHandler } from "../../sales/Location/apis";
import { getCustomCities } from "../../redux/dataFetchers/salesDashboardApis";
import { getHubs } from "../../sales/apis";
import Chip from "@mui/material/Chip";

const classes = {
  actionBtn: {
    height: "48px",
    padding: "0 20px",
    fontSize: 16,
    display: "inline",
    backgroundColor: "#7D97FF",
    color: "white",
    fontWeight: "bold",
  },
};

const Filters = ({
  setHotspots,
  hotspots,
  setIsAddServicableArea,
  setSelectedMarker,
  filters,
  setFilters,
  filterSlotStartTime,
  setFilterSlotStartTime,
  filterSlotEndTime,
  setFilterSlotEndTime,
  districts,
  setDistricts,
  pincodes, 
  setPincodes,
  hubs,
  setHubs
}) => {
  const {
    user: { states },
    sales: { accessToken, customCities },
  } = useSelector((state) => state);
  
  const [selectedDay, setSelectedDay] = useState({
    today: true,
    custom: false,
  });
  const dispatch = useDispatch();

  const getHubsHandler = async ({ cityCodes, status }) => {
    try{
      const data = await getHubs({ cityCodes, status })
      setHubs(data)
    } catch(error){

    }
  }

  useEffect(() => {
    if (!customCities?.length) {
      dispatch(getCustomCities());
    }
    getHubsHandler({})
  }, []);

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const fetchDistrictsHandler = async (value) => {
    await request(
      `${nodeServerBaseUrl}/districts?stateCode=` + value.code,
      requestOptions,
      (response) => {
        setDistricts(response.data.data);
      }
    );

    setFilters({ stateCode: value });
  };

  return (
    <div>
      <div className="sales-controls">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            top: "5rem",
            paddingLeft: "1rem",
            // width: "90%",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "0.5rem",
              borderRadius: "0.5rem",
              boxShadow: "3px 3px 5px 6px #DFE1E720",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: ".875rem",
              }}
            >
              <div
                className="dash-filter filter location"
              >
                <Autocomplete
                  disablePortal
                  multiple
                  id="custom-city"
                  options={customCities || []}
                  sx={{ width: "100%" }}
                  value={filters?.cities ?? []}
                  isOptionEqualToValue={(option, value) =>
                    option?.code === value.code || value === ""
                  }
                  getOptionLabel={(option) => option?.name ?? option}
                  onChange={(event, value) => {
                    setFilters({ cities:value })
                    let cityCodes = value?.map((city)=>city.code)
                    getHubsHandler({ cityCodes });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Custom City" />
                  )}
                />
              </div>

              {/* <div
                className="dash-filter filter location"
                style={{
                  width: "8rem",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="districts"
                  options={districts}
                  sx={{ width: "100%" }}
                  value={filters?.districtId?.name ?? ""}
                  disableClearable={true}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value.id || value === ""
                  }
                  getOptionLabel={(option) => option?.name ?? option}
                  onChange={(event, value) => {
                    setFilters({ ...filters, districtId: value, pinCode: "" });
                    let pincodes = districts.find(
                      (district) => district.id == value.id
                    ).pincodes;
                    setPincodes(pincodes);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="District" required />
                  )}
                />
              </div> */}

              {/* <div
                className="dash-filter filter location"
                style={{
                  width: "8rem",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="pinCode"
                  options={pincodes}
                  sx={{ width: "100%" }}
                  value={filters?.pinCode ?? ""}
                  disableClearable={true}
                  isOptionEqualToValue={(option, value) =>
                    option === value || value === ""
                  }
                  getOptionLabel={(option) => option ?? option}
                  onChange={(event, value) => {
                    setFilters({ ...filters, pinCode: value });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Pincode" required />
                  )}
                />
              </div> */}

              <div
                className="dash-filter filter location"
                style={{
                  width: "25rem",
                }}
              >
                <Autocomplete
                  multiple
                  id="hubs"
                  options={hubs}
                  sx={{ width:"100%" }}
                  value={filters?.hubs ?? []}
                  getOptionLabel={(option) => `${option?.status === HubStatus.NON_SERVICEABLE ? "(U)" : ""} ${option?.name}`}
                  onChange={(event, value) => {
                    setFilters((prev) => ({ ...prev, hubs:value }));
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hubs"
                    />
                  )}
                />
              </div>


              <DateTimePickerComponent
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                selectedStartTime={filterSlotStartTime}
                setSelectedStartTime={setFilterSlotStartTime}
                selectedEndTime={filterSlotEndTime}
                setSelectedEndTime={setFilterSlotEndTime}
              />
              <div style={{ paddingTop: "0.4rem" }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    applyButtonHandler(
                      {
                        startTime: filterSlotStartTime,
                        endTime: filterSlotEndTime,
                        ...(filters["stateCode"]?.code
                          ? { stateCode: filters["stateCode"].code }
                          : {}),
                        ...(filters["districtId"]?.id
                          ? { districtId: filters["districtId"].id }
                          : {}),
                        ...(filters["pinCode"]
                          ? { pinCode: filters["pinCode"] }
                          : {}),
                        ...(filters?.cities?.length
                          ? { cityCode: filters?.cities?.map((city)=>city.code) }
                          : {}),
                        ...(filters?.hubs?.length
                          ? { hubIds: filters?.hubs?.map(hub => hub.id) }
                          : {}),
                      },
                      dispatch,
                      accessToken,
                      setHotspots
                    )
                  }
                  style={{
                    backgroundColor: "#974EC3",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "5rem",
                  }}
                >
                  Apply
                </Button>
              </div>
              <div style={{ paddingTop: "0.4rem" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setFilters({});
                    setHotspots([]);
                  }}
                  style={{
                    backgroundColor: "#974EC3",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "5rem",
                  }}
                >
                  Discard
                </Button>
              </div>
            </div>
          </div>
          {hotspots.length !== 0 && (
            <div>
              <Button
                variant="contained"
                onClick={() => {
                  setIsAddServicableArea(true);
                  setSelectedMarker(null);
                }}
                style={{
                  backgroundColor: "#974EC3",
                  color: "#fff",
                  marginTop: "1rem",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  width: "12rem",
                }}
              >
                <AddIcon /> Add Active Hotspots
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filters;
