import {
  SET_USER_ACCESS_TOKEN,
  SET_APP_USER_DATA,
  SET_PET_BREEDS,
  SET_USER_MEMORIES,
  SET_USER_POSTS,
  SET_SAVED_POSTS,
  SET_USER_ACTIVITY_PAGE_NO,
  SET_USER_PROFILE_COMPLETION_STEPS,
  SET_COUNTRIES,
  SET_STATES,
  SET_CITIES,
  ADD_PET_PROFILE,
} from "../constants";

export const setUserAccessToken = (data) => {
  return {
    type: SET_USER_ACCESS_TOKEN,
    payload: data,
  };
};

export const setAppUserData = (data) => {
  return {
    type: SET_APP_USER_DATA,
    payload: data,
  };
};

export const setPetBreeds = (data) => {
  return {
    type: SET_PET_BREEDS,
    payload: data,
  };
};

export const setUserPosts = (data) => {
  return {
    type: SET_USER_POSTS,
    payload: data,
  };
};

export const setUserMemories = (data) => {
  return {
    type: SET_USER_MEMORIES,
    payload: data,
  };
};

export const setSavedPosts = (data) => {
  return {
    type: SET_SAVED_POSTS,
    payload: data,
  };
};

export const setUserActivityPageNo = (data) => {
  return {
    type: SET_USER_ACTIVITY_PAGE_NO,
    payload: data,
  };
};

export const setUserProfileCompletionSteps = (data) => {
  return {
    type: SET_USER_PROFILE_COMPLETION_STEPS,
    payload: data,
  };
};

export const setCountries = (data) => {
  return {
    type: SET_COUNTRIES,
    payload: data,
  };
};

export const setStates = (data) => {
  return {
    type: SET_STATES,
    payload: data,
  };
};

export const setCities = (data) => {
  return {
    type: SET_CITIES,
    payload: data,
  };
};
