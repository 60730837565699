import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment';
import env from "../environment/production.json";

const analytics = Analytics({
  app: 'sploot-web-app',
  plugins: [
    segmentPlugin({
        writeKey: env.REACT_APP_SEGMENT_ANALYTICS_WRITE_KEY
    })
  ]
})

const actions = {
    page:(props) => {
        /* Track a page view */
        analytics.page(props)
    },
    track:(event,props) => {
        /* Track a custom event */
        analytics.track(event, props);
    },
    identify:(id, props) => {
        analytics.identify(id,props)
    }
}

export let AppAnalytics = actions;