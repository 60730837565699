import { Autocomplete, Button, TextField, Slider } from "@mui/material";
import defaultPetImage from "../../assets/images/pet-avatar.webp";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActionNotif } from "../../redux/actions";
import { getPlanOptions, getPlans, getSlots } from "./apis";
import { useRef } from "react";
import { useEffect } from "react";
import {
  getCities,
  getCountries,
  getStates,
  getUserByPhoneNumber,
} from "../../redux/dataFetchers/appUserApis";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-number-input";
import { forwardRef } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "react-phone-number-input/style.css";
import "./SalesSubscribeUserCard.css";
import { useContext } from "react";
import { SubscriptionsContext } from "../../sales/Users/Users";
import { bookSubscription, createUserSubscription } from "../../redux/dataFetchers/salesDashboardApis";
import { MediaUploadLoaderContext } from "../../App";
import {SlotDurationValue, SourceFilters, SubscriptionTypes, WalkStatus } from "../../redux/constants";
import ModalTitle from "../../common/ModalTitle/ModalTitle";
import SalesTrialSlotPicker from "../SalesSlotPicker/SalesTrialSlotPicker";
import SalesSubscriptionSlotPicker from "../SalesSlotPicker/SalesSubscriptionSlotPicker";

const label = { inputProps: { "aria-label": "Filter Checkbox" } };

const commonBtnStyle = {
  borderRadius: "50px",
  padding: "0.8rem 0",
  textTransform: "Capitalize",
  position: "relative",
  overflow: "hidden",
  marginTop: "0.6rem",
  width: "100%"
};

const classes = {
  btn: {
    ...commonBtnStyle,
    width: "90%",
    borderRadius: "15px",
    opacity: 0.5,
    background: "#EF7123", 
    color: "#fff",
    fontSize: 24,
    fontWeight: 700,
    padding: "0.5rem 0",
    display: "flex", 
    margin: "0 auto", 
    cursor: "not-allowed"
},
enabledBtn: {
    ...commonBtnStyle,
    width: "90%",
    borderRadius: "15px",
    background: "#EF7123", 
    color: "#fff",
    fontSize: 24,
    fontWeight: 700,
    padding: "0.5rem 0",
    display: "flex", 
    margin: "0 auto", 
},
  userInfoWrapper: {
    display: "flex",
    padding: "0.2rem",
    fontWeight: "bold",
    color: "rgb(168, 163, 163)",
  },
  petSelector: {
    fontWeight: "bold",
    color: "rgb(168, 163, 163)",
    display: "flex",
    padding: "0 0.2rem",
    alignItems: "center",
  },
  buyPlanMinibox: {
    padding: "0.6rem 0.3rem",
    margin: "0.6rem 0",
    boxShadow: "0px 2px 4px 0px grey",
    fontWeight: "bold",
  },
  miniBoxHeader: {
    color: "#EF7123"
  }
};

export function isEpoch(value) {
  return typeof value === 'number' && !isNaN(new Date(value).getTime());
}

export const PhoneCustomInputElement = forwardRef((props, ref) => (
  <TextField
    {...props}
    required
    id="outlined-basic"
    label="Phone"
    variant="outlined"
    fullWidth
    inputRef={ref}
  />
));

const petPriorityOrder = 2, maxPets = 2;

export default function SalesSubscribeUserCard({onClose, defaultUser=null, defaultPetIds=[], defaultWalkerId=null, trial}) {
  const [user, setUser] = useState(defaultUser);
  let {
    sales: { accessToken, subCities, data},
    user: { countries,  cities, states },
  } = useSelector((state) => state);
  const [subCitySelected, setSubCitySelected] = useState("");
  const [walk1JsonDate, setWalk1JsonDate] = useState("");
  const [walk2JsonDate, setWalk2JsonDate] = useState("");
  const [assignedWalker, setAssignedWalker] = useState("");
  const [addressInfo, setAddressInfo] = useState();
  const [petIds, setPetIds] = useState(defaultPetIds?.map(petId => ({ id:petId })));
  const [planOptions, setPlanOptions] = useState([])
  const [noOfWalks, setNoOfWalks] = useState(0);
  const [selectedOptionsCodes, setSelectedOptionsCodes] = useState([])
  const [subscriptionSource, setSubscriptionSource] = useState(SourceFilters.DASHBOARD);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [walkTogether, setWalkTogether] = useState(true);
  //const [trial, setTrial] = useState(true);
  const [price, setPrice] = useState(0);
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();
  const profilePicRef = useRef();
  const [selectedPlan, setSelectedPlan] = useState(
    plans?.find((o) => o.code === selectedOptionsCodes.join("-"))
  );
  const [subscriptionType, setSubscriptionType] = useState(SubscriptionTypes.REGULAR)

  const [isSchedule1SlotExist, setIsSchedule1SlotExist] = useState(false);
  const [isSchedule2SlotExist, setIsSchedule2SlotExist] = useState(false);
  
  const [schedule, setSchedule] = useState([""]);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [slotData, setSlotData] = useState([]);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [walkers, setWalkers] = useState([]);
  const [trialSlotRadius, setTrialSlotRadius] = useState(3);

  const { closeSubscribeUserModal, setLoadingParentData } = useContext(SubscriptionsContext);
  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(MediaUploadLoaderContext);

  useEffect(() => {
    if (!petIds?.length) {
      setDisabledBtn(true)
      return
    }
    if (petIds?.length > maxPets) {
      setDisabledBtn(true)
      return
    }
    if (schedule?.length === 2) {
      if (!isEpoch(schedule[0]) || !isEpoch(schedule[1])) {
        setDisabledBtn(true);
        setIsSchedule2SlotExist(false);
      } else {
        setDisabledBtn(false)
      } 
    } else if (schedule?.length === 1) {
      if (!isEpoch(schedule[0])) {
        setDisabledBtn(true)
        setIsSchedule2SlotExist(true);
      } else {
        setDisabledBtn(false)
      }
    }
    
  }, [petIds, schedule])

  const onSubscribeUserSuccess = (response) => {
    dispatch(setActionNotif({
      open: true,
      message: "User Subscribed!",
      severity: "success"
    }))
    // closeSubscribeUserModal()
    onClose();
    setLoadingParentData(true)

    closeMediaUploadLoader()
  }

  const onSubscribeUserFailure = (error) => {
    dispatch(setActionNotif({
      open: true,
      message: "Something Went Wrong!",
      severity: "error"
    }))
    closeMediaUploadLoader()
  }

  const subscribeUserClickHandler = () => {
    if ((addressInfo?.coords?.latitude && !addressInfo?.coords?.longitude)
      || (addressInfo?.coords?.longitude && !addressInfo?.coords?.latitude)) {
      dispatch(setActionNotif({
        open: true,
        message: "Enter Latitude Longitude as well",
        severity: "error"
      }));
      return;
    }

    if (!phoneNumber) {
      dispatch(setActionNotif({
        open: true,
        message: "Enter Phone number",
        severity: "error"
      }));
      return;
    }

    if (!selectedPlan && !trial) {
      dispatch(setActionNotif({
        open: true,
        message: `No Plan Exists of this type. Check if Frequency, Duration, Walker/Day and Pets are selected correctly`,
        severity: "error"
      }))
      return;
    }

    if(!trial && (selectedPlan?.walksPerDay % 2 === 0) && noOfWalks % selectedPlan?.walksPerDay !== 0) 
      return dispatch(setActionNotif({
          open: true,
          message: `Only Even Number of Walks allowed When booking ${selectedPlan.walksPerDay} walks per day!`,
          severity: "error",
      }));
    // const walk1 = new Date(walk1JsonDate).getTime(), walk2 = new Date(walk2JsonDate).getTime();
    // const schedule = [];
    // walk1JsonDate && schedule.push(walk1)
    // walk2JsonDate && schedule.push(walk2);
    // console.log({ schedule, trial })
    if ((!trial && selectedPlan?.walksPerDay !== schedule.length)
      || (trial && schedule.length !== 1)) {
      dispatch(setActionNotif({
        open: true,
        message: !trial ? "Walks timings not entered acc. to plan"
          : "Please enter time slot for Trial",
        severity: "error"
      }))
      return;
    }

console.log('subscribe user card')

    const seedSession = {
      mode: "SPLOOT",
      ...(selectedPlan?.code && { planCode: selectedPlan.code }),
      schedule,
      trial,
      ...(trial && {slotDuration: SlotDurationValue.MEDIUM}),
      address: {
        city: addressInfo.city,
        state: addressInfo.state,
        country: addressInfo.country,
        ...(addressInfo.pinCode && { pinCode: addressInfo.pinCode }),
        ...(addressInfo.address && { address: addressInfo.address }),
        ...((addressInfo?.coords?.mapLink || addressInfo?.coords?.latitude ||
          addressInfo?.coords?.longitude) && {
          coords:
          {
            ...addressInfo.coords,
            ...(addressInfo.coords?.latitude) && {
              latitude: parseFloat(addressInfo.coords.latitude)
            },
            ...(addressInfo.coords?.longitude) && {
              longitude: parseFloat(addressInfo.coords.longitude)
            }
          }
        })
      },
      walkers,
      phoneNumber,
      pets: petIds,
      status: WalkStatus.UPCOMING,
      creatorType: data?.type,
      walkTogether,
      amount: price,
      ...noOfWalks && { noOfWalks }
    }

    const subscription = {
      amount: price,
      creatorType: data.type,
      trial,
      ...(selectedPlan?.code && { planCode: selectedPlan.code }),
      seedSession,
      ...(!trial && {type: subscriptionType}),
      source: subscriptionSource,
      ...(trial && { 
        userId: user?.id,
        latitude:addressInfo.coords.latitude,
        longitude:addressInfo.coords.longitude,
        maxDistance:trialSlotRadius*1000,
      }),
    };
    openMediaUploadLoader();
    trial
      ? dispatch(
          bookSubscription(
            subscription,
            onSubscribeUserSuccess,
            onSubscribeUserFailure
          )
        )
      : dispatch(
          createUserSubscription(
            subscription,
            user?.id,
            onSubscribeUserSuccess,
            onSubscribeUserFailure
          )
        );
    }

  const searchUserSuccessHandler = (user) => {
    user?.id && setUser(user);
  }

  const searchUserFailureHandler = (error) => {
    dispatch(setActionNotif({
        open:true,
        message:error?.message || "Failed to search User",
        severity:"warning"
    }))
    console.log({ error });
  }

  useEffect(() => {
    setSelectedPlan(
      plans?.find((o) => o.code === selectedOptionsCodes.join("-"))
    );
  }, [selectedOptionsCodes, plans?.length]);

  const searchUserClickHandler = async () => {
      try {
          if(!phoneNumber) return;
          await getUserByPhoneNumber(phoneNumber,accessToken,searchUserSuccessHandler, searchUserFailureHandler);
      } catch (error) {
          console.log({ error });
      }
  };

  const handlePetSelection = (checked, petId) => {
    const separatePetsOptions = planOptions.find(o => o.priorityOrder === petPriorityOrder)?.values;
    if (checked) {
      if (!walkTogether && petIds.length >= separatePetsOptions?.length) return;
      if(petIds?.length >= maxPets) return;
      setPetIds((prev) => [...prev, { id: petId }]);
    }
    else
      setPetIds((prev) => {
        const newPetIds = prev.filter((pet) => pet.id !== petId);
        return newPetIds;
      });
  };

  const initGetPlanOptions = async () => {
    try {
      const data = await getPlanOptions(
        accessToken
      );
      if (data) setPlanOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const initGetPlans = async () => {
    try {
      const data = await getPlans(
        accessToken
      );
      if (data) setPlans(data);
    } catch (error) {
      console.log(error);
    }
  };
  
  // const triggerGetSlots = async (slotRadius, setIsSlotSearchDirty) => {
  //   let lat = parseFloat(addressInfo?.coords?.latitude);
  //   let lng = parseFloat(addressInfo?.coords?.longitude);

  //   if (user?.id && addressInfo?.coords) {
  //     try {
  //       setLoadingSlots(true)
  //       const data = await getSlots(
  //         accessToken,
  //         lat,
  //         lng,
  //         SlotDurationValue.MEDIUM,
  //         trial,
  //         user?.id,
  //         slotRadius
  //       )
  //       setSlotData(data?.data);
  //     } catch (err) {
  //       console.log(err, "Error");
  //     }

  //     setLoadingSlots(false)
  //     setIsSlotSearchDirty(false)
  //   }
  // }

  const setScheduleTime = (time, index) => {
    setSchedule((prev) => {
        let prevSchedule = [...prev];
        prevSchedule[index] = time || "";
        return prevSchedule;
    })
}

  useEffect(() => {
    const separatePetsOptions = planOptions.find(o => o.priorityOrder === petPriorityOrder)?.values;
    setSelectedOptionsCodes(prev => {
      let newCodes = [...prev];
      const petOption = separatePetsOptions?.find(o => o.noOfDogs === (walkTogether ? 1 : petIds?.length))
      newCodes[petPriorityOrder] = petOption?.code;
      return newCodes;
    })
  }, [planOptions?.length]) 
  // ? Add petIds.length and walkTogether when feature required in future

  useEffect(() => {
    if (user?.id) {
      const addressDetails = user?.address;
      setAddressInfo({
        city: addressDetails?.city || "",
        state: addressDetails?.state || "",
        country: addressDetails?.country || "",
        address: addressDetails?.address || "",
        pinCode: addressDetails?.pinCode || "",
        coords: addressDetails?.coords || {}
      });
      user?.subCityAssigned && setSubCitySelected(user.subCityAssigned);
      user?.phoneNumber && setPhoneNumber(user.phoneNumber);

      !planOptions.length && initGetPlanOptions();
      !plans.length && initGetPlans();
    }
  }, [user?.id]);

  useEffect(() => {
    setPrice(Math.ceil(selectedPlan?.price / 100) || 0)
  }, [selectedPlan?.id]);

  useEffect(() => {
    !countries.length && dispatch(getCountries());
  }, []);

  useEffect(() => {
    addressInfo?.country && dispatch(getStates(addressInfo?.country?.code));
  }, [addressInfo?.country]);

  useEffect(() => {
    addressInfo?.state &&
      dispatch(getCities(addressInfo?.country?.code, addressInfo?.state?.code));
  }, [addressInfo?.state]);


  
  // useEffect(() => {
  //   if(!isSlotSearchDirty) 
  //     setIsSlotSearchDirty(() => {
  //       setWalk1JsonDate("")
  //       return true;
  //     })
  // }, [addressInfo?.coords, slotRadius])

  const handleCoordsChange = (coords, type) => {
    const regex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (coords === '' || regex.test(coords)) {
      setAddressInfo(prev =>
      ({
        ...prev,
        coords: {
          ...prev?.coords, [type]: parseFloat(coords)
        }
      }))
    }
  }

  useEffect(() => {
    if (selectedOptionsCodes?.[3] === "ONE-WALK") {
      if (!trial) {
        setSchedule([""])
      }
    } else if (selectedOptionsCodes?.[3] === "TWO-WALKS") {
      if (!trial) {
        setSchedule(["", ""])
      }
    }
  }, [selectedOptionsCodes])

  return (
    <div style={{ paddingBottom: "1rem" }}>
      <ModalTitle title={"Plan Details"} onClose={onClose} />

      <div className="card-container" style={{ padding: "1rem 1rem" }}>
        <div className="dash-filter filter" style={{ width: "100%" }}>
          <PhoneInput
            key="user-phone"
            style={{ width: "100%" }}
            value={phoneNumber}
            onChange={setPhoneNumber}
            inputComponent={PhoneCustomInputElement}
            defaultCountry="IN"
            disabled={Boolean(user?.id)}
          />
        </div>
      </div>

      {!user?.id && (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#974EC3",
            color: "#fff",
            marginTop: "1rem",
            fontWeight: "bold",
            fontSize: "1rem",
            width: "7rem",
            display: "flex",
            margin: "0 auto",
            marginBottom: "1rem",
          }}
          onClick={searchUserClickHandler}
        >
          Search
        </Button>
      )}
      {user?.id && (
        <div className="book-plan-form">
          <div
            className="card-container"
            style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <img
                src={user?.photoUrl || defaultPetImage}
                ref={profilePicRef}
                onError={() => (profilePicRef.current.src = defaultPetImage)}
                alt="user profile"
                className="pet-profile-image"
              />
              <span className="name">{user?.name}</span>
            </div>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <TextField
                label="Street Address"
                variant="outlined"
                type="text"
                value={addressInfo?.address || ""}
                onChange={(e) =>
                  setAddressInfo((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }
                fullWidth
                autoComplete="off"
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
              />
            </div>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <Autocomplete
                disablePortal
                id="user-country"
                options={countries}
                sx={{ width: "100%" }}
                value={addressInfo?.country?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.name === value || value === ""
                }
                disableClearable={true}
                getOptionLabel={(option) => option.name ?? option}
                onChange={(event, value) => {
                  setAddressInfo((prev) => ({
                    ...prev,
                    city: "",
                    state: "",
                    country: value,
                  }));
                }}
                renderInput={(params) => (
                  <form autoComplete="off">
                    <TextField {...params} label="Country" required />
                  </form>
                )}
              />
            </div>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <Autocomplete
                disablePortal
                id="user-state"
                options={states}
                sx={{ width: "100%" }}
                value={addressInfo?.state?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.name === value || value === ""
                }
                disableClearable={true}
                getOptionLabel={(option) => option.name ?? option}
                onChange={(event, value) => {
                  setAddressInfo((prev) => ({
                    ...prev,
                    city: "",
                    state: value,
                  }));
                }}
                renderInput={(params) => (
                  <form autoComplete="off">
                    <TextField {...params} label="State" required />
                  </form>
                )}
              />
            </div>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <Autocomplete
                disablePortal
                id="user-city"
                options={cities}
                sx={{ width: "100%" }}
                value={addressInfo?.city?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.name === value || value === ""
                }
                disableClearable={true}
                getOptionLabel={(option) => option.name ?? option}
                onChange={(event, value) => {
                  setAddressInfo((prev) => ({ ...prev, city: value }));
                }}
                renderInput={(params) => (
                  <form autoComplete="off">
                    <TextField {...params} label="City/District" required />
                  </form>
                )}
              />
            </div>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Pincode"
                value={addressInfo?.pinCode}
                onChange={(event) =>
                  setAddressInfo((prev) => ({
                    ...prev,
                    pinCode: event.target.value,
                  }))
                }
                variant="outlined"
                fullWidth
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15, color: "GrayText" } }}
              />
            </div>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Latitude"
                value={addressInfo?.coords?.latitude || ""}
                onChange={(event) =>
                  handleCoordsChange(event.target.value, "latitude")
                }
                variant="outlined"
                fullWidth
                inputProps={{ style: { fontSize: 15 } }}
              />
            </div>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Longitude"
                value={addressInfo?.coords?.longitude || ""}
                onChange={(event) =>
                  handleCoordsChange(event.target.value, "longitude")
                }
                variant="outlined"
                fullWidth
                inputProps={{ style: { fontSize: 15 } }}
              />
            </div>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="mapLink"
                value={addressInfo?.coords?.mapLink || ""}
                onChange={(event) =>
                  setAddressInfo((prev) => ({
                    ...prev,
                    coords: { ...prev.coords, mapLink: event.target.value },
                  }))
                }
                variant="outlined"
                fullWidth
                inputProps={{ style: { fontruetSize: 15 } }}
              />
            </div>
          </div>

          <div className="card-container">
            <div className="headline">
              <h3>{`Select Pets (max ${maxPets})`}</h3>
            </div>
            <div
              className="form-field-wrapper"
              style={{ justifyContent: "normal" }}
            >
              {user?.petProfiles?.map(
                (pet) =>
                  !pet.isDeleted && (
                    <div style={classes.petSelector} key={pet.id}>
                      <Checkbox
                        {...label}
                        checked={Boolean(petIds.find((o) => o.id === pet.id))}
                        onChange={(event) =>
                          handlePetSelection(event.target.checked, pet.id)
                        }
                      />
                      {pet.name}
                    </div>
                  )
              )}
            </div>
          </div>

          {trial ? (
            <div className="card-container">
              <div className="headline">
                <h3>{"Trial"}</h3>
              </div>
              {schedule?.map((time, index) => (
                <SalesTrialSlotPicker
                  key={index}
                  accessToken={accessToken}
                  selectedTime={schedule[index]}
                  setScheduleTime={(scheduleTime) =>
                    setScheduleTime(scheduleTime, index)
                  }
                  userId={user?.id}
                  lat={addressInfo?.coords?.latitude}
                  lng={addressInfo?.coords?.longitude}
                  slotRadius={trialSlotRadius}
                  setSlotRadius={setTrialSlotRadius}
                />
              )
              )}
            </div>
          ) : (
            <>
              <div className="card-container">
                <div className="headline">
                  <h3>{"Subscription"}</h3>
                  <p style={{ color: "#000" }}>(Choose Plan)</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.8rem",
                    width: "100%",
                  }}
                >
                  {planOptions?.map(
                    (option, index) =>
                      option?.priorityOrder !== petPriorityOrder && (
                        <div
                          className="dash-filter filter"
                          style={{ width: "100%" }}
                          key={`${option.name}-${index}`}
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="select-input-id" required>
                              {option.name}
                            </InputLabel>
                            <Select
                              labelId="select-mui"
                              id="select-mui-id"
                              defaultValue=""
                              sx={{ width: "100%" }}
                              onChange={(event) => {
                                setSelectedOptionsCodes((prev) => {
                                  let newCodes = [...prev];
                                  newCodes[option.priorityOrder] =
                                    event.target.value.code;
                                  return newCodes;
                                });
                              }}
                            >
                              {option.values?.map((value, index) => (
                                <MenuItem value={value} key={index}>
                                  {value?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      )
                  )}
                        {!trial? <div
                          className="dash-filter filter"
                          style={{ width: "100%" }}
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="select-input-id" required>
                             Subscription Type
                            </InputLabel>
                            <Select
                              labelId="select-mui"
                              id="select-mui-id"
                              value={subscriptionType}
                              sx={{ width: "100%" }}
                              onChange={(event) => {
                                setSubscriptionType(event.target.value)
                              }}
                            >
                              {
                                Object.keys(SubscriptionTypes).map((option, index)=>{
                                  return <MenuItem value={SubscriptionTypes[option]} key={index}>
                                  {SubscriptionTypes[option]}
                                </MenuItem>
                                })
                              }
                              
                            </Select>
                          </FormControl>
                        </div> : <></>}
                  <p style={{color:"red"}}>* Only Even Number of Walks allowed When booking Even walks per day!</p>
                    <TextField
                      id="outlined-basic"
                      label="Number of Walks"
                      value={noOfWalks || ''}
                      type="text"
                      onChange={(event) => {
                        const value = event.target.value;
                        const numericValue = +value.replace(/\D/g, ''); 

                        if (numericValue >= 0 && numericValue <= 200) { // Check for value within 0 to 200
                            setNoOfWalks(numericValue);
                        }      
                      }}
                      variant="outlined"
                      fullWidth
                      inputProps={{ 
                          style: { fontSize: 15 },
                          // This is to hide the browser's default arrows
                          'min': 0, // Ensuring the value is not negative
                          'max':200
                      }}
                      // Additional styles to hide arrows in number input
                      InputProps={{
                          style: {
                              MozAppearance: 'textfield', // For Firefox
                          },
                      }}
                      />
                </div>
              </div>

              <div className="card-container">
                <div className="headline">
                  <h3>{"Subscription"}</h3>
                  <p style={{ color: "#000" }}>(Walk Details)</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.8rem",
                    marginTop: "1rem",
                    width: "100%",
                  }}
                >
                  {schedule?.map((time, index) => (
                    <SalesSubscriptionSlotPicker
                      key={index}
                      accessToken={accessToken}
                      subscriptionPlan={selectedPlan}
                      lat={addressInfo?.coords?.latitude}
                      lng={addressInfo?.coords?.longitude}
                      selectedTime={schedule[index]}
                      noOfWalks={noOfWalks}
                      walksPerDay={schedule.length}
                      walkIndex={index}
                      setScheduleTime={(scheduleTime) =>
                        setScheduleTime(scheduleTime, index)
                      }
                      setIsSchedule1SlotExist={setIsSchedule1SlotExist}
                      setIsSchedule2SlotExist={setIsSchedule2SlotExist}
                      isSchedule1SlotExist={isSchedule1SlotExist}
                      isSchedule2SlotExist={isSchedule2SlotExist}
                      scheduleIndex={index}
                      setSelectedWalkers={setWalkers}
                      showPreferredTime={false}
                      preferredWalkerId={defaultWalkerId}
                    />
                  ))}

                  <div className="dash-filter filter" style={{ width: "100%" }}>
                    <TextField
                      label="Amount"
                      variant="outlined"
                      type="number"
                      value={price}
                      disabled
                      fullWidth
                      required
                      autoComplete="off"
                      inputProps={{ style: { fontSize: 15 } }}
                      InputLabelProps={{
                        style: { fontSize: 15, color: "GrayText" },
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <Button
           style={(disabledBtn || ((!isSchedule2SlotExist || !isSchedule1SlotExist) && !trial)) ? classes.btn : classes.enabledBtn}
           disabled={(disabledBtn || ((!isSchedule2SlotExist || !isSchedule1SlotExist) && !trial))  ? true: false}
            onClick={!disabledBtn ? subscribeUserClickHandler : undefined}
          >
            Subscribe
          </Button>
        </div>
      )}
    </div>
  );
}
