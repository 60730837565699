import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { List as Li, ListItem, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { userNavActions } from "../../../redux/constants";
import { AppAnalytics } from "../../../utils/appAnalytics";

const useStyles = makeStyles(() => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  link: {
    textDecoration: "none",
  },
}));

const List = ({ anchor, toggleDrawer, navList, openSignoutModal }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Li>
        {navList?.left?.map((navItem, i) => (
          <ListItem
            button
            key={i}
            onClick={() => {
              if (navItem.eventToTrack) {
                AppAnalytics.track(navItem.eventToTrack);
              }

              if (navItem.action) {
                switch (navItem.actionType) {
                  case userNavActions.LOGOUT:
                    navItem.action(openSignoutModal);
                    break;
                  case userNavActions.CONTACT:
                    navItem.action();
                    break;
                  default:
                }
                return;
              }

              navItem.external
                ? (window.location.href = navItem.linkTo)
                : navigate(navItem.linkTo);
            }}
          >
            {navItem.Icon ? navItem.Icon : null}
            <ListItemText
              primary={navItem.linkText}
              style={{ marginLeft: "17%" }}
            />
          </ListItem>
        ))}
        {navList?.right?.map((navItem, i) => (
          <ListItem
            button
            key={i}
            onClick={() => {
              if (navItem.eventToTrack) {
                AppAnalytics.track(navItem.eventToTrack);
              }

              if (navItem.action) {
                switch (navItem.actionType) {
                  case userNavActions.LOGOUT:
                    navItem.action(openSignoutModal);
                    break;
                  case userNavActions.CONTACT:
                    navItem.action();
                    break;
                  default:
                }
                return;
              }

              navItem.external
                ? (window.location.href = navItem.linkTo)
                : navigate(navItem.linkTo);
            }}
          >
            {navItem.Icon ? navItem.Icon : null}
            <ListItemText
              primary={navItem.linkText}
              style={{ marginLeft: "17%" }}
            />
          </ListItem>
        ))}
      </Li>
    </div>
  );
};

export default List;
