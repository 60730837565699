export const copyToClipboard = (text) => {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    elem.setSelectionRange(0, 99999); /* For mobile devices */

    // Copy the text inside the text field
    navigator.clipboard?.writeText(elem.value);
    document.body.removeChild(elem);
}