import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Autocomplete } from '@mui/material';
import { createContext, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import SalesSubscribeUserCard from '../../components/SalesSubscribeUserCard/SalesSubscribeUserCard';
import SalesSubscriptionDisplay from '../../components/SalesSubscriptionDisplay/SalesSubscriptionDisplay';
import "./Users.css"
import { cancelTrial, getUserProductCredits } from './apis';
import SalesEditSubscribedUserForm from '../../components/SalesEditSubscribedUserForm/SalesEditSubscribedUserForm';
import SalesEditSubscriptionCard from '../../components/SalesEditSubscriptionCard/SalesEditSubscriptionCard';
import SalesEditUserDetails from '../../components/SalesEditUserDetails/SalesEditUserDetails';
import {SalesCity, defaultCountry, SourceFilters, UserProductCancellationReason, UserProductCreditStatus, WalkingType, HubStatus } from '../../redux/constants';
import SalesAddUserDetails from '../../components/SalesAddUserDetails/SalesAddUserDetails';
import { getStates } from '../../redux/dataFetchers/appUserApis';
import { fetchDistrictsHandler } from '../Walks/apis';
import AutoCompleteInput from '../../common/AutoCompleteInput/AutoCompleteInput';
import DateTimePickerComponent from '../../components/DateTimePickerComponent/DateTimePickerComponent';
import ModalTitle from '../../common/ModalTitle/ModalTitle';
import { MediaUploadLoaderContext } from '../../App';
import { request } from '../../utils/apiRequest';
import { nodeServerBaseUrl } from '../../redux/dataFetchers/apiUrls';
import { setActionNotif } from '../../redux/actions';
import SelectableButton from '../../common/SelectableButton/SelectableButton';
import { exportHandler } from '../../redux/dataFetchers/common';
import { getCustomCities } from '../../redux/dataFetchers/salesDashboardApis';
import { getHubs } from '../apis';

const classes = {
  actionBtn: {
    height: "48px",
    padding: "0 20px",
    fontSize: 16,
    display: "inline",
    backgroundColor: "#7D97FF",
    color: "white",
    fontWeight: "bold"
  }
}
const initSlotStartTime = new Date().setHours(0, 0, 0, 0),
  initSlotEndTime = new Date().setHours(0,0,0,0) + 24 * 60 * 60 * 1000;
export const SubscriptionsContext = createContext();

export default function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState([]); //?user product credits
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [cityFilter, setCityFilter] = useState('');
  const { sales: { accessToken, customCities }, user: {states} } = useSelector(state => state)
  const [currentSubscribedUser, setCurrentSubscribedUser] = useState(null)
  const { openMediaUploadLoader, closeMediaUploadLoader } = useContext(MediaUploadLoaderContext);
  const [currentSubscription, setCurrentSubscription] = useState(null); //?current user product credit
  const [subscribeUserModalOpen, setSubscribeUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [isReasonTextfieldShow, setIsReasonTextfieldShow] = useState(false);
  const [walkCancellationReason, setWalkCancellationReason] = useState("");
  const [walkCancellationDescription,  setWalkCancellationDescription] = useState("");
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [editSubscribedUserModalOpen, setEditSubscribedUserModalOpen] = useState(false);
  const [editSubscriptionModalOpen, setEditSubscriptionModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState({ today: true, yesterday: false, tomorrow: false, custom: false });
  const [filterSlotStartTime, setFilterSlotStartTime] = useState(initSlotStartTime);
  const [filterSlotEndTime, setFilterSlotEndTime] = useState(initSlotEndTime);
  const [sourceFilter, setSourceFilter] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [currUpc, setCurrUpc] = useState({});
  const [cancelledUpcs, setCancelledUpcs] = useState(false);
  const [cancelWalkConfirmModal, setCancelWalkConfirmModal] = useState(false)
  const isTrial = true;
  const dispatch = useDispatch();

  const [districts, setDistricts] = useState([]);
  const [pincodes, setPincodes] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [locationFilters, setLocationFilters] = useState({});

  const closeEditSubscriptionModal = () => setEditSubscriptionModalOpen(false);
  const openEditSubscriptionModal = () => setEditSubscriptionModalOpen(true);

  const closeSubscribeUserModal = () => setSubscribeUserModalOpen(false);
  const openSubscribeUserModal = () => setSubscribeUserModalOpen(true);

  const openCancelWalkConfirmModal = () => setCancelWalkConfirmModal(true);
  const closeCancelWalkConfirmModal = () => {
    setCancelWalkConfirmModal(false);
    setIsReasonTextfieldShow(false);
    setWalkCancellationDescription("")
    setWalkCancellationReason("");
  };

  const closeEditSubscribedUserModal = () => setEditSubscribedUserModalOpen(false);
  const openEditSubscribedUserModal = () => setEditSubscribedUserModalOpen(true);

  const closeEditUserModal = () => setEditUserModalOpen(false);
  const openEditUserModal = () => setEditUserModalOpen(true);

  const closeAddUserModal = () => setAddUserModalOpen(false);
  const openAddUserModal = () => setAddUserModalOpen(true);

  const initGetUserProductCredits = async (exportData) => {
    try {
      subscriptions?.length && setSubscriptions([]);
      let addressFilter;
      if (cityFilter === SalesCity.DELHI) {
        addressFilter = { stateName: cityFilter };
      } else if (cityFilter && cityFilter !== SalesCity.DELHI) {
        addressFilter = { cityName: cityFilter };
      }
      addressFilter = {...addressFilter,
        ...(locationFilters?.["stateCode"]?.code
          && { stateCode: locationFilters?.["stateCode"]?.code }),
        ...(locationFilters?.["districtId"]?.id
          && { districtId: locationFilters?.["districtId"]?.id }),
        ...(locationFilters?.["pinCode"]
          && { pinCode: locationFilters?.["pinCode"] }),
        ...(locationFilters?.cities?.length && {cityCode: locationFilters?.cities?.map((city)=> city.code)}),
        ...(locationFilters?.hubs?.length && {hubIds: locationFilters?.hubs?.map(hub => hub?.id) }),
      };
      const data = await getUserProductCredits(
        accessToken,
        dispatch,
        setLoadingSubscriptions,
        isTrial,
        { addressFilter, sourceFilter },
        filterSlotStartTime,
        filterSlotEndTime,
        exportData,
        cancelledUpcs,
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  }

  const getMatchingPetNames = (subscribedPets, userPets) => {
    if (!subscribedPets?.length || !userPets?.length) {
      return [];
    } 

    let petData = [];
    subscribedPets.forEach((subscribedPet) => {
      let actualPet = userPets.find((userPet)=> userPet.id == subscribedPet.id);
      if(actualPet) petData.push(actualPet?.name)
    });
    
    return petData;
  }

  const exportButtonHandler = async () => {
    try{
    openMediaUploadLoader()
    setIsExporting(true);
    const data = await initGetUserProductCredits(true);
    const exportedArray = [];

    let startDate = new Date(filterSlotStartTime).toLocaleDateString("en-us", { year: "numeric",
    month: "long",
    day: "numeric",})

    let endDate = new Date(filterSlotEndTime).toLocaleDateString("en-us", { year: "numeric",
    month: "long",
    day: "numeric"})

    data?.map((subs) => {
      const petName = getMatchingPetNames(subs.seedSession.pets, subs.user.petProfiles);
      exportedArray.push({
          "Lead Date": new Date(subs.createdAt).toLocaleString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }),
            "Name" : subs?.user?.name,
            "Phone Number": subs?.user?.phoneNumber,
            "address": subs.user?.address?.address,
            "source": subs?.source,
            "Pets": petName?.length > 0 ? petName.join(', ') : '',
        })
    });

    exportHandler(exportedArray, `Trial Walks Data from ${startDate} to ${endDate}.csv`)
    setIsExporting(false);
    closeMediaUploadLoader()
    }catch(error){
      console.log(error)
      closeMediaUploadLoader()
      setIsExporting(false); 
    }
  
  }

 
  const cancelTrialClickHandler = (userProductCredit) => {
    setCurrUpc(userProductCredit)
    openCancelWalkConfirmModal()
  }

  const sourceFilterOptions = Object.values(SourceFilters); 

  const subscriptionDisplayClickHandler = (subscription) => {
    setCurrentSubscription(subscription)
    openEditSubscriptionModal();
  }

  const subscriptionUserClickHandler = (user) => {
    setCurrentSubscribedUser(user)
    openEditSubscribedUserModal()
  }

  const contextValue = {
    setLoadingParentData:setLoadingSubscriptions,
    closeSubscribeUserModal,
    closeEditSubscribedUserModal,
    closeEditSubscriptionModal,
    closeEditUserModal,
    subscriptionUserClickHandler,
    closeAddUserModal
  }

  useEffect(() => {
    if(loadingSubscriptions){
      (async()=>{
        let data = await initGetUserProductCredits();
        setSubscriptions(data);
      })()
    }
  
  }, [accessToken, loadingSubscriptions])

  const getHubsHandler = async ({ cityCodes, status }) => {
    try{
      const data = await getHubs({ cityCodes, status })
      setHubs(data)
    } catch(error){

    }
  }

  useEffect(() => {
    if (!customCities?.length) {
      dispatch(getCustomCities());
    }
    getHubsHandler({});
  }, []);

  const handleDiscardFilters = () => {
    setCityFilter("");
    setLoadingSubscriptions(true)
    setLocationFilters({})
  }

  const cancelTrialButtonHandler =async () => {
    try{
      openMediaUploadLoader();
      await cancelTrial(accessToken,closeCancelWalkConfirmModal, dispatch, walkCancellationDescription, walkCancellationReason, currUpc, initGetUserProductCredits)
      closeMediaUploadLoader();
    }catch(err){}
  }

  return (
    <SubscriptionsContext.Provider value={contextValue}>
      <div className="walkers-container">
        <div className='pending-filter-bar'>
          <div style={{display: "flex", flexDirection: 'row', gap: "1rem", alignItems: "center", flexWrap:"wrap"}}>
          {/* <div className='dash-filter filter'>
          <FormControl sx={{ width: "100%", height: "56px"}}>
            <InputLabel id="select-input-id">Select City</InputLabel>
            <Select
              labelId="city"
                  id="select-verification"
                  value={cityFilter}
                sx={{width: "100%", height: "56px"}}
              onChange={(event) => setCityFilter(event.target.value)}
            >
              {Object.values(SalesCity).map(city =>
                <MenuItem value={city} key={city}>{city}</MenuItem>
              )}
            </Select>
          </FormControl>
            </div> */}

            <DateTimePickerComponent
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                selectedStartTime={filterSlotStartTime}
                setSelectedStartTime={setFilterSlotStartTime}
                selectedEndTime={filterSlotEndTime}
                setSelectedEndTime={setFilterSlotEndTime}
              />
            
            <div className="dash-filter filter">
                <Autocomplete
                  multiple
                  disablePortal
                  id="custom-city"
                  options={customCities || []}
                  sx={{ width: "100%" }}
                  value={locationFilters?.cities ?? []}
                  isOptionEqualToValue={(option, value) =>
                    option?.code === value.code || value === ""
                  }
                  getOptionLabel={(option) => option?.name ?? option}
                  onChange={(event, value) => {
                    setLocationFilters({cities: value})
                      let cityCodes = value?.map(city => city.code)
                      getHubsHandler({ cityCodes })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Custom City" required />
                  )}
                />
              </div>

              <div
                className="dash-filter filter"
                style={{width:"25rem"}}
              >
                <Autocomplete
                  id="hubs"
                  multiple
                  options={hubs || []}
                  sx={{ width: "100%" }}
                  value={locationFilters?.hubs ?? []}
                  getOptionLabel={(option) => `${option?.status === HubStatus.NON_SERVICEABLE ? "(U)" : ""} ${option?.name}`}
                  onChange={(event, value) => {
                    setLocationFilters(prev => { return { ...prev, hubs:value } });
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Hubs" />
                  )}
                />
              </div>
              {/* <div
                className="dash-filter filter"
              >
                <Autocomplete
                  disablePortal
                  id="districts"
                  options={districts || []}
                  sx={{ width: "100%" }}
                  value={locationFilters?.districtId?.name ?? ""}
                  disableClearable={true}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value.id || value === ""
                  }
                  getOptionLabel={(option) => option?.name ?? option}
                  onChange={(event, value) => {
                    setLocationFilters(prev => { return { ...prev, districtId: value, pinCode: "" } });
                    let pincodes = districts.find(
                      (district) => district.id === value.id
                    ).pincodes;
                    setPincodes(pincodes);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="District" required />
                  )}
                />
              </div>

              <div
                className="dash-filter filter"
                
              >
                <Autocomplete
                  disablePortal
                  id="pinCode"
                  options={pincodes}
                  sx={{ width: "100%" }}
                  value={locationFilters?.pinCode ?? ""}
                  disableClearable={true}
                  isOptionEqualToValue={(option, value) =>
                    option === value || value === ""
                  }
                  getOptionLabel={(option) => option ?? option}
                  onChange={(event, value) => {
                    setLocationFilters(prev => { return { ...prev, pinCode: value } });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Pincode" required />
                  )}
                />
              </div> */}

            <AutoCompleteInput
                optionsList={sourceFilterOptions}
                selected={sourceFilter || ""}
                setSelected={setSourceFilter}
                value={sourceFilter}
                validationFunc={(option, value) => option === value || value === ""}
                findlabelFunc={(option) => option}
                label={"Source"}
                />
            
            <SelectableButton selected={cancelledUpcs} setSelected={setCancelledUpcs} label="Cancelled" />

         {isTrial &&  <div className='sales-controls buttons-wrapper' style={{marginBottom: "1rem"}}>
          <Button style={classes.actionBtn} onClick={openSubscribeUserModal}>Subscribe</Button>
          <Button style={classes.actionBtn} onClick={openAddUserModal}>
            Add User</Button>
          <Button style={classes.actionBtn} onClick={openEditUserModal}>
            Update User</Button>
          </div>}
          </div>

           <div style={{display: "flex", justifyContent: "space-between", gap: "2rem"}}>
            <div style={{ display: "flex", gap: "2rem" }}>
              <Button variant='contained' style={{backgroundColor:"#974EC3", color: "#fff", marginTop: "0.5rem", height: "40px", fontWeight: "bold", fontSize: "1rem", width: "7rem"}} onClick={() => setLoadingSubscriptions(true)}>
                Apply
              </Button>
              <Button variant='contained' style={{backgroundColor:"#974EC3", color: "#fff", marginTop: "0.5rem", height: "40px",fontWeight: "bold", fontSize: "1rem", width: "7rem"}} onClick={handleDiscardFilters}>
                Discard
              </Button>
              <Button variant='contained' disabled={isExporting} style={{backgroundColor:"#974EC3", color: "#fff", marginTop: "0.5rem", height: "40px", fontWeight: "bold", fontSize: "1rem", width: "7rem"}} onClick={exportButtonHandler}>
                {isExporting? "wait...":"Export"}
              </Button>
            </div>

            <h3 style={{display: "flex", flexDirection: "column", justifyContent: "end", whiteSpace: "pre"}}>Count- {subscriptions?.length}</h3>
          </div>
        </div>
        
        <div className="users-info-header walks-heading">
          <span>Subcription Billing Cycles</span>
          <span>Billing Date</span>
          <span>Walk Preferred Date</span>
          <span>Subscribed User</span>
          {/* <span>Payment Mode</span> */}
          <span>Source</span>
          <span>SubCity</span>
          <span>Type</span>
        </div>
        {loadingSubscriptions && (
          <Loader
            wrapperStyle={{ height: "70vh" }}
            loaderStyle={{ color: "#974EC3" }}
          />
        )}
        {subscriptions?.map((subscription) =>
          <SalesSubscriptionDisplay subscription={subscription} key={subscription.id} onClick={() => subscriptionDisplayClickHandler(subscription)} cancelTrialClickHandler={cancelTrialClickHandler}/>
        )}
        <Modal open={editSubscribedUserModalOpen} onClose={closeEditSubscribedUserModal}>
          <div className="modal-default modal-sales modal-max">
            <SalesEditSubscribedUserForm user={currentSubscribedUser} />
          </div>
        </Modal>
        <Modal open={editSubscriptionModalOpen} onClose={closeEditSubscriptionModal}>
          <div className="modal-default modal-sales modal-maxh">
            <SalesEditSubscriptionCard subscription={currentSubscription} onClose={closeEditSubscriptionModal}/>
          </div>
        </Modal>
        <Modal open={cancelWalkConfirmModal} onClose={closeCancelWalkConfirmModal}>
          <div className="modal-default modal-sales" style={classes.walkConfirmModal}>
            <ModalTitle title={"Cancel"} onClose={closeCancelWalkConfirmModal} />

            <div className="card-container">
            <span>Are you Sure you want to cancel ?</span>
            <div className="dash-filter filter" style={{ width: "100%" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="select-input-id" required>
                 Cancellation Reason
                </InputLabel>
                <Select
                  labelId="select-mui"
                  id="select-mui-id"
                  defaultValue=""
                  onChange={(event) => {
                    setIsReasonTextfieldShow(event.target.value === UserProductCancellationReason.OTHER)
                    setWalkCancellationReason(event.target.value);
                  }}
                >
                  {Object.keys(UserProductCancellationReason)?.map(
                    (reasonKey, index) => (
                      <MenuItem
                        value={UserProductCancellationReason[reasonKey]}
                        key={index}
                      >
                        {UserProductCancellationReason[reasonKey]}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
            {isReasonTextfieldShow && <TextField
                id="outlined-basic"
                label="Reason...?"
                multiline
                rows={2}
                variant="outlined"
                style={{ width: "100%", margin:"0.2rem 0" }}
                onChange={(e) => setWalkCancellationDescription(e.target.value)}
                inputProps={{ form: { autocomplete: "off" } }}
            />}
            </div>
            <Button variant="contained" 
            onClick={() => cancelTrialButtonHandler()} 
            disabled={!walkCancellationReason || (walkCancellationReason === UserProductCancellationReason.OTHER && !walkCancellationDescription)}
            style={{ height: "3rem", fontSize: 18, fontWeight: 600, width: "fit-content", display: "flex", margin: "0 auto", marginBottom: "1rem" }} color="warning">Confirm</Button>
          </div>
        </Modal>
        <Modal open={subscribeUserModalOpen} onClose={closeSubscribeUserModal}>
          <div className="modal-default modal-sales modal-maxh">
            <SalesSubscribeUserCard onClose={closeSubscribeUserModal} trial={true}/>
          </div>
        </Modal>
        <Modal open={editUserModalOpen} onClose={closeEditUserModal}>
          <div className="modal-default modal-sales modal-maxh">
            <SalesEditUserDetails onClose={closeEditUserModal}/>
          </div>
        </Modal>
        <Modal open={addUserModalOpen} onClose={closeAddUserModal}>
          <div className="modal-default modal-sales modal-maxh">
            <SalesAddUserDetails onClose={closeAddUserModal}/>
          </div>
        </Modal>
      </div>
    </SubscriptionsContext.Provider>
  )
}
