import React from "react";
import { CircularProgress } from "@mui/material";
import "./Loader.css";

export default function Loader({ wrapperStyle, loaderStyle }) {
  return (
    <div className="loader-wrapper-default" style={wrapperStyle}>
      <CircularProgress style={loaderStyle} />
    </div>
  );
}
