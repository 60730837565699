import {
    SET_DASHBOARD_FORM_OPEN,
    SET_HEADER_VISIBILITY,
    SET_ADMIN_DASHBOARD_DATA_LOADER,
    SET_ACTION_NOTIF,
    SET_INITIAL_APP_LOAD,
    SET_OPEN_IN_APP_DIALOG,
    deeplinkEntities,
    SET_SEPARATE_EVENT_DATA,
  } from "../constants";
  import env from "../../environment/production.json";
  
  export default function eventsReducer(
    state = {
        separateEventData:null,
    },
    action = {}
  ) {
    switch (action.type) {
      case SET_SEPARATE_EVENT_DATA:
        return {
          ...state,
          separateEventData: action.payload,
        };
      default:
        return state;
    }
  }
  