//CMS dashboard form
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_TITLE = "SET_TITLE";
export const SET_TAG = "SET_TAG";
export const SET_REDIRECT_URI = "SET_REDIRECT_URI";
export const SET_CMS_TYPE = "SET_CMS_TYPE";
export const SET_IMG_URL = "SET_IMG_URL";
export const SET_IMG = "SET_IMG";
export const SET_STATUS = "SET_STATUS";
export const SET_DASHBOARD_FORM_DATA = "SET_DASHBOARD_FORM_DATA";
export const SET_CURRENT_DASHBOARD_ACTION = "SET_CURRENT_DASHBOARD_ACTION";

//CMS types
export const EXPERIENCES = "EXPERIENCES";
export const SHOWANDTELL = "SHOWANDTELL";
export const BANNERS = "BANNERS";
export const TOPICS = "TOPICS";
export const QUESTS = "QUESTS";

//CMS dashboard Actions
export const UPDATE = "UPDATE";
export const CREATE = "CREATE";
export const DELETE = "DELETE";

export const CMS_FEED_PAGE_SIZE = 15;

//CMS dashboard data
export const SET_CMS_EXPERIENCES_LIST = "SET_CMS_EXPIERENCE_LIST";
export const SET_CMS_SHOWANDTELL_LIST = "SET_CMS_SHOWANDTELL_LIST";
export const SET_CMS_BANNERS_LIST = "SET_CMS_BANNERS_LIST";
export const SET_BUSINESS_CATEGORIES_LIST = "SET_BUSINESS_CATEGORIES_LIST";
export const SET_LEARN_WHAT_TO_SHOW= "SET_WHAT_TO_SHOW";
export const SET_LEARN_CATEGORY_ID= "SET_CATEGORY_ID";
export const SET_LEARN_COURSE_ID= "SET_COURSE_ID";
export const SET_LEARN_CHAPTER_ID= "SET_CHAPTER_ID";
export const SET_LEARN_CATEGORIES_LIST = "SET_LEARN_CATEGORIES_LIST ";
export const SET_LEARN_COURSES_LIST = "SET_LEARN_COURSES_LIST";
export const SET_LEARN_CHAPTERS_LIST= "SET_LEARN_CHAPTERS_LIST";
export const SET_LEARN_QUIZ= "SET_LEARN_QUIZ";
export const SET_LEARN_TASK= "SET_LEARN_TASK";
export const SET_EVENT_WHAT_TO_SHOW= "SET_EVENT_WHAT_TO_SHOW";
export const SET_EVENT_TAGS_LIST = "SET_EVENT_TAGS_LIST";
export const SET_EVENT_VIDEOS_LIST = "SET_EVENT_VIDEOS_LIST";
export const SET_BUSINESS_LISTINGS_LIST = "SET_BUSINESS_LISTINGS_LIST";
export const SET_SCRAPED_DATA_LIST = "SET_SCRAPED_DATA_LIST";
export const SET_BOTS_LIST = "SET_BOTS_LIST";
export const SET_SHOP_DISCOUNTS_LIST = "SET_SHOP_DISCOUNTS_LIST";
export const SET_SHOP_SECTION_LIST = "SET_SHOP_SECTION_LIST"
export const SET_CMS_TOPICS_LIST = "SET_CMS_TOPICS_LIST";
export const SET_CMS_TOPIC_POSTS_LIST = "SET_CMS_TOPIC_POSTS_LIST";
export const SET_CMS_FEED = "SET_CMS_FEED";
export const SET_CMS_PINNED_POSTS = "SET_CMS_PINNED_POSTS";
export const SET_CMS_SCHEDULED_POSTS = "SET_CMS_SCHEDULED_POSTS";
export const SET_CMS_FEED_PAGE = "SET_CMS_FEED_PAGE";
export const SET_CMS_FEED_TOTAL = "SET_CMS_FEED_TOTAL";
export const SET_CMS_FEED_DELETION_IDS = "SET_CMS_FEED_DELETION_IDS";
export const SET_CMS_COMMENTS_DELETION_IDS = "SET_CMS_COMMENTS_DELETION_IDS";
export const SET_CMS_COMMENT = "SET_CMS_COMMENT";
export const SET_CMS_FEED_FORM_DATA = "SET_CMS_FEED_FORM_DATA";
export const SET_EXPERT_PROFILE_DATA = "SET_EXPERT_PROFILE_DATA";
export const SET_CMS_GROUP_CHATS = "SET_CMS_GROUP_CHATS";
export const SET_CMS_OPEN_CHAT_MEMBERS = "SET_CMS_OPEN_CHAT_MEMBERS";
export const SET_CMS_OPEN_CHAT = "SET_CMS_OPEN_CHAT";
export const chatTypes = {
  MULTIPLE: "MULTIPLE",
  SINGLE: "SINGLE"
}
export const chatRoles = {
  ADMIN: "ADMIN",
  MEMBER: "MEMBER"
}
export const learnTypes = {
  CATEGORY: "CATEGORY",
  COURSE: "COURSE",
  CHAPTER: "CHAPTER"
}
export const eventShowTypes = {
  VIDEOS: "VIDEOS",
  TAGS: "TAGS",
  EVENTS: "EVENTS"
}
export const SubscriptionTypes = {
  REGULAR: "REGULAR",
  ADHOC : "ADHOC"
}

export const WalkingType = { TRIAL: "TRIAL", SUBSCRIPTION: "SUBSCRIPTION", }; 
export const WalkingTrialType = { PAID: "PAID", FREE: "FREE", };

//CMS auth
export const SET_ADMIN_AUTH_TOKEN = "SET_ADMIN_AUTH_TOKEN";
export const SET_ADMIN_USER_DATA = "SET_ADMIN_USER_DATA";
export const SET_ADMIN_ROLE = "SET_ADMIN_ROLE";

//UI
export const SET_HEADER_VISIBILITY = "SET_HEADER_VISIBILITY";
export const SET_DASHBOARD_FORM_OPEN = "SET_DASHBOARD_FORM_OPEN";
export const SET_ADMIN_DASHBOARD_DATA_LOADER =
  "SET_ADMIN_DASHBOARD_DATA_LOADER";

//List business
export const LISTING_SELECT_CATEGORY = "LISTING_SELECT_CATEGORY";
export const LISTING_FILL_FORM = "LISTING_FILL_FORM";
export const listingStages = [LISTING_SELECT_CATEGORY, LISTING_FILL_FORM];
export const SET_LISTING_STAGE_POINTER = "SET_LISTING_STAGE_POINTER";
export const listingCategories = {
  BOARDING_CENTER: "BOARDING_CENTER",
  NUTRITIONIST: "NUTRITIONIST",
};
export const SET_LISTING_CATEGORY = "SET_LISTING_CATEGORY";
export const SET_SPLOOT_FINDER_RESULTS_CACHE_TOKEN =
  "SET_SPLOOT_FINDER_RESULTS_CACHE_TOKEN";
export const SET_SPLOOT_FINDER_INPUT_MODIFIED =
  "SET_SPLOOT_FINDER_INPUT_MODIFIED";

//search results
export const RESULTS_PAGE_SIZE = 10;
export const SET_SPLOOT_FINDER_SEARCH_RESULTS =
  "SET_SPLOOT_FINDER_SEARCH_RESULTS";
export const SET_SPLOOT_FINDER_PAGE_NUMBER = "SET_SPLOOT_FINDER_PAGE_NUMBER";
export const SET_SPLOOT_FINDER_CATEGORY = "SET_SPLOOT_FINDER_CATEGORY";
export const SET_SPLOOT_FINDER_LOCATION = "SET_SPLOOT_FINDER_LOCATION";
export const SET_SPLOOT_FINDER_ADDRESS = "SET_SPLOOT_FINDER_ADDRESS";
export const SET_CURRENT_SEARCH_RESULT_PAGE_DATA =
  "SET_CURRENT_SEARCH_RESULT_PAGE_DATA";
export const SET_TOTAL_SEARCH_RESULT_PAGES = "SET_TOTAL_SEARCH_RESULT_PAGES";
export const TOGGLE_SEARCH_RESULTS_NAVIGATION_STATE =
  "TOGGLE_SEARCH_RESULTS_NAVIGATION_STATE";
export const SET_SEARCH_RESULTS_LOADED = "SET_SEARCH_RESULTS_LOADED";

//pet profile actions (no states involved)
export const profileActions = {
  EDIT: "EDIT",
  ADD: "ADD",
  ONBOARD: "ONBOARD",
  ADD_FOR_WALK: "ADD_FOR_WALK"
};

//user signup prompts
export const userEntryActions = {
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
};

//book a trial steps
export const bookTrialSteps = {
  SIGNIN: "SIGNIN",
  CREATE_PET_PROFILE: "CREATE_PET_PROFILE",
  BOOK_TRIAL: "BOOK_TRIAL",
  TRIAL_BOOKED: "TRIAL_BOOKED"
}

//Toast Message
export const SET_ACTION_NOTIF = "SET_ACTION_NOTIF";

//Open in app dialog
export const SET_OPEN_IN_APP_DIALOG = "SET_OPEN_IN_APP_DIALOG";
export const deeplinkEntities = {
  FEED: "FEED",
  EVENT: "EVENT"
}

//web app user
export const SET_USER_ACCESS_TOKEN = "SET_USER_ACCESS_TOKEN";
export const SET_APP_USER_DATA = "SET_APP_USER_DATA";
export const SET_DISCUSS_DATA = "SET_DISCUSS_DATA";
export const SET_USER_MEMORIES = "SET_USER_MEMORIES";
export const SET_USER_POSTS = "SET_USER_POSTS";
export const SET_SAVED_POSTS = "SET_SAVED_POSTS";
export const SET_USER_ACTIVITY_PAGE_NO = "SET_USER_ACTIVITY_PAGE_NO";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_STATES = "SET_STATES";
export const SET_CITIES = "SET_CITIES";

//user profile completion steps
export const NAME_AND_LOC = "NAME_AND_LOC";
export const ADD_PROFILE_PIC = "ADD_PROFILE_PIC";
export const CREATE_PET_PROFILE = "CREATE_PET_PROFILE";
export const POST_ON_FEED = "POST_ON_FEED";
export const userProfileCompletionSteps = [
  NAME_AND_LOC,
  ADD_PROFILE_PIC,
  CREATE_PET_PROFILE,
  POST_ON_FEED,
];
export const SET_USER_PROFILE_COMPLETION_STEPS =
  "SET_USER_PROFILE_COMPLETION_STEPS";
//user sidenav actions
export const userNavActions = {
  LOGOUT: "LOGOUT",
  CONTACT: "CONTACT",
};

//user activity actions
export const activityCategories = {
  MEMORIES: "MEMORIES",
  POSTS: "POSTS",
  SAVED_POSTS: "SAVED_POSTS",
};

//pets
export const SET_PET_BREEDS = "SET_PET_BREEDS";
export const petBehaviourCode = {
  CALM: "CALM",
  FRIENDLY: "FRIENDLY",
  GETS_EASILY_EXCITED: "GETS_EASILY_EXCITED",
  GETS_EASILY_SCARED: "GETS_EASILY_SCARED",
  NOT_GOOD_WITH_OTHER_DOGS: "NOT_GOOD_WITH_OTHER_DOGS"
}

//universal
export const SET_ACCOUNT_ROLE = "SET_ACCOUNT_ROLE";
export const accountRoles = {
  REGISTERED_USER: "REGISTERED_USER",
  ADMIN: "ADMIN",
  SALES: "SALES",
  GUEST: "GUEST",
};
export const SET_INITIAL_APP_LOAD = "SET_INITIAL_APP_LOAD";

//discuss
export const DISCUSS_DEFAULT_PAGE_SIZE = 15;
export const DISCUSS_INITIAL_PAGE_NO = 0;
export const SET_DISCUSS_PAGE_NO = "SET_DISCUSS_PAGE_NO";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SET_SEPARATE_POST_ID = "SET_SEPARATE_POST_ID";
export const searchKeys = {
  CONTENT: "content",
  TAGS: "tags",
};
export const discussActions = {
  ADD: "ADD",
};
export const SET_SEARCH_RESULTS_USER = "SET_SEARCH_RESULTS_USER";
export const COMMENTS_PREVIEW_THRESHOLD = 5;
export const VIDEO_BYTE_LIMIT = 40 * 1024 * 1024; //file size same before compression 40MB
export const IMAGE_BYTE_LIMIT = 10 * 1024 * 1024; //compressed file size limit 10MB
export const discussPostType = {
  BASIC: "BASIC",
  QUESTION: "QUESTION",
  MEMORY: "MEMORY",
  POLL: "POLL",
  REEL: "REEL"
}

//events
export const SET_SEPARATE_EVENT_DATA = "SET_SEPARATE_EVENT_DATA";

//sales dashboard
export const walkerFilterType = {
  SINGLE_WALK: "SINGLE_WALK",
  UPCOMING_WALKS: "UPCOMING_WALKS",
  DEFAULT_WALKS: "DEFAULT_WALKS",
  TIME_RANGE_WALKS: "TIME_RANGE_WALKS"
}
export const WalkerLeavesStatus = {
  APPROVED : "APPROVED",
  PENDING : "PENDING",
  DENIED : "DENIED"
}
export const RescheduleWalksType = {
  SINGLE_WALK: "SINGLE_WALK",
  UPCOMING_WALKS: "UPCOMING_WALKS",
  DEFAULT_WALKS: "DEFAULT_WALKS",
  DUPLICATE_WALKS: "DUPLICATE_WALKS",
  TIME_RANGE_WALKS: "TIME_RANGE_WALKS"
}
export const SET_APP_SALES_PERSON_DATA = "SET_APP_SALES_PERSON_DATA";
export const SET_SALES_ACCESS_TOKEN = "SET_SALES_ACCESS_TOKEN";
export const walkerCrudActions = {
  ADD: "ADD",
  EDIT: "EDIT"
}
export const leaveCrudActions = {
  ADD: "ADD",
  EDIT: "EDIT"
}
export const SET_SUB_CITIES = "SET_SUB_CITIES";
export const SET_CUSTOM_CITIES = "SET_CUSTOM_CITIES";
export const walksFilters = {
  SLOT: "SLOT",
  SUB_CITIES: "SUB_CITIES",
  DELAYED: "DELAYED"
}
export const WalkStatus = {
  CANCELLED: "CANCELLED",
  UPCOMING: "UPCOMING",
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
  ARTIFICIALLY_COMPLETED: "ARTIFICIALLY_COMPLETED",
  UNATTENDED: "UNATTENDED",
  MANUALLY_COMPLETED: "MANUALLY_COMPLETED"
}
export const WalkRequestStage = {
  IN_PROGRESS :"IN_PROGRESS",
  ASSIGNED: "ASSIGNED",
  CANCELLED: "CANCELLED"
}
export const WalkRequestStatus = {
  CREATED : "CREATED",
  FINDING : "FINDING",
  MANUALLY_ASSIGNED : "MANUALLY_ASSIGNED",
  SYSTEM_ASSIGNED : "SYSTEM_ASSIGNED",
  MANUALLY_UNASSIGNED : "MANUALLY_UNASSIGNED",
  SYSTEM_UNASSIGNED : "SYSTEM_UNASSIGNED",
  CANCELLED : "CANCELLED"
}

export const WalkRequestCycle = {
  CYCLE_1 :{
    name:"Cycle 1",
    value:1
  },
  CYCLE_2 :{
    name:"Cycle 2",
    value:2
  },
}

export const WalkRequestType = {
  NEW : "NEW",
  RESCHEDULE_SINGLE_WALK : "RESCHEDULE_SINGLE_WALK",
  RESCHEDULE_MULTIPLE_WALKS : "RESCHEDULE_MULTIPLE_WALKS",
  REPLACE_SINGLE_WALK : "REPLACE_SINGLE_WALK",
  REPLACE_MULTIPLE_WALKS : "REPLACE_MULTIPLE_WALKS",
}

export const WalkRequestTypeDropdown = {
  NEW :{
    name:"New",
    value:[WalkRequestType.NEW]
  },
  RESCHEDULE :{
    name:"Reschedule",
    value:[WalkRequestType.RESCHEDULE_SINGLE_WALK, WalkRequestType.RESCHEDULE_MULTIPLE_WALKS]
  },
  REPLACE :{
    name:"Replace",
    value:[WalkRequestType.REPLACE_SINGLE_WALK, WalkRequestType.REPLACE_MULTIPLE_WALKS]
  },
}

export const WalkRequestWalkerAction = {
  ACCEPTED :"ACCEPTED",
  DENIED: "DENIED",
  NO_ACTION: "NO_ACTION",
}

export const WalkRequestSystemAction = {
  RULED_OUT: "RULED_OUT",
  SKIPPED: "SKIPPED"
}

export const WalkRequestAction = {
  FORCE_STOP: "FORCE_STOP",
}

export const HubStatus = {
  SERVICEABLE:"SERVICEABLE",
  NON_SERVICEABLE:"NON_SERVICEABLE"
}

export const UserProductCreditStatus = {
  CANCELLED: "CANCELLED",
  PENDING: "PENDING"
}

export const WalkActionStatus = {
  UNASSIGNED:"UNASSIGNED",
  DUPLICATE: "DUPLICATE",
  CONVERT_TO_SUBSCRIPTION: "CONVERT_TO_SUBSCRIPTION",
  COPY: "COPY"
}

export const EPOCH = {
  ONE_MINUTE_MS : 60*1000,
  ONE_SECOND_MS : 1000,
  ONE_HOUR_MS : 60*60*1000,
  ONE_DAY_MS : 24*60*60*1000,
  ONE_MONTH_MS : 30*24*60*60*1000,
  INDIAN_TIMEZONE_OFFSET_MS : 5*60*60*1000 + 30*60*1000
}

export const WeekDay = {
  MONDAY : "MONDAY",
  TUESDAY : "TUESDAY",
  WEDNESDAY : "WEDNESDAY",
  THURSDAY : "THURSDAY",
  FRIDAY : "FRIDAY",
  SATURDAY : "SATURDAY",
  SUNDAY : "SUNDAY",
}

export const DaysOfWeek = [WeekDay.SUNDAY, WeekDay.MONDAY, WeekDay.TUESDAY, WeekDay.WEDNESDAY, WeekDay.THURSDAY, WeekDay.FRIDAY, WeekDay.SATURDAY]

export const SlotDurationValue = {
  SMALL : 20*EPOCH.ONE_MINUTE_MS,
  MEDIUM : 40*EPOCH.ONE_MINUTE_MS
}

export const WalkType = {
  SINGLE_WALK: "SINGLE_WALK",
  DEFAULT_WALKS: "DEFAULT_WALKS",
  UPCOMING_WALKS: "UPCOMING_WALKS",
};

export const SEARCH_PERIOD_IN_DAYS_AUTOMATCHING = 10;

export const SourceFilters = {
    WEB : "WEB",
    APP: "APP", 
    DASHBOARD: "DASHBOARD"
}

export const CheckInStatus = {
  YES: "YES", 
  NO: "NO",
  ON_TIME: "ON_TIME",
  LATE: "LATE"
}

export const WalkerCheckinDataTags = {
  TSHIRT_MISSING:{ key:"TSHIRT_MISSING", text:"T-shirt Missing", color:"#FFE5D5" },
  KIT_MISSING:{ key:"KIT_MISSING", text:"Kit Missing", color:"#CFD6F0" },
  BOTH_MISSING:{ key:"BOTH_MISSING", text:"Both Missing" , color:'#FBA898'},
  BOTH_OK:{ key:"BOTH_OK", text:"Both Ok" , color:"#BBF8D0"},
}

export const SubscriptionsFilters = {
  NONE: "NONE",
  CANCELLATION_REQUEST: "CANCELLATION_REQUEST"
}

export const SubscriptionStatus = {
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  //TO_BE_CANCELLED: "TO_BE_CANCELLED",
  CANCELLED: "CANCELLED",
  //COMPLETED: "COMPLETED"
}

export const SubscriptionTabStatus = {
  NEW:"NEW",
  ...SubscriptionStatus,
}

export const SubscriptionCancellationMode = {
  CANCEL_AT_CYCLE_END: "CANCEL_AT_CYCLE_END",
  CANCEL_IMMEDIATELY: "CANCEL_IMMEDIATELY"
}

export const WalkerAssetCategory = {
  PROFILE_IMAGE: "PROFILE_IMAGE",
  PROFESSIONAL_IMAGE: "PROFESSIONAL_IMAGE",
  KIT: "KIT",
  TSHIRT: "TSHIRT",
  AADHAAR: "AADHAAR",
  PAN: "PAN"
}

export const WalkerVerification = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  LEAD: "LEAD"
}

export const WalkerTags = {
  TRAINING: "TRAINING", 
  REGULAR: "REGULAR"
}

export const SalesCity = {
  GURGAON: "Gurgaon",
  DELHI: "Delhi",
  NOIDA: "Noida",
  GHAZIABAD: "Ghaziabad",
  FARIDABAD: "Faridabad",
  FARIDABAD_DISTRICT: "Faridabad District",
  BANGALORE_URBAN: "Bangalore Urban",
  BANGALORE_RURAL: "Bangalore Rural",
}

export const WalkerCheckinSlot = {
  MORNING: "MORNING",
  EVENING: "EVENING"
}

export const MakeCallTo = {
  WALKER: "WALKER",
  CUSTOMER: "CUSTOMER",
  TEAM:"TEAM"
}

export const defaultCountry = {
  id: 101,
  name: "India",
  code: "IND"
}

export const EditWalkMode = {
  WALKER_REPLACED: "WALKER_REPLACED",
  WALK_RESCHEDULED: "WALK_RESCHEDULED",
  WALK_CANCELLED:"WALK_CANCELLED",
  DUPLICATE_WALK: "DUPLICATE_WALK"
}

export const WalkerReplacementReason =  {
  WALKER_NOT_AVAILABLE : "Walker Not Available",
  WALKER_NOT_REACHABLE : "Walker Not Reachable",
  WALKER_REQUEST_FOR_CHANGE : "Walker Request for Change",
  CUSTOMER_REQUEST_FOR_WALKER_CHANGE_QUALITY : "Customer Request for Walker Change - Quality",
  CUSTOMER_REQUEST_FOR_WALKER_CHANGE_TIME : "Customer Request for Walker Change - Time",
  OTHER : "OTHER",
}

export const SubscriptionCancellationReason =  {
  WALKER_QUALITY : "Walker Quality",
  SUPPLY_ISSUE : "Supply Issue",
  PRICE_ISSUE : "Price Issue",
  SHORT_TERM_REQUIREMENT : "Short Term Requirement",
  NO_FEEDBACK_SHARED : "No feedback shared",
  OTHER : "Other"
}


export const WalkerCancellationReason = {
  WALKER_REQUEST_FOR_CANCELLATION : "Walker Request for Cancellation",
  WALKER_NOT_REACHABLE : "Walker Not Reachable",
  USER_REQUEST_FOR_CANCELLATION:"User Request for Cancellation",
  CUSTOMER_NOT_REACHABLE : "Customer Not Reachable",
  OTHER: "Other"
}

export const SUBSCRIPTION_CANCELLED = "Subscription Cancelled"


export const UserProductCancellationReason = {
  SUPPLY_ISSUE : "SUPPLY_ISSUE",
  IRRELEVANT_LEAD : "IRRELEVANT_LEAD",
  CUSTOMER_DNP : "CUSTOMER_DNP",
  CUSTOMER_RESCHEDULE : "CUSTOMER_RESCHEDULE",
  OTHER : "OTHER"
}

export const Source = {
  APP: "APP",
  DASHBOARD: "DASHBOARD",
  SYSTEM: "SYSTEM"
}

export const WalkCancellationFilter = [
  {
    source: Source.DASHBOARD,
    reason: WalkerCancellationReason.WALKER_NOT_REACHABLE
  },
  {
    source: Source.DASHBOARD,
    reason: WalkerCancellationReason.WALKER_REQUEST_FOR_CANCELLATION
  },
  {
    source: Source.DASHBOARD,
    reason: WalkerCancellationReason.OTHER
  },
  {
    source: Source.DASHBOARD,
    reason: WalkerCancellationReason.USER_REQUEST_FOR_CANCELLATION
  },
  {
    source: Source.DASHBOARD,
    reason: WalkerCancellationReason.CUSTOMER_NOT_REACHABLE
  },
  {
    source: Source.APP,
    reason: WalkerCancellationReason.USER_REQUEST_FOR_CANCELLATION
  },
  {
    source: Source.SYSTEM,
    reason: SUBSCRIPTION_CANCELLED
  }
]

export const WalkerCompletionReason = {
  TECH_ISSUE:"TECH_ISSUE",
  WALKER_ISSUE:"WALKER_ISSUE",
  OTHER:"OTHER"
}

export const WalkSlotDuration = {
  SMALL: 20 * 60 * 1000,
  MEDIUM: 40 * 60 * 1000
}

export const SET_DASHBOARD_ROLE = "SET_DASHBOARD_ROLE";

export const RatingFilterType = {
  NO_RATING : "NO_RATING",
  RATING : "RATING"
}

export const Ratings = [1, 2, 3, 4, 5];
export const monthsOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const SlotType = [
    {
      value: "MORNING",
      label: "Morning",
    },
    {
      value: "EVENING",
      label: "Evening",
    },
    {
      value: "ALL_SLOTS",
      label: "All Slots",
    },
    {
      value: "SPECIFIC_SLOTS",
      label: "Select Slot",
    },
  ]

export const slots = [
  {
    start:5,
    startMinute:30,
    end:7,
    endMinute:0
  },
  {
    start:6,
    startMinute:0,
    end:7,
    endMinute:0
  },
  {
    start:7,
    startMinute:0,
    end:8,
    endMinute:0
  },
  {
    start:8,
    startMinute:0,
    end:9,
    endMinute:0
  },
  {
    start:9,
    startMinute:0,
    end:10,
    endMinute:0
  },
   {
    start:10,
    startMinute:0,
    end:11,
    endMinute:0
  },
  {
    start:11,
    startMinute:0,
    end:12,
    endMinute:0
  },
  {
    start:12,
    startMinute:0,
    end:13,
    endMinute:0
  },
  {
    start:13,
    startMinute:0,
    end:14,
    endMinute:0
  },
  {
    start:14,
    startMinute:0,
    end:15,
    endMinute:0
  },
  {
    start:15,
    startMinute:0,
    end:16,
    endMinute:0
  },
  {
    start:16,
    startMinute:0,
    end:17,
    endMinute:0
  },
  {
    start:17,
    startMinute:0,
    end:18,
    endMinute:0
  },
  {
    start:18,
    startMinute:0,
    end:19,
    endMinute:0
  },
  {
    start:19,
    startMinute:0,
    end:20,
    endMinute:0
  },
  {
    start:20,
    startMinute:0,
    end:21,
    endMinute:0
  },
  {
    start:21,
    startMinute:0,
    end:22,
    endMinute:0
  },
  {
    start:21,
    startMinute:0,
    end:22,
    endMinute:30
  }
]

export const TodayStartEpoch = new Date().setHours(0, 0, 0, 0);
export const TomorrowStartEpoch = new Date().setHours(0,0,0,0) + 24 * 60 * 60 * 1000;
