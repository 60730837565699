import { eraseCookie } from "./cookies";
import env from "../environment/production.json";

export const request = async (requestUrl, requestOptions, successHandler = () => null, failureHandler = () => null) => {
      try {
        let response = await fetch(
          requestUrl,
          requestOptions
        );
    
        let parsedResponse = await response.json();

        if (response.ok) {
          return successHandler(parsedResponse);
        } else throw parsedResponse;

      } catch (error) {
        //logout unauthorized users
        if(error?.statusCode === 401){
          const pathArray = window.location.pathname.split("/");
          const pathPrefix = pathArray[1], pathSuffix = pathArray[pathArray.length-1];

          switch(pathPrefix){
            case "admin":
              window.localStorage.removeItem("authToken");

              if(pathSuffix !== "login")
                window.location.href = `${window.location.protocol
                }//${window.location.host}/${pathPrefix}/login`;
              break;
            case "sales":
              // alert("101")
              // window.localStorage.removeItem("salesAccessToken")
              
              // if(pathSuffix !== "login")
              //   window.location.href = `${window.location.protocol
              //   }//${window.location.host}/${pathPrefix}/login`;
              // break;
            default: //user app
            //   eraseCookie(
            //     "userAccessToken",
            //     document.domain.replace(`${env.REACT_APP_SUBDOMAIN}.`, "")
            //   );
              
            // const subdomain = window.location.host.split('.')[0];

            // if(subdomain === env.REACT_APP_SUBDOMAIN)
            //   window.location.href = `${window.location.protocol
            //     }//${window.location.host.replace(
            //       `${env.REACT_APP_SUBDOMAIN}.`,
            //       env.NODE_ENV === "staging" ? "staging." : ""
            //     )}/login`;
          }
        }
        console.log({error})
        await failureHandler(error)
      }
}
