import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import env from "../environment/production.json";
import * as firebaseAuth from 'firebase/auth';

const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// firestore
export const db = getFirestore(app);

export { firebaseAuth };
