import { setActionNotif } from "../../redux/actions";

export const isSlotSelected = (slot, selectedSlots) => {
    let isSlotFind = selectedSlots.some((selectedSlot) => {
      if (
        slot.start === selectedSlot.start.hour &&
        slot.startMinute === selectedSlot.start.minute &&
        slot.end === selectedSlot.end.hour &&
        slot.endMinute === selectedSlot.end.minute
      )
        return true;

      return false;
    });

    return isSlotFind;
  };

 export const removeSlots = (slotIndex, selectedSlots) => {
    let data = selectedSlots.filter((_, index) => index !== slotIndex);
    return data;
  }

  export const addSlots = (slot, dispatch, selectedSlots) => {
    if (
        (slot.start === 5 &&
          slot.startMinute === 30 &&
          slot.end === 7 &&
          slot.endMinute === 0 &&
          isSlotSelected({
            start: 6,
            startMinute: 0,
            end: 7,
            endMinute: 0,
          }, selectedSlots)) ||
        (slot.start === 6 &&
          slot.startMinute === 0 &&
          slot.end === 7 &&
          slot.endMinute === 0 &&
          isSlotSelected({
            start: 5,
            startMinute: 30,
            end: 7,
            endMinute: 0,
          }, selectedSlots))
      ) {
         dispatch(
          setActionNotif({
            open: true,
            message: `You have already selected either 6-7 or 5:30 - 7`,
            severity: "error",
          })
        );
        return null
      }
  
      if (
        (slot.start === 21 &&
          slot.startMinute === 0 &&
          slot.end === 22 &&
          slot.endMinute === 0 &&
          isSlotSelected({
            start: 21,
            startMinute: 0,
            end: 22,
            endMinute: 30,
          }, selectedSlots)) ||
        (slot.start === 21 &&
          slot.startMinute === 0 &&
          slot.end === 22 &&
          slot.endMinute === 30 &&
          isSlotSelected({
            start: 21,
            startMinute: 0,
            end: 22,
            endMinute: 0,
          }, selectedSlots))
      ) {
         dispatch(
          setActionNotif({
            open: true,
            message: `You have already selected either 21-22 or 21 - 22:30`,
            severity: "error",
          })
        );
        return null
      }
  
      let updatedSlots = [
        ...selectedSlots,
        {
          start: {
            hour: slot.start,
            minute: slot.startMinute,
          },
          end: {
            hour: slot.end,
            minute: slot.endMinute,
          },
        },
      ];
  
      updatedSlots.sort((a, b) => {
        if (a.start.hour !== b.start.hour) {
            return a.start.hour - b.start.hour;
        } else {
            return a.end.hour - b.end.hour;
        }
    });
    return updatedSlots;
  }