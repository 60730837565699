import React, { useEffect, useRef, forwardRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { accountRoles } from "../../../redux/constants";
import { logoutAdmin } from "../../../redux/dataFetchers/adminCMSDashboardApis";
import Button from "../../Button/Button";
import "./DesktopHeader.css";

const defaultUserImage =
  process.env.PUBLIC_URL + "/assets/images/pet-parent-avatar.webp";

const classes = {
  logoutIcon: {
    marginLeft: "auto",
  },
  signupBtn: {
    borderRadius: "5px",
    padding: "0.6rem 0.8rem",
    margin: 0,
    fontSize: "0.9rem",
  },
  navItem: {
    textDecoration: "none",
    color: "black",
    padding: "1.6rem 0",
  },
  loginBtn: {
    padding: "1.2rem",
    fontWeight: "bold",
    textDecoration: "none",
    color: "black",
  },
};

export default function DesktopHeader({ navList }) {
  const location = useLocation();
  const linkRefs = useRef([]);
  const userProfileImgRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, universal, admin } = useSelector((state) => state);
  const baseProfilePath = `users/${user.data.id}`;
  const isUserRole =
    universal.accountRole === accountRoles.REGISTERED_USER && user.accessToken;
  const isAdminRole =
    universal.accountRole === accountRoles.ADMIN && admin.authToken;
  console.log({ universal, admin, isAdminRole });
  useEffect(() => {
    //remove existing active-link class
    linkRefs.current?.map((ref) => ref?.classList.remove("active-link"));

    //add active-link to current route
    let link = navList.left?.find((link) => link.linkTo === location.pathname);
    if (!link)
      link = navList.right?.find((link) => link.linkTo === location.pathname);

    linkRefs.current.length &&
      link &&
      linkRefs.current[link?.pos]?.classList.add("active-link");
  }, [location.pathname, navList]);

  return (
    <div className="desktop-header">
      <div className="find-logo">
        <img
          alt="sploot-logo"
          src={process.env.PUBLIC_URL + "/assets/images/sploot-logo.webp"}
          onClick={isUserRole ? undefined : () => navigate("/")}
        />
      </div>
      <div className="nav">
        <ul className="nav-list">
          {navList.left?.map((navItem, i) =>
            isUserRole ? (
              navItem.inWebapp && (
                <List
                  navItem={navItem}
                  key={navItem.pos}
                  ref={linkRefs}
                  index={navItem.pos}
                />
              )
            ) : (
              <List
                navItem={navItem}
                key={navItem.pos}
                ref={linkRefs}
                index={navItem.pos}
              />
            )
          )}
          {!isAdminRole && navList.right.length > 0 && (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.5rem",
              }}
            >
              {" "}
              |{" "}
            </span>
          )}
          {navList.right?.map((navItem, i) => (
            <List
              navItem={navItem}
              key={navItem.pos}
              ref={linkRefs}
              index={navItem.pos}
            />
          ))}
        </ul>
      </div>

      {isAdminRole ? (
        <IconButton
          style={classes.logoutIcon}
          onClick={() => dispatch(logoutAdmin(navigate))}
        >
          <LogoutIcon />
        </IconButton>
      ) : null}

      {isUserRole ? (
        <div
          className="nav-user-info"
          onClick={() => navigate(`${baseProfilePath}/dashboard`)}
        >
          <img
            src={user.data.photoUrl || defaultUserImage}
            ref={userProfileImgRef}
            onError={() => (userProfileImgRef.current.src = defaultUserImage)}
            alt="user profile"
          />
          <span>{user.data?.name || "Guest"}</span>
        </div>
      ) : !isAdminRole ? (
        <div className="nav-user-actions">
          <Link to="/login" style={classes.loginBtn}>
            Login
          </Link>
          <Button
            text="SIGN UP"
            btnStyle={classes.signupBtn}
            clickAction={() => {
              navigate("/signup");
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

const List = forwardRef(({ navItem, index }, linkRefs) => (
  <li
    className="nav-link"
    ref={(el) => (linkRefs.current[index] = el)}
    key={index}
  >
    {navItem.external ? (
      navItem.follow ? (
        <a href={navItem.linkTo} style={classes.navItem}>
          {navItem.linkText}
        </a>
      ) : (
        <a href={navItem.linkTo} rel="nofollow" style={classes.navItem}>
          {navItem.linkText}
        </a>
      )
    ) : (
      <Link to={navItem.linkTo} style={classes.navItem}>
        {navItem.linkText}
      </Link>
    )}
  </li>
));
