export default function SaveIcon({ wrapperStyle = {}, style = {} }) {
  return (
    <div style={wrapperStyle}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <g clipPath="url(#clip0_434_1741)">
          <path
            d="M18.2339 3.0083H6.51611C6.17177 3.0083 5.89282 3.26404 5.89282 3.57973V18.4205C5.89282 18.6423 6.03299 18.844 6.25177 18.9381C6.47072 19.032 6.72948 19.0014 6.91521 18.8592L12.3748 14.6833L17.8343 18.8592C17.9486 18.9467 18.0903 18.992 18.2338 18.992C18.3234 18.992 18.4136 18.9741 18.4978 18.9381C18.7169 18.8442 18.8567 18.6423 18.8567 18.4205V3.57973C18.8571 3.26404 18.578 3.0083 18.2338 3.0083H18.2339ZM17.6106 17.1993L12.7744 13.5002C12.6587 13.4117 12.5168 13.3674 12.3749 13.3674C12.233 13.3674 12.0913 13.4115 11.9754 13.5002L7.13916 17.1993V4.15116H17.6104L17.6106 17.1993Z"
            fill="#121212"
          />
        </g>
        <defs>
          <clipPath id="clip0_434_1741">
            <rect
              width="13"
              height="16"
              fill="white"
              transform="translate(5.875 3)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
