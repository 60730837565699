import { memo } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import "./AppLoader.css";

const ModifiedLinearProgress = styled(LinearProgress)({
  "& .MuiLinearProgress-bar": {
    transition: "none",
  },
});

const AppLoader = () => {
  return (
    <div className="app-loader">
      <div
        style={{
          background: `url(${
            process.env.PUBLIC_URL + "/assets/images/sploot-logo.webp"
          }) no-repeat center center`,
          backgroundSize: "contain",
          width: "15rem",
          height: "8rem",
        }}
      ></div>
      <div style={{ color: "#EF7123" }}>
        <ModifiedLinearProgress style={{ width: "15rem" }} color="inherit" />
      </div>
    </div>
  );
};

export default memo(AppLoader);
