import React from "react";

function CarouselRightArrow({onclick}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
      style={{ cursor: "Pointer" }}
      onClick={onclick}
    >
      <path
        stroke="#586379"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M12.5 22.5c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
      ></path>
      <path
        stroke="#586379"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.24 16.03l3.52-3.53-3.52-3.53"
      ></path>
    </svg>
  );
}

export default CarouselRightArrow;
