import React from 'react'
import SalesBookPlanCard from '../SalesBookPlanCard/SalesBookPlanCard';
import SalesPlanDescriptionCard from '../SalesPlanDescriptionCard/SalesPlanDescriptionCard'
import "./SalesEditSubscriptionCard.css";
import ModalTitle from '../../common/ModalTitle/ModalTitle';

export default function SalesEditSubscriptionCard({
  subscription, extend = false, subscriptionId, extendModalClose = () => { },
  reloadSubscriptions = () => { }, onClose = () => { } }) {

    console.log(subscription)

  return (
    <>
      <ModalTitle title={subscription.trial ? "Trial Details" : "Plan Details" } onClose={onClose}/>
        {!subscription.trial &&
          <SalesPlanDescriptionCard plan={subscription.plan} amountPaid={subscription.amount} orderId={subscription?.orderId}/>
        }
        <SalesBookPlanCard
          extendModalClose={extendModalClose}
          subscriptionId={subscription.trial? subscription?.subscription?.id: subscription.id}
          orderId={subscription.orderId}
          userProductCreditId={subscription.trial ? subscription.id : subscription.userProductCreditId}
          session={subscription.seedSession}
          subscriptionEnd={subscription.end}
          subscribedUser={subscription.user}
          plan={subscription.plan}
          reloadSubscriptions={reloadSubscriptions}
          subscriptionStatus={subscription.status}
          noOfWalks={subscription.seedSession.noOfWalks}
          extend={extend} 
          isTrial={subscription.trial}/>
    </>
  )
}
